import {getCurrentImpactFigureType} from "../../utils/PassportImpactFigures";
import {useAppSelector} from "../../../app/hooks";
import {PassportInterface} from "../../interfaces/PassportInterface";

interface Props {
    passport: PassportInterface
}

export const DashboardConnectedPassportDisclaimer = (props: Props) => {

    const {impactFigureCalculationTypes} = useAppSelector(state => state.root.options);

    const currentImpactFigureType = getCurrentImpactFigureType(impactFigureCalculationTypes?.data, props.passport?.passportImpactFigureCalculationTypeId)
    const disclaimer = (props.passport?.impactFigureDetermination?.code === 'calculated' ? currentImpactFigureType?.disclaimer : props.passport?.impactFigureDetermination?.disclaimer)

    if (disclaimer) {
        return (
            <ul className={'c-disclaimers'}>
                <li><span dangerouslySetInnerHTML={{__html: disclaimer + ''}} /></li>
            </ul>
        )
    } else return null

}
