import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PassportGroupInterface} from "../../common/interfaces/PassportInterface";
import {apiOrganisationGroupDetail} from "../../common/api/organisation/group/apiOrganisationGroupDetail";

interface PassportGroupDetailInterface {
  passportGroup: PassportGroupInterface,
  loading: boolean,
  passportFilter: string
}

const initialState: PassportGroupDetailInterface = {
  passportGroup: {
    id: 0,
    name: '',
    description: '',
    passports: []
  },
  passportFilter: '',
  loading: true,
}

export const passportGroupDetail = createAsyncThunk(
  'passportGroup/detail',
  async (id: string) => {
    const response = await apiOrganisationGroupDetail(id)

    if (response && response.data && response.data.items && response.data.items.data) {
      return response.data.items.data
    }
  }
)

const passportGroupSlice = createSlice({
  name: 'passportGroup',
  initialState,
  reducers: {
    setFilterName: (state, action: PayloadAction<string>) => {
      state.passportFilter = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(passportGroupDetail.pending, (state) => {
      state.loading = true
    })

    builder.addCase(passportGroupDetail.fulfilled, (state, action: PayloadAction<any>) => {
      state.passportGroup = action.payload
      state.loading = false
    })

  }
})

export const {setFilterName} = passportGroupSlice.actions

export const passportGroupReducer = passportGroupSlice.reducer
