import {useAppAxios} from "../../../../app/hooks";
import {ApiEnum} from "../../../enums/ApiEnum";
import {DashboardPreferenceInterface} from "../../../interfaces/DashboardPreferenceInterface";

export const apiOrganisationUpdateDashboardPreference = async (dashboardPreference: DashboardPreferenceInterface) => {

    return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/update-dashboard-preference',
        {
            dashboardPreference: dashboardPreference
        }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}