import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect} from "react";
import {load} from "./cockpitSlice";
import {AssistantSteps} from "./components/Assistant";
import {InfoCards} from "./components/InfoCards";
import {SdgGoals} from "./components/SdgGoals";
import {CockpitWelcome} from "./components/CockpitWelcome";
import {CreatePassportItems} from "./components/CreatePassportItems";
import {GridLoader} from "react-spinners";

export const CockpitPageContainer = () => {

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(load())
  }, [])

  const {info} = useAppSelector((state) => state.cockpit)

  if (info) {

    return (
      <div className={'c-cockpit'}>
        <div className="c-app__content__container">
          <div className="c-dashboard">

            <CockpitWelcome/>

            <section className="c-dashboard__row">
              <div className="c-dashboard__group c-dashboard__group--space-evenly">

                <CreatePassportItems/>
                <AssistantSteps/>


              </div>
            </section>

            <SdgGoals/>

            <section className="c-dashboard__row">
              <div className="c-dashboard__group c-dashboard__group--space-evenly">

                <InfoCards/>

                {!!info?.info.text &&
                <div
                    className="c-dashboard__card c-dashboard__card--fill background-color-blocks c-cockpit__passport-intro">
                                <span className="c-cockpit__passport-intro__disclaimer"><img
                                    src="/images/icons/info-red.svg"
                                    alt="disclaimer"/><span>{info?.info.title}</span></span>
                    <div dangerouslySetInnerHTML={{__html: info?.info.text}}/>
                </div>
                }
              </div>
            </section>

          </div>
        </div>
      </div>
    )
  } else return (
    <div className={'c-loader'}>
      <GridLoader color={'#E14343'}/>
    </div>
  )

}
