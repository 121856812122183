import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {deleteDashboard, setTrashDashboard} from "./trashDashboardModalSlice";


export const TrashDashboardModalContainer = () => {

    const dispatch = useAppDispatch()
    const {dashboard, submitting} = useAppSelector(state => state.trashDashboardModal)

    return (
        <div className="c-modal" style={{display: 'flex', opacity: 1, pointerEvents: 'all'}}>
            <div className="c-modal__background"/>
            <div className="c-modal__content">
                <button className="c-modal__close" onClick={() => {
                    dispatch(setTrashDashboard({
                        showPopup: false
                    }))
                }}>
                    <img src="/images/icons/cross-black.svg" alt=""/>
                </button>
                <header className="c-modal__header">
                    <h1>Deleting dashboard - {dashboard?.title}</h1>
                </header>
                <div className="c-modal__body">

                    <p>
                        Are you sure you would like to delete this C_dashboard<sup>®</sup>? This action can't be undone.
                    </p>

                    <button className="btn btn--rounded btn--red" disabled={submitting}
                            onClick={() => dispatch(deleteDashboard())}>
                        <span>Confirm deletion</span>
                    </button>

                </div>
            </div>
        </div>
    )
}