import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiCockpitInfoWebsite} from "../../common/api/cockpit/apiCockpitInfoWebsite";
import {apiCockpitInfo} from "../../common/api/cockpit/apiCockpitInfo";
import {SdgGoalInterface} from "../../common/interfaces/SdgGoalInterface";


interface cockpitInterface {
    info?: {
        hasPassport: boolean,
        hasDashboard: boolean,
        hasStyle: boolean,
        passportTotals: {
            endproduct: number,
            product: number,
            component: number
        },
        info: {
            title: string,
            text: string
        },
        assistant: {
            videoPassportUrl: string,
            videoDashboardUrl: string
        },
        sdgGoals: SdgGoalInterface[],
    },
    websiteInfo?: [],
    assistantStep: number,
    currentStep: number
}


const initialState: cockpitInterface = {
    assistantStep: 0,
    currentStep: 0
};

export const load = createAsyncThunk(
    'cockpit/load',
    async (_) => {

        const responseInfo = await apiCockpitInfo()
        const responseInfoWebsite = await apiCockpitInfoWebsite()

        if (responseInfo) {
            return {
                info: responseInfo.data.items,
                websiteInfo: responseInfoWebsite?.data?.data
            }
        }
    }
)

const cockpitSlice = createSlice({
    name: 'cockpit',
    initialState,
    reducers: {
        previousStep: (state) => {
            state.assistantStep = state.assistantStep - 1
        },
        nextStep: (state) => {
            state.assistantStep = state.assistantStep + 1
        },
        setStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload
            state.assistantStep = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, action: PayloadAction<any>) => {
            state.info = action.payload?.info
            state.websiteInfo = action.payload?.websiteInfo
        })
    }
})

export const {
    previousStep, nextStep, setStep
} = cockpitSlice.actions

export const cockpitReducer = cockpitSlice.reducer
