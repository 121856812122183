import {ActivateAccountForm} from "./components/ActivateAccountForm";
import {ActivateAccountWrapper} from "./components/ActivateAccountWrapper";

const ActivateAccountPageContainer = () => {


    return (
        <ActivateAccountWrapper>
            <ActivateAccountForm/>
        </ActivateAccountWrapper>
    )

}

export default ActivateAccountPageContainer