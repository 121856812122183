export const SignupResult = () => {

    return (
        <div className="c-app__info-card">
            <div className="c-app__info-card__intro">
                <h2>Registration completed!</h2>
                <p>Your registration is completed. Please check your email to activate your account and login.</p>
            </div>
        </div>
    )

}

