import {CompositionInterface} from "../interfaces/PassportInterface";
import {useAppSelector} from "../../app/hooks";

export const FormatDominantMaterialGroupsToString = (compositions: CompositionInterface[]) => {

    const {materialGroup} = useAppSelector(state => state.root.options);
    let dominantMaterialString: string = ''

    compositions?.forEach((composition) => {

        const materialGroupItem = materialGroup?.data?.find((materialGroupItem) => materialGroupItem.id === composition.materialGroupId)

        if (materialGroupItem) {
            dominantMaterialString += materialGroupItem.name + ', '
        }

    })

    return dominantMaterialString.replace(/,\s*$/, "");
}