export const formatCompositionPercentageDecimals = (compositionPercentage?: string) => {

    if (compositionPercentage && compositionPercentage !== 'null') {

        const decimalNumber = (compositionPercentage + "").split(".")[1]
        let formattedCompositionPercentage = parseFloat(compositionPercentage)
        let fixedNumber = 2

        if (decimalNumber && decimalNumber.slice(0, 2) === '00') {
            fixedNumber = Math.floor(Math.abs(Math.log10(formattedCompositionPercentage))) + 1
        }

        return formattedCompositionPercentage.toFixed(fixedNumber) + '%'

    } else {
        return 0 + '%'
    }


}