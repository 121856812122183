import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setView} from "../DashboardSettingsSlice";

export const DashboardSettingsNavigation = () => {

    const {activeView} = useAppSelector(state => state.dashboardSettings)
    const dispatch = useAppDispatch()
    const navigationItems = [
        {label: 'Public dashboards', value: 'public'},
    ] as { label: string, value: 'public' | 'styling' }[]

    return (
        <div className="c-app__tab">
            {navigationItems.map((navigationItem) => {

                return (
                    <a
                        onClick={() => dispatch(setView(navigationItem.value))}
                        className={(activeView === navigationItem.value ? 'isActive' : '')}>
                        {navigationItem.label}
                    </a>
                )

            })}
        </div>
    )

}