import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setUserData, submit} from "../activateAccountSlice";
import {ClipLoader} from "react-spinners";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {RouteEnum} from "../../../common/enums/RouteEnum";

interface ActivateAccountParams {
    token: string
}

export const ActivateAccountForm = () => {

    const {user, submitting, submitStatus} = useAppSelector(state => state.activateAccount);
    const dispatch = useAppDispatch()
    let {token} = useParams<ActivateAccountParams>();
    const history = useHistory()

    useEffect(() => {
            if (submitStatus === 200) {
                history.push(RouteEnum.LOGIN)
            }
        }, [submitStatus, history]
    );

    return (

        <div className="c-auth__primary__container">


            <h1 className="c-auth__primary__title">Activate account</h1>

            <p>Create a new password to activate your account</p>

            <form
                onSubmit={(e) => {
                    e.preventDefault();

                    if (user.password === user.passwordRepeat) {
                        dispatch(submit(token))
                    }
                }}
                className="c-auth__form c-form c-form--black">

                <div className="c-form__row">
                    <div className="c-form__group">

                        <label htmlFor="">Password</label>

                        <input type="password"
                               value={user.password}
                               required={true}
                               minLength={8}
                               onChange={(e) => dispatch(setUserData({password: e.target.value}))}
                        />

                    </div>
                </div>

                <div className="c-form__row">
                    <div className="c-form__group">

                        <label htmlFor="">Repeat password</label>

                        <input type="password"
                               required={true}
                               value={user.passwordRepeat}
                               minLength={8}
                               onChange={(e) => dispatch(setUserData({passwordRepeat: e.target.value}))}
                        />

                    </div>
                </div>


                {user.password !== user.passwordRepeat && <p>The passwords aren't identical</p>}

                {(submitStatus === 401 || submitStatus === 404) &&
                <div className={'c-form__row'}>
                    <div className={'c-notification c-notification--red'} style={{marginTop: '1rem'}}>
                        <p style={{textAlign: 'center'}}>The user can't be found</p>
                    </div>
                </div>
                }

                <div className="c-form__row">
                    <div className="c-form__group">
                        <button disabled={submitting || user.password !== user.passwordRepeat} style={{display: 'flex'}}
                                className="btn btn--rounded btn--large btn--red">
                            <span style={{margin: 0}}>Activate account</span> {submitting &&
                        <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}
                        </button>
                    </div>
                </div>


            </form>
        </div>
    )
}