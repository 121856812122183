import {AccountWrapper} from "../../wrappers/AccountWrapper/AccountWrapper";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {load} from "./AccountOrganisationSlice";
import {GridLoader} from "react-spinners";
import {FreeTrialBanner} from "../../common/components/general/FreeTrialBanner";

const AccountOrganisationPageContainer = () => {

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
            setLoading(true)
            dispatch(load()).then(() => {
                setLoading(false)
            })
        }, [dispatch]
    )

    const {addressTypes, countries} = useAppSelector(state => state.root.options);
    const {addresses} = useAppSelector(state => state.organisation);
    const {organisation} = useAppSelector(state => state.root);


    return (
        <AccountWrapper>

            {!loading ? <>

                    <div className="c-app__info-list">
                        <div className="c-app__info-list__single">
                            <h2>General information</h2>
                            <div>
                                <span>Company name</span>
                                <span>{organisation?.companyName}</span>
                            </div>
                            <div>
                                <span>COC. nr.</span>
                                <span>{organisation?.cocNumber}</span>
                            </div>
                            <div>
                                <span>VAT number</span>
                                <span>{organisation?.vatNumber}</span>
                            </div>
                            <div>
                                <span>Website</span>
                                <span>{organisation?.website}</span>
                            </div>
                            <div>
                                <span>Invoice email</span>
                                <span>{organisation?.invoiceEmail}</span>
                            </div>
                            <div>
                                <span>Phone</span>
                                <span>{organisation?.phoneNumber}</span>
                            </div>
                            <div>
                                <span>Purchase order nr.</span>
                                <span>{organisation?.purchaseOrderNr}</span>
                            </div>
                        </div>
                        <div className="c-app__info-list__single">
                            <h2>Contact person</h2>
                            <div>
                                <span>Name</span>
                                <span>{organisation?.contactPersonName}</span>
                            </div>
                            <div>
                                <span>Email address</span>
                                <span>{organisation?.contactPersonEmail}</span>
                            </div>
                        </div>
                    </div>

                    {addresses &&
                    <div className="c-app__info-block">
                        <h2>Addresses</h2>
                        {addresses.map((address) => {

                            const country = countries?.data?.find((country) => {
                                return country.id === address.countryId
                            })

                            const addressType = addressTypes?.data?.find((addressType) => {
                                return addressType.id === address.typeId
                            })

                            return (
                                <div className="c-app__info-block__single" key={'address: ' + address.id}>
                                    <span><strong>{addressType?.name}</strong></span>
                                    <span>{address.street} {address.number}{address.numberAddition} {address.postalCode}, {address.city} {address.state} {country?.nameEng}</span>
                                </div>
                            )
                        })
                        }

                    </div>
                    }
                </> :
                <div className={'c-loader'}>
                    <GridLoader color={'#E14343'}/>
                </div>
            }

            <FreeTrialBanner text={'Enjoying Cirmar and want to create more passports and dashboards? Complete your Cirmar account today!'} />

        </AccountWrapper>
    )

}

export default AccountOrganisationPageContainer
