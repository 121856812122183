export const Loader = () => {


    return(
        <div className={'c-loader c-loader--full'}>
            <img alt={'logo'} src={'/images/CIRMAR-logo.svg'} />
        </div>
    )


}