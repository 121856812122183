import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiMeasurementAmountTypes = async () => {
    return await useAppAxios.post(ApiEnum.MEASUREMENT + '/amount-types', {}, {
        params: {
            limit: 9999
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        //dispatch(setAuthApiError(error.response.status))
        return error
    })
}
