import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setDuplicatePassportGroup, duplicatePassportGroup} from "./duplicatePassportGroupModalSlice";


export const DuplicatePassportGroupModalContainer = () => {

    const dispatch = useAppDispatch()
    const {group, submitting} = useAppSelector(state => state.duplicatePassportGroupModal)

    return (
        <div className="c-modal" style={{display: 'flex', opacity: 1, pointerEvents: 'all'}}>
            <div className="c-modal__background"/>
            <div className="c-modal__content">
                <button className="c-modal__close" onClick={() => {
                    dispatch(setDuplicatePassportGroup({
                        showPopup: false
                    }))
                }}>
                    <img src="/images/icons/cross-black.svg" alt=""/>
                </button>
                <header className="c-modal__header">
                    <h1>Duplicating Group - {group?.name}</h1>
                </header>
                <div className="c-modal__body">

                    <p>
                        You are about to duplicate this group.
                    </p>

                    <button className="btn btn--rounded btn--red" disabled={submitting} onClick={() => dispatch(duplicatePassportGroup())}>
                        <span>Confirm duplication</span>
                    </button>

                </div>
            </div>
        </div>
    )

}
