import FormInput from "../../common/components/form/FormInput";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    passportList,
    setCreateDashboardData,
    setPassports,
    setPassportData,
    submit,
    load, reset
} from "./dashboardCreateSlice";
import FormFile from "../../common/components/form/FormFile";
import FormTextArea from "../../common/components/form/FormTextArea";
import Select from "react-select";
import {useEffect, useState} from "react";
import {Link, useParams} from 'react-router-dom'
import {RouteEnum} from "../../common/enums/RouteEnum";
import {ClipLoader, GridLoader} from "react-spinners";
import ReactQuill from 'react-quill'; // Typescript
import 'react-quill/dist/quill.snow.css';


interface DashboardEditParams {
    uuid: string;
}

export const DashboardCreatePageContainer = () => {

    const dispatch = useAppDispatch()
    const {uuid} = useParams<DashboardEditParams>();

    const [loading, setLoading] = useState(true)

    const {dashboard, passports, submitted, submitting} = useAppSelector(root => root.dashboardCreate)
    const {options, activeLanguage} = useAppSelector(state => state.root);
    const {passportTypes} = options;
    const buttonText = dashboard.id ? 'Update' : 'Create'
    const navigationText = dashboard.id ? 'Edit' : 'Create'

    const publicTypes = [
        {
            value: 1,
            label: 'Public'
        },
        {
            value: 0,
            label: 'Private'
        }
    ]

    const passportOptions = passports?.data.filter((passportItem) => {
        return passportItem.typeId === 2 || passportItem.typeId === 3
    }).map((passport) => {
        const passportType = passportTypes?.data?.find((passportType) => passportType.id === passport.typeId)
        return {
            value: Number(passport.id),
            label: passport.name + ' (' + passportType?.name + ')'
        }
    });

    useEffect(() => {

          dispatch(reset())

          dispatch(passportList()).then(() => {
              if (uuid) {
                  dispatch(load(uuid)).then(() => {
                      setLoading(false)
                  })
              } else {
                  setLoading(false)
              }
          })
      }, [dispatch, uuid, activeLanguage]
    );

    const activeOptions = dashboard.passports?.map((dashboardPassport) => {

        const passport = passports?.data.find((passport) => passport.id === dashboardPassport.passportId)
        const passportType = passportTypes?.data?.find((passportType) => passportType.id === passport?.typeId)

        return {
            value: Number(passport?.id),
            label: passport?.name + ' (' + passportType?.name + ')'
        }

    });

    return (

      <main className="c-app__content">
          <>{!loading ?

            <form onSubmit={(e) => {
                e.preventDefault();
                dispatch(submit())
            }}>
                <header className="c-app__content__header">
                    <nav className="c-breadcrumbs">
                        <ul className="u-reset-ul">
                            <li>
                                <Link to={RouteEnum.DASHBOARD_OVERVIEW}>C_dashboard<sup>®</sup></Link>
                                <img src="/images/icons/arrow-right-black.svg" alt={'Arrow right black'}/>
                            </li>
                            <li>
                                <button>{navigationText}</button>
                                <img src="/images/icons/arrow-right-black.svg" alt={'Arrow right black'}/>
                            </li>
                        </ul>
                    </nav>
                    <div>

                        <Link to={RouteEnum.DASHBOARD_OVERVIEW} className="btn btn--small btn--text">
                            <span>Cancel</span>
                        </Link>

                        {dashboard.uuid &&
                        <Link to={RouteEnum.DASHBOARD_DETAIL + '/' + dashboard.uuid}
                              className="btn btn--small btn--red btn--rounded">
                            <span>View dashboard</span>
                        </Link>
                        }


                        {!submitted &&
                        <button style={{marginLeft: '1rem'}} type={'submit'}
                                className="btn btn--small btn--black btn--rounded">
                            <span style={{margin: 0}}>{buttonText}</span>
                            {submitting &&
                            <div style={{marginLeft: '1rem'}}><ClipLoader size={18} color={'#ffffff'}/></div>}
                        </button>
                        }


                    </div>
                </header>
                <div className="c-app__content__container">
                    <div className="c-app__view">

                        {options.dashboardTypes?.data?.length &&

                        <div className="c-app__info-card">
                            <div className="c-app__info-card__intro" style={{margin: 0}}>
                                <h1>Type of dashboard</h1>
                                <div className="c-form c-form--black">
                                    <div className="c-form__row c-form__row--radio c-form__row--half">
                                        {options.dashboardTypes?.data.map((type) => {
                                            return (
                                              <div className="c-form__group">
                                                  <label htmlFor="">
                                                      <input
                                                        type="radio"
                                                        required={true}
                                                        value={type.id}
                                                        name={'dashboardType'}
                                                        checked={dashboard.typeId === type.id}
                                                      />
                                                      <span>{type.name}</span>
                                                  </label>
                                              </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>

                        }


                        <div className="c-app__info-card">
                            <div className="c-app__info-card__intro" style={{margin: 0}}><h1>Sharing options</h1>
                                <div className="c-form c-form--black">
                                    <div className="c-form__row c-form__row--radio c-form__row--half">

                                        {publicTypes.map((publicType) => {

                                            return (
                                              <div className="c-form__group">
                                                  <label onClick={() => {
                                                      dispatch(setCreateDashboardData({
                                                          isPublic: publicType.value
                                                      }))
                                                  }}>
                                                      <input
                                                        required={true}
                                                        value={publicType.value}
                                                        name={'publicType'}
                                                        type="radio"
                                                        checked={dashboard.isPublic === publicType.value}

                                                      />
                                                      <span>{publicType.label}</span>
                                                  </label>
                                              </div>
                                            )

                                        })}


                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="c-app__info-card">
                            <div className="c-app__info-card__intro">
                                <h2>C_passport<sup>®</sup> included in C_dashboard<sup>®</sup></h2>
                                <p>
                                    Only product and end product passports can be added to dashboards.
                                </p>

                                <Select
                                  isMulti={true}
                                  options={passportOptions}
                                  value={activeOptions}
                                  onChange={(passportOptions) => dispatch(
                                    setPassports(passportOptions.map((option) => (option.value)))
                                  )}
                                />

                            </div>
                        </div>

                        {!!dashboard.passports?.length &&
                        <div className="c-app__info-card c-app__info-card--connected-passports">
                            <div className="c-app__info-card__intro" style={{margin: 0}}><h1>Number of
                                C_passports<sup>®</sup></h1>
                                <p>Change these numbers to see the difference in impact</p>
                                <div className="c-form c-form--black">

                                    <div className="c-form__row c-form__row--half">

                                        {dashboard.passports.map((dashboardPassport) => {

                                            const passport = passports?.data.find((passport) => passport.id === dashboardPassport.passportId)
                                            const passportType = passportTypes?.data?.find((passportType) => passportType.id === passport?.typeId)

                                            if (passport) {
                                                return (
                                                  <div className="c-form__group">
                                                      <label
                                                        htmlFor="">{passport.name} ({passportType?.name})</label>
                                                      <input
                                                        type="number"
                                                        onChange={(e) => {
                                                            dispatch(setPassportData({
                                                                passportId: passport.id,
                                                                amount: e.target.value
                                                            }))
                                                        }}
                                                        value={dashboardPassport.amount}
                                                      />
                                                  </div>
                                                )
                                            } else return null
                                        })}

                                    </div>

                                </div>
                            </div>
                        </div>
                        }

                        <div className="c-app__info-card-wrap">
                            <div className="c-app__info-card c-app__info-card--60"
                                 style={{flex: 1, width: '100%', marginRight: 0}}>

                                <div className="c-app__info-card__intro">
                                    <h2>C_dashboard<sup>®</sup> input</h2>
                                </div>

                                <div className="c-form c-form--black">
                                    <div className="c-form__row">
                                        <FormInput
                                          onChange={(value) => {
                                              dispatch(setCreateDashboardData({
                                                  title: value + ''
                                              }))
                                          }}
                                          label={'Title'}
                                          required={true}
                                          type={'text'}
                                          value={dashboard.title}
                                        />
                                    </div>
                                    <div className="c-form__row">

                                        <FormTextArea
                                          onChange={(value) => {
                                              dispatch(setCreateDashboardData({
                                                  introduction: value + ''
                                              }))
                                          }}
                                          label={'Description'}
                                          value={dashboard.introduction}
                                        />

                                    </div>
                                    <div className="c-form__row">

                                        <FormFile
                                          defaultLabel={true}
                                          accept={'.jpg, .jpeg, .png'}
                                          onChange={(value) => dispatch(setCreateDashboardData({imageFile: value}))}
                                          value={dashboard.imageFile}
                                          currentPath={dashboard.image}
                                          label={'Image'}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="c-app__info-card-wrap">
                            <div className="c-app__info-card c-app__info-card--60"
                                 style={{margin: 0, flex: 1, width: '100%'}}>

                                <div className="c-app__info-card__intro">
                                    <h2>Extra information</h2>
                                </div>

                                <div className="c-form c-form--black">

                                    <div className="c-form__row" style={{display: 'block'}}>

                                        <ReactQuill
                                          onChange={(value) => {
                                              dispatch(setCreateDashboardData({
                                                  extraInformation: value + ''
                                              }))
                                          }}
                                          value={dashboard.extraInformation}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            :
            <div className={'c-loader'}>
                <GridLoader color={'#E14343'}/>
            </div>

          }</>

      </main>
    )

}
