import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {UserInterface} from "../../../interfaces/UserInterface";

export const apiOrganisationUserLogin = async ( user: UserInterface) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_USER + '/login', {
        email: user.email,
        password: user.password
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
