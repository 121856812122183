import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import Select from "react-select";
import {GridLoader} from "react-spinners";
import {useEffect, useRef} from "react";
import {
    passportSelectList,
    setFilterName,
    addPassport,
    removePassport,
    removeAllPassports
} from "../passportGroupCreateSlice";
import ReactPaginate from "react-paginate";
import {setPage} from "../passportGroupCreateSlice";

const PassportGroupCreateInformationSelect = () => {
    const mountedRef = useRef() as { current: boolean }
    const dispatch = useAppDispatch()
    const {passportGroup, passports, settings, loading, allPassports} = useAppSelector(state => state.passportGroupCreate);
    let selectedPassports = passportGroup?.passports


    useEffect(() => {
            if (mountedRef.current) {
                const timeout = setTimeout(() => {
                    dispatch(passportSelectList())
                }, 600);
                return () => {
                    clearTimeout(timeout);
                }
            }
        }, [settings.filters.name, dispatch]
    );

    useEffect(() => {
            dispatch(passportSelectList())
            mountedRef.current = true
        }, [settings.page, dispatch]
    );

    function handlePageClick(data: any) {
        dispatch(setPage(data.selected + 1))
    }

    const activeOptions = selectedPassports?.map((selectedPassport) => {
        const passport = allPassports?.data.find((passport) => passport.id === selectedPassport)
        return {
            value: Number(passport?.id),
            label: passport?.name
        }
    });

    const onChange = (_newValue: any, actionMeta: any) => {
        if (actionMeta.action === 'clear') {
            dispatch(removeAllPassports())
        }

        if (actionMeta.action === 'remove-value') {
            const removedPassport = actionMeta.removedValue
            dispatch(removePassport(removedPassport.value))
        }
    };

    if (passports && passports.data) {

        let pages: any = passports.pages
        let total: any = passports.total

        let current = settings.page * settings.limit

        if (total && (current > total)) {
            current = total
        }

        if (passports?.data && !loading) {

            return (
                <>
                    <div className="c-app__passport-group__content">
                        <h4>Added C_passports<sup>®</sup> ({selectedPassports ? selectedPassports.length : 0})</h4>

                        <Select
                            className="passport-select"
                            isMulti={true}
                            value={activeOptions}
                            placeholder={'C_passports®'}
                            isSearchable={false}
                            onChange={onChange}
                        />
                    </div>


                    <form className="c-app__filter c-form--black c-app__filter--passport-group"
                          onSubmit={(e) => e.preventDefault()}>
                        <div className="c-form__row">
                            <h2>Add end product C_passports<sup>®</sup></h2>
                            <div className="c-form__group c-form__group--horizontal">
                                <input
                                    value={settings.filters.name}
                                    onChange={(e) => {
                                        dispatch(setFilterName(e.target.value))
                                    }}
                                    type="text" placeholder="Search passports"/>
                            </div>

                        </div>

                    </form>

                    <div className="c-card" style={{minHeight: '30rem'}}>

                        {passports?.data.map((passport) => {

                            let cardClass = 'c-card__single c-card__single--small'
                            if (selectedPassports?.includes(passport.id ? passport.id : 0)) {
                                cardClass += ' c-card__single--inActive'
                            }

                            return (
                                <div className={cardClass} key={'group:' + passport.id}>

                                    <div className="c-card__single__info">
                                        <div className="c-card__single__info__text">
                                            <h3>{passport.name}</h3>
                                        </div>
                                    </div>

                                    {!!passport.id &&
                                    <div className="c-card__single__cta">
                                        <div className="c-card__single__cta__urls">
                                            <div>

                                                <button className="btn btn--rounded btn--small btn--red"
                                                        onClick={() => {
                                                            if (!selectedPassports?.includes(passport.id ? passport.id : 0)) {
                                                                dispatch(addPassport(passport.id ? passport.id : 0))
                                                            }
                                                        }}>
                                                    <span>Add to group</span>
                                                </button>

                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            )
                        })}
                    </div>

                    {!!(pages && pages.total && pages.total > 1) &&
                    <div className="c-app__pagination">
                        <p>{current} - {total} total</p>
                        <nav className={'c-pagination'}>
                            <ReactPaginate
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={Number(pages.total)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={'pagination'}
                                forcePage={Number(pages.current)}
                                activeClassName={'isActive'}
                            />
                        </nav>
                    </div>
                    }

                </>
            )
        } else {
            return (
                <div className={'c-loader'}>
                    <GridLoader color={'#E14343'}/>
                </div>
            )
        }

    } else return null
}

export default PassportGroupCreateInformationSelect
