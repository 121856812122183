import {ForgotPasswordResetForm} from "./components/ForgotPasswordResetForm";
import {ForgotPasswordResetWrapper} from "./components/ForgotPasswordResetWrapper";

const ForgotPasswordResetPageContainer = () => {


    return (
        <ForgotPasswordResetWrapper>
            <ForgotPasswordResetForm/>
        </ForgotPasswordResetWrapper>
    )

}

export default ForgotPasswordResetPageContainer