import {RouteEnum} from "../../common/enums/RouteEnum";
import {Link} from 'react-router-dom'
import {useEffect, useState} from "react";
import {dashboardList, setPage} from "./dashboardOverviewSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {GridLoader} from "react-spinners";
import ReactPaginate from "react-paginate";
import {displayModuleByUserRole} from "../../common/utils/displayModuleByUserRole";
import {setTrashDashboard} from "../../modals/trashDashboardModal/trashDashboardModalSlice";

export const DasbhoardOverviewPageContainer = () => {

    const dispatch = useAppDispatch();

    function handlePageClick(data: any) {
        dispatch(setPage(data.selected + 1))
    }

    const {userMe} = useAppSelector((state) => state.root)
    const {dashboard} = useAppSelector((state) => state.trashDashboardModal)
    const [initialLoading, setInitialLoading] = useState(true)
    const {dashboardContainers, loading, settings} = useAppSelector((state) => state.dashboardOverview)


    useEffect(() => {
            dispatch(dashboardList())
        }, [dispatch, settings]
    );

    useEffect(() => {
            if (!dashboard) {
                dispatch(dashboardList()).then(() => {
                    setInitialLoading(false)
                })
            } else setInitialLoading(false)
        }, [dispatch, dashboard]
    );

    let current = settings.page * settings.limit

    if (!loading && !initialLoading) {

        if (dashboardContainers) {

            const {total, pages} = dashboardContainers

            if (total && (current > total)) {
                current = total
            }

            return (
                <>
                    <header className="c-app__content__header">
                        <nav className="c-breadcrumbs">
                            <ul className="u-reset-ul">
                                <li>
                                    <Link to={RouteEnum.DASHBOARD_OVERVIEW}>C_dashboard<sup>®</sup></Link>
                                    <img src="images/icons/arrow-right-black.svg" alt=""/>
                                </li>
                                <li>
                                    <Link to={RouteEnum.DASHBOARD_OVERVIEW}>Overview</Link>
                                    <img src="images/icons/arrow-right-black.svg" alt=""/>
                                </li>
                            </ul>
                        </nav>

                        {displayModuleByUserRole('dashboardEdit', userMe) &&
                            <div>

                                {displayModuleByUserRole('settings', userMe) &&
                                    <Link to={RouteEnum.DASHBOARD_SETTINGS}
                                          className="btn btn--small btn--outline btn--rounded">
                                        <span>Settings</span>
                                    </Link>
                                }

                                <Link to={RouteEnum.DASHBOARD_CREATE}
                                      className="btn btn--small btn--black btn--rounded">
                                    <span>Create C_dashboard<sup>®</sup></span>
                                </Link>

                            </div>
                        }
                    </header>

                    <div className="c-app__content__container">
                        <div className="c-app__view">
                            <div className="c-app__view__row">
                                <header className="c-app__view__header">
                                    <h1>C_dashboard<sup>®</sup> overview ({total})</h1>
                                </header>
                            </div>

                            <div className="c-card">

                                {!!dashboardContainers?.data && dashboardContainers?.data.map((dashboardContainer) => {

                                    if (dashboardContainer?.dashboard) {
                                        return (
                                            <div className="c-card__single c-card__single--small">
                                                <div className="c-card__single__info">
                                                    <div className="c-card__single__info__text">
                                                        {!!dashboardContainer?.dashboard?.title &&
                                                            <h3>{dashboardContainer.dashboard.title}</h3>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="c-card__single__cta">
                                                    <div className="c-card__single__cta__urls">
                                                        <div>
                                                            <Link
                                                                to={RouteEnum.DASHBOARD_DETAIL + '/' + dashboardContainer.dashboard.uuid}
                                                                className="btn btn--text"><span>View</span>
                                                            </Link>

                                                            {displayModuleByUserRole('dashboardEdit', userMe) &&
                                                                <Link
                                                                    to={RouteEnum.DASHBOARD_EDIT + '/' + dashboardContainer.dashboard.uuid}
                                                                    className="btn btn--red btn--circle">
                                                                    <img src="/images/icons/edit-white.svg"
                                                                         alt=""/></Link>
                                                            }

                                                            {displayModuleByUserRole('dashboardEdit', userMe) &&
                                                                <button className="btn btn--black btn--circle"
                                                                        style={{marginLeft: '.5rem'}}
                                                                        onClick={() => {
                                                                            dispatch(setTrashDashboard({
                                                                                showPopup: true,
                                                                                dashboard: dashboardContainer.dashboard
                                                                            }))
                                                                        }}
                                                                >
                                                                    <img src="/images/icons/trash.svg"
                                                                         alt={'trash icon'}
                                                                         style={{height: '2rem'}}/>
                                                                </button>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}

                                {!!(pages && pages.total && pages.total > 1) &&
                                    <div className="c-app__pagination">
                                        <p>{current} - {total} total</p>
                                        <nav className={'c-pagination'}>
                                            <ReactPaginate
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={Number(pages.total)}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={handlePageClick}
                                                containerClassName={'pagination'}
                                                forcePage={Number(pages.current)}
                                                activeClassName={'isActive'}
                                            />
                                        </nav>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                </>
            )
        } else return null
    } else return (
        <div className={'c-loader'}>
            <GridLoader color={'#E14343'}/>
        </div>
    )

}
