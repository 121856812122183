import {CompositionInterface} from "../../../common/interfaces/PassportInterface";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setCompositionData, setCompositionMaterials} from "../passportCreateSlice";
import FormInput from "../../../common/components/form/FormInput";
import Select from "react-select";
import {PassportCreateMaterialItem} from "./PassportCreateMaterialItem";
import {HowToCloud} from "../../../common/components/howTo/HowToCloud";


export const PassportCreateMaterialComposition = (props: CompositionInterface) => {

    const {materialGroup, materials} = useAppSelector(state => state.root.options);
    const activeMaterialGroup = materialGroup?.data?.find((materialGroup) => materialGroup.id === props.materialGroupId)

    const options = materials?.data?.filter((material) => material.materialGroupId === activeMaterialGroup?.id).map((materialItem: any) => ({
        value: materialItem.id,
        label: materialItem.name,
        searchValue: materialItem.code + ' ' + materialItem.name
    }));
    
    const activeOptions = options && options.filter((option) => props.materials && props.materials.find((materialGroup) => materialGroup.materialId === option.value))

    const dispatch = useAppDispatch()

    if (activeMaterialGroup) {
        return (
            <div className="c-tab__content">
                <div className="c-tab__content__primary">
                    <header>

                        <h3>{activeMaterialGroup.name}</h3>

                        <FormInput
                            howToCode={'pp-percentage-of-product'}
                            onChange={(value) => {
                                dispatch(setCompositionData({
                                    materialGroupId: activeMaterialGroup.id,
                                    compositionPercentage: value + ''
                                }))
                            }}
                            label={'Percentage of product'}
                            extraClass={'c-tab__percentage'}
                            type={'number'}
                            value={props.compositionPercentage}
                            smallHtml={'%'}
                        />

                    </header>

                    {props.percentageWarning &&
                    <div className="c-notification c-notification--red" style={{margin: '2rem 0'}}>
                        <p>Total percentage cannot be higher than 100%</p>
                    </div>
                    }

                    <div className="c-form__row">
                        <div className="c-form__group">
                            <label htmlFor="">Material <HowToCloud code={'pp-comp-material'} /></label>
                            <Select
                                isMulti={true}
                                value={activeOptions}
                                options={options}
                                isSearchable={true}
                                getOptionValue ={(option)=>option.searchValue}
                                onChange={(options) => dispatch(
                                    setCompositionMaterials({
                                        materialGroupId: activeMaterialGroup.id,
                                        materialIds: options.map((option) => (option.value))
                                    })
                                )}
                            />
                        </div>
                    </div>
                </div>
                {props.materials && props.materials.map((material) => {
                    return (<PassportCreateMaterialItem material={material} materialGroupId={activeMaterialGroup.id}/>)
                })}

            </div>
        )
    } else return null

}