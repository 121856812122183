import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useParams} from "react-router-dom";

import {PassportInfo} from "../../common/components/passport/PassportInfo";
import {PassportSdgGoals} from "../../common/components/passport/PassportSdgGoals";
import {PublicPassportQrCode} from "../../common/components/passport/PublicPassportQrCode";
import {PassportTabNav} from "../../common/components/passport/PassportTabNav";
import {GridLoader} from "react-spinners";
import {PassportMaterials} from "../../common/components/passport/PassportMaterials";
import {PassportEncodings} from "../../common/components/passport/PassportEncodings";
import {PassportExtraInformation} from "../../common/components/passport/PassportExtraInformation";
import {PassportTabEnums} from "../../common/enums/PassportTabEnums";
import {PassportPoweredBy} from "../../common/components/passport/PassportPoweredBy";
import {PassportInterested} from "../../common/components/passport/PassportInterested";
import {connect, getTranslationList} from "../../app/rootReducer";
import {calculateImpact, changePublicTab, load, loadPreference, loadStyle} from "./PublicPassportSlice";
import {FormatCompositionToPieData} from "../../common/utils/FormatCompositionToPieData";
import {PassportImpact} from "../../common/components/passport/PassportImpact";
import {PassportWebsite} from "../../common/components/passport/PassportWebsite";
import {PublicStyle} from "../../common/components/general/PublicStyle";
import {LanguagePicker} from "../../common/components/general/LanguagePicker";
import {getUuid} from "../passportDetailPage/PassportDetailSlice";
import {PassportLabels} from "../../common/components/passport/PassportLabels";

interface PassportParams {
    uuid: string;
}

const PublicPassportPageContainer = () => {

    const {uuid} = useParams<PassportParams>();
    const dispatch = useAppDispatch()
    const {materialGroup, materials} = useAppSelector(state => state.root.options);
    const {activeLanguage} = useAppSelector(state => state.root);

    const {
        loading,
        activeTab,
        passport,
        composition,
        style,
        passportPreference,
        loadImpactCalculation,
        activeImpactFigureTypeId
    } = useAppSelector(state => state.publicPassport);


    const {translations} = useAppSelector(state => state.root);

    let hideMaterials = false
    if (passportPreference && !passportPreference.materialsVisible) {
        hideMaterials = true
    }

    const graphData = FormatCompositionToPieData({
        composition: composition,
        materialGroup: materialGroup?.data,
        materials: materials?.data,
        hideMaterials: hideMaterials
    })

    useEffect(() => {

            if (!isNaN(Number(uuid))) {
                dispatch(connect()).then(() => {
                    dispatch(getUuid(parseFloat(uuid))).then((response) => {
                        if (response.payload) {
                            window.location.href = '/public-passport/' + response.payload
                        }
                    })
                })
            }

            if (uuid) {
                dispatch(connect()).then(() => {
                    dispatch(load(uuid)).then(() => {
                        dispatch(loadStyle()).then(() => {
                            dispatch(loadPreference())
                        })
                    })
                })
            }

        }, [uuid]
    );

    useEffect(() => {

        dispatch(connect()).then(() => {
            dispatch(getTranslationList(activeLanguage))
        })

    }, [activeLanguage])

    useEffect(() => {

        let activeTab = PassportTabEnums.MATERIALS;

        if (!passportPreference.materialShareDiagram || !passportPreference.extraInformation) {
            activeTab = PassportTabEnums.IMPACT;
        }

        dispatch(changePublicTab(activeTab))

    }, [passportPreference]);

    if (!loading) {
        return (
            <main className="c-app__content background-color-passport">

                <LanguagePicker/>

                <PublicStyle style={style}/>

                <header className="c-app__content__header c-app__content__header--public">

                    <div className="c-app__content__header__name">

                        {(passportPreference.logo && passport?.organisation?.logo) && <>
                            <img src={passport.organisation.logo} alt=""/>
                            <figure/>
                        </>}

                        <h1 className="text-color-highlight">{passport?.organisation?.companyName}</h1>

                    </div>

                </header>

                <div className="c-app__content__container">
                    <div className="c-dashboard c-passport">
                        <div className="c-dashboard__row">
                            <div className="c-dashboard__group">

                                <PassportInfo translations={translations} passportPreference={passportPreference}
                                              passport={passport}
                                              composition={composition} public={true}/>
                                <PassportInterested translations={translations}/>

                            </div>

                            <div className="c-dashboard__group">
                                {passport.webUrl &&
                                    <PassportWebsite translations={translations} website={passport.webUrl}/>}
                                <PublicPassportQrCode translations={translations} passport={passport}
                                                      composition={composition}/>
                            </div>

                        </div>

                        <div className="c-dashboard__row">

                            <div className="c-dashboard__group">
                                {!!passportPreference.sustainableDevelopmentGoals &&
                                    <PassportSdgGoals translations={translations} passport={passport}
                                                      composition={composition}/>
                                }

                                {!!passportPreference.labelsAndCertificates &&
                                    <PassportLabels passport={passport} composition={composition}
                                                    translations={translations}/>
                                }

                            </div>

                            <div className="c-dashboard__group">
                                <div className="c-dashboard__card background-color-blocks c-passport__tabs">

                                    <PassportTabNav
                                        translations={translations}
                                        passport={passport}
                                        passportPreference={passportPreference}
                                        onChangeImpactMultiplier={(value: number) => dispatch(calculateImpact(value))}
                                        showMultiplier={activeTab === PassportTabEnums.IMPACT}
                                        activeTab={activeTab} public={true}
                                    />

                                    <div className="c-passport__tabs__content">
                                        {activeTab === PassportTabEnums.MATERIALS &&
                                            <PassportMaterials
                                                translations={translations}
                                                graphData={graphData}
                                                passport={passport}
                                                passportPreference={passportPreference}
                                                composition={composition}
                                            />
                                        }

                                        {activeTab === PassportTabEnums.IMPACT &&
                                            <PassportImpact
                                                translations={translations}
                                                loading={loadImpactCalculation}
                                                passportPreference={passportPreference}
                                                activeImpactFigureTypeId={activeImpactFigureTypeId}
                                                passport={passport}
                                                composition={composition}
                                                isPublic={true}
                                            />}

                                        {activeTab === PassportTabEnums.ENCODINGS &&
                                            <PassportEncodings
                                                translations={translations}
                                                passport={passport}
                                                composition={composition}
                                            />
                                        }

                                        {activeTab === PassportTabEnums.EXTRA_INFORMATION &&
                                            <PassportExtraInformation
                                                translations={translations}
                                                passport={passport}
                                                composition={composition}
                                                public={true}
                                            />
                                        }
                                    </div>

                                </div>
                            </div>


                        </div>

                        <PassportPoweredBy translations={translations}/>

                    </div>

                </div>

            </main>
        )
    } else return (
        <div className={'c-loader'}>
            <GridLoader color={'#E14343'}/>
        </div>
    )

}

export default PublicPassportPageContainer
