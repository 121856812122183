import {PassportInterface} from "../../common/interfaces/PassportInterface";
import {DashboardInterface, DashboardPassportInterface} from "../../common/interfaces/DashboardInterface";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiOrganisationPassportList} from "../../common/api/organisation/passport/apiOrganisationPassportList";
import {apiOrganisationDashboardCreate} from "../../common/api/organisation/dashboard/apiOrganisationDashboardCreate";
import {apiOrganisationDashboardUpdate} from "../../common/api/organisation/dashboard/apiOrganisationDashboardUpdate";
import {apiOrganisationDashboardDetail} from "../../common/api/organisation/dashboard/apiOrganisationDashboardDetail";

interface dashboardCreateInterface {
    dashboard: DashboardInterface,
    id?: number,
    uuid?: string
    passports?: {
        data: PassportInterface[],
        pages?: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    submitting: boolean,
    submitted: boolean,
}


const initialState: dashboardCreateInterface = {
    dashboard: {
        typeId: 1,
        isPublic: 1,
        passports: [],
        extraInformation: ''
    },
    submitting: false,
    submitted: true,
}

export const passportList = createAsyncThunk(
    'dashboardCreate/passportList',
    async (_) => {
        const response = await apiOrganisationPassportList(9999)
        if (response && response.data && response.data.items && response.data.items.data) {
            return response.data.items
        }
    }
)

export const submit = createAsyncThunk(
    'dashboardCreate/submit',
    async (_, {getState}) => {

        const {dashboardCreate} = getState() as { dashboardCreate: dashboardCreateInterface };

        if (dashboardCreate.dashboard.uuid) {
            const response = await apiOrganisationDashboardUpdate(dashboardCreate.dashboard)

            if (response && response.data && response.data.items) {
                return response.data.items.dashboard.uuid
            }

        } else {
            const response = await apiOrganisationDashboardCreate(dashboardCreate.dashboard)

            if (response && response.data && response.data.items) {
                return response.data.items.dashboard.uuid
            }
        }
    }
)

export const load = createAsyncThunk(
    'dashboardCreate/load',
    async (uuid: string, {}) => {

        const response = await apiOrganisationDashboardDetail({
            uuid: uuid
        })

        const dashboard = response.data.items.dashboard
        dashboard.passports = [] as DashboardPassportInterface[]

        response.data.items?.passports.forEach((passport: PassportInterface) => {

            dashboard.passports.push({
                passportId: Number(passport.passportId),
                amount: passport.amount + ''
            })
        })

        return dashboard
    }
)


const dashboardCreateSlice = createSlice({
    name: 'dashboardCreate',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState)
        },
        setCreateDashboardData: (state, action: PayloadAction<DashboardInterface>) => {
            state.submitted = false
            state.dashboard = {
                ...state.dashboard,
                ...action.payload
            }
        },
        setPassports: (state, action: PayloadAction<number[]>) => {
            state.submitted = false

            // Remove items
            state.dashboard.passports = (state.dashboard.passports && state.dashboard.passports.filter((passport) => action.payload.find((passportId) => passportId === passport.passportId)))


            // Add items
            action.payload.forEach((passportId) => {

                if (state.dashboard.passports && !state.dashboard.passports.find((passportItem) => passportItem.passportId === passportId)) {
                    state.dashboard.passports && state.dashboard.passports.push({
                        passportId: passportId,
                        amount: '1'
                    })
                }
            })
        },
        setPassportData: (state, action: PayloadAction<DashboardPassportInterface>) => {
            state.submitted = false

            state.dashboard.passports = state.dashboard.passports?.map((passport) => {
                if (passport.passportId === action.payload.passportId) {
                    return {
                        ...passport,
                        ...action.payload
                    }
                } else return passport
            })

        }
    },
    extraReducers: (builder) => {
        builder.addCase(passportList.fulfilled, (state, action: PayloadAction<dashboardCreateInterface['passports']>) => {
            state.passports = action.payload
        })
        builder.addCase(load.fulfilled, (state, action: PayloadAction<dashboardCreateInterface['dashboard']>) => {
            state.dashboard = action.payload
        })
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state, action: PayloadAction<any>) => {
            state.dashboard.uuid = action.payload
            state.submitting = false
            state.submitted = true
        })

    },
})

export const {
    setCreateDashboardData,
    setPassportData,
    setPassports,
    reset
} = dashboardCreateSlice.actions

export const dashboardCreateReducer = dashboardCreateSlice.reducer
