import FormSelect from "../form/FormSelect";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    changeActiveTab?: (activeTab: string) => void;
    translations?: TranslationsInterface;
    activeTab?: string;
    extraOptions?: {
        value: string,
        label: string
    }[]
}

export const ImpactMaterialSelector = (props: Props) => {


    const impactMaterials = [
        {
            label: props.translations?.materials || "Materials",
            value: "materials"
        },
        {
            label: props.translations?.impact || "Impact",
            value: "impact"
        }
    ];

    if (props.extraOptions) {
        impactMaterials?.push(...props.extraOptions)
    }

    const activeOption = impactMaterials.find((option) => props.activeTab === option.value);

    return (
        <div className="c-form__select c-form__select--smaller" style={{minWidth: "18rem"}}>
            <FormSelect
                options={impactMaterials}
                value={activeOption ? activeOption.value : undefined}
                onChange={(option) => {
                    props.changeActiveTab?.(option);
                }}
            />
        </div>
    );
};
