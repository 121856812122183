import {BrowserRouter, Route, Switch, useHistory} from "react-router-dom";
import {RouteEnum} from "../common/enums/RouteEnum";
import AccountBillingPageContainer from "../pages/accountBillingPage/AccountBillingPageContainer";
import AccountOrganisationPageContainer
    from "../pages/accountOrganisationPage/AccountOrganisationPageContainer";
import AccountStylingPageContainer from "../pages/accountStylingPage/AccountStylingPageContainer";
import CancelSubscriptionPageContainer from "../pages/cancelSubscriptionPage/CancelSubscriptionPageContainer";
import ForgotPasswordPageContainer from "../pages/forgotPasswordPage/ForgotPasswordPageContainer";
import LoginPageContainer from "../pages/loginPage/LoginPageContainer";
import PassportCreatePageContainer from "../pages/passportCreatePage/PassportCreatePageContainer";
import PassportDetailPageContainer from "../pages/passportDetailPage/PassportDetailPageContainer";
import PassportOverviewPageContainer from "../pages/passportOverviewPage/PassportOverviewPageContainer";
import PassportSettingsPageContainer from "../pages/passportSettingsPage/PassportSettingsPageContainer";
import SignupPageContainer from "../pages/signupPage/SingupPageContainer";
import {displayModuleByUserRole} from "../common/utils/displayModuleByUserRole";

import OnboardingPageContainer from "../pages/onboardingPage/OnboardingPageContainer";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "./hooks";
import {
    addressTypeList,
    amountTypeList,
    connect,
    countryList, dashboardTypeList,
    endUseList,
    genderList, getTranslationLanguages, getUserMe, howToList, impactFigureCalculationTypeList, labelList,
    materialGroupList,
    materialList,
    materialSets, organisationDetail,
    passportTypeList,
    sdgList,
    weightTypeList
} from "./rootReducer";
import {DashboardWrapper} from "../wrappers/DashboardWrapper/DashboardWrapper";
import {
    AccountOrganisationEditPageContainer
} from "../pages/accountOrganisationEditPage/AccountOrganisationEditPageContainer";
import AccountUsersPageContainer from "../pages/accountUsersPage/AccountUsersPageContainer";
import {AccountUsersCreatePageContainer} from "../pages/accountUsersCreatePage/AccountUsersCreatePageContainer";
import PublicPassportPageContainer from "../pages/passportPublicPage/PublicPassportPageContainer";
import ActivateAccountPageContainer from "../pages/activateAccountPage/ActivateAccountPageContainer";
import {DashboardCreatePageContainer} from "../pages/dashboardCreatePage/DashboardCreatePageContainer";
import {DasbhoardOverviewPageContainer} from "../pages/dashboardOverviewPage/DasbhoardOverviewPageContainer";
import {DashboardDetailPageContainer} from "../pages/dashboardDetailPage/DashboardDetailPageContainer";
import {DashboardPublicPageContainer} from "../pages/dashboardPublicPage/DashboardPublicPageContainer";
import ForgotPasswordResetPageContainer from "../pages/forgotPasswordResetPage/ForgotPasswordResetPageContainer";
import {TrashPassportModalContainer} from "../modals/trashPassportModal/TrashPassportModalContainer";
import {TrashDashboardModalContainer} from "../modals/trashDashboardModal/TrashDashboardModalContainer";
import {DuplicatePassportModalContainer} from "../modals/duplicatePassportModal/DuplicatePassportModalContainer";
import {Loader} from "../common/components/general/Loader";
import DashboardSettingsPageContainer from "../pages/dashboardSettingsPage/DashboardSettingsPageContainer";
import {
    IsFreeTrialOrganisation,
    IsMaxTrialConcurrentPassportsCreated,
    IsMaxTrialTotalPassportsCreated
} from "../common/utils/freeTrialRestrictions";
import UpgradeAccountPageContainer from "../pages/upgradeAccountPage/UpgradeAccountPageContainer";
import {TrashPassportGroupModalContainer} from "../modals/trashPassportGroupModal/TrashPassportGroupModalContainer";
import {
    DuplicatePassportGroupModalContainer
} from "../modals/duplicatePassportGroupModal/DuplicatePassportGroupModalContainer";
import PassportGroupPageContainer from "../pages/passportGroupPage/PassportGroupPageContainer";
import {CockpitPageContainer} from "../pages/cockpitPage/CockpitPageContainer";
import PassportGroupCreatePageContainer from "../pages/passportGroupCreatePage/PassportGroupCreatePageContainer";
import {RemoveFromPassportGroupModalContainer} from "../modals/removeFromPassportGroupModal/RemoveFromPassportGroupModalContainer";
const App = () => {

    const dispatch = useAppDispatch()
    const {authToggle, userMe} = useAppSelector(state => state.root);
    const {
        trashPassportModal,
        trashDashboardModal,
        duplicatePassportModal,
        trashPassportGroupModal,
        duplicatePassportGroupModal,
        removeFromPassportGroupModal
    } = useAppSelector(state => state);

    const history = useHistory()

    if (window.location.hash.startsWith('#/')) {
        window.location.href = window.location.hash.replace('#', '') // or history.replace
    }

    useEffect(() => {
            dispatch(connect()).then(() => {

                dispatch(getTranslationLanguages())
                dispatch(countryList())
                dispatch(addressTypeList())
                dispatch(genderList())
                dispatch(passportTypeList())
                dispatch(dashboardTypeList())
                dispatch(weightTypeList())
                dispatch(amountTypeList())
                dispatch(materialGroupList())
                dispatch(materialList())
                dispatch(sdgList())
                dispatch(labelList())
                dispatch(endUseList())
                dispatch(impactFigureCalculationTypeList())

                if (localStorage.user) {
                    dispatch(getUserMe())
                    dispatch(materialSets())
                    dispatch(organisationDetail())
                    dispatch(howToList())
                }

            })

        }, [authToggle, dispatch]
    );

    useEffect(() => {

        history?.push('/')

    }, [authToggle])


    return (
        <>

            <BrowserRouter>

                <Switch>

                    <Route path={'/#' + RouteEnum.PUBLIC_PASSPORT + '/:uuid'} exact={true}>
                        <PublicPassportPageContainer/>
                    </Route>

                    <Route path={RouteEnum.PUBLIC_PASSPORT + '/:uuid'} exact={true}>
                        <PublicPassportPageContainer/>
                    </Route>

                    <Route path={RouteEnum.PUBLIC_DASHBOARD + '/:uuid'} exact={true}>
                        <DashboardPublicPageContainer/>
                    </Route>

                    {localStorage.getItem('user') ?
                        <>
                            {userMe ?
                                <DashboardWrapper>

                                    {trashPassportModal.passport && <TrashPassportModalContainer/>}
                                    {trashDashboardModal.dashboard && <TrashDashboardModalContainer/>}

                                    {trashPassportGroupModal.group && <TrashPassportGroupModalContainer/>}
                                    {duplicatePassportGroupModal.group && <DuplicatePassportGroupModalContainer/>}

                                    {duplicatePassportModal.passport &&
                                        !IsFreeTrialOrganisation(userMe) &&
                                        <DuplicatePassportModalContainer/>
                                    }

                                    {removeFromPassportGroupModal.group && <RemoveFromPassportGroupModalContainer/>}

                                    <Switch>

                                        <Route path={RouteEnum.UPGRADE_ACCOUNT} exact={true}>
                                            <UpgradeAccountPageContainer/>
                                        </Route>

                                        {displayModuleByUserRole('settingsBilling', userMe) &&
                                            <Route path={RouteEnum.ACCOUNT_BILLING} exact={true}>
                                                <AccountBillingPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.ACCOUNT_ORGANISATION} exact={true}>
                                                <AccountOrganisationPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.ACCOUNT_ORGANISATION_EDIT} exact={true}>
                                                <AccountOrganisationEditPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.ACCOUNT_USERS} exact={true}>
                                                <AccountUsersPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.ACCOUNT_USERS_CREATE} exact={true}>
                                                <AccountUsersCreatePageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.ACCOUNT_USERS_EDIT + '/:id'} exact={true}>
                                                <AccountUsersCreatePageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.ACCOUNT_STYLING} exact={true}>
                                                <AccountStylingPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.CANCEL_SUBSCRIPTION} exact={true}>
                                                <CancelSubscriptionPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            !(IsMaxTrialTotalPassportsCreated(userMe) || IsMaxTrialConcurrentPassportsCreated(userMe)) &&
                                            <Route path={RouteEnum.PASSPORT_CREATE} exact={true}>
                                                <PassportCreatePageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('passportEdit', userMe) &&
                                            <Route path={RouteEnum.PASSPORT_EDIT + '/:uuid/:step?'} exact={true}>
                                                <PassportCreatePageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('passportView', userMe) &&
                                            <Route path={RouteEnum.PASSPORT_DETAIL + '/:uuid'} exact={true}>
                                                <PassportDetailPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.PASSPORT_SETTINGS} exact={true}>
                                                <PassportSettingsPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('dashboardView', userMe) &&
                                            <Route path={RouteEnum.DASHBOARD_OVERVIEW} exact={true}>
                                                <DasbhoardOverviewPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('settings', userMe) &&
                                            <Route path={RouteEnum.DASHBOARD_SETTINGS} exact={true}>
                                                <DashboardSettingsPageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('dashboardEdit', userMe) &&
                                            <Route path={RouteEnum.DASHBOARD_CREATE} exact={true}>
                                                <DashboardCreatePageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('dashboardEdit', userMe) &&
                                            <Route path={RouteEnum.DASHBOARD_EDIT + '/:uuid'} exact={true}>
                                                <DashboardCreatePageContainer/>
                                            </Route>
                                        }

                                        {displayModuleByUserRole('dashboardView', userMe) &&
                                            <Route path={RouteEnum.DASHBOARD_DETAIL + '/:uuid'} exact={true}>
                                                <DashboardDetailPageContainer/>
                                            </Route>
                                        }

                                        <Route path={RouteEnum.PASSPORT_GROUP + '/:id'}>
                                            <PassportGroupPageContainer/>
                                        </Route>

                                        <Route path={RouteEnum.PASSPORT_GROUP_CREATE}>
                                            <PassportGroupCreatePageContainer/>
                                        </Route>

                                        <Route path={RouteEnum.PASSPORT_GROUP_EDIT + '/:id'}>
                                            <PassportGroupCreatePageContainer/>
                                        </Route>

                                        {displayModuleByUserRole('passportView', userMe) &&
                                            <Route path={RouteEnum.PASSPORT_OVERVIEW} exact={true}>
                                                <PassportOverviewPageContainer/>
                                            </Route>
                                        }

                                        <Route>
                                            <CockpitPageContainer/>
                                        </Route>


                                    </Switch>
                                </DashboardWrapper>
                                : <Loader/>}

                        </>
                        :
                        <Switch>
                            <Route path={RouteEnum.RESET_PASSWORD} exact={true}>
                                <ForgotPasswordPageContainer/>
                            </Route>

                            <Route path={RouteEnum.RESET_PASSWORD + '/:resetToken'} exact={true}>
                                <ForgotPasswordResetPageContainer/>
                            </Route>

                            <Route path={RouteEnum.ACTIVATE_ACCOUNT + '/:token'} exact={true}>
                                <ActivateAccountPageContainer/>
                            </Route>

                            <Route path={RouteEnum.LOGIN} exact={true}>
                                <LoginPageContainer/>
                            </Route>

                            <Route path={RouteEnum.ONBOARDING} exact={true}>
                                <OnboardingPageContainer/>
                            </Route>

                            <Route path={RouteEnum.SIGNUP_PAGE} exact={true}>
                                <SignupPageContainer/>
                            </Route>

                            <Route exact={true}>
                                <LoginPageContainer/>
                            </Route>
                        </Switch>


                    }

                </Switch>

            </BrowserRouter>
        </>
    );
}

export default App;
