import {
    apiOrganisationPassportCalculateImpact
} from "../api/organisation/passport/apiOrganisationPassportCalculateImpact";
import {SimulatedPassportAmountInterface} from "../interfaces/SimulatedPassportAmountInterface";
import {CompositionInterface, PassportInterface} from "../interfaces/PassportInterface";
import {AssemblyInterface} from "../interfaces/AssemblyInterface";

export const mapSimulatedPassports = async (simulatedPassports: {
    active: boolean,
    amounts?: SimulatedPassportAmountInterface[]
}, connectedPassport: {
    passport: PassportInterface,
    assembly: AssemblyInterface,
    composition: CompositionInterface[]
}) => {

    // Retrieving the passport simulation
    const simulatedPassport = simulatedPassports.amounts?.find((simulatedPassport) => {
        return simulatedPassport.uuid === connectedPassport.passport.uuid
    })

    // If passport simulation is found
    if (simulatedPassport) {

        const args = {
            uuid: simulatedPassport.uuid,
            amount: parseInt(simulatedPassport.amount),
            passportImpactFigureCalculationTypeId: simulatedPassport.passportImpactFigureCalculationTypeId,
        } as any

        if (simulatedPassport.passportEndOfUseSolutionId) {
            args.passportEndOfUseSolutionId = simulatedPassport.passportEndOfUseSolutionId
        }

        const connectedPassportResponse = await apiOrganisationPassportCalculateImpact(args)

        const calculatedPassportsImpactFigures = connectedPassport.passport.passportImpactFigures?.map((passportImpactFigure) => {

            if (simulatedPassport.passportEndOfUseSolutionId && passportImpactFigure.passportImpactFigureCalculationTypeId === simulatedPassport.passportImpactFigureCalculationTypeId && passportImpactFigure.materialImpactFigureTypeId === simulatedPassport.passportEndOfUseSolutionId) {
                return {
                    ...passportImpactFigure,
                    ...connectedPassportResponse.data.items?.figures
                }
            }

            if (passportImpactFigure.passportImpactFigureCalculationTypeId === simulatedPassport.passportImpactFigureCalculationTypeId) {
                return {
                    ...passportImpactFigure,
                    ...connectedPassportResponse.data.items?.figures
                }
            }

            return passportImpactFigure
        })

        return {
            ...connectedPassport,
            passport: {
                ...connectedPassport.passport,
                passportImpactFigures: calculatedPassportsImpactFigures
            }
        }

        // If not found return current passport as it was
    } else return {
        ...connectedPassport
    }

}
