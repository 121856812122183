import {StyleInterface} from "../../interfaces/StyleInterface";

interface Props {
    style?: StyleInterface
}

export const PublicStyle = (props: Props) => {

    const {style} = props

    if (style?.customStyle) {

        return (

            <style>{`
      .background-color-passport {
        /*Background color of passport*/
        background-color: ` + style.primaryColorLight + ` !important;
      }
      .background-color-blocks {
        /*Background color of blocks*/
        background-color:  ` + style.secondaryColorLight + ` !important;
      }
      .text-color-copy, .c-passport__tabs__header a, .c-dashboard__impact h2, .c-dashboard__impact h4 {
        /*Color of copy*/
        color:  ` + style.primaryColorDark + ` !important;
      }
      .text-color-highlight, .c-passport__tabs__header .isActive, .c-dashboard__composition .c-dashboard__card h4, .c-dashboard__sdg__text h2, .c-dashboard__passport header h4 {
        /*Highlighted color*/
        color: ` + style.secondaryColorDark + `  !important;
      }
      .c-passport__public .btn--rounded {
        /*Highlighted color*/
        background-color: ` + style.secondaryColorDark + ` !important;
      }
      .c-passport__tabs__header .isActive {
        /*Highlighted color*/
        border-bottom: 3px solid ` + style.secondaryColorDark + ` !important;
      }
      
      .c-dash-extra-info h4, .c-dashboard__card__tabs > button.isActive {
          color: ` + style.secondaryColorDark + `  !important;
       }
       
       .c-input-dash-amount {
          border-color: ` + style.secondaryColorDark + `  !important;
       }
       
          `}</style>

        )
    } else return null

}