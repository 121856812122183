import {useAppAxios} from "../../../../app/hooks";
import {ApiEnum} from "../../../enums/ApiEnum";
import {StyleInterface} from "../../../interfaces/StyleInterface";

export const apiOrganisationInfoStyleUpdate = async (style: StyleInterface) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/update-style', {style: style}).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}