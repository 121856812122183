import {CompositionInterface, PassportInterface} from "../interfaces/PassportInterface";
import {decamelize} from "humps";
import {AssemblyInterface} from "../interfaces/AssemblyInterface";


export const passportToFormData = (passport: PassportInterface, composition: CompositionInterface[], assemblies: AssemblyInterface[], parentAssemblies: AssemblyInterface[]) => {

    let compositionCount = 0
    let assemblyCount = 0
    const formData = new FormData();

    Object.entries(passport).forEach(([key, value]) => {
        if (value !== null && typeof value !== 'undefined' && key !== 'id' && value !== undefined) {

            switch (key) {
                case('endOfUseSolution'):

                    formData.append('Passport[end_use_solution]', value.id)
                    break;
                case('sdgGoals'):
                case('connectedPassports'):
                    value.forEach((id: number) => {
                        formData.append('Passport[' + decamelize(key) + '][]', id + '')
                    })
                    break;

                case('reverseLogisticsInstructionFilesUpload'):
                case('assessmentInstructionFilesUpload'):
                case('disassemblyInstructionFilesUpload'):
                case('recyclingInstructionFilesUpload'):
                case('nextUseApplicationDescriptionFilesUpload'):
                case('appendicesFilesUpload'):

                    Array.from(value).forEach((file: File) => {
                        formData.append('Passport[' + decamelize(key.replace('Upload', '')) + '][]', file)
                    })
                    break;

                case('passportImpactFigures'):

                    value.forEach((impactFigure: any, index: number) => {
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][id]', impactFigure.id)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][co2_impact]', impactFigure.co2Impact)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][co2_impact_converted]', impactFigure.co2ImpactConverted)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][co2_impact_converted_type]', impactFigure.co2ImpactConvertedType)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][h2o_impact]', impactFigure.h2oImpact)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][h2o_impact_converted]', impactFigure.h2oImpactConverted)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][h2o_impact_converted_type]', impactFigure.h2oImpactConvertedType)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][raw_material_impact]', impactFigure.rawMaterialImpact)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][raw_material_impact_converted]', impactFigure.rawMaterialImpactConverted)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][raw_material_impact_converted_type]', impactFigure.rawMaterialImpactConvertedType)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][energy_impact]', impactFigure.energyImpact)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][energy_impact_converted]', impactFigure.energyImpactConverted)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][co2_price]', impactFigure.co2Price)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][co2_price_converted]', impactFigure.co2PriceConverted)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][material_impact_figure_type_id]', impactFigure.materialImpactFigureTypeId)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][passport_id]', impactFigure.passportId)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][passport_impact_figure_calculation_type_id]', impactFigure.passportImpactFigureCalculationTypeId)
                    })
                    break;

                case('labels'):
                    value.forEach((label: any, index: number) => {
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][id]', label.id)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][name]', label.name)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][fileComment]', label.fileComment ?? '')
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][fileUpload]', label.fileUpload)
                        formData.append('Passport[' + decamelize(key) + '][' + index + '][newFile]', label.fileUpload ? 'true' : 'false')
                    })
                    break;

                default:
                    formData.append('Passport[' + decamelize(key.replace('Upload', '')) + ']', value)
                    break;

            }
        }
    })

    assemblies.forEach((assembly) => {
        formData.append('assemblies[' + assemblyCount + '][assembly_passport_id]', assembly.assemblyPassportId + '')
        formData.append('assemblies[' + assemblyCount + '][amount]', assembly.amount + '')
        assemblyCount++
    })

    parentAssemblies.forEach((assembly) => {
        formData.append('parent_assemblies[' + assemblyCount + '][passport_id]', assembly.passportId + '')
        formData.append('parent_assemblies[' + assemblyCount + '][amount]', assembly.amount + '')
        assemblyCount++
    })

    composition?.forEach((composition) => {
        if (composition.materialGroupId) {
            formData.append('composition[' + compositionCount + '][materialGroupId]', composition.materialGroupId + '')
        }
        if (composition.compositionPercentage) {
            formData.append('composition[' + compositionCount + '][compositionPercentage]', composition.compositionPercentage + '')
        }

        let materialCount = 0;

        composition.materials?.forEach((material) => {

            if (material.materialId) {
                formData.append('composition[' + compositionCount + '][materials][' + materialCount + '][materialId]', material.materialId + '')
            }

            if (material.compositionPercentage) {
                formData.append('composition[' + compositionCount + '][materials][' + materialCount + '][compositionPercentage]', material.compositionPercentage + '')

                if (typeof material.organisationMaterials?.casNumber !== 'undefined' && material.organisationMaterials?.casNumber !== 'undefined') {
                    formData.append('composition[' + compositionCount + '][materials][' + materialCount + '][casNumber]', material.organisationMaterials?.casNumber + '')

                    if (typeof material.organisationMaterials?.abbreviation !== 'undefined' && material.organisationMaterials?.abbreviation !== 'undefined') {
                        formData.append('composition[' + compositionCount + '][materials][' + materialCount + '][abbreviation]', material.organisationMaterials?.abbreviation + '')
                    }

                    if (typeof material.organisationMaterials?.alternateDescription !== 'undefined' && material.organisationMaterials?.alternateDescription !== 'undefined') {
                        formData.append('composition[' + compositionCount + '][materials][' + materialCount + '][alternateDescription]', material.organisationMaterials?.alternateDescription + '')
                    }

                }


            }

            materialCount++
        })

        compositionCount++

    })

    return formData

}
