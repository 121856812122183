const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
})

const formatPrice = (number: any) => {

    return formatter.format(parseFloat(number))

}

export default formatPrice



