import {UserInterface} from "../interfaces/UserInterface";

export const IsMaxTrialConcurrentPassportsCreated = (userMe: UserInterface | undefined) => {

  if (userMe?.activePassports && userMe?.activePassports >= 2 && IsFreeTrialOrganisation(userMe)) {
    return true;
  }

  return false;
}

export const IsMaxTrialTotalPassportsCreated = (userMe: UserInterface | undefined) => {
  const organisation = userMe?.organisation
  return !!(userMe && organisation && organisation.passportsCreated && organisation.passportsCreated >= 6 && IsFreeTrialOrganisation(userMe));
}

export const IsFreeTrialOrganisation = (userMe: UserInterface | undefined) => {
  if (userMe && userMe.organisation) {
    return !!userMe.organisation.freeTrial
  }

  return false
}

export const SetDisabledButtonClassIf = (condition: boolean, buttonClass: string) => {
  return condition ? buttonClass + ' btn--disabled' : buttonClass
}

export const SetTrialPassportName = (userMe: UserInterface | undefined) => {
  const organisation = userMe?.organisation

  if (userMe && organisation) {
    let passportCount = organisation.passportsCreated

    if (passportCount === null || passportCount === undefined) {
      passportCount = 0
    }

    return 'Free trial passport #' + (passportCount + 1)
  }
}