import Select from "react-select";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setCompositions, setActiveCompositionMaterialGroupId, changeCreateStep} from "../passportCreateSlice";
import {PassportCreateMaterialComposition} from "../components/PassportCreateMaterialComposition";
import {useEffect} from "react";
import {setCreatePassportData} from "../passportCreateSlice";
import {PassportTypeInterface} from "../../../common/interfaces/PassportTypeInterface";
import {HowToCloud} from "../../../common/components/howTo/HowToCloud";

export const PassportCreateMaterials = () => {

    const dispatch = useAppDispatch()
    const {activeCompositionMaterialGroupId, composition, passport} = useAppSelector(state => state.passportCreate);

    const {materialGroup, passportTypes} = useAppSelector(state => state.root.options);

    const options = materialGroup?.data?.map((materialGroupItem) => ({
        value: materialGroupItem.id,
        label: materialGroupItem.name
    }));

    const activeOptions = options && options.filter((option) => composition && composition.find((compositionItem) => compositionItem.materialGroupId === option.value))
    const activeComposition = composition && composition.find((compositionItem) => compositionItem.materialGroupId === activeCompositionMaterialGroupId)
    const passportType = passportTypes?.data?.find((passportType: PassportTypeInterface) => passportType.id === passport.typeId)

    useEffect(() => {
            window.scrollTo(0, 0)
        }, []
    );

    return (
        <div className="c-app__create__primary">

            <header className="c-app__create__header">
                <small>Step 3/6</small>
                <h1>Materials</h1>
                <p>Define the material composition of your item.</p>
            </header>


            {passportType?.name !== 'Component' &&
            <div className="c-app__info-card">
                <div className="c-app__info-card__intro" style={{margin: 0}}>

                    <h2>Select material source</h2>

                    <form action="" className="c-form c-form--black">

                        <div className="c-form__row c-form__row--radio c-form__row--half">

                            <div className="c-form__group">
                                <label>
                                    <input
                                        onClick={() => dispatch(setCreatePassportData({
                                            useAssemblyMaterials: 0
                                        }))}
                                        checked={!passport.useAssemblyMaterials}
                                        name={'useAssemblyMaterials'}
                                        type="radio"
                                    />
                                    <span>Choose materials here</span>
                                </label>
                            </div>

                            <div className="c-form__group">
                                <label>
                                    <input
                                        onClick={() => dispatch(setCreatePassportData({
                                            useAssemblyMaterials: 1
                                        }))}
                                        checked={!!passport.useAssemblyMaterials}
                                        name={'useAssemblyMaterials'}
                                        type="radio"
                                    />
                                    <span>Use the materials selected from a lower assembly level (products and/or components)</span>
                                </label>
                            </div>

                            {!!passport.useAssemblyMaterials &&
                                <p style={{marginTop: '1.5rem', fontSize: '1rem'}}>*A lower level C_passport<sup>®</sup> needs to be selected in the assembly (step 6) to use it's materials</p>
                            }

                        </div>
                    </form>
                </div>

            </div>
            }

            {!passport.useAssemblyMaterials && <>

                <form action="" className="c-form">
                    <div className="c-form__row">
                        <div className="c-form__group">
                            <label htmlFor="">Dominant material group(s) {<HowToCloud code={'pp-dominant-material-groups'} />}</label>
                            <Select
                                value={activeOptions}
                                isMulti={true}
                                options={options}
                                onChange={(options) => dispatch(
                                    setCompositions(options.map((option) => (option.value)))
                                )}
                            />
                        </div>
                    </div>
                </form>

                <div className="c-app__create__composition">
                    <h2>Material composition</h2>
                    {composition &&
                    <div className="c-tab">

                        <header className="c-tab__header">

                            {composition.map((composition) => {
                                const activeMaterialGroup = materialGroup?.data?.find((materialGroup) => materialGroup.id === composition.materialGroupId)

                                let className = ''
                                if (activeMaterialGroup && activeMaterialGroup.id === activeCompositionMaterialGroupId) {
                                    className += 'isActive'
                                }

                                if (activeMaterialGroup) {
                                    return (
                                        <button
                                            onClick={() => dispatch(setActiveCompositionMaterialGroupId(activeMaterialGroup.id))}
                                            className={className}>
                                            {activeMaterialGroup.name}
                                        </button>
                                    )
                                } else return null
                            })}
                        </header>

                        {activeComposition && <PassportCreateMaterialComposition {...activeComposition}/>}

                    </div>
                    }



                </div>
            </>}

            <button onClick={() => dispatch(changeCreateStep(4))}
                    className="btn btn--large btn--rounded btn--red btn--width btn--margin" style={{zIndex: 0}}>
                <span>Step 4: impact</span>
            </button>

        </div>
    )
}

