import {CompositionInterface} from "../interfaces/PassportInterface";

export function sortCompositions(compositions: CompositionInterface[]) {
    if (compositions && compositions.length > 1) {
        compositions = [...compositions];
        compositions.sort((a, b) => {
            if (a.compositionPercentage > b.compositionPercentage) {
                return -1;
            }
            if (a.compositionPercentage < b.compositionPercentage) {
                return 1;
            }
            return 0;
        });
    }
    return compositions;
}