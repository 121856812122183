import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiHowToList = async (limit: number) => {
    return await useAppAxios.post(ApiEnum.HOW_TO + '/list', {}, {
        params: {
            limit: limit
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
