import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationUserResetPassword = async (resetToken: string, password: string) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_USER + '/reset-password', {
        reset_token: resetToken,
        password: password,
        password_confirm: password
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
