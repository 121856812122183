import FormInput from "../../common/components/form/FormInput";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {addAddress, load, setOrganisationData, submit} from "./AccountOrganisationEditSlice";
import {AccountOrganisationEditAddress} from "./components/AccountOrganisationEditAddress";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {ClipLoader, GridLoader} from "react-spinners";
import FormFile from "../../common/components/form/FormFile";

export const AccountOrganisationEditPageContainer = () => {

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)


    useEffect(() => {

            setLoading(true)

            dispatch(load()).then(() => {
                setLoading(false)
            })

        }, [dispatch]
    )

    const {organisation, addresses, submitting, submitted} = useAppSelector(state => state.organisationEdit);

    return (

        <form onSubmit={(e) => {
            e.preventDefault()
            dispatch(submit())
        }}>
            <header className="c-app__content__header">
                <nav className="c-breadcrumbs">
                    <ul className="u-reset-ul">
                        <li><Link to={RouteEnum.ACCOUNT_ORGANISATION}>Account</Link> <img
                            src="/images/icons/arrow-right-black.svg" alt=""/></li>
                        <li><Link to={RouteEnum.ACCOUNT_ORGANISATION}>Organisation</Link> <img
                            src="/images/icons/arrow-right-black.svg" alt=""/></li>
                        <li><Link to={RouteEnum.ACCOUNT_ORGANISATION_EDIT}>Edit</Link> <img
                            src="/images/icons/arrow-right-black.svg" alt=""/></li>
                    </ul>
                </nav>
                <div>
                    <Link to={RouteEnum.ACCOUNT_ORGANISATION}
                          className="btn btn--small btn--text"><span>Back</span></Link>
                    <button disabled={submitting} type={'submit'} style={{marginLeft: '1rem'}}
                            className="btn btn--small btn--rounded btn--black">

                        {!submitted ?
                            <span style={{display: 'flex', alignItems: 'center'}}>Save {submitting &&
                            <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}</span>
                            :
                            <span style={{display: 'flex', alignItems: 'center'}}>Saved {submitting &&
                            <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}</span>
                        }
                    </button>
                </div>
            </header>


            {!loading ?
                <div className={'c-app__content__container'}>
                    <div className={'c-app__view'}>
                        <div className="c-app__info-card">
                            <div className="c-app__info-card__intro">
                                <h2>General information</h2>
                            </div>
                            <div className="c-form c-form--black">

                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.companyName}
                                        label={'Company name'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            companyName: value + ''
                                        }))}
                                        required={true}
                                        type={'text'}/>
                                </div>

                                <div className={'c-form__row'}>

                                    <FormFile
                                        accept={'.jpg, .jpeg, .png'}
                                        onChange={(value) => dispatch(setOrganisationData({logoUpload: value}))}
                                        value={organisation.logoUpload}
                                        currentPath={organisation.logo}
                                        label={'Logo'}
                                    />

                                </div>

                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.cocNumber}
                                        label={'COC nr.'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            cocNumber: Number(value)
                                        }))}
                                        required={true}
                                        type={'number'}/>
                                </div>

                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.vatNumber}
                                        label={'VAT nr.'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            vatNumber: value + ''
                                        }))}
                                        required={true}
                                        type={'text'}/>
                                </div>

                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.purchaseOrderNr}
                                        label={'Purchase order nr.'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            purchaseOrderNr: String(value)
                                        }))}
                                        type={'text'}/>
                                </div>

                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.website}
                                        label={'Website'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            website: value + ''
                                        }))}

                                        type={'url'}/>
                                </div>

                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.phoneNumber}
                                        label={'Phone'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            phoneNumber: value + ''
                                        }))}
                                        type={'text'}/>
                                </div>

                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.invoiceEmail}
                                        label={'Invoice Email'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            invoiceEmail: value + ''
                                        }))}
                                        required={true}
                                        type={'email'}/>
                                </div>

                            </div>
                        </div>

                        <div className="c-app__info-card">
                            <div className="c-app__info-card__intro">
                                <h2>Contact person</h2>
                            </div>
                            <div className="c-form c-form--black">
                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.contactPersonName}
                                        label={'Name'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            contactPersonName: value + ''
                                        }))}
                                        required={true}
                                        type={'text'}/>
                                </div>
                                <div className={'c-form__row'}>
                                    <FormInput
                                        value={organisation.contactPersonEmail}
                                        label={'Email'}
                                        onChange={(value) => dispatch(setOrganisationData({
                                            contactPersonEmail: value + ''
                                        }))}
                                        required={true}
                                        type={'email'}/>
                                </div>
                            </div>
                        </div>

                        <div className="c-app__info-card">
                            <div className="c-app__info-card__intro">
                                <h2>Addresses</h2>
                            </div>
                            <div className="c-form c-form--black">
                                {addresses && addresses.map((address) => {
                                    return <AccountOrganisationEditAddress
                                        key={'address-' + address.createId} {...address} />
                                })}
                            </div>

                            <button onClick={() => dispatch(addAddress())} style={{marginTop: '1rem'}}
                                    className="btn btn--rounded btn--small btn--black"><span>Add new address</span>
                            </button>

                        </div>


                    </div>
                </div>
                :
                <div className={'c-loader'}>
                    <GridLoader color={'#E14343'}/>
                </div>
            }

        </form>
    )
}