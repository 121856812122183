import {RouteEnum} from "../../common/enums/RouteEnum";
import {Link} from "react-router-dom";
import {useAppLocation, useAppSelector} from "../../app/hooks";

interface Props {
    children?: React.ReactNode;
}


export const AccountWrapper = (props: Props) => {


    const location = useAppLocation();
    const {userMe} = useAppSelector((state) => state.root)

    const menuItems = [
        {
            label: 'Organisation',
            route: RouteEnum.ACCOUNT_ORGANISATION,
            visible: true
        },
        {
            label: 'Users',
            route: RouteEnum.ACCOUNT_USERS,
            visible: true
        },
        {
            label: 'Plan & billing',
            route: RouteEnum.ACCOUNT_BILLING,
            visible: !!userMe?.organisation?.pricingVisible
        },
        {
            label: 'Styling',
            route: RouteEnum.ACCOUNT_STYLING,
            visible: true
        }
    ]

    const activePage = menuItems.find((menuItem) => menuItem.route === location.pathname)


    return (
        <>
            <header className="c-app__content__header">
                <nav className="c-breadcrumbs">
                    <ul className="u-reset-ul">

                        <li>
                            <Link to={RouteEnum.ACCOUNT_ORGANISATION}>Account</Link>
                            <img src="/images/icons/arrow-right-black.svg" alt=""/>
                        </li>

                        {activePage &&
                        <li>
                            <span>{activePage.label}</span>
                            <img src="/images/icons/arrow-right-black.svg" alt=""/>
                        </li>
                        }

                    </ul>
                </nav>
                {activePage?.route === RouteEnum.ACCOUNT_ORGANISATION &&

                <Link to={RouteEnum.ACCOUNT_ORGANISATION_EDIT}
                      className="btn btn--small btn--outline btn--rounded"><span>Edit information</span></Link>

                }

                {activePage?.route === RouteEnum.ACCOUNT_USERS &&
                <Link to={RouteEnum.ACCOUNT_USERS_CREATE} className="btn btn--small btn--black btn--rounded"><span>New user</span></Link>
                }

            </header>
            <div className="c-app__content__container">
                <div className="c-app__view">
                    <div className="c-app__tab">
                        {menuItems.map((menuItem) => {

                            let className = ''

                            if (location.pathname === menuItem.route) {
                                className += 'isActive'
                            }

                            if (menuItem.visible) {
                                return (<Link className={className} to={menuItem.route}>{menuItem.label}</Link>)
                            } else return null

                        })}
                    </div>



                    {props.children}



                </div>

            </div>

        </>
    )
}