import {RouteEnum} from "../../../common/enums/RouteEnum";
import {Link} from "react-router-dom";
import {useAppSelector} from "../../../app/hooks";
import {displayModuleByUserRole} from "../../../common/utils/displayModuleByUserRole";
import {
    IsMaxTrialConcurrentPassportsCreated,
    IsMaxTrialTotalPassportsCreated, SetDisabledButtonClassIf
} from "../../../common/utils/freeTrialRestrictions";
import {FreeTrialHoverCloud} from "../../../common/components/general/FreeTrialHoverCloud";

interface Props {
    children?: React.ReactNode;
}

export const PassportOverviewWrapper = (props: Props) => {

    const {tabState} = useAppSelector(state => state.passportOverview);
    const {userMe} = useAppSelector(state => state.root);

    let createPassportBtnClass = SetDisabledButtonClassIf(
        (IsMaxTrialConcurrentPassportsCreated(userMe) || IsMaxTrialTotalPassportsCreated(userMe)),
        'btn btn--small btn--black btn--rounded'
    )

    return (
        <>
            <div className={'c-app__content__header'}>
                <nav className="c-breadcrumbs">
                    <ul className="u-reset-ul">
                        <li>
                            <Link to={RouteEnum.PASSPORT_OVERVIEW}>C_passport<sup>®</sup></Link>
                            <img src="/images/icons/arrow-right-black.svg" alt=""/>
                        </li>
                        <li>
                            <Link to={RouteEnum.PASSPORT_OVERVIEW}>Overview</Link>
                            <img src="/images/icons/arrow-right-black.svg" alt=""/>
                        </li>
                    </ul>
                </nav>


                <div>
                    {displayModuleByUserRole('settings', userMe) &&
                        <Link to={RouteEnum.PASSPORT_SETTINGS} className="btn btn--small btn--outline btn--rounded">
                            <span>Settings</span>
                        </Link>
                    }

                    {tabState === 'passport' && displayModuleByUserRole('passportEdit', userMe) &&
                        <Link to={RouteEnum.PASSPORT_CREATE} className={createPassportBtnClass}>
                            <span>Create C_passport<sup>®</sup></span>
                            <FreeTrialHoverCloud text={'You have created the maximum amount of passports during this trial period. Please complete your account to create more passports.'}></FreeTrialHoverCloud>
                        </Link>
                    }

                    {tabState === 'group' && displayModuleByUserRole('passportEdit', userMe) &&
                    <Link to={RouteEnum.PASSPORT_GROUP_CREATE} className="btn btn--small btn--black btn--rounded">
                        <span>Create new group</span>
                    </Link>
                    }
                </div>

            </div>

            {props.children}
        </>
    )

}

