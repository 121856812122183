import React from "react";
interface Props {
    children?: React.ReactNode;
}

export const SignupWrapper = (props:Props) => {

    return (
        <section className="c-signup">
            <div className="o-container o-container--secondary">
                <img src="/images/CIRMAR-logo.svg" alt="" className="c-signup__logo"/>
                {props.children}
            </div>
        </section>
    )

}

