import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {DashboardInterface} from "../../../interfaces/DashboardInterface";
import {dashboardToFormData} from "../../../utils/dashboardToFormData";

export const apiOrganisationDashboardUpdate = async (dashboard: DashboardInterface) => {

    const formData = dashboardToFormData(dashboard);

    return await useAppAxios.post(ApiEnum.ORGANISATION_DASHBOARD + '/update', formData, {
        headers: {'content-type': 'multipart/form-data'},
        params: {
            uuid: dashboard.uuid
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
