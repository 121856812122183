import {CompositionInterface, PassportInterface} from "../../common/interfaces/PassportInterface";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiOrganisationDashboardDetail} from "../../common/api/organisation/dashboard/apiOrganisationDashboardDetail";
import {DashboardContainerInterface} from "../../common/interfaces/DashboardContainerInterface";
import {AssemblyInterface} from "../../common/interfaces/AssemblyInterface";
import {SimulatedPassportAmountInterface} from "../../common/interfaces/SimulatedPassportAmountInterface";
import {LoadDashboardAction} from "../../common/actions/LoadDashboardAction";
import {DashboardPreferenceInterface} from "../../common/interfaces/DashboardPreferenceInterface";
import {mapSimulatedPassports} from "../../common/utils/mapSimulatedPassports";
import {useAppAxios} from "../../app/hooks";
import {ApiEnum} from "../../common/enums/ApiEnum";

interface dashboardDetailInterface {
    dashboardContainer?: DashboardContainerInterface,
    id?: number,
    connectedPassports: {
        passport: PassportInterface,
        assembly: AssemblyInterface,
        composition: CompositionInterface[]
    }[],
    simulatedPassports: {
        active: boolean,
        amounts?: SimulatedPassportAmountInterface[]
    },
    dashboardPreference?: DashboardPreferenceInterface,
    overruleEndUseId?: number,
    overruleActiveTab?: string,
    loadImpacts: boolean
}


const initialState: dashboardDetailInterface = {
    connectedPassports: [],
    simulatedPassports: {
        active: false,
        amounts: []
    },
    overruleActiveTab: 'materials',
    loadImpacts: false
}


export const load = createAsyncThunk(
    'dashboardDetail/load',
    LoadDashboardAction
)

export const getUuid = createAsyncThunk(
    'dashboardDetail/get-uuid',
    async (id: number) => {
        return await useAppAxios.post(ApiEnum.ORGANISATION_DASHBOARD + '/get-uuid', {}, {
            params: {
                id: id,
            },
        }).then(function (response) {
            return response?.data?.items?.data
        }).catch(function (error) {
            return error
        })

    }
)


export const loadBySimulation = createAsyncThunk(
    'dashboardDetail/loadBySimulation',
    async (uuid: string, {getState}) => {
        const {dashboardDetail} = getState() as { dashboardDetail: dashboardDetailInterface };

        const response = await apiOrganisationDashboardDetail({
            uuid: uuid,
            simulatedPassportAmounts: dashboardDetail.simulatedPassports.amounts
        })

        const connectedPassports = await Promise.all(dashboardDetail.connectedPassports?.map(async (connectedPassport) => await mapSimulatedPassports(dashboardDetail.simulatedPassports, connectedPassport)))

        return {
            dashboardContainer: response.data.items,
            connectedPassports: connectedPassports
        }
    }
)


const dashboardDetailSlice = createSlice({
    name: 'dashboardDetail',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState)
        },
        setPassportTab: (state, action: PayloadAction<{
            uuid: string,
            tab: string
        }>) => {

            let hasPassportsWithCustomActiveTab = false

            state.connectedPassports = state.connectedPassports.map((passport) => {
                if ((passport.passport.uuid === action.payload.uuid)) {

                    if (action.payload.tab !== state.overruleActiveTab) {
                        hasPassportsWithCustomActiveTab = true
                    }

                    return {
                        ...passport,
                        passport: {
                            ...passport.passport,
                            dashboardSettings: {
                                activeTab: action.payload.tab
                            }
                        }

                    }
                }
                return passport
            })


            if (hasPassportsWithCustomActiveTab) {
                state.overruleActiveTab = 'custom'
            }

        },
        setOverruleActiveTab: (state, action: PayloadAction<string>) => {
            state.overruleActiveTab = action.payload
            state.connectedPassports = state.connectedPassports.map((passport) => {
                return {
                    ...passport,
                    passport: {
                        ...passport.passport,
                        dashboardSettings: {
                            activeTab: action.payload
                        }
                    }
                }
            })
        },
        setSimulatedPassportAmount: (state, action: PayloadAction<{
            uuid: string,
            amount: string,
            passportEndOfUseSolutionId?: number,
        }>) => {
            state.simulatedPassports.amounts = state.simulatedPassports.amounts?.map((simulatedPassportAmount) => {
                if (simulatedPassportAmount.uuid === action.payload.uuid) {
                    return {
                        ...simulatedPassportAmount,
                        amount: action.payload.amount,
                        passportEndOfUseSolutionId: action.payload.passportEndOfUseSolutionId,
                    }
                } else return simulatedPassportAmount
            })

            const hasPassportsWithCustomEndOfUse = state.simulatedPassports.amounts?.some((simulatedPassportAmount) => !!simulatedPassportAmount.passportEndOfUseSolutionId)

            if (hasPassportsWithCustomEndOfUse) {
                state.overruleEndUseId = 99
            }
        },
        changeOverruleEndUse: (state, action: PayloadAction<number>) => {
            state.overruleEndUseId = action.payload
            state.simulatedPassports.amounts = state.simulatedPassports.amounts?.map((amount) => {
                return {
                    ...amount,
                    passportEndOfUseSolutionId: action.payload
                }
            })
        },
    },
    extraReducers: (builder) => {
        builder.addCase(load.pending, (state) => {
            Object.assign(state, initialState)
        })
        builder.addCase(load.fulfilled, (state, action: PayloadAction<any>) => {
            state.dashboardContainer = action.payload.dashboardContainer
            state.connectedPassports = action.payload.connectedPassports
            state.dashboardPreference = action.payload.dashboardPreference
            state.simulatedPassports.active = true
            state.simulatedPassports.amounts = action.payload.defaultSimulatedPassportAmounts
        })
        builder.addCase(loadBySimulation.pending, (state) => {
            state.loadImpacts = true
        })
        builder.addCase(loadBySimulation.fulfilled, (state, action: PayloadAction<any>) => {
            state.dashboardContainer = action.payload.dashboardContainer
            state.connectedPassports = action.payload.connectedPassports
            state.loadImpacts = false
        })
    },
})

export const {
    changeOverruleEndUse,
    setSimulatedPassportAmount,
    setPassportTab,
    setOverruleActiveTab
} = dashboardDetailSlice.actions

export const dashboardDetailReducer = dashboardDetailSlice.reducer
