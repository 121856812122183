import {Pie} from "react-chartjs-2";
import {CompositionLegenda} from "../general/CompositionLegenda";
import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {AssemblyInterface} from "../../interfaces/AssemblyInterface";
import {SimulatedPassportAmountInterface} from "../../interfaces/SimulatedPassportAmountInterface";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {formatImpactNumberDecimals} from "../../utils/formatImpactNumberDecimals";
import {DashboardConnectedPassportDisclaimer} from "./DashboardConnectedPassportDisclaimer";
import {getCurrentImpactFigure} from "../../utils/PassportImpactFigures";
import {EndOfUseSelector} from "../general/EndOfUseSelector";

interface Props {
    connectedPassports?: any
    connectedPassport: {
        passport: PassportInterface,
        assembly: AssemblyInterface,
        composition: CompositionInterface[]
    },
    simulatedPassportAmount?: SimulatedPassportAmountInterface,
    graphData?: any,
    activeTab?: string,
    setActiveTab?: any,
    passportStyles: {
        width: string,
        maxWidth: string
    },
    setSimulatedPassportAmount: any,
    translations?: TranslationsInterface,
    hideMaterials?: boolean,
    setPassportTab?: any
}

export const DashboardConnectedPassport = (props: Props) => {

    const {
        connectedPassport,
        simulatedPassportAmount,
        graphData,
        passportStyles,
        setSimulatedPassportAmount
    } = props
    const dispatch = useAppDispatch()
    const {materialGroup, materials} = useAppSelector(state => state.root.options);

    const {impactFigureCalculationTypes} = useAppSelector(state => state.root.options);
    const {passport} = connectedPassport
    const activeImpactFigureTypeId = passport.passportImpactFigureCalculationTypeId ?? undefined
    const activeTabState = connectedPassport?.passport.dashboardSettings?.activeTab ?? 'materials'

    const currentImpactFigure = getCurrentImpactFigure(
        {
            passport: passport,
            activeImpactFigureTypeId: activeImpactFigureTypeId,
            impactFigureCalculationTypes: impactFigureCalculationTypes?.data,
            overruleEndUseId: simulatedPassportAmount?.passportEndOfUseSolutionId
        })

    const sroiImpactObject = currentImpactFigure?.sroiImpactObject ?? passport.sroiImpactObject;

    const tabs = [
        {
            label: props.translations?.materials,
            value: 'materials'
        },
        {
            label: props.translations?.impact,
            value: 'impact'
        }
    ]

    return (
        <div className="c-dashboard__group"
             style={{'flex': 1, marginRight: 0, marginBottom: '2rem', maxWidth: passportStyles.maxWidth}}>
            <div className="c-dashboard__card c-dashboard__passport" style={{justifyContent: 'space-between'}}
            >
                <header>
                    <h4>{connectedPassport.passport.name}
                    </h4>
                    {simulatedPassportAmount && setSimulatedPassportAmount &&
                        <small>{props.translations?.amount}: <input className={'c-input-dash-amount'} style={{
                            width: '4rem',
                            border: '0',
                            borderBottom: '1px solid #E14343',
                            textAlign: 'center'
                        }}
                                                                    type={'number'}
                                                                    onChange={(e) => dispatch(setSimulatedPassportAmount({
                                                                        ...simulatedPassportAmount,
                                                                        amount: e.target.value,
                                                                    }))}
                                                                    value={simulatedPassportAmount.amount}/></small>}
                </header>

                {activeTabState === 'materials' &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignSelf: 'center',
                        alignItems: 'center',
                        padding: '3rem',
                        flex: 1
                    }}>

                        <div className={'c-pie-chart'} style={{width: passportStyles.width}}>
                            <Pie data={graphData}/>
                        </div>

                        {(materialGroup?.data && materials?.data) &&
                            <CompositionLegenda composition={connectedPassport.composition}
                                                materialGroup={materialGroup.data}
                                                materials={materials.data}
                                                hideMaterials={props.hideMaterials}
                            />
                        }
                    </div>
                }

                {activeTabState === 'impact' &&
                    <div className={'c-dashboard-passport__material-list'}>
                        <ul>
                            {!!sroiImpactObject &&
                                <li>
                                    <img src={'/images/people.png'} alt={'layer'}/>
                                    <span><b>{props.translations?.sroiImpact}:</b> {sroiImpactObject.hours}:{sroiImpactObject.minutes} h</span>
                                </li>
                            }
                            {!!currentImpactFigure?.h2oImpactConverted &&
                                <li>
                                    <img src={'/images/raindrops.png'} alt={'raindrops'}/>
                                    <span><b>{props.translations?.h2oImpact}:</b> {formatImpactNumberDecimals(currentImpactFigure.h2oImpactConverted?.toFixed(8) + '')} {currentImpactFigure.h2oImpactConvertedType}</span>
                                </li>
                            }
                            {!!currentImpactFigure?.co2ImpactConverted &&
                                <li>
                                    <img src={'/images/tree.png'} alt={'tree'}/>
                                    <span><b>{props.translations?.co2Impact}:</b> {formatImpactNumberDecimals(currentImpactFigure.co2ImpactConverted?.toFixed(8) + '')} {currentImpactFigure.co2ImpactConvertedType}</span>
                                </li>
                            }
                            {!!currentImpactFigure?.co2PriceConverted &&
                                <li>
                                    <img src={'/images/co2prijs.png'} alt={'tree'}/>
                                    <span><b>{props.translations?.co2Price}:</b> {formatImpactNumberDecimals(currentImpactFigure.co2PriceConverted?.toFixed(8) + '')} EUR</span>
                                </li>
                            }
                            {!!currentImpactFigure?.energyImpact &&
                                <li>
                                    <img src={'/images/thunder.png'} alt={'thunder'}/>
                                    <span><b>{props.translations?.energyImpact}:</b> {formatImpactNumberDecimals(parseFloat(currentImpactFigure.energyImpactConverted + '')?.toFixed(8) + '')} kWh</span>
                                </li>
                            }
                            {!!currentImpactFigure?.rawMaterialImpact &&
                                <li>
                                    <img src={'/images/layer.png'} alt={'layer'}/>
                                    <span><b>{props.translations?.rawMaterialsSaved}:</b> {formatImpactNumberDecimals(currentImpactFigure.rawMaterialImpactConverted?.toFixed(8) + '')} {currentImpactFigure.rawMaterialImpactConvertedType}</span>
                                </li>
                            }
                        </ul>

                        <div className="c-dashboard__passport__end-of-use">
                            <div className={'c-dashboard__passport__end-of-use__labels'}>
                                <span
                                    className={'text-color-highlight'}>{!!simulatedPassportAmount?.passportEndOfUseSolutionId && <>Original </>} {props.translations?.endOfUseTreatment}</span>
                                <span className={'text-color-copy'}>{passport.endOfUseCalculatedList}</span>
                            </div>

                            <EndOfUseSelector
                                activeImpactFigureTypeId={passport.passportImpactFigureCalculationTypeId}
                                passport={passport}
                                overruleEndUseId={simulatedPassportAmount?.passportEndOfUseSolutionId}
                                changeEndUseId={(endUseId) => {
                                    dispatch(setSimulatedPassportAmount({
                                        ...simulatedPassportAmount,
                                        passportEndOfUseSolutionId: endUseId
                                    }))
                                }}
                            />


                        </div>

                        <DashboardConnectedPassportDisclaimer
                            passport={connectedPassport.passport}
                        />
                    </div>
                }


                <div className={'c-dashboard__card__tabs'}>
                    {tabs.map((tab) => {

                        let className = ''

                        if (tab.value === activeTabState) {
                            className += 'isActive'
                        }

                        return (
                            <button onClick={() => {
                                dispatch(props.setPassportTab({
                                    uuid: connectedPassport.passport.uuid,
                                    tab: tab.value
                                }))
                            }} className={className}>{tab.label}</button>
                        )
                    })}

                </div>
            </div>
        </div>)

}
