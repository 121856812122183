export const RouteEnum = {
    ACCOUNT_BILLING: '/account/billing',
    ACCOUNT_ORGANISATION: '/account',
    ACCOUNT_ORGANISATION_EDIT: '/account/edit',
    ACCOUNT_USERS: '/account/users',
    ACCOUNT_USERS_CREATE: '/account/users/create',
    ACCOUNT_USERS_EDIT: '/account/users/edit',
    ACCOUNT_STYLING: '/account/styling',
    ACTIVATE_ACCOUNT: '/activate-account',
    UPGRADE_ACCOUNT: '/upgrade-account',
    CANCEL_SUBSCRIPTION: '/cancel-subscription',
    RESET_PASSWORD: '/reset-password',
    LOGIN: '/login',
    PASSPORT_DETAIL: '/passport/detail',
    PASSPORT_CREATE: '/passport/create',
    PASSPORT_EDIT: '/passport/edit',
    PASSPORT_OVERVIEW: '/passport',
    PASSPORT_SETTINGS: '/passport/settings',
    PUBLIC_PASSPORT: '/public-passport',
    SIGNUP_PAGE: '/signup',
    ONBOARDING: '/onboarding',
    DASHBOARD_OVERVIEW: '/dashboard',
    DASHBOARD_CREATE: '/dashboard/create',
    DASHBOARD_EDIT: '/dashboard/edit',
    DASHBOARD_SETTINGS: '/dashboard/settings',
    DASHBOARD_DETAIL: '/dashboard/detail',
    PUBLIC_DASHBOARD: '/public-dashboard',
    PASSPORT_GROUP: '/passport-group/detail',
    PASSPORT_GROUP_CREATE: '/passport-group/create',
    PASSPORT_GROUP_EDIT: '/passport-group/edit'
}

