import {ApiEnum} from "../../../enums/ApiEnum";

import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationUserList = async () => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_USER + '/list?limit=9999', {}, {
        params: {
            limit: 9999
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
