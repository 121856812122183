import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserInterface} from "../../common/interfaces/UserInterface";
import {apiOrganisationUserCreate} from "../../common/api/organisation/user/apiOrganisationUserCreate";
import {apiOrganisationUserDetail} from "../../common/api/organisation/user/apiOrganisationUserDetail";
import {apiOrganisationUserUpdate} from "../../common/api/organisation/user/apiOrganisationUserUpdate";

export const submit = createAsyncThunk(
    'userCreate/submit',
    async (_, {getState}) => {
        const {userCreate} = getState() as { userCreate: userCreateStateInterface };

        if (userCreate.user.id) {
            const response = await apiOrganisationUserUpdate(userCreate.user, userCreate.user.id)
            return response.data.status.code
        } else {
            const response = await apiOrganisationUserCreate(userCreate.user)
            return response.data.status.code
        }


    }
)

export const load = createAsyncThunk(
    'userCreate/load',
    async (id: number) => {
        const response = await apiOrganisationUserDetail(id)

        const user = response.data.items.data

        delete user.status

        return user
    }
)

interface userCreateStateInterface {
    submitting: boolean,
    submitStatus?: number,
    submitted: boolean,
    user: UserInterface,
}

const initialState: userCreateStateInterface = {
    submitting: false,
    submitted: false,
    submitStatus: 0,
    user: {
        email: '',
        password: '',
        passwordRepeat: '',
        firstName: '',
        lastName: '',
        insertion: '',
    },
}

const userCreate = createSlice({
    name: 'userCreate',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState)
        },
        setUserData: (state, action: PayloadAction<UserInterface>) => {
            state.user = {
                ...state.user,
                ...action.payload
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })

        builder.addCase(submit.fulfilled, (state, action: PayloadAction<number>) => {
            state.submitting = false
            state.submitted = true
            state.submitStatus = action.payload
        })
        builder.addCase(submit.rejected, (state) => {
            state.submitting = false
            state.submitted = false
            state.submitStatus = 400
        })

        builder.addCase(load.fulfilled, (state, action: PayloadAction<UserInterface>) => {
            state.user = action.payload
        })

    },
})

export const {
    setUserData,
    reset
} = userCreate.actions
export const userCreateReducer = userCreate.reducer