import {changeStep} from "../upgradeAccountSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

export const UpgradeStep2 = () => {

    const dispatch = useAppDispatch()
    const {examples} = useAppSelector(state => state.upgradeAccount);



    return (
        <>
            <p>Check out more info about our pricing <a style={{color: '#E14343'}} target={'_blank'} rel={'noreferrer'} href={'https://cirmar.com/pricing/'}>here</a>.</p>

            <div className="c-signup__info">
                <div className="c-signup__examples">
                    {examples.map((example) => {

                        return (
                            <div className={'c-signup__examples-item'} key={'example:' + example.title}>
                                <header>

                                    <div className={'left'}>
                                        <img src={'/images/icons/' + example.icon} alt={'example icon'}/>
                                    </div>

                                    <div className={'right'}>
                                        <h2>{example.title}</h2>
                                        <p>{example.text}</p>
                                    </div>

                                </header>
                                <div className={'c-signup__examples-item__content'}>
                                    <div className={'left'}>

                                        <div className={'c-example-data'}>
                                            <span style={{display: 'flex', alignItems: 'center'}}><img
                                                style={{marginRight: '.5rem'}} src={'/images/icons/passport.svg'}
                                                alt={'passport icon'}/>{example.passports.amount}x C_passports<sup>®</sup> + C_dashboard<sup>®</sup></span>
                                            <span>€ {example.passports.subscription?.price}</span>
                                        </div>

                                        <div className={'c-example-data'}>
                                            <span style={{display: 'flex', alignItems: 'center'}}><img
                                                style={{marginRight: '.5rem'}} src={'/images/icons/check-red.svg'}
                                                alt={'check-red'}/> 1 free user and {example.users.amount}x extra users</span>
                                            <span>€ {example.users.subscription?.price}</span>
                                        </div>

                                    </div>
                                    <div className={'right'}>
                                        <small>Price per month</small>
                                        <h3 className={'c-price'}>€ {example.price}</h3>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>


            </div>
            <button className="btn btn--large btn--rounded btn--red" onClick={() => dispatch(changeStep(3))}>
                <span>Step 3: create account</span>
            </button>
        </>
    )

}