import {changeCreateStep, submit} from "../passportCreateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {calculateStepPercentage} from "../../../common/utils/calculateStepPercentage";
import {PassportTypeInterface} from "../../../common/interfaces/PassportTypeInterface";
import {ClipLoader} from "react-spinners";
import {RouteEnum} from "../../../common/enums/RouteEnum";
import {Link} from 'react-router-dom'
import {passportIsSaveAble} from "../../../common/utils/passportIsSaveAble";

interface Props {
    step: number,
}


export const PassportSummary = (props: Props) => {

    const dispatch = useAppDispatch()

    const changeStep = changeCreateStep

    const {
        passport,
        activeStep,
        submitting,
        submitted,
        composition,
        passports,
        assemblies,
        parentAssemblies
    } = useAppSelector(state => state.passportCreate);
    const {passportTypes} = useAppSelector(state => state.root.options);
    const {uuid} = useAppSelector(state => state.passportCreate.passport);
    let maxStep = 6

    const step2Process = calculateStepPercentage(2, passport, composition)
    const step3Process = calculateStepPercentage(3, passport, composition)
    const step4Process = calculateStepPercentage(4, passport, composition)
    const step5Process = calculateStepPercentage(5, passport, composition)

    const passportType = passportTypes?.data?.find((passportType: PassportTypeInterface) => passportType.id === passport.typeId)
    let title = uuid ? 'Update' : 'Save'

    const activeAssemblyPassports = passports?.data.filter((passport) => assemblies.find((assembly) => assembly.assemblyPassportId === passport.uuid))
    const activeParentAssemblyPassports = passports?.data.filter((passport) => parentAssemblies.find((assembly) => assembly.passportId === passport.uuid))

    const isSaveAble = passportIsSaveAble({
        passport: passport,
        activeAssemblyPassports: activeAssemblyPassports,
        activeParentAssemblyPassports: activeParentAssemblyPassports
    })

    if (props.step > 1) {

        return (

            <div className="c-app__create__overview-container">

                <aside className="c-app__create__overview">
                    

                    <header>
                        <h4>Passport summary</h4>
                        <img src="/images/icons/arrow-down-black.svg" alt={''}/>
                    </header>


                    {(activeStep >= 1 || passport.id) &&
                    <div className="c-app__create__overview__single">
                        {passportType && <div>
                            <h5>Type of C_passport<sup>®</sup> (Step 1/{maxStep})</h5><span>{passportType.name}</span>
                        </div>}
                        {activeStep !== 1 &&
                        <button
                            onClick={() => dispatch(changeStep(1))}
                            className="btn btn--circle btn--red">
                            <img src="/images/icons/edit-white.svg" alt={''}/>
                        </button>
                        }
                    </div>
                    }

                    {(activeStep >= 2 || passport.id) &&
                    <div className="c-app__create__overview__single c-app__create__overview__single--top">
                        <div>
                            <h5>Basic information (Step 2/{maxStep})</h5>
                            <div className="c-app__create__overview__single__bar">
                                <div style={{width: step2Process + '%'}}/>
                            </div>
                            <small>{step2Process}% complete</small>
                        </div>
                        {activeStep !== 2 &&
                        <button
                            onClick={() => dispatch(changeStep(2))}
                            className="btn btn--circle btn--red">
                            <img src="/images/icons/edit-white.svg" alt={''}/>
                        </button>
                        }
                    </div>
                    }

                    {(activeStep >= 3 || passport.id) &&
                    <div className="c-app__create__overview__single c-app__create__overview__single--top">
                        <div>
                            <h5>Materials (Step 3/{maxStep})</h5>
                            <div className="c-app__create__overview__single__bar">
                                <div style={{width: step3Process + '%'}}/>
                            </div>
                            <small>{step3Process}% complete</small>
                        </div>
                        {activeStep !== 3 &&
                        <button
                            onClick={() => dispatch(changeStep(3))}
                            className="btn btn--circle btn--red"><img src="/images/icons/edit-white.svg" alt=""/>
                        </button>
                        }
                    </div>
                    }

                    {(activeStep >= 4 || passport.id) &&
                    <div className="c-app__create__overview__single c-app__create__overview__single--top">
                        <div>
                            <h5>Impact (Step 4/{maxStep})</h5>
                            <div className="c-app__create__overview__single__bar">
                                <div style={{width: step4Process + '%'}}/>
                            </div>
                            <small>{step4Process}% complete</small>
                        </div>

                        {activeStep !== 4 &&
                        <button
                            onClick={() => dispatch(changeStep(4))}
                            className="btn btn--circle btn--red">
                            <img src="/images/icons/edit-white.svg" alt=""/>
                        </button>
                        }

                    </div>
                    }

                    {(activeStep >= 5 || passport.id) &&
                    <div className="c-app__create__overview__single c-app__create__overview__single--top">
                        <div><h5>Extra information (Step 5/{maxStep})</h5>
                            <div className="c-app__create__overview__single__bar">
                                <div style={{width: step5Process + '%'}}/>
                            </div>
                            <small>{step5Process}% complete</small></div>
                        {activeStep !== 5 &&
                        <button
                            onClick={() => dispatch(changeStep(5))}
                            className="btn btn--circle btn--red">
                            <img src="/images/icons/edit-white.svg" alt=""/>
                        </button>
                        }
                    </div>
                    }

                    {((activeStep >= 6 || passport.id) && maxStep === 6) &&
                    <div className="c-app__create__overview__single c-app__create__overview__single--top"
                         style={{alignItems: 'center'}}>
                        <div><h5>Assembly (Step 6/{maxStep})</h5></div>
                        {activeStep !== 6 &&
                        <button
                            onClick={() => dispatch(changeStep(6))}
                            className="btn btn--circle btn--red">
                            <img src="/images/icons/edit-white.svg" alt=""/>
                        </button>
                        }
                    </div>
                    }


                    {!!uuid &&
                    <div>
                        <Link className={'btn btn--rounded btn--small btn--red'}
                              style={{width: '100%', marginBottom: '1rem'}}
                              to={RouteEnum.PASSPORT_DETAIL + '/' + uuid}><span>View passport</span></Link>
                    </div>
                    }
                    {isSaveAble.saveAble &&
                    <div className="c-app__create__overview__save">

                        <button
                            disabled={submitting}
                            onClick={() => dispatch(submit())}
                            className="btn btn--rounded btn--small btn--black">
                            <span
                                style={{display: 'flex', alignItems: 'center'}}>{title} C_passport<sup>®</sup>

                                {submitting &&
                                <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>
                                }

                            </span>
                        </button>

                        {submitted &&
                        <div className={'c-notification c-notification--green'} style={{marginTop: '1rem'}}>
                            <p style={{textAlign: 'center'}}>Successfully saved</p>
                        </div>
                        }

                    </div>
                    }

                    {(!isSaveAble.saveAble && isSaveAble.message) &&
                    <p className={'c-alert'} style={{left: 0, marginTop: '1rem'}}>
                        <b>{isSaveAble.message}</b>
                    </p>
                    }


                </aside>

                {isSaveAble.saveAble && submitted &&
                <div className={'c-notification c-notification--yellow c-notification--warning'}
                     style={{marginTop: '2rem'}}>
                    <p style={{fontWeight: 'bold'}}>Impact figures are being calculated in the background. This process
                        can take a few seconds up to a couple minutes. Please refresh the passport view page to see the
                        result.</p>
                </div>
                }

            </div>
        )


    } else return null

}
