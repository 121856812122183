import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserInterface} from "../../common/interfaces/UserInterface";
import {OrganisationInfoInterface} from "../../common/interfaces/OrganisationInfoInterface";
import {AddressInterface} from "../../common/interfaces/AddressInterface";
import nextId from "react-id-generator";
import {apiSubscriptionPreview} from "../../common/api/subscription/apiSubscriptionPreview";
import {SubscriptionInterface} from "../../common/interfaces/SubscriptionInterface";
import {apiOrganisationInfoUpgradeAccount} from "../../common/api/organisation/info/apiOrganisationInfoUpgradeAccount";
import {getUserMe, organisationDetail, RootInterface} from "../../app/rootReducer";


export const previewSubscription = createAsyncThunk(
  'upgrade/previewSubscription',
  async (_, {getState}) => {
    const {upgradeAccount} = getState() as { upgradeAccount: upgradeAccountStateInterface };

    if (upgradeAccount.preview.passportCount) {
      const response = await apiSubscriptionPreview('passport', upgradeAccount.preview.passportCount)
      return response.data.items.data
    }
  }
)

export const loadExamplePrices = createAsyncThunk(
  'upgrade/loadExamplePrices',
  async (_, {getState}) => {

    const {upgradeAccount} = getState() as { upgradeAccount: upgradeAccountStateInterface };

    const examples = JSON.parse(JSON.stringify(upgradeAccount.examples))

    const item = examples.map(async (example: any) => {

      let price = 0

      const subscriptionPassportResponse = await apiSubscriptionPreview('passport', example.passports.amount)
      example.passports.subscription = subscriptionPassportResponse.data?.items?.data

      price += Number(subscriptionPassportResponse.data?.items.data.price)

      const subscriptionUserResponse = await apiSubscriptionPreview('user', example.users.amount)
      example.users.subscription = subscriptionUserResponse.data?.items?.data

      price += Number(subscriptionUserResponse.data?.items.data.price)
      example.price = price.toFixed(2)

      return example

    })


    return Promise.all(item)

  }
)

export const load = createAsyncThunk(
  'upgrade/load',
  async (_, {getState}) => {
    const {root} = getState() as { root: RootInterface };

    return root.userMe


  }
)

export const submit = createAsyncThunk(
  'upgrade/submit',
  async (_, {getState, dispatch}) => {
    const {upgradeAccount} = getState() as { upgradeAccount: upgradeAccountStateInterface };

    if (upgradeAccount.organisation && upgradeAccount.addresses && upgradeAccount.user) {
      const response = await apiOrganisationInfoUpgradeAccount(upgradeAccount.organisation, upgradeAccount.user, upgradeAccount.addresses)

      dispatch(getUserMe())
      dispatch(organisationDetail())

      return response.data.status.code
    }
  }
)

interface upgradeAccountStateInterface {
  activeStep: number,
  submitting: boolean,
  submitStatus?: number,
  completed: boolean,
  user: UserInterface,
  organisation: OrganisationInfoInterface,
  addresses: AddressInterface[],
  preview: {
    passportCount: number,
    subscription?: SubscriptionInterface
  },
  examples: {
    icon: string,
    title: string,
    text: string,
    passports: {
      amount: number,
      subscription?: SubscriptionInterface
    },
    users: {
      amount: number,
      subscription?: SubscriptionInterface
    }
    price?: string
  }[]
}

const addressState = {
  createId: nextId(),
  street: '',
  number: undefined,
  typeId: undefined,
  postalCode: '',
  city: '',
  state: '',
  countryId: 123
}

const initialState: upgradeAccountStateInterface = {
  activeStep: 1,
  submitting: false,
  completed: false,
  examples: [
    {
      icon: 'company-small.png',
      title: 'Small company',
      text: 'A company with a small selection of products and moderate level of layering.',
      passports: {
        amount: 19
      },
      users: {
        amount: 4
      }
    },
    {
      icon: 'company-large.png',
      title: 'Large company',
      text: ' A company with a vast number of products and high level of layering.',
      passports: {
        amount: 400
      },
      users: {
        amount: 15
      }
    }
  ],
  user: {
    email: '',
    password: '',
    passwordRepeat: '',
    firstName: '',
    lastName: '',
    insertion: ''
  },
  organisation: {
    cocNumber: undefined,
    companyName: '',
    invoiceEmail: '',
    phoneNumber: '',
    vatNumber: '',
    website: '',
    contactPersonName: '',
    contactPersonEmail: ''
  },
  addresses: [
    {
      ...addressState,
      createId: 'default',
      typeId: 1
    }
  ],
  preview: {
    passportCount: 1,
  }
}

const upgradeAccountSlice = createSlice({
  name: 'upgradeAccount',
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setPreviewPassportCount: (state, action: PayloadAction<number>) => {
      state.preview.passportCount = action.payload
    },
    setUserData: (state, action: PayloadAction<UserInterface>) => {
      state.user = {
        ...state.user,
        ...action.payload
      }
    },
    setOrganisationData: (state, action: PayloadAction<OrganisationInfoInterface>) => {
      state.organisation = {
        ...state.organisation,
        ...action.payload
      }
    },
    setAddressData: (state, action: PayloadAction<AddressInterface>) => {

      state.addresses = state.addresses.map((address) => {
        if (address.createId === action.payload.createId) {
          return {
            ...address,
            ...action.payload
          }
        } else return address
      })

    },
    addAddress: (state) => {
      state.addresses.push({
        ...addressState,
        createId: nextId()
      })
    },
    removeAddress: (state, action: PayloadAction<AddressInterface>) => {
      state.addresses = state.addresses.filter((address) => address.createId !== action.payload.createId)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(submit.pending, (state) => {
      state.submitting = true
    })
    builder.addCase(submit.fulfilled, (state, action: PayloadAction<number>) => {
      state.submitting = false
      state.completed = true
      state.submitStatus = action.payload
    })
    builder.addCase(load.fulfilled, (state, action: PayloadAction<any>) => {
      state.user = action.payload
      state.organisation = action.payload.organisation
    })
    builder.addCase(loadExamplePrices.fulfilled, (state, action: PayloadAction<any>) => {
      state.examples = action.payload
    })
    builder.addCase(previewSubscription.fulfilled, (state, action: PayloadAction<SubscriptionInterface>) => {
      state.preview.subscription = action.payload
    })
    builder.addCase(submit.rejected, (state) => {
      state.submitting = false
      state.completed = false
      state.submitStatus = 400
    })
  },
})

export const {
  changeStep,
  setUserData,
  setOrganisationData,
  setAddressData,
  addAddress,
  removeAddress,
  setPreviewPassportCount
} = upgradeAccountSlice.actions
export const upgradeAccountReducer = upgradeAccountSlice.reducer