import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {setDuplicatePassport, duplicatePassport, setRelated} from "./duplicatePassportModalSlice";
import {HowToCloud} from "../../common/components/howTo/HowToCloud";


export const DuplicatePassportModalContainer = () => {

  const dispatch = useAppDispatch()
  const {passport, submitting, related} = useAppSelector(state => state.duplicatePassportModal)

  return (
    <div className="c-modal" style={{display: 'flex', opacity: 1, pointerEvents: 'all'}}>
      <div className="c-modal__background"/>
      <div className="c-modal__content">
        <button className="c-modal__close" onClick={() => {
          dispatch(setDuplicatePassport({
            showPopup: false
          }))
        }}>
          <img src="/images/icons/cross-black.svg" alt=""/>
        </button>
        <header className="c-modal__header">
          <h1>Duplicating passport - {passport?.name} {passport?.typeId !== 1 && <HowToCloud code={'pp-duplicate'} />}</h1>
        </header>
        <div className="c-modal__body">

          {passport?.typeId !== 1 && <section className={'c-modal__question'}>
              <p>Would you like to copy or connect the underlying passports?</p>

              <article className={'c-modal__question__bool-radios'}>
                  <div className="c-form__group">
                      <label onClick={() => dispatch(setRelated(true))}>
                          <input
                              type="radio"
                              checked={!!related}
                              name={'relatedPassportDuplication'}
                          />
                          Connect passports
                      </label>
                  </div>

                  <div className="c-form__group">
                      <label onClick={() => dispatch(setRelated(false))}>
                          <input
                              type="radio"
                              checked={!related}
                              name={'relatedPassportDuplication'}
                          />
                          Copy passports
                      </label>
                  </div>

              </article>

          </section>
          }

          <p>
            You are about to duplicate this passport. By doing so, your monthly bill might increase. Continue?
          </p>

          <button className="btn btn--rounded btn--red" disabled={submitting}
                  onClick={() => dispatch(duplicatePassport())}>
            <span>Confirm duplication</span>
          </button>

        </div>
      </div>
    </div>
  )

}