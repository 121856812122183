import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CompositionInterface, PassportInterface} from "../../common/interfaces/PassportInterface";
import {apiOrganisationPassportList} from "../../common/api/organisation/passport/apiOrganisationPassportList";
import {LoadPassportAction} from "../../common/actions/LoadPassportAction";
import {PassportTabEnums} from "../../common/enums/PassportTabEnums";
import {StyleInterface} from "../../common/interfaces/StyleInterface";
import {PassportPreferenceInterface} from "../../common/interfaces/PassportPreferenceInterface";
import {LoadStyleAction} from "../../common/actions/LoadStyleAction";
import {LoadPreferenceAction} from "../../common/actions/LoadPreferenceAction";
import {AssemblyInterface} from "../../common/interfaces/AssemblyInterface";
import {apiOrganisationPassportCalculateImpact} from "../../common/api/organisation/passport/apiOrganisationPassportCalculateImpact";
import {PassportImpactFiguresInterface} from "../../common/interfaces/PassportImpactFiguresInterface";

interface publicPassportInterface {
    passport: PassportInterface,
    passports?: {
        data: PassportInterface[],
        pages?: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    composition: CompositionInterface[],
    assemblies: AssemblyInterface[],
    loading: boolean,
    impactMultiplier: number,
    activeTab: number,
    style: StyleInterface,
    passportPreference: PassportPreferenceInterface,
    loadImpactCalculation: boolean
    activeImpactFigureTypeId?: number
}

const initialState: publicPassportInterface = {
    passport: {
        sdgGoals: [],
        connectedPassports: [],
    },
    composition: [],
    assemblies: [],
    loading: true,
    activeTab: PassportTabEnums.MATERIALS,
    impactMultiplier: 1,
    style: {
        customStyle: false
    },
    passportPreference: {},
    loadImpactCalculation: false
}

export const passportList = createAsyncThunk(
    'publicPassport/passportList',
    async (_) => {
        const response = await apiOrganisationPassportList(9999)
        if (response && response.data && response.data.items && response.data.items.data) {
            return response.data.items
        }
    }
)

export const load = createAsyncThunk(
    'publicPassport/load',
    LoadPassportAction
)

export const calculateImpact = createAsyncThunk(
    'publicPassport/calculateImpact',
    async (multiplier: number, {getState}) => {
        const {publicPassport} = getState() as { publicPassport: publicPassportInterface };

        const response = await apiOrganisationPassportCalculateImpact({
            uuid: publicPassport.passport.uuid,
            amount: multiplier,
            passportImpactFigureCalculationTypeId: publicPassport.activeImpactFigureTypeId
        })

        return response.data.items?.figures as PassportImpactFiguresInterface
    }
)


export const loadStyle = createAsyncThunk(
    'publicPassport/loadStyle',
    async (_, {getState}) => {
        const {publicPassport} = getState() as { publicPassport: publicPassportInterface };
        return LoadStyleAction(publicPassport.passport.organisation?.id)
    }
)

export const loadPreference = createAsyncThunk(
    'publicPassport/loadPreference',
    async (_, {getState}) => {
        const {publicPassport} = getState() as { publicPassport: publicPassportInterface };
        return LoadPreferenceAction(publicPassport.passport)
    }
)

const publicPassportSlice = createSlice({
    name: 'publicPassport',
    initialState,
    reducers: {
        changePublicTab: (state, action: PayloadAction<number>) => {
            state.activeTab = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(passportList.fulfilled, (state, action: PayloadAction<publicPassportInterface['passports']>) => {
            state.passports = action.payload
        })

        builder.addCase(loadStyle.fulfilled, (state, action: PayloadAction<StyleInterface>) => {
            state.style = action.payload
        })
        builder.addCase(loadPreference.fulfilled, (state, action: PayloadAction<PassportPreferenceInterface>) => {
            state.passportPreference = action.payload
            state.loading = false
        })

        builder.addCase(calculateImpact.pending, (state) => {
            state.loadImpactCalculation = true
        })
        builder.addCase(calculateImpact.fulfilled, (state, action: PayloadAction<PassportImpactFiguresInterface>) => {
            state.loadImpactCalculation = false
            state.passport.passportImpactFigures = state.passport.passportImpactFigures?.map((passportImpactFigure) => {

                if(action.payload.id === passportImpactFigure.id) {
                    return {
                        ...passportImpactFigure,
                        ...action.payload
                    }
                }

                return passportImpactFigure
            })
        })

        builder.addCase(load.pending, (state) => {
            Object.assign(state, initialState)
        })

        builder.addCase(load.fulfilled, (state, action: PayloadAction<any>) => {
            state.passport = action.payload.passport

            if (action.payload.composition && action.payload.composition.length) {
                state.composition = action.payload.composition
            }

            if (action.payload.assemblies && action.payload.assemblies.length) {
                state.assemblies = action.payload.assemblies
            }

            if (action.payload.activeImpactFigureTypeId) {
                state.activeImpactFigureTypeId = action.payload.activeImpactFigureTypeId
            }
        })
    }
})

export const {changePublicTab} = publicPassportSlice.actions

export const publicPassportReducer = publicPassportSlice.reducer
