import {useAppAxios} from "../../../../app/hooks";
import {ApiEnum} from "../../../enums/ApiEnum";
import {PassportPreferenceInterface} from "../../../interfaces/PassportPreferenceInterface";

export const apiOrganisationUpdatePassportPreference = async (passportPrefrence: PassportPreferenceInterface) => {

    return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/update-passport-preference',
        {
            passportPreference: passportPrefrence
        }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}