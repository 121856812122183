import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationPassportDuplicate = async (uuid: string, related: boolean) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_PASSPORT + '/duplicate', {
        relate: related ? 1 : 0
    }, {
        params: {
            uuid: uuid
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
