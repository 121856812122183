import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {PassportGroupInterface} from "../../../common/interfaces/PassportInterface";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../../common/enums/RouteEnum";
import {setFilterGroupName} from "../PassportOverviewPageSlice";
import {GridLoader} from "react-spinners";
import {displayModuleByUserRole} from "../../../common/utils/displayModuleByUserRole";
import {setDuplicatePassportGroup} from "../../../modals/duplicatePassportGroupModal/duplicatePassportGroupModalSlice";
import {setTrashPassportGroup} from "../../../modals/trashPassportGroupModal/trashPassportGroupModalSlice";

export const PassportOverviewGroups = () => {

    const {groups, settings, loading} = useAppSelector(state => state.passportOverview);
    const {userMe} = useAppSelector(state => state.root);

    const dispatch = useAppDispatch()

    if (groups && groups.data) {

        const {total} = groups

        return (
            <>

                <header className="c-app__view__header">
                    <h1>Groups overview ({total})</h1>
                </header>
                <form className="c-app__filter c-form--black" onSubmit={(e) => e.preventDefault()}>
                    <div className="c-form__row">
                        <div className="c-form__group c-form__group--horizontal">
                            <input
                                value={settings.filters.groupName}
                                onChange={(e) => {
                                    dispatch(setFilterGroupName(e.target.value))
                                }}
                                type="text" placeholder="Search groups"/>
                        </div>
                    </div>
                </form>

                {loading &&
                <div className={'c-loader'}>
                    <GridLoader color={'#E14343'}/>
                </div>
                }


                {Number(total) === 0 &&

                <div className="c-text c-text--small c-text--center"
                     style={{width: '100%', margin: '4rem 0', alignItems: 'flex-start'}}>
                    <p>
                        No groups found.
                    </p>
                    {displayModuleByUserRole('passportEdit', userMe) &&
                    <Link to={RouteEnum.PASSPORT_GROUP_CREATE} className="btn btn--rounded btn--red">
                        <span>Create new group</span>
                    </Link>
                    }
                </div>

                }

                {(Number(total) !== 0 && !loading) &&

                <div className="c-card" style={{minHeight: '30rem'}}>

                    {groups.data.map((group: PassportGroupInterface) => {

                        return (
                            <div className="c-card__single c-card__single--small" key={'group:' + group.id}>

                                <div className="c-card__single__info">
                                    <div className="c-card__single__info__text">
                                        <h3>{group.name}</h3>
                                    </div>
                                </div>

                                <div className="c-card__single__cta">
                                    <div className="c-card__single__cta__urls">
                                        <div>
                                            <Link to={RouteEnum.PASSPORT_GROUP + '/' + group.id}
                                                  className="btn btn--text"><span>View</span>
                                            </Link>

                                            {displayModuleByUserRole('passportEdit', userMe) &&
                                            <Link to={RouteEnum.PASSPORT_GROUP + '/' + group.id}
                                                  className="btn btn--red btn--circle">
                                                <img src="/images/icons/edit-white.svg" alt=""/></Link>
                                            }

                                            {displayModuleByUserRole('passportEdit', userMe) &&
                                            <button className="btn btn--black btn--circle"
                                                    style={{marginLeft: '.5rem'}}
                                                    onClick={() => {
                                                        dispatch(setTrashPassportGroup({
                                                            showPopup: true,
                                                            group: group
                                                        }))
                                                    }}
                                            >
                                                <img src="/images/icons/trash.svg" alt={'trash icon'}
                                                     style={{height: '2rem'}}/>
                                            </button>
                                            }

                                            {displayModuleByUserRole('passportEdit', userMe) &&
                                            <button className="btn btn--black btn--circle"
                                                    style={{marginLeft: '.5rem'}}
                                                    onClick={() => {
                                                        dispatch(setDuplicatePassportGroup({
                                                            showPopup: true,
                                                            group: group
                                                        }))
                                                    }}
                                            >

                                                <img src="/images/icons/icon-duplicate.svg" alt={'trash icon'}
                                                     style={{height: '2rem'}}/>

                                            </button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                }

            </>

        )
    } else return null

}
