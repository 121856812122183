import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserInterface} from "../../common/interfaces/UserInterface";
import {apiOrganisationUserSendResetMail} from "../../common/api/organisation/user/apiOrganisationUserSendResetMail";

interface forgotPasswordInterface {
    submitting: boolean,
    submitStatus?: number,
    user: UserInterface,
}

const initialState: forgotPasswordInterface = {
    submitting: false,
    user: {
        email: '',
    }
}

export const submit = createAsyncThunk(
    'forgotPassword/submit',
    async (_, {getState}) => {
        const {forgotPassword} = getState() as { forgotPassword: forgotPasswordInterface };
        if (forgotPassword.user.email) {
            const response = await apiOrganisationUserSendResetMail(forgotPassword.user)
            return response.data.status.code
        }
    }
)


const forgotPasswordSlice = createSlice({
    name: 'forgotPassword',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<UserInterface>) => {
            state.user = {
                ...state.user,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state, action: PayloadAction<number>) => {
            state.submitting = false
            state.submitStatus = action.payload
        })
    },
})

export const {setUserData} = forgotPasswordSlice.actions
export const forgotPasswordReducer = forgotPasswordSlice.reducer