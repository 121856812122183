import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AddressInterface} from "../../common/interfaces/AddressInterface";
import {apiOrganisationAddressList} from "../../common/api/organisation/address/apiOrganisationAddressList";

export const load = createAsyncThunk(
    'organisation/load',
    async () => {

        const addressResponse = await apiOrganisationAddressList()

        return {
            addresses: addressResponse.data.items.data
        }
    }
)

interface organisationStateInterface {
    addresses: AddressInterface[]
}

const initialState: organisationStateInterface = {
    addresses: []
}

const organisationSlice = createSlice({
    name: 'organisation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, action: PayloadAction<{ addresses: AddressInterface[] }>) => {
            state.addresses = action.payload.addresses
        })
    },
})

export const organisationReducer = organisationSlice.reducer