import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PassportGroupCreateInterface, PassportInterface} from "../../common/interfaces/PassportInterface";
import {apiOrganisationPassportList} from "../../common/api/organisation/passport/apiOrganisationPassportList";
import {apiOrganisationGroupCreate} from "../../common/api/organisation/group/apiOrganisationGroupCreate";
import {apiOrganisationGroupUpdate} from "../../common/api/organisation/group/apiOrganisationGroupUpdate";
import {apiOrganisationGroupDetail} from "../../common/api/organisation/group/apiOrganisationGroupDetail";

interface passportGroupCreateInterface {
  activeStep: number,
  id?: number,
  passportGroup: PassportGroupCreateInterface
  passports?: {
    data: PassportInterface[],
    pages?: {
      limit?: number,
      total?: number,
      current?: number
    }
    total?: number,
  },
  allPassports?: {
    data: PassportInterface[],
  },
  settings: {
    page: number,
    limit: number,
    filters: {
      name?: string,
      type?: number
    }
  },
  loading: boolean,
  created: boolean,
}

const initialState: passportGroupCreateInterface = {
  settings: {
    page: 1,
    limit: 10,
    filters: {
      type: 3,
    }
  },
  passportGroup: {
    name: '',
    description: '',
    passports: []
  },
  loading: true,
  activeStep: 1,
  created: false
}

export const allPassportsList = createAsyncThunk(
  'passportGroupCreate/allList',
  async (_) => {

    const response = await apiOrganisationPassportList(9999)
    if (response && response.data && response.data.items && response.data.items.data) {
      return response.data.items
    }
  }
)

export const passportSelectList = createAsyncThunk(
  'passportGroupCreate/list',
  async (_, {getState}) => {

    const {passportGroupCreate} = getState() as { passportGroupCreate: passportGroupCreateInterface };

    const response = await apiOrganisationPassportList(passportGroupCreate.settings.limit, passportGroupCreate.settings.page, passportGroupCreate.settings.filters.name, passportGroupCreate.settings.filters.type)
    if (response && response.data && response.data.items && response.data.items.data) {
      return response.data.items
    }
  }
)

export const load = createAsyncThunk(
  '',
  async (id: string, {}) => {

    const response = await apiOrganisationGroupDetail(id)

    const passportGroup = response.data.items.data
    const passports = passportGroup.passports

    passportGroup.passports = []

    passports.forEach((passport: PassportInterface) => {
      passportGroup.passports.push(passport.id)
    })

    if (response && response.data && response.data.items && passportGroup) {
      return passportGroup
    }
  }
)

export const submitGroup = createAsyncThunk(
  'passportGroupCreate/create',
  async (_, {getState}) => {

    const {passportGroupCreate} = getState() as { passportGroupCreate: passportGroupCreateInterface };

    if (passportGroupCreate.passportGroup.id) {
      const response = await apiOrganisationGroupUpdate(passportGroupCreate.passportGroup)

      if (response && response.data && response.data.items && response.data.items.data && response.data.items.data.id) {
        return response.data.items.data.id
      }
    } else {
      const response = await apiOrganisationGroupCreate(passportGroupCreate.passportGroup)

      if (response && response.data && response.data.items && response.data.items.data && response.data.items.data.id) {
        return response.data.items.data.id
      }
    }
  }
)

const passportGroupCreateSlice = createSlice({
  name: 'passportGroupCreate',
  initialState,
  reducers: {
    changeStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.settings.page = action.payload
    },
    setFilterName: (state, action: PayloadAction<string>) => {
      state.settings.filters.name = action.payload
      state.settings.page = 1
    },
    setName: (state, action: PayloadAction<string>) => {
      state.passportGroup.name = action.payload
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.passportGroup.description = action.payload
    },
    addPassport: (state, action: PayloadAction<number>) => {
      if (state.passportGroup.passports) {
        state.passportGroup.passports = [...state.passportGroup.passports, action.payload]
      }
    },
    removePassport: (state, action: PayloadAction<number>) => {
      let groupPassports = state.passportGroup.passports
      const index = groupPassports?.indexOf(action.payload, 0);
      if (groupPassports && index && index > -1) {
        groupPassports.splice(index, 1);
      }
      state.passportGroup.passports = groupPassports
    },
    removeAllPassports: (state) => {
      state.passportGroup.passports = []
    },
    resetCreated: (state) => {
      state.created = false
    },
    resetGroup: (state) => {
      delete state.passportGroup.id
      state.passportGroup.name = ''
      state.passportGroup.description = ''
      state.passportGroup.passports = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(passportSelectList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(passportSelectList.fulfilled, (state, action: PayloadAction<passportGroupCreateInterface["passports"]>) => {
      state.loading = false
      state.passports = action.payload
    })
    builder.addCase(allPassportsList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(allPassportsList.fulfilled, (state, action: PayloadAction<passportGroupCreateInterface["passports"]>) => {
      state.loading = false
      state.allPassports = action.payload
    })
    builder.addCase(load.pending, (state) => {
      state.loading = true
    })
    builder.addCase(load.fulfilled, (state, action: PayloadAction<PassportGroupCreateInterface>) => {
      state.loading = false
      state.passportGroup = action.payload
    })
    builder.addCase(submitGroup.pending, (state) => {
      state.loading = true
      state.created = false
    })
    builder.addCase(submitGroup.fulfilled, (state, action: PayloadAction<number>) => {
      state.loading = false
      state.created = true
      state.activeStep = 1
      state.settings.page = 1
      state.passportGroup.id = action.payload
      state.passportGroup.name = ''
      state.passportGroup.description = ''
      state.passportGroup.passports = []
    })
  }
})

export const {
  changeStep,
  setPage,
  setFilterName,
  setName,
  setDescription,
  addPassport,
  removePassport,
  removeAllPassports,
  resetCreated,
  resetGroup
} = passportGroupCreateSlice.actions

export const passportGroupCreateReducer = passportGroupCreateSlice.reducer
