import {Link} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";
import {useAppDispatch, useAppLocation, useAppSelector} from "../../../app/hooks";
import {authToggle} from "../../../app/rootReducer";


const menuItems = [
    {
        label: 'Cockpit',
        route: '/cockpit'
    },
    {
        label: 'C_dashboard<sup>®</sup>',
        route: RouteEnum.DASHBOARD_OVERVIEW
    },
    {
        label: 'C_passport<sup>®</sup>',
        route: RouteEnum.PASSPORT_OVERVIEW
    },
    {
        label: 'C_account',
        route: RouteEnum.ACCOUNT_ORGANISATION,
        userRoles: ['admin', 'super_admin']
    }
]

export const DashboardNavigation = () => {

    const location = useAppLocation()
    const dispatch = useAppDispatch()

    const {userMe} = useAppSelector((state) => state.root)

    return (
        <nav className="c-app__menu__nav">
            <ul className="u-reset-ul">
                {menuItems.map((menuItem) => {

                    let className = ''

                    if (location.pathname.includes(menuItem.route)) {
                        className += 'isActive'
                    }

                    if (location.pathname === '/' && menuItem.label === 'Cockpit') {
                        className += 'isActive'
                    }

                    if (!menuItem.userRoles || menuItem.userRoles.includes(userMe?.roleName + ''))

                        return (
                            <li className={className} key={'dashboard-navigation: ' + menuItem.label}>
                                <Link to={menuItem.route}>
                                    <div dangerouslySetInnerHTML={{__html: menuItem.label}}/>
                                </Link>
                            </li>
                        )

                    else return null

                })}

                <li>
                    <button
                        onClick={() => {
                            localStorage.removeItem('user')
                            dispatch(authToggle())
                        }

                        }
                        className={'btn btn--small btn--outline btn--rounded'}><span>Log out</span></button>
                </li>

            </ul>
        </nav>
    )

}