export const formatImpactNumberDecimals = (impactNumber: string) => {

  const decimalNumber = (impactNumber + "").split(".")[1]
  let formattedCompositionPercentage = parseFloat(impactNumber + '')
  let fixedNumber = 2

  if (decimalNumber && decimalNumber.slice(0, 2) === '00' && decimalNumber.slice(0, 10) !== '00000000') {
    fixedNumber = Math.floor(Math.abs(Math.log10(formattedCompositionPercentage))) + 1
  }

  return formattedCompositionPercentage.toFixed(fixedNumber)

}
