import {PassportSettingsPublicView} from "./components/PassportSettingsPublicView";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

import {submitPublic} from "./PassportSettingsSlice";
import {PassportSettingsHeader} from "./components/PassportSettingsHeader";
import {PassportSettingsNavigation} from "./components/PassportSettingsNavigation";

const PassportSettingsPageContainer = () => {

    const {activeView} = useAppSelector((state) => state.passportSettings)

    const dispatch = useAppDispatch()

    return (

        <form
            onSubmit={(e) => {
                e.preventDefault()
                if (activeView === 'public') {
                    dispatch(submitPublic())
                }
            }}>

            <PassportSettingsHeader/>


            <div className="c-app__content__container">
                <div className="c-app__view">
                    <PassportSettingsNavigation/>
                    {activeView === 'public' && <PassportSettingsPublicView/>}
                </div>
            </div>
        </form>

    )

}

export default PassportSettingsPageContainer