import React from "react";
import {UpgradeIntro} from "./UpgradeIntro";

interface Props {
  children?: React.ReactNode;
};

export const UpgradeWrapper = (props: Props) => {

  return (
    <section className="c-signup c-signup--upgrade">
      <div className="o-container o-container--secondary">
        <UpgradeIntro/>
        {props.children}
      </div>
    </section>
  )

}

