import {Link} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";
import {useAppSelector} from "../../../app/hooks";

interface Props {
  text: string
}


export const FreeTrialBanner = (props: Props) => {

  const {userMe} = useAppSelector((state) => state.root)

  if (!userMe?.organisation?.freeTrial) {
    return null
  }

  return (
    <footer className={'c-free-trial-banner'}>
      <p>{props.text}</p>
      <Link to={RouteEnum.UPGRADE_ACCOUNT} className={'btn btn--small btn--red btn--rounded'}>
        <span>Complete account</span>
      </Link>
    </footer>
  )

}