import {decamelize} from "humps";
import {DashboardInterface} from "../interfaces/DashboardInterface";

export const dashboardToFormData = (dashboard: DashboardInterface) => {

    let passportCount = 0
    const formData = new FormData();

    Object.entries(dashboard).forEach(([key, value]) => {

        if (value !== null &&  key !== 'id' && key !== 'passports') {
            switch (key) {
                default:
                    formData.append('Dashboard[' + decamelize(key.replace('Upload', '')) + ']', value)
                    break;
            }
        }
    })

    dashboard.passports?.forEach((passport) => {
        formData.append('passports[' + passportCount + '][passport_id]', passport.passportId + '')
        formData.append('passports[' + passportCount + '][amount]', passport.amount + '')
        passportCount++
    })

    return formData

}
