import {PassportInterface} from "../interfaces/PassportInterface";
import {PassportPreferenceInterface} from "../interfaces/PassportPreferenceInterface";
import {PassportTabEnums} from "../enums/PassportTabEnums";
import {TranslationsInterface} from "../interfaces/TranslationsInterface";

export const formatDashboardTabButtons = (passport: PassportInterface, passportPreference?: PassportPreferenceInterface, translations?: TranslationsInterface) => {

    let buttons = [
        {
            title: translations?.materials,
            id: PassportTabEnums.MATERIALS
        },
        {
            title: translations?.impact,
            id: PassportTabEnums.IMPACT
        },
        {
            title: translations?.encodings,
            id: PassportTabEnums.ENCODINGS
        },
        {
            title: translations?.extraInformation,
            id: PassportTabEnums.EXTRA_INFORMATION
        },
    ]

    if (passportPreference) {
        const {
            energyConsumption,
            waterConsumption,
            emission,
            rawMaterialConsumption,
            sroiConsumption
        } = passportPreference

        if (!energyConsumption && !waterConsumption && !emission && !rawMaterialConsumption && !sroiConsumption) {
            buttons = buttons.filter((button) => button.id !== PassportTabEnums.IMPACT)
        }

        if (!passportPreference.materialShareDiagram) {
            buttons = buttons.filter((button) => button.id !== PassportTabEnums.MATERIALS)
        }

        if (!passportPreference.extraInformation) {
            buttons = buttons.filter((button) => button.id !== PassportTabEnums.EXTRA_INFORMATION)
        }

        if (!passportPreference.encodings) {
            buttons = buttons.filter((button) => button.id !== PassportTabEnums.ENCODINGS)
        }
    }

    if (passport) {
        const {
            msdsFile,
            webUrl,
            reverseLogisticsInstructionFiles,
            reverseLogisticsInstructionFilesComment,
            nextUseApplicationDescriptionFiles,
            nextUseApplicationDescriptionFilesComment,
            assessmentInstructionFiles,
            assessmentInstructionFilesComment,
            disassemblyInstructionFiles,
            disassemblyInstructionFilesComment,
            recyclingInstructionFiles,
            recyclingInstructionFilesComment,
            code,
            etimCode,
            gtinNumber,
            serialNumber,
        } = passport

        const hasFiles = reverseLogisticsInstructionFiles?.length !== 0
            || !!reverseLogisticsInstructionFilesComment
            || assessmentInstructionFiles?.length !== 0
            || !!assessmentInstructionFilesComment
            || disassemblyInstructionFiles?.length !== 0
            || !!disassemblyInstructionFilesComment
            || recyclingInstructionFiles?.length !== 0
            || !!recyclingInstructionFilesComment
            || nextUseApplicationDescriptionFiles?.length !== 0
            || !!nextUseApplicationDescriptionFilesComment

        if (!msdsFile && !webUrl && !hasFiles) {
            buttons = buttons.filter((button) => button.id !== PassportTabEnums.EXTRA_INFORMATION)
        }

        if (!code && !etimCode && !gtinNumber && !serialNumber) {
            buttons = buttons.filter((button) => button.id !== PassportTabEnums.ENCODINGS)
        }

        if (!passport.passportImpactFigures?.length) {
            buttons = buttons.filter((button) => button.id !== PassportTabEnums.IMPACT)
        }

    }

    return buttons
}
