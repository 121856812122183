import {Link, useHistory, useParams} from "react-router-dom";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {allPassportsList, changeStep, passportSelectList, submitGroup} from "./passportGroupCreateSlice";
import PassportGroupCreateInformationForm from "./steps/PassportGroupCreateInformationForm";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import PassportGroupCreateInformationSelect from "./steps/PassportGroupCreatePassportSelect";
import {useEffect} from "react";
import {resetCreated, resetGroup, load} from "./passportGroupCreateSlice";

interface PassportGroupEditParams {
  id: string;
}

const PassportGroupCreatePageContainer = () => {

  const dispatch = useAppDispatch()
  const history = useHistory()

  const {id} = useParams<PassportGroupEditParams>();

  const {activeStep, created, passportGroup} = useAppSelector(state => state.passportGroupCreate);

  let step1Class = ''
  let step2Class = ''

  if (activeStep === 1) {
    step1Class += 'isActive'
  }

  if (activeStep === 2) {
    step2Class += 'isActive'
  }

  useEffect(() => {
    dispatch(resetGroup())
    dispatch(allPassportsList())
    dispatch(passportSelectList())

    if (id) {
      dispatch(load(id))
    } else {
      dispatch(changeStep(1))
    }

  }, [dispatch, id])

  useEffect(() => {
    if (created && passportGroup && passportGroup.id) {
      dispatch(resetCreated())
      history.push(RouteEnum.PASSPORT_GROUP + '/' + passportGroup.id);
    }
  }, [created])

  return (
    <>

      <div className={'c-app__content__header'}>
        <nav className="c-breadcrumbs">
          <ul className="u-reset-ul">
            <li>
              <Link to={RouteEnum.PASSPORT_OVERVIEW}>C_passport<sup>®</sup></Link>
              <img src="/images/icons/arrow-right-black.svg" alt=""/>
            </li>
            {!!id ? (
              <li>
                <Link to={RouteEnum.PASSPORT_GROUP_EDIT + '/' + id}>{passportGroup.name}</Link>
                <img src="/images/icons/arrow-right-black.svg" alt=""/>
              </li>
            ) : (
              <li>
                <Link to={RouteEnum.PASSPORT_GROUP_CREATE}>Create group</Link>
                <img src="/images/icons/arrow-right-black.svg" alt=""/>
              </li>
            )}
          </ul>
        </nav>

        <div>

          <Link to={RouteEnum.PASSPORT_OVERVIEW} className="btn btn--small btn--outline btn--rounded">
            <span>Cancel</span>
          </Link>

          <button onClick={() => dispatch(submitGroup())} className="btn btn--small btn--black btn--rounded">
            <span>Save</span>
          </button>

        </div>

      </div>

      <div className="c-app__view">

        <div className="c-app__passport-group">

          <div className="c-app__passport-group__content c-app__passport-group__content--steps">
            <div className={'c-app__passport-group__step c-app__passport-group__step--pointer ' + step1Class}
                 onClick={() => dispatch(changeStep(1))}>
              <div className="btn btn--red btn--circle">1</div>
              <span>Information</span>
            </div>
            <div className={'c-app__passport-group__step ' + step2Class}>
              <div className="btn btn--red btn--circle">2</div>
              <span>Add C_passports<sup>®</sup></span>
            </div>
          </div>

          {activeStep === 1 &&
          <PassportGroupCreateInformationForm/>
          }

          {activeStep === 2 &&
          <PassportGroupCreateInformationSelect/>
          }

        </div>

      </div>


    </>
  )

}

export default PassportGroupCreatePageContainer
