import {useQRCode} from "next-qrcode";
import {RouteEnum} from "../../enums/RouteEnum";
import {DashboardInterface} from "../../interfaces/DashboardInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    dashboard: DashboardInterface,
    translations?: TranslationsInterface
}

export const DashboardQrCode = (props: Props) => {

    const {inputRef} = useQRCode<HTMLCanvasElement>({
        text: window.location.origin + RouteEnum.PUBLIC_DASHBOARD + '/' + props.dashboard.uuid,
        options: {
            level: 'M',
            margin: 0,
            scale: 1,
            width: 100,
            color: {
                dark: '#000',
            },
        },
    });

    const downloadQR = (title: string) => {
        const canvas = document.getElementById("qr-code") as any;
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = title + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className="c-dashboard__group c-dashboard__primary__share">
            <div
                className="c-dashboard__card background-color-blocks c-passport__public c-passport__public--public">
                <canvas id={'qr-code'} ref={inputRef}/>
                <button
                    onClick={() => downloadQR(props.dashboard.title + '')}
                    className="btn btn--small btn--rounded btn--red"
                    style={{marginTop: '2rem'}}>
                    <span>{props.translations?.downloadQrCode}</span>
                </button>
                <div
                    onClick={() => navigator.clipboard.writeText(window.location.origin + RouteEnum.PUBLIC_DASHBOARD + '/' + props.dashboard.uuid)}
                    className="btn btn--text">
                    <span className="text-color-copy">{props.translations?.copyPublic} C_dashboard<sup>®</sup> {props.translations?.link}</span>
                </div>
            </div>
        </div>
    )

}
