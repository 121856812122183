import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiLabelList = async () => {
    return await useAppAxios.post(ApiEnum.LABEL + '/list', {}, {
        params: {
            limit: 9999
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}

