import {useAppSelector} from "../../../app/hooks";

export const SdgGoals = () => {
    const {info} = useAppSelector((state) => state.cockpit)

    return (
        <>
            {!!info?.sdgGoals.length &&
                <section className="c-dashboard__row">
                    <div className="c-dashboard__group">
                        <div className="c-dashboard__card c-dashboard__card--full-width">
                            <h4 className="c-cockpit__card-title">Sustainable Development Goals (SDG)</h4>
                            <div className="c-cockpit__sdg-container">

                                {info?.sdgGoals.map((sdgGoal) => {

                                    return (
                                        <img className="c-cockpit__sdg-container__sdg"
                                             src={sdgGoal.image}/>
                                    )
                                })}

                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}