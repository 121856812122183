import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationDashboardList = async (limit?: number, page?: number) => {

    if (page) {
        page = page - 1
    }

    return await useAppAxios.post(ApiEnum.ORGANISATION_DASHBOARD + '/list', {}, {
        params: {
            page: page,
            limit: limit
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
