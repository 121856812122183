import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiMaterialSets = async () => {
    return await useAppAxios.post(ApiEnum.MATERIAL + '/sets?limit=99999', {}, {
        params: {
            limit: 9999
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}

