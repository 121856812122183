import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiOrganisationUserList} from "../../common/api/organisation/user/apiOrganisationUserList";
import {UserInterface} from "../../common/interfaces/UserInterface";
import {apiOrganisationUserDelete} from "../../common/api/organisation/user/apiOrganisationUserDelete";

export const load = createAsyncThunk(
    'users/load',
    async () => {
        const response = await apiOrganisationUserList()
        return response.data.items.users
    }
)

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (id:number) => {
        const response = await apiOrganisationUserDelete(id)
        return response.data.items.users
    }
)

interface usersStateInterface {
    users: UserInterface[],
}

const initialState: usersStateInterface = {
    users: []
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, action: PayloadAction<UserInterface[]>) => {
            state.users = action.payload
        })
    },
})

export const usersReducer = usersSlice.reducer