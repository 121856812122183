import {SdgGoalInterface} from "../../interfaces/SdgGoalInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    sdgGoals?: SdgGoalInterface[],
    translations?: TranslationsInterface
}

export const DashboardSdgGoals = (props: Props) => {

    const {sdgGoals} = props

    if (!!sdgGoals?.length) {
        return (
            <div className="c-dashboard__row">
                <div className="c-dashboard__group">
                    <div className="c-dashboard__card background-color-blocks c-dashboard__sdg">
                        <div className="c-dashboard__sdg__icons">
                            {sdgGoals.map((sdgGoal) => {
                                return (
                                    <img src={sdgGoal.image} alt={''}/>
                                )
                            })}
                        </div>
                        <div className="c-dashboard__sdg__text">
                            <h2>{props.translations?.sustainableDevelopmentGoals}</h2>
                            <p className="text-color-copy">{props.translations?.sdgText}</p></div>
                    </div>
                </div>
            </div>
        )
    } else return null

}
