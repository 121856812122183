import {DashboardContainerInterface} from "../../interfaces/DashboardContainerInterface";
import {useAppSelector} from "../../../app/hooks";
import {FormatCompositionToPieData} from "../../utils/FormatCompositionToPieData";
import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {AssemblyInterface} from "../../interfaces/AssemblyInterface";
import {SimulatedPassportAmountInterface} from "../../interfaces/SimulatedPassportAmountInterface";
import {DashboardConnectedPassport} from "./DashboardConnectedPassport";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {DashboardPreferenceInterface} from "../../interfaces/DashboardPreferenceInterface";

interface Props {
    dashboardContainer?: DashboardContainerInterface,
    connectedPassports?: {
        passport: PassportInterface,
        assembly: AssemblyInterface,
        composition: CompositionInterface[]
    }[],
    simulatedPassportAmounts?: SimulatedPassportAmountInterface[],
    setSimulatedPassportAmount?: any,
    translations?: TranslationsInterface,
    loadImpacts?: boolean
    dashboardPreference?: DashboardPreferenceInterface,
    setPassportTab?: any
}

export const DashboardConnectedPassports = (props: Props) => {

    const {
        connectedPassports,
        dashboardContainer,
        simulatedPassportAmounts,
        setSimulatedPassportAmount,
        loadImpacts,
        dashboardPreference
    } = props
    const {materialGroup, materials} = useAppSelector(state => state.root.options);

    let hideMaterials = false
    if (dashboardPreference && !dashboardPreference.materialsVisible) {
        hideMaterials = true
    }

    if (connectedPassports && dashboardContainer && materialGroup && materials) {
        return (
            <div className="c-dashboard__row c-dashboard__passports-row" style={{
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                marginBottom: 0,
                opacity: loadImpacts ? 0.2 : 1
            }}>
                {connectedPassports.map((connectedPassport) => {

                    const graphData = FormatCompositionToPieData({
                        composition: connectedPassport.composition,
                        materialGroup: materialGroup.data,
                        materials: materials.data,
                        hideMaterials: hideMaterials
                    })
                    let passportWidth = connectedPassports.length > 1 ? '20rem' : '30rem';
                    let passportMaxWidth = connectedPassports.length > 1 ? 'calc(50% - 1rem)' : '100%';

                    const simulatedPassportAmount = simulatedPassportAmounts?.find((simulatedPassportAmount) => simulatedPassportAmount.uuid === connectedPassport.passport.uuid)

                    return <DashboardConnectedPassport
                        translations={props.translations}
                        graphData={graphData}
                        passportStyles={{
                            width: passportWidth,
                            maxWidth: passportMaxWidth
                        }}
                        simulatedPassportAmount={simulatedPassportAmount}
                        connectedPassport={connectedPassport}
                        setSimulatedPassportAmount={setSimulatedPassportAmount}
                        hideMaterials={hideMaterials}
                        setPassportTab={props.setPassportTab}
                    />
                })}
            </div>
        )
    } else return null

}
