import {DashboardContainerInterface} from "../../interfaces/DashboardContainerInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    dashboardContainer?: DashboardContainerInterface,
    translations?: TranslationsInterface
}

export const DashboardIntroduction = (props: Props) => {

    const {dashboardContainer} = props

    if (dashboardContainer) {
        return (
            <div className="c-dashboard__row c-dashboard__intro">
                {dashboardContainer.dashboard.image &&
                <div className="c-dashboard__group">
                    <div className="c-dashboard__card background-color-blocks c-dashboard__image">
                        <img src={dashboardContainer.dashboard.image} alt={''}/>
                    </div>
                </div>
                }

                {(!!dashboardContainer.dashboard.title || !!dashboardContainer.dashboard.introduction) &&
                <div className="c-dashboard__group" style={{flex: 1}}>
                    <div className="c-dashboard__card background-color-blocks c-dashboard__info" style={{flex: 1}}>
                        <h1>{dashboardContainer.dashboard.title}</h1>
                        <p>
                            {dashboardContainer.dashboard.introduction}
                        </p>
                    </div>
                </div>
                }
            </div>
        )
    } else return null

}
