import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PassportGroupInterface} from "../../common/interfaces/PassportInterface";
import {apiOrganisationGroupDuplicate} from "../../common/api/organisation/group/apiOrganisationGroupDuplicate";


interface DuplicatePassportGroupInterface {
    group?: PassportGroupInterface,
    submitting: boolean,
}


export const duplicatePassportGroup = createAsyncThunk(
    'duplicateGroupModal/duplicatePassportGroup',
    async (_, {getState}) => {

        const {duplicatePassportGroupModal} = getState() as { duplicatePassportGroupModal: DuplicatePassportGroupInterface };

        if (duplicatePassportGroupModal.group?.id) {
            const response = await apiOrganisationGroupDuplicate(duplicatePassportGroupModal.group.id)

            if (response && response.data && response.data.items && response.data.items.data) {
                return response.data.items
            }
        }
    }
)


const initialState: DuplicatePassportGroupInterface = {
    submitting: false
}

const duplicatePassportGroupModal = createSlice({
    name: 'duplicatePassportGroupModal',
    initialState,
    reducers: {
        setDuplicatePassportGroup: (state, action: PayloadAction<{
            group?: PassportGroupInterface,
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup) {
                state.group = action.payload.group
            } else {
                delete state.group
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(duplicatePassportGroup.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(duplicatePassportGroup.fulfilled, (state) => {
            delete state.group
            state.submitting = false
        })
    },
})

export const {setDuplicatePassportGroup} = duplicatePassportGroupModal.actions

export const duplicatePassportGroupModalReducer = duplicatePassportGroupModal.reducer
