import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Link, useHistory, useParams} from "react-router-dom";
import {GridLoader} from "react-spinners";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {displayModuleByUserRole} from "../../common/utils/displayModuleByUserRole";
import {passportGroupDetail, setFilterName} from "./PassportGroupSlice";
import {setDuplicatePassport} from "../../modals/duplicatePassportModal/duplicatePassportModalSlice";
import {PassportInterface} from "../../common/interfaces/PassportInterface";
import {setTrashPassportGroup, resetDeleted} from "../../modals/trashPassportGroupModal/trashPassportGroupModalSlice";
import {changeStep} from "../passportGroupCreatePage/passportGroupCreateSlice";
import {setRemoveFromPassportGroup} from "../../modals/removeFromPassportGroupModal/removeFromPassportGroupModalSlice";

interface PassportGroupParams {
  id: string;
}

const PassportGroupPageContainer = () => {

  const {id} = useParams<PassportGroupParams>();
  const dispatch = useAppDispatch()
  const history = useHistory()

  const {userMe} = useAppSelector(state => state.root);
  const {passportGroup, loading, passportFilter} = useAppSelector(state => state.passportGroup);
  const {deleted} = useAppSelector(state => state.trashPassportGroupModal)
  const {duplicated} = useAppSelector(state => state.duplicatePassportModal);

  const {submitting} = useAppSelector(state => state.removeFromPassportGroupModal)

  const [passports, setPassports] = useState<PassportInterface[]>([])

  useEffect(() => {
      dispatch(passportGroupDetail(id))
    }, [submitting, duplicated]
  );

  /** TODO: Dont put logic like this in the view */

  useEffect(() => {
    if (passportGroup.passports) {
      setPassports(passportGroup.passports)
      if (passportFilter) {
        setPassports(passportGroup.passports.filter((passport) => {
          if (passport.name) {
            const passportName = passport.name.toLowerCase()
            if (passportName.includes(passportFilter.toLowerCase())) {
              return true
            }
          }
        }))
      }
    }
  }, [passportGroup, passportFilter])

  useEffect(() => {
    if (deleted) {
      dispatch(resetDeleted())
      history.push(RouteEnum.PASSPORT_OVERVIEW);
    }
  }, [deleted])

  const editInfo = () => {
    dispatch(changeStep(1))
    history.push(RouteEnum.PASSPORT_GROUP_EDIT + '/' + id)
  }

  const editPassports = () => {
    dispatch(changeStep(2))
    history.push(RouteEnum.PASSPORT_GROUP_EDIT + '/' + id)
  }

  if (!loading) {

    return (
      <>

        <div className={'c-app__content__header'}>
          <nav className="c-breadcrumbs">
            <ul className="u-reset-ul">
              <li>
                <Link to={RouteEnum.PASSPORT_OVERVIEW}>C_passport<sup>®</sup></Link>
                <img src="/images/icons/arrow-right-black.svg" alt=""/>
              </li>
              <li>
                <Link to={RouteEnum.PASSPORT_GROUP + '/' + passportGroup.id}>{passportGroup.name}</Link>
                <img src="/images/icons/arrow-right-black.svg" alt=""/>
              </li>
            </ul>
          </nav>

          <div>

            <button className="btn btn--small btn--red btn--rounded" onClick={() => {
              dispatch(setTrashPassportGroup({
                showPopup: true,
                detailPage: true,
                group: passportGroup
              }))
            }}>
              <span>Delete group</span>
            </button>

            <button onClick={() => editInfo()} className="btn btn--small btn--outline btn--rounded">
              <span>Edit Information</span>
            </button>

            <button onClick={() => editPassports()} className="btn btn--small btn--black btn--rounded">
              <span>Edit C_passports<sup>®</sup></span>
            </button>

          </div>

        </div>

        <div className="c-app__view">

          <div className="c-app__passport-group">
            <div className="c-app__passport-group__content">
              <h1>{passportGroup.name}</h1>
            </div>

            {!!passportGroup.description &&
            <div className="c-app__passport-group__content">
                <p>{passportGroup.description}</p>
            </div>
            }

          </div>

          <form className="c-app__filter c-form--black c-app__filter--passport-group"
                onSubmit={(e) => e.preventDefault()}>
            <div className="c-form__row">
              <h2>C_passports<sup>®</sup> in this group ({passports ? passports.length : 0})</h2>
              <div className="c-form__group c-form__group--horizontal">
                <input
                  value={passportFilter}
                  onChange={(e) => {
                    dispatch(setFilterName(e.target.value))
                  }}
                  type="text" placeholder="Search passports"/>
              </div>
            </div>
          </form>

          {loading &&
          <div className={'c-loader'}>
              <GridLoader color={'#E14343'}/>
          </div>
          }

          {(passports && passports.length !== 0 && !loading) &&

          <div className="c-card" style={{minHeight: '30rem'}}>

            {passports.map((passport) => {

              return (
                <div className="c-card__single c-card__single--small" key={'group:' + passport.id}>

                  <div className="c-card__single__info">
                    <div className="c-card__single__info__text">
                      <h3>{passport.name}</h3>
                    </div>
                  </div>

                  <div className="c-card__single__cta">
                    <div className="c-card__single__cta__urls">
                      <div>
                        <Link to={RouteEnum.PASSPORT_DETAIL + '/' + passport.uuid}
                              className="btn btn--text"><span>View</span>
                        </Link>

                        {displayModuleByUserRole('passportEdit', userMe) &&
                        <Link to={RouteEnum.PASSPORT_EDIT + '/' + passport.uuid}
                              className="btn btn--red btn--circle">
                            <img src="/images/icons/edit-white.svg" alt=""/></Link>
                        }

                        {displayModuleByUserRole('passportEdit', userMe) &&
                        <button className="btn btn--black btn--circle"
                                style={{marginLeft: '.5rem'}}
                                onClick={() => {
                                  dispatch(setRemoveFromPassportGroup({
                                    showPopup: true,
                                    passportId: passport.id,
                                    group: passportGroup
                                  }))
                                }}
                        >
                            <img src="/images/icons/trash.svg" alt={'trash icon'}
                                 style={{height: '2rem'}}/>
                        </button>
                        }

                        {displayModuleByUserRole('passportEdit', userMe) &&
                        <button className="btn btn--black btn--circle"
                                style={{marginLeft: '.5rem'}}
                                onClick={() => {
                                  dispatch(setDuplicatePassport({
                                    showPopup: true,
                                    passport: passport,
                                    passportGroup: {
                                      ...passportGroup,
                                      passports: passportGroup.passports?.map((passport) => {
                                        if (passport.id) {
                                          return passport.id
                                        } else return 0
                                      })
                                    }
                                  }))
                                }}
                        >

                            <img src="/images/icons/icon-duplicate.svg" alt={'duplicate icon'}
                                 style={{height: '2rem'}}/>

                        </button>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          }

        </div>

      </>
    )
  } else return (
    <div className={'c-loader'}>
      <GridLoader color={'#E14343'}/>
    </div>
  )

}

export default PassportGroupPageContainer
