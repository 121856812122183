import {ForgotPasswordWrapper} from "./components/ForgotPasswordWrapper";
import {ForgotPasswordForm} from "./components/ForgotPasswordForm";

const ForgotPasswordPageContainer = () => {

    return (
        <ForgotPasswordWrapper>
            <ForgotPasswordForm />
        </ForgotPasswordWrapper>
    )

}

export default ForgotPasswordPageContainer