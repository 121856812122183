import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    extraInformation?: string,
    translations?: TranslationsInterface
}

export const DashboardExtraInformation = (props: Props) => {
    if (props.extraInformation) {
        return (
            <div className="c-dashboard__row c-dash-extra-info">
                <div className="c-dashboard__group">
                    <div className="c-dashboard__card c-dashboard__info background-color-blocks c-dashboard__info--accordion isActive"
                         style={{minWidth: '100%'}}>
                        <header><h4>{props.translations?.extraInformation}</h4></header>
                        <div dangerouslySetInnerHTML={{__html: props.extraInformation}}/>

                    </div>
                </div>
            </div>
        )
    } else return null
}
