import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {AssemblyInterface} from "../../interfaces/AssemblyInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {PassportConnectedPassportLink} from "./PassportConnectedPassportLink";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    passports?: {
        data?: PassportInterface[]
    },
    translations?: TranslationsInterface,
    assemblies?: AssemblyInterface[],
}

export const PassportConnectedPassports = (props: Props) => {

    if (props.assemblies) {


        return (
            <div className="c-dashboard__card c-dashboard__card--full-width c-passport__connected">
                <h2>{props.translations?.connected} C_passport<sup>®</sup></h2>

                <div className="c-passport__connected__single c-passport__connected__single--parent">
                    <img src="/images/icons/passport.svg" alt=""/>
                    <span>{props.passport.name}</span>
                </div>

                {props.assemblies?.map((assembly) => {

                    const connectedPassport = props.passports?.data?.find((passport) => passport.id === assembly.assemblyPassportId)


                    if (connectedPassport) {
                        return (
                            <>
                                <PassportConnectedPassportLink connectedPassport={connectedPassport} assemblyAmount={assembly.amount} isGrandChild={false}/>

                                {assembly.children?.map((child) => {
                                    const childPassport = props.passports?.data?.find((passport) => passport.id === child.assemblyPassportId)

                                    if (childPassport) {
                                        return (
                                            <PassportConnectedPassportLink connectedPassport={childPassport} assemblyAmount={child.amount} isGrandChild={true}/>
                                        )
                                    } else return null
                                })}
                            </>

                        )
                    } else return null
                })}

            </div>
        )
    } else return null

}
