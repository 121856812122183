import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {OrganisationInfoInterface} from "../../../interfaces/OrganisationInfoInterface";
import {UserInterface} from "../../../interfaces/UserInterface";

export const apiOrganisationInfoCreate = async (organisation: OrganisationInfoInterface, user: UserInterface, captchaToken: string) => {

  return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/create', {
    organisation: organisation,
    user: user,
    captchaToken: captchaToken
  }).then(function (response) {
    return response
  }).catch(function (error) {
    return error
  })
}
