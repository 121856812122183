import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {StyleInterface} from "../../common/interfaces/StyleInterface";
import {apiOrganisationInfoStyle} from "../../common/api/organisation/info/apiOrganisationInfoStyle";
import {apiOrganisationInfoStyleUpdate} from "../../common/api/organisation/info/apiOrganisationInfoStyleUpdate";

interface StylingStateInterface {
    edit: boolean,
    submitting: boolean,
    submitStatus?: number,
    style: StyleInterface,
}

const initialState: StylingStateInterface = {
    submitting: false,
    edit: false,
    style: {
        customStyle: false
    },
}


export const load = createAsyncThunk(
    'style/load',
    async () => {
        const response = await apiOrganisationInfoStyle()
        return response.data.items.style
    }
)

export const submit = createAsyncThunk(
    'style/submit',
    async (_, {getState}) => {
        const {styling} = getState() as { styling: StylingStateInterface };
        await apiOrganisationInfoStyleUpdate(styling.style)
    }
)


const style = createSlice({
    name: 'style',
    initialState,
    reducers: {
        setStylingData: (state, action: PayloadAction<StyleInterface>) => {

            state.edit = true

            state.style = {
                ...state.style,
                ...action.payload
            }
        },

    },
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, action: PayloadAction<StyleInterface>) => {
            state.style = action.payload
        })
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state) => {
            state.submitting = false
            state.edit = false
        })

    }
})

export const {
    setStylingData,
} = style.actions
export const styleReducer = style.reducer