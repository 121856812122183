import {SignupWrapper} from "./components/SignupWrapper";
import {SignupStep} from "./steps/SignupStep";
import {useAppSelector} from "../../app/hooks";
import {SignupResult} from "./steps/SignupResult";

const SignupPageContainer = () => {

  const {completed, submitStatus} = useAppSelector(state => state.signup);

  return (
    <SignupWrapper>
      {!completed && <SignupStep/>}
      {!!completed && !(
        submitStatus === 401 ||
        submitStatus === 404 ||
        submitStatus === 400) &&
      <SignupResult/>}
    </SignupWrapper>
  )

}

export default SignupPageContainer
