import {Page, Text, View, Image, Document, StyleSheet, Font} from '@react-pdf/renderer';
import {CompositionInterface, PassportInterface} from "../../../common/interfaces/PassportInterface";
import {Chart as ChartJS} from 'chart.js';
import {GraphDataInterface} from "../../../common/interfaces/GraphDataInterface";
import {useAppSelector} from "../../../app/hooks";
import {PassportTypeInterface} from "../../../common/interfaces/PassportTypeInterface";
import {WeightTypeInterface} from "../../../common/interfaces/WeightTypeInterface";
import {AmountTypeInterface} from "../../../common/interfaces/AmountTypeInterface";
import {FormatDominantMaterialGroupsToString} from "../../../common/utils/FormatDominantMaterialGroupsToString";
import {FormatEndUseTreatmentsString} from "../../../common/utils/FormatEndUseTreatmentsString";
import {StyleInterface} from "../../../common/interfaces/StyleInterface";
import {stringToColour} from "../../../common/utils/StringToColor";
import {formatCompositionPercentageDecimals} from "../../../common/utils/formatCompositionPercentageDecimals";
import {formatImpactNumberDecimals} from "../../../common/utils/formatImpactNumberDecimals";
import {getCurrentImpactFigure, getCurrentImpactFigureType} from "../../../common/utils/PassportImpactFigures";
import {sortCompositions} from "../../../common/utils/sortComposition";


ChartJS.register();

// Register Font
Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",

});

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        fontFamily: "Roboto"
    },
    section: {
        margin: 10,
        padding: 10,
    },

    headerTitle: {
        color: '#ffff',
        fontWeight: 'bold',
        fontSize: 20
    },
    headerSubTitle: {
        color: '#ffff',
        fontWeight: 'bold',
        fontSize: 13
    },
    headerPassportType: {
        fontSize: 10,
        marginBottom: 3,
    },
    infoView: {
        marginBottom: 5,
        marginRight: 15,
        width: 114
    },
    headerImage: {
        width: '100',
    },
    splitBody: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    infoContainer: {
        flex: 1
    },
    extraInfoView: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '20 20 0 60'
    },
    descriptionView: {
        padding: '20 60 20 20',
        width: 250,
        borderLeft: '1px solid #eee'
    },
    description: {
        fontSize: 8,
        lineHeight: 1.4,
        maxWidth: 400,
    },
    disclaimer: {
        marginTop: '15',
        fontSize: '6',
    },
    logo: {
        width: 30,
    },
    copyright: {
        height: '40',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30',
    },
    footer: {
        flexDirection: 'row',
        padding: '10 10 0',
        justifyContent: 'space-between'
    },
    subscript: {
        fontSize: 5,
    }
});

interface Props {
    passport: PassportInterface,
    graphBlob: Blob,
    qrBlob: Blob,
    composition: CompositionInterface[],
    graphData: GraphDataInterface,
    style: StyleInterface,
    activeImpactFigureTypeId?: number
}

// Create Document Component
export const PassportPdf = (props: Props) => {

    const url = URL.createObjectURL(props.graphBlob)
    const qrUrl = URL.createObjectURL(props.qrBlob)

    const {
        passportTypes,
        weightTypes,
        amountTypes,
        materialGroup,
        materials,
        impactFigureCalculationTypes
    } = useAppSelector(state => state.root.options);
    const {passport} = props

    const passportType = passportTypes?.data?.find((passportType: PassportTypeInterface) => passportType.id === passport.typeId)
    const weightType = weightTypes?.data?.find((weightType: WeightTypeInterface) => weightType.id === passport.weightTypeId)
    const amountType = amountTypes?.data?.find((amountType: AmountTypeInterface) => amountType.id === passport.amountTypeId)

    const currentImpactFigure = getCurrentImpactFigure({
        passport: passport,
        activeImpactFigureTypeId: props.activeImpactFigureTypeId,
        impactFigureCalculationTypes: impactFigureCalculationTypes?.data
    })

    const currentImpactFigureType = getCurrentImpactFigureType(impactFigureCalculationTypes?.data, props.activeImpactFigureTypeId)

    const dominantMaterialString = FormatDominantMaterialGroupsToString(props.composition)
    const endUseTreatmentsString = FormatEndUseTreatmentsString(passport.endOfUseSolution?.id)
    const strippedDisclaimer = currentImpactFigureType?.disclaimer?.replace(/ *\([^)]*\) */g, "");

    let compositions = sortCompositions(props.composition)

    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <View style={styles.footer}>

                    {(passport?.organisation?.logo) &&
                        <Image style={{height: 60}} src={passport.organisation.logo}/>
                    }

                    {passport.qrCode &&
                        <Image style={{height: 60}} src={qrUrl}/>
                    }

                </View>

                <View style={{
                    marginTop: 40,
                    marginBottom: 20,
                    paddingLeft: 40,
                    paddingRight: 40,
                    paddingBottom: 10,
                    paddingTop: 10,
                    backgroundColor: '#E14343',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>

                    <view>
                        <Text style={styles.headerTitle}>{passport.organisation?.companyName}</Text>
                        <Text style={styles.headerTitle}>{passport.name}</Text>
                        {!!passport.code && <Text style={styles.headerSubTitle}>Ref. {passport.code}</Text>}
                        <Text style={styles.headerPassportType}>{passportType?.name}</Text>
                        <Text style={styles.description}>{passport.description}</Text>
                    </view>


                    {(passport.image) &&
                        <view style={{marginTop: -12, marginBottom: -12, overflow: 'hidden'}}>
                            <Image style={styles.headerImage} src={passport.image?.uploadPath}/>
                        </view>
                    }

                </View>

                <View style={{flexDirection: 'row', padding: '20 60', alignItems: 'center', justifyContent: 'center'}}>

                    {url &&
                        <Image style={{height: 150}} src={url}/>
                    }
                    <View
                        style={{flexDirection: 'row', flexWrap: 'wrap', width: 250, marginLeft: '40', fontSize: '8'}}>

                        {compositions.map((composition) => {

                            const materialGroupItem = materialGroup?.data?.find((materialGroup) => materialGroup.id === composition.materialGroupId)

                            let compositionMaterials = composition.materials

                            if (composition.materials && composition.materials.length > 1) {
                                compositionMaterials = [...composition.materials]
                                compositionMaterials.sort((a: { compositionPercentage: number; }, b: {
                                    compositionPercentage: number;
                                }) => {
                                    if (a.compositionPercentage > b.compositionPercentage) {
                                        return -1;
                                    }
                                    if (a.compositionPercentage < b.compositionPercentage) {
                                        return 1;
                                    }
                                    return 0;
                                });
                            }


                            if (materialGroupItem) {

                                const color = stringToColour(materialGroupItem.name)


                                return (

                                    <View>

                                        <View key={materialGroupItem.name} style={{
                                            flexDirection: 'row',
                                            marginLeft: 10,
                                            alignItems: 'center',
                                            marginTop: '4'
                                        }}>

                                            <Text style={{
                                                backgroundColor: color,
                                                minWidth: 10,
                                                minHeight: 10,
                                                borderRadius: 100,
                                                marginRight: 6,
                                            }}/>

                                            <Text>{materialGroupItem.name} {formatCompositionPercentageDecimals(composition.compositionPercentage + '')}</Text>

                                        </View>

                                        {!!composition.materials?.length &&
                                            <View key={materialGroupItem.name}>
                                                {compositionMaterials?.map((compositionMaterial) => {

                                                    const materialItem = materials?.data.find((materialItem) => materialItem.id === compositionMaterial.materialId)

                                                    if (materialItem) {
                                                        const materialColor = stringToColour(materialItem.name)

                                                        return (
                                                            <View key={materialGroupItem.name} style={{
                                                                flexDirection: 'row',
                                                                marginLeft: 10,
                                                                alignItems: 'center',
                                                                marginTop: 4
                                                            }}>

                                                                <Text style={{
                                                                    backgroundColor: materialColor,
                                                                    minWidth: 10,
                                                                    minHeight: 10,
                                                                    borderRadius: 100,
                                                                    marginRight: 6,
                                                                    marginLeft: 20
                                                                }}/>

                                                                <Text>
                                                                    {materialItem.name} {formatCompositionPercentageDecimals(compositionMaterial.compositionPercentage + '')}
                                                                </Text>
                                                            </View>
                                                        )
                                                    } else return null

                                                })}

                                            </View>
                                        }
                                    </View>
                                )
                            } else return null
                        })}


                    </View>

                </View>

                <View style={styles.splitBody}>
                    <View style={styles.infoContainer}>
                        <View style={styles.extraInfoView}>

                            <Text style={{
                                fontSize: 13,
                                marginBottom: 5,
                                width: '250',
                                color: '#E14343'
                            }}>Basic information</Text>


                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Defined use period (years)</Text>
                                <Text style={{fontSize: 8}}>{passport.definedUsePeriod} years</Text>
                            </View>
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Dominant material groups</Text>
                                <Text style={{fontSize: 8}}>{dominantMaterialString}</Text>
                            </View>
                            {!!passport.nextUseApplication &&
                                <View style={styles.infoView}>
                                    <Text style={{fontSize: 8}}>Next use application</Text>
                                    <Text style={{fontSize: 8}}>{passport.nextUseApplication}</Text>
                                </View>
                            }
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>End of use treatment</Text>
                                <Text style={{fontSize: 8}}>{endUseTreatmentsString}</Text>
                            </View>
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Weight</Text>
                                <Text style={{fontSize: 8}}>{passport.weight} {weightType?.name}</Text>
                            </View>
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Quantity</Text>
                                <Text style={{fontSize: 8}}>{passport.amount} {amountType?.name}</Text>
                            </View>
                        </View>

                        <View style={styles.extraInfoView}>

                            <Text style={{
                                fontSize: 13,
                                marginBottom: 5,
                                width: '250',
                                color: '#E14343'
                            }}>Impact</Text>

                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>SROI impact</Text>
                                <Text
                                    style={{fontSize: 8}}>{passport.sroiImpactObject?.hours}:{passport.sroiImpactObject?.minutes} h</Text>
                            </View>

                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Raw material impact</Text>
                                <Text
                                    style={{fontSize: 8}}>{formatImpactNumberDecimals(currentImpactFigure?.rawMaterialImpactConverted?.toFixed(8) + '')} {currentImpactFigure?.rawMaterialImpactConvertedType}</Text>
                            </View>

                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Energy impact</Text>
                                <Text
                                    style={{fontSize: 8}}>{formatImpactNumberDecimals(parseFloat(currentImpactFigure?.energyImpactConverted + '')?.toFixed(8) + '')} kWh</Text>
                            </View>

                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>CO
                                    <text style={styles.subscript}>2</text> impact</Text>
                                <Text
                                    style={{fontSize: 8}}>{formatImpactNumberDecimals(currentImpactFigure?.co2ImpactConverted?.toFixed(8) + '')} {currentImpactFigure?.co2ImpactConvertedType}</Text>
                            </View>

                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>CO
                                    <text style={styles.subscript}>2</text> impact prijs</Text>
                                <Text
                                    style={{fontSize: 8}}>{formatImpactNumberDecimals(currentImpactFigure?.co2Price?.toFixed(8) + '')} EUR</Text>
                            </View>

                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>H
                                    <text style={styles.subscript}>2</text>O impact</Text>
                                <Text
                                    style={{fontSize: 8}}>{formatImpactNumberDecimals(currentImpactFigure?.h2oImpactConverted?.toFixed(8) + '')} {currentImpactFigure?.h2oImpactConvertedType}</Text>
                            </View>


                        </View>

                    </View>
                    <View style={styles.descriptionView}>

                        {(!!passport.code || !!passport.serialNumber || !!passport.gtinNumber || !!passport.etimCode) &&
                            <Text style={{
                                fontSize: 13,
                                marginBottom: 5,
                                marginTop: 10,
                                color: '#E14343'
                            }}>Encodings</Text>
                        }

                        {(!!passport.code) &&
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Code: {passport.code}</Text>
                            </View>
                        }
                        {(!!passport.serialNumber) &&
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>Serial number: {passport.serialNumber}</Text>
                            </View>
                        }

                        {(!!passport.etimCode) &&
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>ETIM code: {passport.etimCode}</Text>
                            </View>
                        }

                        {(!!passport.gtinNumber) &&
                            <View style={styles.infoView}>
                                <Text style={{fontSize: 8}}>GTIN number: {passport.gtinNumber}</Text>
                            </View>
                        }

                        {(!!passport.passportImpactFigureCalculationTypeId) &&
                            <View style={styles.infoView}>
                                <Text style={styles.disclaimer}>{strippedDisclaimer}</Text>
                            </View>
                        }

                    </View>
                </View>

                <View style={styles.copyright}>
                    <Text
                        style={{
                            fontSize: 8,
                        }}>Copyright {new Date().getFullYear()} <Image style={styles.logo}
                                                                       src={'/images/CIRMAR-logo.png'}/>
                    </Text>
                </View>

            </Page>
        </Document>
    )
};
