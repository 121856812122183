import {LoginForm} from "./components/LoginForm";
import {LoginWrapper} from "./components/LoginWrapper";

const LoginPageContainer = () => {


    return (
        <LoginWrapper>
            <LoginForm/>
        </LoginWrapper>
    )

}

export default LoginPageContainer