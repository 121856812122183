import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

interface Args {
    uuid?: string,
    amount: number,
    passportImpactFigureCalculationTypeId?: number,
    passportEndOfUseSolutionId?: number
}

export const apiOrganisationPassportCalculateImpact = async (args: Args) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_PASSPORT + '/calculate-impact', {}, {
        params: {
            uuid: args.uuid,
            amount: args.amount,
            passportImpactFigureCalculationTypeId: args.passportImpactFigureCalculationTypeId,
            passportEndOfUseSolutionId: args.passportEndOfUseSolutionId
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
