import {CompositionInterface, PassportInterface} from "../interfaces/PassportInterface";


export const calculateStepPercentage = (step: number, passport: PassportInterface, composition: CompositionInterface[]) => {

    const defaultCalculation = (passportProps: PassportInterface, duplicates?: number) => {

        let values: (string | boolean | number | undefined | File)[] = []
        let count = 0


        values = Object.values(passportProps)

        values.forEach((value) => {

            if (Array.isArray(value)) {
                if (value.length) {
                    count++
                }
            } else {
                if (value) {
                    count++
                }
            }

        })

        let result = 0


        if (duplicates)

            result = parseInt(((Number(count)) / (values.length - duplicates) * 100) + '')

        else result = parseInt((count / values.length * 100) + '')

        if (result < 0) {
            result = 0
        }

        return result


    }

    switch (step) {
        case(2):

            const basicInformationProps = {
                name: passport.name,
                description: passport.description,
                weight: passport.weight,
                weightTypeId: passport.weightTypeId,
                amount: passport.amount,
                amountTypeId: passport.amountTypeId,
                length: passport.length,
                width: passport.width,
                height: passport.height,
                definedUsePeriod: passport.definedUsePeriod,
                msdsFile: passport.msdsFile,
                msdsFileUpload: passport.msdsFileUpload,
                nextUseApplication: passport.nextUseApplication,
                endOfUseSolution: passport.endOfUseSolution,
                code: passport.code,
                serial: passport.serialNumber,
                hsetimCodeCode: passport.etimCode,
                gtinNumber: passport.gtinNumber
            }

            return defaultCalculation(basicInformationProps, 1)

        case(3):

            if (passport.useAssemblyMaterials) {
                return 100
            }

            let percentage = 0;

            composition && composition.forEach((compositionItem) => {

                let compositionPercentage = 0
                let multiplier = .5

                if (compositionItem.compositionPercentage) {
                    compositionPercentage += Number(compositionItem.compositionPercentage)
                }

                if (compositionItem.materials?.length) {
                    multiplier = 0
                    compositionItem.materials.forEach((material) => {
                        if (material.compositionPercentage) {
                            multiplier += Number(material.compositionPercentage) / 100
                        }
                    })

                }

                percentage += compositionPercentage * multiplier

            })

            return parseInt(percentage + '')

        case(4):

            if (passport.calculateImpactFigures) {
                if (passport.typeId === 3) {

                    if (passport.sdgGoals?.length && passport.sroiImpact?.length) {
                        return 100
                    }

                    if (passport.sdgGoals?.length) {
                        return 83
                    } else return 66
                }

                if (passport.sdgGoals?.length) {
                    return 100
                } else return 80

            } else {

                let impactProps = {
                    h2oImpact: passport.h2oImpact,
                    energyImpact: passport.energyImpact,
                    rawMaterialImpact: passport.rawMaterialImpact,
                    co2Impact: passport.co2Impact,
                    sdgGoals: passport.sdgGoals,
                }

                if (passport.typeId === 3) {
                    Object.assign(impactProps, {sroiImpact: passport.sroiImpact});
                }

                return defaultCalculation(impactProps)
            }


        case(5):

            const extraInformationProps = {
                webUrl: passport.webUrl,
                image: passport.image,
                imageFileUpload: passport.imageFileUpload,
                reverseLogisticsInstructionFile: passport.reverseLogisticsInstructionFiles,
                reverseLogisticsInstructionFileUpload: passport.reverseLogisticsInstructionFilesUpload,
                assessmentInstructionFile: passport.assessmentInstructionFiles,
                assessmentInstructionFileUpload: passport.assessmentInstructionFilesUpload,
                disassemblyInstructionFile: passport.disassemblyInstructionFiles,
                disassemblyInstructionFileUpload: passport.disassemblyInstructionFilesUpload,
                recyclingInstructionFile: passport.recyclingInstructionFiles,
                recyclingInstructionFileUpload: passport.recyclingInstructionFilesUpload,
                nextUseApplicationDescriptionFile: passport.nextUseApplicationDescriptionFiles,
                nextUseApplicationDescriptionFileUpload: passport.nextUseApplicationDescriptionFilesUpload,
                appendicesFile: passport.appendicesFiles,
                appendicesFileUpload: passport.appendicesFilesUpload,
            }


            return defaultCalculation(extraInformationProps, 7)

    }


    return 0

}
