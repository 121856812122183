import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    translations?: TranslationsInterface
}

export const PassportEncodings = (props:Props) => {


    return (
        <div className="c-passport__tabs__content__wrapper">
            {!!props.passport.code &&
            <div className="c-passport__tabs__content__single">
                <span className={'text-color-copy'}>{props.translations?.code}</span>
                <span className={'text-color-copy'}>{props.passport.code}</span>
            </div>
            }
            {!!props.passport.serialNumber &&
            <div className="c-passport__tabs__content__single">
                <span className={'text-color-copy'}>{props.translations?.serialNumber}</span>
                <span className={'text-color-copy'}>{props.passport.serialNumber}</span>
            </div>
            }
            {!!props.passport.etimCode &&
            <div className="c-passport__tabs__content__single">
                <span className={'text-color-copy'}>{props.translations?.etimCode}</span>
                <span className={'text-color-copy'}>{props.passport.etimCode}</span>
            </div>
            }
            {!!props.passport.gtinNumber &&
            <div className="c-passport__tabs__content__single">
                <span className={'text-color-copy'}>{props.translations?.gtinNumber}</span>
                <span className={'text-color-copy'}>{props.passport.gtinNumber}</span>
            </div>
            }
        </div>
    )

}