import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationPassportList = async (limit?: number, page?: number, searchValue?: string, filterType?: number, filterWeightType?: number) => {

    if (page) {
        page = page - 1
    }

    return await useAppAxios.post(ApiEnum.ORGANISATION_PASSPORT + '/list', {
        filter: {
            weightType: filterWeightType,
            type: filterType,
            searchString: searchValue
        }
    }, {
        params: {
            page: page,
            limit: limit,
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
