import {ApiEnum} from "../../../enums/ApiEnum";
import {CompositionInterface, PassportInterface} from "../../../interfaces/PassportInterface";
import {passportToFormData} from "../../../utils/passportToFormData";
import {useAppAxios} from "../../../../app/hooks";
import {AssemblyInterface} from "../../../interfaces/AssemblyInterface";

export const apiOrganisationPassportUpdate = async (passport: PassportInterface, composition: CompositionInterface[], assemblies: AssemblyInterface[], parentAssemblies: AssemblyInterface[]) => {
    const formData = passportToFormData(passport, composition, assemblies, parentAssemblies);

    return await useAppAxios.post(ApiEnum.ORGANISATION_PASSPORT + '/update', formData, {
        params: {
            uuid: passport.uuid,
        },
        headers: {'content-type': 'multipart/form-data'}
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
