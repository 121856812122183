import {Link} from "react-router-dom";
import {RouteEnum} from "../../../common/enums/RouteEnum";

export const OnboardingContent = () => {

    return (
        <div className="c-auth__primary__container">
            <h1 className="c-auth__primary__title">
                Create your Cirmar account
            </h1>
            <ul className="c-list u-reset-ul">
                <li>
                    <img src="/images/icons/check-red.svg" alt=""/>
                    <span>Production composition, supply cycle instruction and impact data</span>
                </li>
                <li>
                    <img src="/images/icons/check-red.svg" alt=""/>
                    <span>Production composition, supply cycle instruction and impact data</span>
                </li>
                <li>
                    <img src="/images/icons/check-red.svg" alt=""/>
                    <span>Production composition, supply cycle instruction and impact data</span>
                </li>
                <li>
                    <img src="/images/icons/check-red.svg" alt=""/>
                    <span>Production composition, supply cycle instruction and impact data</span>
                </li>
            </ul>
            <Link to={RouteEnum.SIGNUP_PAGE}
                  className="btn btn--large btn--red btn--rounded"><span>Get started</span></Link>
        </div>
    )

}