import {
    IsFreeTrialOrganisation,
    IsMaxTrialConcurrentPassportsCreated,
    IsMaxTrialTotalPassportsCreated
} from "../../utils/freeTrialRestrictions";
import {useAppSelector} from "../../../app/hooks";

interface Props {
    text: string
}


export const FreeTrialHoverCloud = (props: Props) => {

    const {userMe} = useAppSelector(state => state.root);

    if (
        !(
            IsFreeTrialOrganisation(userMe)
            || IsMaxTrialConcurrentPassportsCreated(userMe)
            || IsMaxTrialTotalPassportsCreated(userMe)
        )
    ) {
        return null
    }

    return (
        <div className={'btn--disabled__cloud'}>{props.text}</div>
    )
}