export const splitTextByUrls = (text: string, regex: any) => {
    let parts = [];
    let lastIndex = 0;
    let match;
    let prefix = 'https://';

    while ((match = regex.exec(text)) !== null) {
        // Add the text before the URL
        if (match.index > lastIndex) {
            parts.push(text.slice(lastIndex, match.index));
        }

        // Add the URL
        let fullUrl = match[0]
        if (match[0].startsWith('www.')) {
            fullUrl = prefix + match[0];
        }
        parts.push(fullUrl);
        lastIndex = match.index + match[0].length;
    }

    // Add any remaining text after the last URL
    if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
    }
    return parts.filter((part) => part && part !== '' && part !== ' ');
}