import {useAppSelector} from "../../../app/hooks";
import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    translations?: TranslationsInterface
}


export const PassportSdgGoals = (props:Props) => {
    const {sdgGoals} = useAppSelector(state => state.root.options);

    if (props.passport.sdgGoals?.length) {
        return (
            <div className="c-dashboard__card background-color-blocks c-passport__sdg">
                <h2 className={'text-color-highlight'}>UN<br/> {props.translations?.sustainableDevelopmentGoals}</h2>
                <div>
                    {props.passport.sdgGoals.map((sdgId) => {

                        const sdgGoal = sdgGoals?.data?.find((sdgGoal) => sdgGoal.id === sdgId)

                        if (sdgGoal) {
                            return (<img src={sdgGoal.image} alt='' />)
                        } else return null


                    })}
                </div>
            </div>
        )
    } else return null

}