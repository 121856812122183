import {HowToCloud} from "../howTo/HowToCloud";

interface InputProps {
    label?: string,
    name?: string,
    required?: boolean,
    onChange: (value: string | number) => void;
    placeholder?: string,
    value?: string | number,
    modal?: string,
    type: string,
    loading?: boolean,
    smallHtml?: string,
    extraClass?: string,
    howToCode?: string,
}

const FormInput = (props: InputProps) => {

    const value = props.value ? props.value : ''

    return (
        <div className={'c-form__group ' + props.extraClass} style={{position: 'relative'}}>

            <label htmlFor="">{props.label} {props.howToCode && <HowToCloud code={props.howToCode}/>}</label>

            <input
                onWheel={(e) => e.currentTarget.blur()}
                disabled={props.loading}
                name={props.name}
                required={props.required} type={props.type} value={value}
                onChange={(e) => props.onChange(e.target.value)}
                placeholder={props.placeholder}
            />

            {props.smallHtml && <small>{props.smallHtml}</small>}


        </div>

    )

}

export default FormInput