import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserInterface} from "../../common/interfaces/UserInterface";
import {OrganisationInfoInterface} from "../../common/interfaces/OrganisationInfoInterface";
import {AddressInterface} from "../../common/interfaces/AddressInterface";
import nextId from "react-id-generator";
import {apiOrganisationInfoCreate} from "../../common/api/organisation/info/apiOrganisationInfoCreate";
import {apiSubscriptionPreview} from "../../common/api/subscription/apiSubscriptionPreview";
import {SubscriptionInterface} from "../../common/interfaces/SubscriptionInterface";


export const previewSubscription = createAsyncThunk(
    'signup/previewSubscription',
    async (_, {getState}) => {
        const {signup} = getState() as { signup: signupStateInterface };

        if (signup.preview.passportCount) {
            const response = await apiSubscriptionPreview('passport', signup.preview.passportCount)
            return response.data.items.data
        }
    }
)

export const loadExamplePrices = createAsyncThunk(
    'signup/loadExamplePrices',
    async (_, {getState}) => {
        const {signup} = getState() as { signup: signupStateInterface };
        const examples = JSON.parse(JSON.stringify(signup.examples))

        const item = examples.map(async (example: any) => {

            let price = 0

            const subscriptionPassportResponse = await apiSubscriptionPreview('passport', example.passports.amount)
            example.passports.subscription = subscriptionPassportResponse.data?.items?.data

            price += Number(subscriptionPassportResponse.data?.items.data.price)

            const subscriptionUserResponse = await apiSubscriptionPreview('user', example.users.amount)
            example.users.subscription = subscriptionUserResponse.data?.items?.data

            price += Number(subscriptionUserResponse.data?.items.data.price)
            example.price = price.toFixed(2)

            return example

        })

        return Promise.all(item)

    }
)

export const submit = createAsyncThunk(
    'signup/submit',
    async (captchaToken:string, {getState}) => {

        const {signup} = getState() as { signup: signupStateInterface };

        if (signup.organisation && signup.user) {
            const response = await apiOrganisationInfoCreate(signup.organisation, signup.user, captchaToken)
            return response.data.status.code
        }
    }
)

interface signupStateInterface {
    submitting: boolean,
    submitStatus?: number,
    completed: boolean,
    user: UserInterface,
    organisation: OrganisationInfoInterface,
    addresses: AddressInterface[],
    preview: {
        passportCount: number,
        subscription?: SubscriptionInterface
    },
    examples: {
        icon: string,
        title: string,
        text: string,
        passports: {
            amount: number,
            subscription?: SubscriptionInterface
        },
        users: {
            amount: number,
            subscription?: SubscriptionInterface
        }
        price?: string
    }[]
}

const addressState = {
    createId: nextId(),
    street: '',
    number: undefined,
    typeId: undefined,
    postalCode: '',
    city: '',
    state: '',
    countryId: 123
}

const initialState: signupStateInterface = {
    submitting: false,
    completed: false,
    examples: [
        {
            icon: 'company-small.png',
            title: 'Small company',
            text: 'A company with a small selection of products and moderate level of layering.',
            passports: {
                amount: 19
            },
            users: {
                amount: 4
            }
        },
        {
            icon: 'company-large.png',
            title: 'Large company',
            text: ' A company with a vast number of products and high level of layering.',
            passports: {
                amount: 50
            },
            users: {
                amount: 15
            }
        }
    ],
    user: {
        email: '',
        password: '',
        passwordRepeat: '',
        firstName: '',
        lastName: '',
        insertion: ''
    },
    organisation: {
        cocNumber: undefined,
        companyName: '',
        invoiceEmail: '',
        phoneNumber: '',
        vatNumber: '',
        website: '',
        contactPersonName: '',
        contactPersonEmail: ''
    },
    addresses: [
        {
            ...addressState,
            createId: 'default',
            typeId: 1
        }
    ],
    preview: {
        passportCount: 1,
    }
}

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setPreviewPassportCount: (state, action: PayloadAction<number>) => {
            state.preview.passportCount = action.payload
        },
        setUserData: (state, action: PayloadAction<UserInterface>) => {
            state.user = {
                ...state.user,
                ...action.payload
            }
        },
        setOrganisationData: (state, action: PayloadAction<OrganisationInfoInterface>) => {
            state.organisation = {
                ...state.organisation,
                ...action.payload
            }
        },
        setAddressData: (state, action: PayloadAction<AddressInterface>) => {

            state.addresses = state.addresses.map((address) => {
                if (address.createId === action.payload.createId) {
                    return {
                        ...address,
                        ...action.payload
                    }
                } else return address
            })

        },
        addAddress: (state) => {
            state.addresses.push({
                ...addressState,
                createId: nextId()
            })
        },
        removeAddress: (state, action: PayloadAction<AddressInterface>) => {
            state.addresses = state.addresses.filter((address) => address.createId !== action.payload.createId)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state, action: PayloadAction<number>) => {
            state.submitting = false

            state.completed = action.payload === 200
            state.submitStatus = action.payload
        })
        builder.addCase(loadExamplePrices.fulfilled, (state, action: PayloadAction<any>) => {
            state.examples = action.payload
        })
        builder.addCase(previewSubscription.fulfilled, (state, action: PayloadAction<SubscriptionInterface>) => {
            state.preview.subscription = action.payload
        })
        builder.addCase(submit.rejected, (state) => {
            state.submitting = false
            state.completed = false
            state.submitStatus = 404
        })
    },
})

export const {
    setUserData,
    setOrganisationData,
    setAddressData,
    addAddress,
    removeAddress,
    setPreviewPassportCount
} = signupSlice.actions
export const signupReducer = signupSlice.reducer
