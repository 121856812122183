import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeStep} from "../upgradeAccountSlice";

const mapContent = (step: number) => {

  switch (step) {
    case(1):
      return {
        title: 'Complete account',
      }
    case(2):
      return {
        title: 'Pricing examples',
        description: 'Your monthly invoice is dependant on the amount of C_passports© and extra users you create. See examples of different companies and their C_passports© and user requirements below. More info about our pricing can be found <a href="https://cirmar.com/pricing">here</a>.'
      }
    default:
      return {
        title: 'Account summary',
      }
  }

}

export const UpgradeIntro = () => {

  const {activeStep} = useAppSelector(state => state.upgradeAccount);

  const content = mapContent(activeStep)
  const dispatch = useAppDispatch()

  if (activeStep > 3) {
    return null
  }

  return (
    <div className="c-signup__intro">
      <div className="c-signup__step">

        <div className="c-signup__edit">

          <div className="c-signup__edit__single">

            {activeStep > 1 ? <img src="/images/icons/check-red.svg" alt=""/> :
              <i>1</i>
            }
            <span>Basic information</span>
            {activeStep > 1 && <span className={'span-button'} style={{textDecoration: 'underline'}}
                                     onClick={() => dispatch(changeStep(1))}>edit</span>}
          </div>

          <div className="c-signup__edit__single">

            <i>2</i>
            <span>Pricing examples</span>
            {activeStep > 2 && <span className={'span-button'} style={{textDecoration: 'underline'}}
                                     onClick={() => dispatch(changeStep(2))}>view</span>}

          </div>

          <div className="c-signup__edit__single">
            <i>3</i>
            <span>Summary</span>
          </div>

        </div>


      </div>

      <div className={'c-signup__progress-bar'}>
        <span style={{width: 100 * (activeStep / 3) + '%'}}/>
      </div>

      <h1 dangerouslySetInnerHTML={{__html: content.title}}/>
      {!!content.description && <p dangerouslySetInnerHTML={{__html: content.description}}/>}
    </div>
  )

}