import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {UserInterface} from "../../common/interfaces/UserInterface";
import {apiOrganisationUserResetPassword} from "../../common/api/organisation/user/apiOrganisationUserResetPassword";

interface forgotPasswordResetStateInterface {
    submitStatus?: number,
    submitting: boolean,
    user: UserInterface,
}

const initialState: forgotPasswordResetStateInterface = {
    submitting: false,
    user: {
        passwordRepeat: '',
        password: ''
    }
}

export const submit = createAsyncThunk(
    'forgotPasswordReset/submit',
    async (token: string, {getState}) => {
        const {forgotPasswordReset} = getState() as { forgotPasswordReset: forgotPasswordResetStateInterface };
        if (forgotPasswordReset.user && forgotPasswordReset.user.password) {
            const response = await apiOrganisationUserResetPassword(token + '', forgotPasswordReset.user.password)
            return response.data.status.code
        }
    }
)

const forgotPasswordResetSlice = createSlice({
    name: 'forgotPasswordReset',
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<UserInterface>) => {
            state.user = {
                ...state.user,
                ...action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state, action: PayloadAction<number>) => {
            state.submitting = false
            state.submitStatus = action.payload
        })
    },
})

export const {setUserData} = forgotPasswordResetSlice.actions
export const forgotPasswordResetReducer = forgotPasswordResetSlice.reducer