import {useAppSelector} from "../../../app/hooks";

export const InfoCards = () => {

    const {websiteInfo} = useAppSelector((state) => state.cockpit)

    if (!websiteInfo) {
        return null;
    }

    return (
        <>
            {websiteInfo.map((info) => {
                return (
                    <div style={{flex: 1}} className="c-dashboard__card c-dashboard__card--fixed-20 c-dashboard__card--flex-column">
                        <div>
                            <h4 className="c-cockpit__card-title">{info['titel']}</h4>
                            <div className="c-cockpit__card-text" dangerouslySetInnerHTML={{__html: info['text']}} />
                        </div>
                        <a href={info['knop_link']} className="btn btn--rounded btn--red" target="_blank">
                            <span>{info['knop_tekst']}</span>
                        </a>
                    </div>
                )
            })}
        </>
    )
}
