import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props{
    translations?: TranslationsInterface
}

export const PassportInterested = (props:Props) => {

    return(
        <div className="c-dashboard__card background-color-blocks c-passport__progress">
            <div className="c-passport__progress__indication c-passport__progress__indication--public">
                <h4 className={'text-color-highlight'}>{props.translations?.interestedInYourOwn} C_passport<sup>®</sup>?</h4><p className={'text-color-copy'}>{props.translations?.goTo} <a className={'text-color-highlight'} href="https://www.cirmar.com">cirmar.com</a> {props.translations?.andLearnAllAbout} product passports.</p>
            </div>
        </div>
    )

}