import React from "react";
import {DashboardNavigation} from "../../common/components/dashboard/DashboardNavigation";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../common/enums/RouteEnum";
import FreeTrialMenu from "../../common/components/general/FreeTrialMenu";

interface Props {
  children?: React.ReactNode;
};

export const DashboardWrapper = (props: Props) => {


  return (
    <>
      <FreeTrialMenu/>
      <section className="c-app">
        <aside className="c-app__menu">
          <Link to={RouteEnum.PASSPORT_OVERVIEW} className="c-app__logo">
            <img src="/images/CIRMAR-logo.svg" alt={''}/>
          </Link>
          <DashboardNavigation/>
        </aside>
        <main className="c-app__content">
          {props.children}
        </main>
      </section>
    </>
  )
}