import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiOrganisationPassportDelete} from "../../common/api/organisation/passport/apiOrganisationPassportDelete";
import {PassportInterface} from "../../common/interfaces/PassportInterface";
import {getUserMe} from "../../app/rootReducer";


interface TrashPassportModalInterface {
    passport?: PassportInterface,
    submitting: boolean,
}


export const deletePassport = createAsyncThunk(
    'trashPassportModal/deletePassport',
    async (_, {getState, dispatch}) => {

        const {trashPassportModal} = getState() as { trashPassportModal: TrashPassportModalInterface };

        if (trashPassportModal.passport?.uuid) {
            const response = await apiOrganisationPassportDelete(trashPassportModal.passport.uuid)

            // Used to retrieve new passport count in user.organisation
            dispatch(getUserMe())

            if (response && response.data && response.data.items && response.data.items.data) {
                return response.data.items
            }
        }
    }
)


const initialState: TrashPassportModalInterface = {
    submitting: false
}

const trashPassportModal = createSlice({
    name: 'trashPassportModal',
    initialState,
    reducers: {
        setTrashPassport: (state, action: PayloadAction<{
            passport?: PassportInterface,
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup) {
                state.passport = action.payload.passport
            } else {
                delete state.passport
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deletePassport.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(deletePassport.fulfilled, (state) => {
            delete state.passport
            state.submitting = false
        })
    },
})

export const {setTrashPassport} = trashPassportModal.actions

export const trashPassportModalReducer = trashPassportModal.reducer
