import {CompositionInterface, MaterialInterface} from "../interfaces/PassportInterface";
import {stringToColour} from "./StringToColor";
import {MaterialGroupInterface} from "../interfaces/MaterialGroupInterface";
import {TranslationsInterface} from "../interfaces/TranslationsInterface";

export interface dataset {
    label: string,
    data: number[],
    backgroundColor: string[],
}

interface Args {
    composition: CompositionInterface[],
    materialGroup?: MaterialGroupInterface[],
    materials?: MaterialInterface[],
    translations?: TranslationsInterface,
    hideMaterials?: boolean
}


export const FormatCompositionToPieData = (args:Args) => {

    const {composition, materialGroup, materials, translations, hideMaterials} = args

    let leftOverCompositionPercentage = 100
    const labels: string[] = []

    const dominantMaterialSets = {
        label: translations?.dominantMaterials,
        data: [],
        backgroundColor: [],
        borderWidth: 0,
    } as dataset

    const materialSets = {
        label: translations?.materials,
        data: [],
        backgroundColor: [],
        borderWidth: 0,
    } as dataset

    composition.forEach((composition) => {
        if (composition.compositionPercentage) {

            const materialGroupItem = materialGroup?.find((materialGroup) => materialGroup.id === composition.materialGroupId)

            if (materialGroupItem) {
                labels.push(materialGroupItem.name)
                leftOverCompositionPercentage -= Number(composition.compositionPercentage)

                dominantMaterialSets.data.push(Number(composition.compositionPercentage))
                materialSets.data.push(0)

                const color = stringToColour(materialGroupItem.name)

                dominantMaterialSets.backgroundColor.push(color)
                materialSets.backgroundColor.push(color)

            }
        }
    })

    if (leftOverCompositionPercentage) {
        labels.push('Undefined')
        dominantMaterialSets.data.push(leftOverCompositionPercentage)
        materialSets.data.push(0)
        dominantMaterialSets.backgroundColor.push('#f5f5f5')
        materialSets.backgroundColor.push('#f5f5f5')
    }


    composition.forEach((composition) => {

        if (composition.compositionPercentage) {

            const materialGroupItem = materialGroup?.find((materialGroup) => materialGroup.id === composition.materialGroupId)

            if (materialGroupItem) {
                let leftOverMaterialPercentage = Number(composition.compositionPercentage)

                if (!hideMaterials) {
                    composition.materials?.forEach((material: MaterialInterface) => {

                        if (material.compositionPercentage && composition.compositionPercentage) {
                            const materialItem = materials?.find((materialItem) => materialItem.id === material.materialId)

                            if (materialItem && material.compositionPercentage) {

                                labels.push(materialItem.name + '')

                                const relativePercentage = (Number(material.compositionPercentage) / 100) * Number(composition.compositionPercentage)
                                const color = stringToColour(materialItem.name + '')
                                dominantMaterialSets.backgroundColor.push(color)
                                materialSets.backgroundColor.push(color)
                                materialSets.data.push(relativePercentage)
                                leftOverMaterialPercentage -= relativePercentage
                            }
                        }
                    })
                }

                if (leftOverMaterialPercentage) {
                    labels.push('Undefined')
                    materialSets.data.push(leftOverMaterialPercentage)
                    materialSets.backgroundColor.push('#f5f5f5')
                    dominantMaterialSets.backgroundColor.push('#f5f5f5')
                }
            }
        }
    })
    if (leftOverCompositionPercentage) {
        materialSets.data.push(leftOverCompositionPercentage)
        materialSets.backgroundColor.push('#f5f5f5')
        dominantMaterialSets.backgroundColor.push('#f5f5f5')
        labels.push('Undefined')
    }


    return {
        labels: labels,
        datasets: [
            dominantMaterialSets,
            materialSets

        ],
    };

}
