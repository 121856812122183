import {Link} from "react-router-dom";
import {RouteEnum} from "../../../common/enums/RouteEnum";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setUserData, submit} from "../forgotPasswordSlice";
import {ClipLoader} from "react-spinners";

export const ForgotPasswordForm = () => {

    const {user, submitStatus, submitting} = useAppSelector(state => state.forgotPassword);
    const dispatch = useAppDispatch()

    return (
        <div className="c-auth__primary__container">
            <h1 className="c-auth__primary__title">
                Forgot your password?
            </h1>
            <p>
                Enter credentials to start the recovery process
            </p>
            <form action="" className="c-auth__form c-form c-form--black" onSubmit={(e) => {
                e.preventDefault()
                dispatch(submit())
            }}>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Email address</label>
                        <input type="email"
                               value={user.email}
                               onChange={(e) => dispatch(setUserData({email: e.target.value}))}
                        />
                    </div>
                </div>
                {submitStatus !== 200 &&
                <div className="c-form__row">
                    <div className="c-form__group">
                        <button disabled={submitting} style={{display: 'flex'}}
                                className="btn btn--rounded btn--large btn--red">
                            <span style={{margin: 0}}>Submit</span> {submitting &&
                        <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}
                        </button>
                    </div>
                </div>
                }

                {submitStatus === 200 &&
                <div className="c-form__row">
                    <div className="c-form__group">
                        <p>Please check your mail for further instructions</p>
                    </div>
                </div>
                }

                {(submitStatus === 401 || submitStatus === 404) &&
                <div className={'c-form__row'}>
                    <div className={'c-notification c-notification--red'} style={{marginTop: '1rem'}}>
                        <p style={{textAlign: 'center'}}>The given email is unknown</p>
                    </div>
                </div>
                }

                <div className="c-form__row">
                    <div className="c-form__group">
                        <Link to={RouteEnum.LOGIN} className="btn btn--text">
                            <span>Login</span>
                        </Link>
                    </div>
                </div>
            </form>
        </div>

    )

}