import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiOrganisationInfoSubscriptions} from "../../common/api/organisation/info/apiOrganisationInfoSubscriptions";
import {SubscriptionInterface} from "../../common/interfaces/SubscriptionInterface";
import {apiOrganisationInfoDetail} from "../../common/api/organisation/info/apiOrganisationInfoDetail";
import {OrganisationInfoInterface} from "../../common/interfaces/OrganisationInfoInterface";

export const load = createAsyncThunk(
    'billing/load',
    async () => {

        const organisationResponse = await apiOrganisationInfoDetail()
        const subscriptionResponse = await apiOrganisationInfoSubscriptions()

        return {
            subscriptions: subscriptionResponse.data.items.data,
            organisation: organisationResponse.data.items.info.organisation
        }
    }
)

interface billingStateInterface {
    data: {
        passportTotal?: number,
        totalPrice?: number,
        userPrimaryTotal?: number,
        userTotal?: number,
        userViewTotal?: number,
        subscriptions?: SubscriptionInterface[]
    },
    invoiceEmail?: string
}

const initialState: billingStateInterface = {
    data: {},
    invoiceEmail: ''
}

const billingSlice = createSlice({
    name: 'billing',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, action: PayloadAction<{subscriptions: billingStateInterface["data"], organisation: OrganisationInfoInterface}>) => {
            state.data = action.payload.subscriptions
            state.invoiceEmail = action.payload.organisation.invoiceEmail
        })
    },
})

export const billingReducer = billingSlice.reducer