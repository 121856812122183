import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    CompositionInterface,
    PassportGroupInterface,
    PassportInterface
} from "../../common/interfaces/PassportInterface";
import {apiOrganisationPassportList} from "../../common/api/organisation/passport/apiOrganisationPassportList";
import {LoadPassportAction} from "../../common/actions/LoadPassportAction";
import {PassportTabEnums} from "../../common/enums/PassportTabEnums";
import {LoadStyleAction} from "../../common/actions/LoadStyleAction";
import {LoadPreferenceAction} from "../../common/actions/LoadPreferenceAction";
import {StyleInterface} from "../../common/interfaces/StyleInterface";
import {PassportPreferenceInterface} from "../../common/interfaces/PassportPreferenceInterface";
import {AssemblyInterface} from "../../common/interfaces/AssemblyInterface";
import {
    apiOrganisationPassportCalculateImpact
} from "../../common/api/organisation/passport/apiOrganisationPassportCalculateImpact";
import {useAppAxios} from "../../app/hooks";
import {ApiEnum} from "../../common/enums/ApiEnum";
import {PassportImpactFiguresInterface} from "../../common/interfaces/PassportImpactFiguresInterface";

interface passportDetailInterface {
    passport: PassportInterface,
    passports?: {
        data: PassportInterface[],
        pages?: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    assemblies: AssemblyInterface[],
    parentAssemblies: AssemblyInterface[],
    composition: CompositionInterface[],
    passportGroups: PassportGroupInterface[],
    graphBlob?: Blob,
    qrBlob?: Blob,
    style: StyleInterface,
    passportPreference: PassportPreferenceInterface,
    activeTab: number,
    loadImpactCalculation: boolean
    activeImpactFigureTypeId?: number,
    overruleEndUseId?: number
}

const initialState: passportDetailInterface = {
    passport: {
        sdgGoals: [],
        connectedPassports: [],
    },
    assemblies: [],
    parentAssemblies: [],
    composition: [],
    passportGroups: [],
    activeTab: PassportTabEnums.MATERIALS,
    style: {
        customStyle: false
    },
    passportPreference: {},
    loadImpactCalculation: false,
}

export const passportList = createAsyncThunk(
    'passportDetail/passportList',
    async (_) => {
        const response = await apiOrganisationPassportList(9999)

        if (response && response.data && response.data.items && response.data.items.data) {
            return response.data.items
        }

    }
)

export const loadStyle = createAsyncThunk(
    'publicPassport/loadStyle',
    async (_, {getState}) => {
        const {passportDetail} = getState() as { passportDetail: passportDetailInterface };
        return LoadStyleAction(passportDetail.passport.organisation?.id)
    }
)

export const loadPreference = createAsyncThunk(
    'passportDetail/loadPreference',
    async (_, {getState}) => {
        const {passportDetail} = getState() as { passportDetail: passportDetailInterface };
        return LoadPreferenceAction(passportDetail.passport)
    }
)

export const getUuid = createAsyncThunk(
    'passportDetail/get-uuid',
    async (id: number) => {
        return await useAppAxios.post(ApiEnum.ORGANISATION_PASSPORT + '/get-uuid', {}, {
            params: {
                id: id,
            },
        }).then(function (response) {
            return response?.data?.items?.data
        }).catch(function (error) {
            return error
        })

    }
)

export const load = createAsyncThunk(
    'passportDetail/load',
    LoadPassportAction
)

export const calculateImpact = createAsyncThunk(
    'passportDetail/calculateImpact',
    async (multiplier: number, {getState}) => {
        const {passportDetail} = getState() as { passportDetail: passportDetailInterface };

        const args = {
            uuid: passportDetail.passport.uuid,
            amount: multiplier,
            passportImpactFigureCalculationTypeId: passportDetail.activeImpactFigureTypeId,
        } as any

        if (passportDetail.overruleEndUseId) {
            args.passportEndOfUseSolutionId = passportDetail.overruleEndUseId
        }

        const response = await apiOrganisationPassportCalculateImpact(args)

        return response.data.items?.figures as PassportImpactFiguresInterface
    }
)


export const setGraphImage = createAsyncThunk(
    'passportDetail/setGraphImage',
    async (base64Data: string) => {
        const base64 = await fetch(base64Data);
        return await base64.blob();
    }
)

export const setQrBLob = createAsyncThunk(
    'passportDetail/setQrBlob',
    async (base64Data: string) => {
        const base64 = await fetch(base64Data);
        return await base64.blob();
    }
)


const passportDetailSlice = createSlice({
    name: 'passportDetail',
    initialState,
    reducers: {
        changeTab: (state, action: PayloadAction<number>) => {
            state.activeTab = action.payload
        },
        changeOverruleEndUse: (state, action: PayloadAction<number>) => {
            state.overruleEndUseId = action.payload
        },
        changeImpactFigureCalculationType: (state, action: PayloadAction<number>) => {
            state.activeImpactFigureTypeId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(passportList.fulfilled, (state, action: PayloadAction<passportDetailInterface['passports']>) => {
            state.passports = action.payload
        })
        builder.addCase(calculateImpact.pending, (state) => {
            state.loadImpactCalculation = true
        })
        builder.addCase(calculateImpact.fulfilled, (state, action: PayloadAction<PassportImpactFiguresInterface>) => {
            state.loadImpactCalculation = false
            state.passport.passportImpactFigures = state.passport.passportImpactFigures?.map((passportImpactFigure) => {

                if (state.overruleEndUseId && (passportImpactFigure.passportImpactFigureCalculationTypeId === state.activeImpactFigureTypeId && passportImpactFigure.materialImpactFigureTypeId === state.overruleEndUseId)) {
                    return {
                        ...passportImpactFigure,
                        ...action.payload
                    }
                }

                if (action.payload.id === passportImpactFigure.id) {
                    return {
                        ...passportImpactFigure,
                        ...action.payload
                    }
                }

                return passportImpactFigure
            })
        })
        builder.addCase(load.pending, (state) => {
            Object.assign(state, initialState)
        })
        builder.addCase(load.fulfilled, (state, action: PayloadAction<any>) => {
            state.passport = action.payload.passport

            if (action.payload.composition && action.payload.composition.length) {
                state.composition = action.payload.composition
            }

            if (action.payload.assemblies && action.payload.assemblies.length) {
                state.assemblies = action.payload.assemblies
            }

            if (action.payload.parentAssemblies && action.payload.parentAssemblies.length) {
                state.parentAssemblies = action.payload.parentAssemblies
            }

            if (action.payload.passportGroups && action.payload.passportGroups.length) {
                state.passportGroups = action.payload.passportGroups
            }

            if (action.payload.activeImpactFigureTypeId) {
                state.activeImpactFigureTypeId = action.payload.activeImpactFigureTypeId
            }
        })
        builder.addCase(setGraphImage.fulfilled, (state, action: PayloadAction<any>) => {
            state.graphBlob = action.payload
        })

        builder.addCase(setQrBLob.fulfilled, (state, action: PayloadAction<any>) => {
            state.qrBlob = action.payload
        })

        builder.addCase(loadStyle.fulfilled, (state, action: PayloadAction<StyleInterface>) => {
            state.style = action.payload
        })

        builder.addCase(loadPreference.fulfilled, (state, action: PayloadAction<PassportPreferenceInterface>) => {
            state.passportPreference = action.payload
        })

    }
})

export const {changeTab, changeOverruleEndUse, changeImpactFigureCalculationType} = passportDetailSlice.actions

export const passportDetailReducer = passportDetailSlice.reducer
