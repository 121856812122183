import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    setCreatePassportData,
    changeCreateStep,
    setSdgGoal,
    setManualPassportImpactFigure
} from "../passportCreateSlice";
import {useEffect, useState} from "react";
import {HowToCloud} from "../../../common/components/howTo/HowToCloud";
import {getManualImpactFigure} from "../../../common/utils/PassportImpactFigures";

export const PassportCreateImpact = () => {

    const dispatch = useAppDispatch()
    const {passport} = useAppSelector(state => state.passportCreate);
    const {options} = useAppSelector(state => state.root);

    useEffect(() => {
            window.scrollTo(0, 0)
        }, []
    );

    const [defaultCalculationTypeOnAutoCalculation, setDefaultCalculationTypeOnAutoCalculation] = useState<number | null | undefined>(null);
    useEffect(() => {
        const calculationType = passport.passportImpactFigureCalculationTypeId !== options.impactFigureCalculationTypes?.data?.find((type) => type.code === 'manual')?.id
            ? passport.passportImpactFigureCalculationTypeId
            : options.impactFigureCalculationTypes?.data?.find((type) => type.code === 'lca_plus')?.id
        // Update the state variable
        setDefaultCalculationTypeOnAutoCalculation(calculationType);
    }, []);

    const manualImpactFigures = getManualImpactFigure(passport, options.impactFigureCalculationTypes?.data)

    return (
        <div className="c-app__create__primary">
            <header className="c-app__create__header">
                <small>Step 4/6</small>
                <h1>Impact</h1>
                <p>The impact of your product can be calculated by the system or you can use your own impact data.
                    In the latter case, Cirmar can't take responsibility for the accuracy of the values.</p>
                <p>The values calculated by Cirmar are derived from ISO 14000 compliant international databases and
                    projected by Cirmar at a Cradle to gate, Cradle to grave or an LCA+ scope(more info <a
                        href='https://cirmar.com/faq' target={'_blank'} style={{color: '#E14343'}}>here)</a>.</p>

            </header>

            {passport.typeId === 3 &&
                <form action="" className="c-form c-form--black" style={{marginBottom: "2rem"}}>
                    <div className="c-form__row">
                        <ImpactInput
                            onChange={(value) => dispatch(setCreatePassportData({
                                sroiImpact: value + ''
                            }))}
                            value={passport.sroiImpact}
                            howToCode={'pp-sroi-impact'}
                            label={'SROI impact'}
                            measurement={'Minutes'}
                        />
                    </div>
                </form>
            }

            <div className="c-app__info-card">

                <div className="c-app__info-card__intro" style={{margin: 0}}><h2>Select impact source {<HowToCloud
                    code={'pp-select-impact-source'}/>}</h2>
                    <form action="" className="c-form c-form--black">
                        <div className="c-form__row c-form__row--radio c-form__row--half">

                            <div className="c-form__group">
                                <label htmlFor="">
                                    <input
                                        type="radio"
                                        onClick={() => dispatch(setCreatePassportData({
                                            calculateImpactFigures: 0,
                                            passportImpactFigureCalculationTypeId: 1
                                        }))}
                                        checked={!passport.calculateImpactFigures}
                                        name={'calculateImpactFigures'}
                                    />
                                    <span>Fill in your own impact figures</span>
                                </label>
                            </div>

                            <div className="c-form__group">
                                <label htmlFor="">
                                    <input
                                        type="radio"
                                        onClick={() => dispatch(setCreatePassportData({
                                            calculateImpactFigures: 1,
                                            passportImpactFigureCalculationTypeId: defaultCalculationTypeOnAutoCalculation
                                        }))}
                                        checked={!!passport.calculateImpactFigures}
                                        name={'calculateImpactFigures'}
                                    />
                                    <span>Let the system calculate the impact values, based on the materials and weights in this C_passport<sup>®</sup></span>
                                </label>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

            {!passport.calculateImpactFigures &&
                <form action="" className="c-form c-form--black">

                    <div className="c-form__row c-form__row--half">

                        <ImpactInput
                            onChange={(value) => dispatch(setManualPassportImpactFigure({
                                passportImpactFigure:{
                                    co2Impact: value + ''
                                },
                                passportImpactFigureCalculationTypes: options.impactFigureCalculationTypes?.data

                            }))}
                            value={manualImpactFigures?.co2Impact}
                            label={'CO₂ impact'}
                            measurement={'Kg'}
                        />

                        <ImpactInput
                            onChange={(value) => dispatch(setManualPassportImpactFigure({
                                passportImpactFigure:{
                                    rawMaterialImpact: value + ''
                                },
                                passportImpactFigureCalculationTypes: options.impactFigureCalculationTypes?.data
                            }))}
                            value={manualImpactFigures?.rawMaterialImpact}
                            label={'Raw material impact'}
                            measurement={'Kg'}
                        />

                    </div>

                    <div className="c-form__row c-form__row--half">

                        <ImpactInput
                            onChange={(value) => dispatch(setManualPassportImpactFigure({
                                passportImpactFigure:{
                                    energyImpact: value + ''
                                },
                                passportImpactFigureCalculationTypes: options.impactFigureCalculationTypes?.data
                            }))}
                            value={manualImpactFigures?.energyImpact}
                            label={'Energy impact'}
                            measurement={'kWh'}
                        />

                        <ImpactInput
                            onChange={(value) => dispatch(setManualPassportImpactFigure({
                                passportImpactFigure:{
                                    h2oImpact: value + ''
                                },
                                passportImpactFigureCalculationTypes: options.impactFigureCalculationTypes?.data
                            }))}
                            value={manualImpactFigures?.h2oImpact}
                            label={'H₂O impact'}
                            measurement={'L'}
                        />

                    </div>

                    {/**
                     <div className="c-form__row">
                     <div className="c-form__group c-form__group--checkbox">
                     <label htmlFor="">
                     <input onChange={() => dispatch(toggleCustomImpact())} checked={customImpact}
                     type="checkbox"/>
                     <span>I want to use my own impact numbers</span>
                     </label>
                     </div>
                     </div> */}

                </form>
            }

            {!!passport.calculateImpactFigures &&

                <div className="c-app__info-card">
                    <div className="c-app__info-card__intro" style={{margin: 0}}><h2>Select impact calculation scope {
                        <HowToCloud
                            code={'pp-select-impact-calculation-type'}/>}</h2>
                    </div>
                    <form action="" className="c-form c-form--black">
                        <div className="c-form__row c-form__row--radio c-form__row--half">

                            <div className="c-form__group">
                                {options.impactFigureCalculationTypes?.data?.filter(calculationType => calculationType.enabled)
                                    .map((calculationType) => {

                                        return (
                                            <label htmlFor="">
                                                <input
                                                    type="radio"
                                                    onClick={() => dispatch(setCreatePassportData({
                                                        passportImpactFigureCalculationTypeId: calculationType.id
                                                    }))}
                                                    checked={passport.passportImpactFigureCalculationTypeId === calculationType.id}
                                                    name={'passportImpactFigureCalculationType'}
                                                />
                                                <span>{calculationType.name}</span>
                                            </label>
                                        )
                                    })}
                            </div>

                        </div>
                    </form>
                </div>
            }

            <div className="c-app__create__sdg">
                <h2>SDG impact <HowToCloud code={'pp-sdg-impact'}/></h2>
                <p>
                    Please select the SDG goals that are relevant to this passport. No more than 4 goals should be
                    selected.
                </p>

                <div className="c-app__create__sdg__container">
                    {options.sdgGoals?.data?.map((sdgGoal) => {

                        return (
                            <div className="c-app__create__sdg__single">
                                <label htmlFor={'sdg' + sdgGoal.id}>
                                    <img src={sdgGoal.image} alt=""/>
                                    <input
                                        disabled={!passport.sdgGoals?.includes(sdgGoal.id) && passport.sdgGoals && passport.sdgGoals.length > 3}
                                        onClick={() => {
                                            dispatch(setSdgGoal(sdgGoal.id))
                                        }}
                                        checked={!!passport.sdgGoals?.includes(sdgGoal.id)} id={'sdg' + sdgGoal.id}
                                        type="checkbox"/>
                                </label>
                            </div>
                        )
                    })}

                </div>
            </div>


            <button onClick={() => dispatch(changeCreateStep(5))}
                    className="btn btn--large btn--margin btn--width btn--rounded btn--red">
                <span>Stap 5: Extra information</span>
            </button>
        </div>
    )

}

interface ImpactInputInterface {
    value?: string,
    label?: string,
    howToCode?: string,
    onChange
        :
        (value: string) => void;
    measurement?: string
}

const ImpactInput = (props: ImpactInputInterface) => {

    const {customImpact} = useAppSelector(state => state.passportCreate);

    return (
        <div className="c-form__group c-form__select">
            <label htmlFor="">{props.label} {props.howToCode && <HowToCloud code={props.howToCode}/>}</label>
            <input disabled={!customImpact} onChange={(e) => props.onChange(e.target.value)} type="number"
                   value={props.value}/>
            {!!props.measurement &&
                <small>{props.measurement}</small>
            }
        </div>
    )
}
