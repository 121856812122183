import {ApiEnum} from "../../../enums/ApiEnum";
import {PassportGroupCreateInterface} from "../../../interfaces/PassportInterface";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationGroupUpdate = async (passportGroup: PassportGroupCreateInterface) => {

    return await useAppAxios.post(ApiEnum.ORGANISATION_GROUP + '/update', passportGroup, {
        params: {
            id: passportGroup.id
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
