import {apiOrganisationPassportDetail} from "../api/organisation/passport/apiOrganisationPassportDetail";
import {SdgGoalInterface} from "../interfaces/SdgGoalInterface";

export const LoadPassportAction = async (uuid: string) => {

    const response = await apiOrganisationPassportDetail({
        uuid: uuid,
    })

    if (response && response.data && response.data.items.data && response.data.items.data.passport) {
        const passport = response.data.items.data.passport

        if ('sroiImpact' in passport) {
            passport.sroiImpact = passport.sroiImpact.replace(',', '.')
        }

        const connected = response.data.items.data.connected

        if (connected) {

            passport.connectedPassports = []

            Object.values(connected).forEach((passportCategory: any) => {
                passportCategory.forEach((passportId: any) => {
                    passport.connectedPassports.push(passportId)
                })
            })

        }

        if (response.data.items.data.passport.type) {
            passport.typeId = response.data.items.data.passport.type.id
            delete passport.type
        }

        if (response.data.items.data.passport.weightType) {
            passport.weightTypeId = response.data.items.data.passport.weightType.id
            delete passport.weightType
        }

        if (response.data.items.data.passport.amountType) {
            passport.amountTypeId = response.data.items.data.passport.amountType.id
            delete passport.amountType
        }

        if (response.data.items.data.passport.sdgGoals) {

            let sdgGoals = [] as number[]

            Object.values(response.data.items.data.passport.sdgGoals).forEach((sdgGoal: SdgGoalInterface) => {
                sdgGoals.push(sdgGoal.id)
            })

            passport.sdgGoals = sdgGoals

        }

        delete passport.dateCreated
        delete passport.dateModified

        return {
            passport: passport,
            assemblies: response.data.items.data.assemblies,
            parentAssemblies: response.data.items.data.parentAssemblies,
            composition: response.data.items.data.composition,
            passportGroups: response.data.items.data.passportGroups,
            activeImpactFigureTypeId: response.data.items.data.passport.passportImpactFigureCalculationTypeId
        }
    }
}
