import {AccountWrapper} from "../../wrappers/AccountWrapper/AccountWrapper";
import FormInput from "../../common/components/form/FormInput";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {load, setStylingData, submit} from "./accountStylingSlice";
import {useEffect, useState} from "react";
import {GridLoader} from "react-spinners";
import {FreeTrialBanner} from "../../common/components/general/FreeTrialBanner";

const AccountStylingPageContainer = () => {

    const {styling} = useAppSelector(state => state);
    const {style} = styling
    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)


    useEffect(() => {
            setLoading(true)
            dispatch(load()).then(() => {
                setLoading(false)
            })
        }, [dispatch]
    )


    useEffect(() => {
            if (styling.edit) {

                const timeout = setTimeout(() => {
                    dispatch(submit())
                }, 600);
                return () => {
                    clearTimeout(timeout);
                }

            }
        }, [styling, dispatch]
    )

    return (
        <AccountWrapper>

            {!loading ? <>
                    <div className="c-app__info-card">
                        <div className="c-app__info-card__intro" style={{margin: 0}}><h1>Preferences</h1>
                            <form action="" className="c-form">
                                <div className="c-form__row c-form__row--radio c-form__row--half">
                                    <div className="c-form__group">
                                        <label htmlFor="">
                                            <input type="radio"
                                                   checked={!style.customStyle}
                                                   onClick={() => {
                                                       dispatch(setStylingData({
                                                           customStyle: false
                                                       }))
                                                   }}
                                                   name={'custom-style'}/>
                                            <span>Default Cirmar style</span>
                                        </label>
                                    </div>
                                    <div className="c-form__group"><label htmlFor="">
                                        <input type="radio"
                                               checked={style.customStyle}
                                               onClick={() => {
                                                   dispatch(setStylingData({
                                                       customStyle: true
                                                   }))
                                               }}
                                               name={'custom-style'}/>
                                        <span>Custom style</span></label></div>
                                </div>
                            </form>
                            <div className="c-message">
                                <header className="c-message__header">
                                    <img src="/images/icons/info-red.svg" alt=""/>
                                    <h4>Where is this style applied?</h4>
                                </header>
                                <p>Custom style is applied on <u>public C_passport pages</u>, <u>PDF exports of C_passports</u> and <u>public C_dashboard pages</u>.</p>
                            </div>

                        </div>
                    </div>

                    {style.customStyle &&
                    <div className="c-app__info-card">
                        <div className="c-app__info-card__intro" style={{margin: 0}}>
                            <h2>Custom style preferences</h2>
                            <div className="c-form__row c-form__row--radio c-form__row--quarter">

                                <FormInput
                                    value={style.primaryColorLight}
                                    label={'Primary color light'}
                                    type={'color'}
                                    onChange={(value) => dispatch(setStylingData({
                                        primaryColorLight: value + ''
                                    }))}
                                />
                                <FormInput
                                    value={style.primaryColorDark}
                                    label={'Primary color dark'}
                                    type={'color'}
                                    onChange={(value) => dispatch(setStylingData({
                                        primaryColorDark: value + ''
                                    }))}
                                />
                                <FormInput
                                    value={style.secondaryColorLight}
                                    label={'Secondary color light'}
                                    type={'color'}
                                    onChange={(value) => dispatch(setStylingData({
                                        secondaryColorLight: value + ''
                                    }))}
                                />
                                <FormInput
                                    value={style.secondaryColorDark}
                                    label={'Secondary color dark'}
                                    type={'color'}
                                    onChange={(value) => dispatch(setStylingData({
                                        secondaryColorDark: value + ''
                                    }))}
                                />
                            </div>
                        </div>
                    </div>
                    }
                </> :
                <div className={'c-loader'}>
                    <GridLoader color={'#E14343'}/>
                </div>
            }

          <FreeTrialBanner text={'Would you like to add styling to your passports and dashboards? Complete your Cirmar account today!'} />

        </AccountWrapper>
    )

}

export default AccountStylingPageContainer