import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiOrganisationInfoDashboardPreference} from "../../common/api/organisation/info/apiOrganisationInfoDashboardPreference";
import {DashboardPreferenceInterface} from "../../common/interfaces/DashboardPreferenceInterface";
import {apiOrganisationUpdateDashboardPreference} from "../../common/api/organisation/info/apiOrganisationUpdateDashboardPreference";

interface DashboardSettingsStateInterface {
    loading: boolean,
    publicDashboard: {
        submitting: boolean,
        submitted: boolean,
    }
    styling: {
        submitting: boolean,
        submitted: boolean,
    }
    dashboardPreference: DashboardPreferenceInterface,
    activeView: 'public' | 'styling'
}

const initialState: DashboardSettingsStateInterface = {
    publicDashboard: {
        submitting: false,
        submitted: false,
    },
    styling: {
        submitting: false,
        submitted: false,
    },
    loading: false,
    dashboardPreference: {},
    activeView: 'public'
}

export const load = createAsyncThunk(
    'dashboardSettings/load',
    async () => {

        const response = await apiOrganisationInfoDashboardPreference()
        return response.data.items.dashboardPreference

    }
)

export const submitPublic = createAsyncThunk(
    'dashboardSettings/submitPublic',
    async (_, {getState}) => {

        const {dashboardSettings} = getState() as { dashboardSettings: DashboardSettingsStateInterface };
        await apiOrganisationUpdateDashboardPreference(dashboardSettings.dashboardPreference)

    }
)


const dashboardSettings = createSlice({
    name: 'dashboardSettings',
    initialState,
    reducers: {
        setDashboardSettingsPublicData: (state, action: PayloadAction<DashboardPreferenceInterface>) => {
            state.publicDashboard.submitted = false
            state.styling.submitted = false
            state.dashboardPreference = {
                ...state.dashboardPreference,
                ...action.payload
            }
        },
        setView: (state, action: PayloadAction<'public' | 'styling'>) => {
            state.activeView = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(load.pending, (state) => {
            state.loading = true
        })
        builder.addCase(load.fulfilled, (state, action: PayloadAction<DashboardPreferenceInterface>) => {
            state.loading = false
            state.dashboardPreference = action.payload
        })
        builder.addCase(submitPublic.pending, (state) => {
            state.publicDashboard.submitting = true
        })
        builder.addCase(submitPublic.fulfilled, (state) => {
            state.publicDashboard.submitting = false
            state.publicDashboard.submitted = true

        })

    }
})

export const {
    setView,
    setDashboardSettingsPublicData,
} = dashboardSettings.actions
export const dashboardSettingsReducer = dashboardSettings.reducer