import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    PassportGroupCreateInterface,
    PassportGroupInterface,
    PassportInterface
} from "../../common/interfaces/PassportInterface";
import {apiOrganisationGroupUpdate} from "../../common/api/organisation/group/apiOrganisationGroupUpdate";

interface RemoveFromPassportGroupModalInterface {
    group?: PassportGroupCreateInterface,
    detailPage: boolean,
    deleted: boolean,
    submitting: boolean,
}

export const removeFromPassportGroup = createAsyncThunk(
    'removeFromPassportGroupModal/removePassport',
    async (_, {getState}) => {

        const {removeFromPassportGroupModal} = getState() as { removeFromPassportGroupModal: RemoveFromPassportGroupModalInterface };

        if (removeFromPassportGroupModal.group) {
            const response = await apiOrganisationGroupUpdate(removeFromPassportGroupModal.group)

            if (response && response.data && response.data.items && response.data.items.data) {
                return response.data.items
            }
        }

    }
)

const initialState: RemoveFromPassportGroupModalInterface = {
    submitting: false,
    detailPage: false,
    deleted: false
}

const removeFromPassportGroupModal = createSlice({
    name: 'removeFromPassportGroupModal',
    initialState,
    reducers: {
        setRemoveFromPassportGroup: (state, action: PayloadAction<{
            passportId?: number,
            group?: PassportGroupInterface,
            detailPage?: boolean
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup && action.payload.group && action.payload.group.passports) {
                if (action.payload.detailPage) {
                    state.detailPage = true
                }

                let groupPassports: any = []

                action.payload.group.passports.forEach((passport: PassportInterface) => {
                    groupPassports.push(passport.id)
                })

                if (action.payload.passportId) {
                    const index = groupPassports.indexOf(action.payload.passportId, 0);
                    if (index > -1) {
                        groupPassports.splice(index, 1);
                    }
                }

                state.group = {
                    id: action.payload.group.id,
                    name: action.payload.group.name,
                    description: action.payload.group.description,
                    passports: groupPassports
                }

            } else {
                state.detailPage = false
                delete state.group
            }
        },
        resetDeleted: (state) => {
            state.deleted = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(removeFromPassportGroup.pending, (state) => {
            state.deleted = false
            state.submitting = true
        })
        builder.addCase(removeFromPassportGroup.fulfilled, (state) => {
            if (state.detailPage) {
                state.deleted = true
                state.detailPage = false
            }
            delete state.group
            state.submitting = false
        })
    },
})

export const {setRemoveFromPassportGroup, resetDeleted} = removeFromPassportGroupModal.actions

export const removeFromPassportGroupModalReducer = removeFromPassportGroupModal.reducer
