import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {SimulatedPassportAmountInterface} from "../../../interfaces/SimulatedPassportAmountInterface";

interface Args {
    uuid: string,
    simulatedPassportAmounts?: SimulatedPassportAmountInterface[]
}

export const apiOrganisationDashboardDetail = async (args: Args) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_DASHBOARD + '/detail', {
        simulatedPassportAmounts: args.simulatedPassportAmounts?.map((simulatedPassport) => {
            return {
                uuid: simulatedPassport.uuid,
                amount: Number(simulatedPassport.amount),
                passportImpactFigureCalculationTypeId: simulatedPassport.passportImpactFigureCalculationTypeId,
                passportEndOfUseSolutionId: simulatedPassport.passportEndOfUseSolutionId
            }
        })
    }, {
        params: {
            uuid: args.uuid,
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
