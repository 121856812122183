import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
    PassportGroupInterface,
    PassportInterface,
} from "../../common/interfaces/PassportInterface";
import {apiOrganisationPassportList} from "../../common/api/organisation/passport/apiOrganisationPassportList";
import {apiOrganisationGroupList} from "../../common/api/organisation/group/apiOrganisationGroupList";

interface passportOverviewInterface {
    settings: {
        page: number,
        limit: number,
        filters: {
            groupName?: string,
            searchString?: string,
            type?: number
            weightType?: number
        }
    },
    passports?: {
        data: PassportInterface[],
        pages?: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    groups?: {
        data: PassportGroupInterface[],
        pages: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    loading: boolean,
    tabState: string,
}

const initialState: passportOverviewInterface = {
    settings: {
        page: 1,
        limit: 10,
        filters: {}
    },
    loading: true,
    tabState: 'passport'
}

export const passportList = createAsyncThunk(
    'root/passportList',
    async (_, {getState}) => {

        const {passportOverview} = getState() as { passportOverview: passportOverviewInterface };

        const response = await apiOrganisationPassportList(passportOverview.settings.limit, passportOverview.settings.page, passportOverview.settings.filters.searchString, passportOverview.settings.filters.type, passportOverview.settings.filters.weightType)
        if (response && response.data && response.data.items && response.data.items.data) {
            return response.data.items
        }
    }
)

export const groupList = createAsyncThunk(
    'root/groupList',
    async (_, {getState}) => {

        const {passportOverview} = getState() as { passportOverview: passportOverviewInterface };

        const response = await apiOrganisationGroupList(passportOverview.settings.limit, passportOverview.settings.page, passportOverview.settings.filters.groupName)

        if (response && response.data && response.data.items && response.data.items.data) {
            return response.data.items
        }
    }
)


const passportOverviewSlice = createSlice({
    name: 'passportOverview',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<number>) => {
            state.settings.page = action.payload
        },
        setTabState: (state, action: PayloadAction<string>) => {
            state.tabState = action.payload
        },
        setLimit: (state, action: PayloadAction<number>) => {
            state.settings.limit = action.payload
        },
        setFilterSearchString: (state, action: PayloadAction<string>) => {
            state.settings.filters.searchString = action.payload
            state.settings.page = 1
        },
        setFilterType: (state, action: PayloadAction<number>) => {
            state.settings.filters.type = action.payload
            state.settings.page = 1
        },
        setFilterWeightType: (state, action: PayloadAction<number>) => {
            state.settings.filters.weightType = action.payload
            state.settings.page = 1
        },
        setFilterGroupName: (state, action: PayloadAction<string>) => {
            state.settings.filters.groupName = action.payload
            state.settings.page = 1
        }
    },
    extraReducers: (builder) => {
        builder.addCase(passportList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(passportList.fulfilled, (state, action: PayloadAction<passportOverviewInterface["passports"]>) => {
            state.loading = false
            state.passports = action.payload
        })
        builder.addCase(groupList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(groupList.fulfilled, (state, action: PayloadAction<passportOverviewInterface["groups"]>) => {
            state.loading = false
            state.groups = action.payload
        })
    }
})

export const {
    setFilterSearchString, setFilterType, setFilterGroupName, setLimit, setTabState, setPage, setFilterWeightType
} = passportOverviewSlice.actions

export const passportOverviewReducer = passportOverviewSlice.reducer
