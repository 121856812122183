import {configureStore} from '@reduxjs/toolkit'
import {loginReducer} from "../pages/loginPage/loginSlice";
import {forgotPasswordReducer} from "../pages/forgotPasswordPage/forgotPasswordSlice";
import {signupReducer} from "../pages/signupPage/signupSlice";
import rootReducer from "./rootReducer";
import {passportCreateReducer} from "../pages/passportCreatePage/passportCreateSlice";
import {passportOverviewReducer} from "../pages/passportOverviewPage/PassportOverviewPageSlice";
import {organisationReducer} from "../pages/accountOrganisationPage/AccountOrganisationSlice";
import {organisationEditReducer} from "../pages/accountOrganisationEditPage/AccountOrganisationEditSlice";
import {usersReducer} from "../pages/accountUsersPage/AccountUsersSlice";
import {userCreateReducer} from "../pages/accountUsersCreatePage/accountUsersCreateSlice";
import {styleReducer} from "../pages/accountStylingPage/accountStylingSlice";
import {billingReducer} from "../pages/accountBillingPage/accountBillingSlice";
import {passportSettingsReducer} from "../pages/passportSettingsPage/PassportSettingsSlice";
import {passportDetailReducer} from "../pages/passportDetailPage/PassportDetailSlice";
import {publicPassportReducer} from "../pages/passportPublicPage/PublicPassportSlice";
import {activateAccountReducer} from "../pages/activateAccountPage/activateAccountSlice";
import {dashboardCreateReducer} from "../pages/dashboardCreatePage/dashboardCreateSlice";
import {dashboardOverviewReducer} from "../pages/dashboardOverviewPage/dashboardOverviewSlice";
import {dashboardDetailReducer} from "../pages/dashboardDetailPage/dashboardDetailPageSlice";
import {dashboardPublicReducer} from "../pages/dashboardPublicPage/dashboardPublicSlice";
import {forgotPasswordResetReducer} from "../pages/forgotPasswordResetPage/forgotPasswordResetSlice";
import {trashPassportModalReducer} from "../modals/trashPassportModal/trashPassportModalSlice";
import {trashDashboardModalReducer} from "../modals/trashDashboardModal/trashDashboardModalSlice";
import {duplicatePassportModalReducer} from "../modals/duplicatePassportModal/duplicatePassportModalSlice";
import {dashboardSettingsReducer} from "../pages/dashboardSettingsPage/DashboardSettingsSlice";
import {cockpitReducer} from "../pages/cockpitPage/cockpitSlice";
import {upgradeAccountReducer} from "../pages/upgradeAccountPage/upgradeAccountSlice";
import {duplicatePassportGroupModalReducer} from "../modals/duplicatePassportGroupModal/duplicatePassportGroupModalSlice";
import {trashPassportGroupModalReducer} from "../modals/trashPassportGroupModal/trashPassportGroupModalSlice";
import {passportGroupReducer} from "../pages/passportGroupPage/PassportGroupSlice";
import {passportGroupCreateReducer} from "../pages/passportGroupCreatePage/passportGroupCreateSlice";
import {removeFromPassportGroupModalReducer} from "../modals/removeFromPassportGroupModal/removeFromPassportGroupModalSlice";

export const store = configureStore({
    reducer: {
        root: rootReducer,
        login: loginReducer,
        activateAccount: activateAccountReducer,
        forgotPassword: forgotPasswordReducer,
        cockpit: cockpitReducer,
        forgotPasswordReset: forgotPasswordResetReducer,
        signup: signupReducer,
        passportCreate: passportCreateReducer,
        passportOverview: passportOverviewReducer,
        passportSettings: passportSettingsReducer,
        organisation: organisationReducer,
        organisationEdit: organisationEditReducer,
        upgradeAccount: upgradeAccountReducer,
        users: usersReducer,
        userCreate: userCreateReducer,
        styling: styleReducer,
        billing: billingReducer,
        passportDetail: passportDetailReducer,
        publicPassport: publicPassportReducer,
        dashboardCreate: dashboardCreateReducer,
        dashboardOverview: dashboardOverviewReducer,
        passportGroup: passportGroupReducer,
        passportGroupCreate: passportGroupCreateReducer,
        dashboardSettings: dashboardSettingsReducer,
        dashboardDetail: dashboardDetailReducer,
        dashboardPublic: dashboardPublicReducer,
        trashPassportModal: trashPassportModalReducer,
        trashPassportGroupModal: trashPassportGroupModalReducer,
        trashDashboardModal: trashDashboardModalReducer,
        duplicatePassportModal: duplicatePassportModalReducer,
        duplicatePassportGroupModal: duplicatePassportGroupModalReducer,
        removeFromPassportGroupModal: removeFromPassportGroupModalReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

