import {DashboardSettingsPublicView} from "./components/DashboardSettingsPublicView";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

import {submitPublic} from "./DashboardSettingsSlice";
import {DashboardSettingsHeader} from "./components/DashboardSettingsHeader";
import {DashboardSettingsNavigation} from "./components/DashboardSettingsNavigation";

const DashboardSettingsPageContainer = () => {

    const {activeView} = useAppSelector((state) => state.dashboardSettings)

    const dispatch = useAppDispatch()

    return (

        <form
            onSubmit={(e) => {
                e.preventDefault()
                if (activeView === 'public') {
                    dispatch(submitPublic())
                }
            }}>

            <DashboardSettingsHeader/>


            <div className="c-app__content__container">
                <div className="c-app__view">
                    <DashboardSettingsNavigation/>
                    {activeView === 'public' && <DashboardSettingsPublicView/>}
                </div>
            </div>
        </form>

    )

}

export default DashboardSettingsPageContainer