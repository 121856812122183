import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationGroupDuplicate = async (id: number) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_GROUP + '/duplicate', {}, {
        params: {
            id: id
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
