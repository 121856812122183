import FormInput from "../../../common/components/form/FormInput";
import {changeStep, setName, setDescription} from "../passportGroupCreateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

const PassportGroupCreateInformationForm = () => {

    const dispatch = useAppDispatch()

    const {passportGroup} = useAppSelector(state => state.passportGroupCreate);

    return (
        <>

            <div className="c-app__passport-group__content">
                <h4>Name and description</h4>
                <p>Please add a name and description for this C_passport<sup>®</sup> group.</p>
            </div>

            <form
                onSubmit={() => {
                    dispatch(changeStep(2))
                }}
                className="c-form">

                <div className="c-form__row">
                    <FormInput
                        onChange={(value) => {dispatch(setName(value + ''))}}
                        type={'text'}
                        label={'Name of group'}
                        value={passportGroup.name}
                        required={true}
                    />
                </div>

                <div className="c-form__row">

                    <div className="c-form__group">

                        <label htmlFor="">Description</label>

                        <textarea
                            onChange={(e) => {dispatch(setDescription(e.target.value + ''))}}
                            value={passportGroup.description}
                        />

                    </div>

                </div>

                <button type={'submit'} className="btn btn--margin btn--rounded btn--red">
                    <span>Add C_passports<sup>®</sup></span>
                </button>

            </form>

        </>
    )

}

export default PassportGroupCreateInformationForm
