import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {Link} from "react-router-dom";
import {previousStep, nextStep, setStep} from "../cockpitSlice";
import {useEffect} from "react";
import {RouteEnum} from "../../../common/enums/RouteEnum";
import {useState} from 'react'
import ModalVideo from 'react-modal-video'

interface Steps {
    step: number,
    actionText: string,
    textExplanation: string,
    buttonText: string,
    isVideo: boolean | undefined,
    percentageCompleted: string
    url: string
}

export const AssistantSteps = () => {

    const {info} = useAppSelector((state) => state.cockpit)

    const data = [
        {
            step: 1,
            actionText: 'Watch the C_passport® tutorial',
            textExplanation: 'Digital product passports are the heart and soul of Cirmar. In this short video, we explain how you can create them yourself.',
            buttonText: 'Watch video',
            isVideo: true,
            url: info?.assistant?.videoPassportUrl,
            percentageCompleted: ""
        },
        {
            step: 2,
            actionText: 'Create your first C_passport®',
            textExplanation: 'Let\'s get started! In step 1 we explained how C_passport® works. Now it\'s time for you to jump into action.',
            buttonText: 'Create C_passport®',
            percentageCompleted: "25",
            url: RouteEnum.PASSPORT_CREATE
        },
        {
            step: 3,
            actionText: 'Add company flavours',
            textExplanation: 'Change the look and feel to match your company\'s colours.',
            buttonText: 'Change branding',
            percentageCompleted: "50",
            url: RouteEnum.ACCOUNT_STYLING
        },
        {
            step: 4,
            actionText: 'Learn how to create and use C_dashboard®',
            textExplanation: 'Watch the tutorial to find out how it works and how you can use it.',
            buttonText: 'Watch video',
            isVideo: true,
            percentageCompleted: "75",
            url: info?.assistant?.videoDashboardUrl,
        },
        {
            step: 5,
            actionText: 'Create your first C_dashboard®',
            textExplanation: 'It only takes a few minutes: discover the possibilities!',
            buttonText: 'Create C_dashboard®',
            percentageCompleted: "100",
            url: RouteEnum.DASHBOARD_CREATE,
        },
        {
            step: 6,
            actionText: 'You have taken your first steps in Cirmar!',
            textExplanation: 'Continue your journey by creating more passports and dashboards. Add relevant information and show your circular impact.',
            percentageCompleted: "100",
        }
    ] as Steps[]

    return (
        <Assistant assistantSteps={data}/>
    )
}

interface Props {
    assistantSteps: Steps[]
}

const Assistant = (props: Props) => {

    const dispatch = useAppDispatch();

    const {info} = useAppSelector((state) => state.cockpit)

    const {assistantSteps} = props
    const {assistantStep} = useAppSelector((state) => state.cockpit)

    const [isOpen, setOpen] = useState(false)

    let url = assistantSteps[assistantStep]?.url
    const videoId = url?.split('?v=')[1]


    useEffect(() => {
        if (info?.hasPassport) {
            dispatch(setStep(2))
        }

        if (info?.hasStyle) {
            dispatch(setStep(3))
        }

        if (info?.hasDashboard) {
            dispatch(setStep(5))
        }

    }, [info])

    return (
        <>
            <div className="c-dashboard__card c-dashboard__card--fill c-assistant">
                <h4 className="c-cockpit__card-title">Cirmar assistant</h4>

                <div className="c-assistant__step-instruction">
                    {assistantSteps[assistantStep].step !== 6 ? (
                        <h3 className="c-dashboard__card__title">{assistantSteps[assistantStep].step + '/5: ' + assistantSteps[assistantStep].actionText}</h3>
                    ) : (
                        <h3 className="c-dashboard__card__title">{assistantSteps[assistantStep].actionText}</h3>
                    )
                    }
                    <p>{assistantSteps[assistantStep].textExplanation}</p>

                    {assistantSteps[assistantStep].isVideo &&
                    <button className="btn btn--rounded btn--red" onClick={() => setOpen(true)}>
                        <span>{assistantSteps[assistantStep].buttonText}</span>
                    </button>
                    }

                    {!assistantSteps[assistantStep].isVideo && !!assistantSteps[assistantStep].url && !!assistantSteps[assistantStep].buttonText &&
                    <Link to={assistantSteps[assistantStep].url} className="btn btn--rounded btn--red">
                        <span>{assistantSteps[assistantStep].buttonText}</span>
                    </Link>
                    }

                    {assistantSteps[assistantStep].step !== 1 &&
                    <button className="btn--text btn--text--margin-right-2" onClick={() => dispatch(previousStep())}>Previous step</button>
                    }

                    {assistantSteps[assistantStep].step !== 6 &&
                    <button className="btn--text" onClick={() => dispatch(nextStep())}>Next step</button>
                    }

                </div>

                <div className="c-assistant__steps">

                    <span
                        className={'c-assistant__steps__bar c-assistant__steps__bar--filled-' + assistantSteps[assistantStep].percentageCompleted}/>

                    {assistantSteps.map((step, index) => {
                        return <StepCounter step={step} index={index}/>
                    })}

                </div>
            </div>
            <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId} onClose={() => setOpen(false)}/>
        </>
    )
}

const StepCounter = (props: any) => {

    let stepClass = 'c-assistant__steps__step '

    const {step} = props
    const {index} = props

    const {info} = useAppSelector((state) => state.cockpit)
    const {currentStep} = useAppSelector((state) => state.cockpit)

    if (index === currentStep) {
        stepClass += 'c-assistant__steps__step--current'
    }

    if (index < currentStep) {
        stepClass += 'c-assistant__steps__step--completed'
    }

    if (info?.hasDashboard) {
        stepClass = 'c-assistant__steps__step c-assistant__steps__step--completed'
    }

    // don't make extra step for endstep
    if (step.step === 6) {
        return null
    }

    return (
        <div className={stepClass}>
            <span>{step.step}</span>
            <img src="/images/icons/check-red.svg" alt="check"></img>
        </div>
    )
}
