import {createRef, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {Link, useParams} from "react-router-dom";
import {
    calculateImpact,
    load,
    getUuid,
    loadPreference,
    loadStyle,
    passportList,
    changeImpactFigureCalculationType, changeOverruleEndUse
} from "./PassportDetailSlice";
import {PassportInfo} from "../../common/components/passport/PassportInfo";
import {PassportHeader} from "../../common/components/passport/PassportHeader";
import {PassportProgress} from "../../common/components/passport/PassportProgress";
import {PassportConnectedPassports} from "../../common/components/passport/PassportConnectedPassports";
import {PassportSdgGoals} from "../../common/components/passport/PassportSdgGoals";
import {PassportQrCode} from "../../common/components/passport/PassportQrCode";
import {PassportTabNav} from "../../common/components/passport/PassportTabNav";
import {GridLoader} from "react-spinners";
import {PassportMaterials} from "../../common/components/passport/PassportMaterials";
import {PassportEncodings} from "../../common/components/passport/PassportEncodings";
import {PassportExtraInformation} from "../../common/components/passport/PassportExtraInformation";
import {PassportTabEnums} from "../../common/enums/PassportTabEnums";
import {PassportPdf} from "./pdf/PassportPdf";
import {store} from "../../app/store";
import {Provider} from "react-redux";
import {FormatCompositionToPieData} from "../../common/utils/FormatCompositionToPieData";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {pdf} from '@react-pdf/renderer';
import {useHistory} from 'react-router-dom';
import {saveAs} from 'file-saver';
import {PassportImpact} from "../../common/components/passport/PassportImpact";
import {PassportWebsite} from "../../common/components/passport/PassportWebsite";
import {displayModuleByUserRole} from "../../common/utils/displayModuleByUserRole";
import {getTranslationList} from "../../app/rootReducer";
import {PassportGroupInterface} from "../../common/interfaces/PassportInterface";
import {PassportLabels} from "../../common/components/passport/PassportLabels";

interface PassportParams {
    uuid: string;
}

const PassportDetailPageContainer = () => {

    const history = useHistory()

    const [loading, setLoading] = useState(true)

    const {uuid} = useParams<PassportParams>();
    const dispatch = useAppDispatch()
    const testRef = createRef<any>()
    const {materialGroup, materials} = useAppSelector(state => state.root.options);
    const {userMe, translations} = useAppSelector(state => state.root);

    const {
        graphBlob,
        qrBlob,
        activeTab,
        passport,
        style,
        composition,
        passports,
        assemblies,
        loadImpactCalculation,
        passportGroups,
        activeImpactFigureTypeId,
        overruleEndUseId
    } = useAppSelector(state => state.passportDetail);

    const generatePdfDocument = async (fileName: string) => {

        if (graphBlob && qrBlob) {
            const blob = await pdf((
                <Provider store={store}>
                    <PassportPdf
                        style={style}
                        graphData={graphData}
                        graphBlob={graphBlob}
                        qrBlob={qrBlob}
                        composition={composition}
                        passport={passport}
                        activeImpactFigureTypeId={activeImpactFigureTypeId}
                    />
                </Provider>
            )).toBlob();
            saveAs(blob, fileName);

        }
    };

    useEffect(() => {

            if (!isNaN(Number(uuid))) {
                dispatch(getUuid(parseFloat(uuid))).then((response) => {
                    if (response.payload) {
                        history.push('/passport/detail/' + response.payload)
                    }
                })
            }

            if (uuid) {
                dispatch(passportList())
                dispatch(getTranslationList('EN'))

                dispatch(load(uuid)).then(() => {
                    dispatch(loadStyle()).then(() => {
                        dispatch(loadPreference()).then(() => {
                            setLoading(false)
                        })
                    })
                })
            } else {
                setLoading(false)
            }

        }, [uuid, dispatch]
    );

    const graphData = FormatCompositionToPieData({
        composition: composition,
        materialGroup: materialGroup?.data,
        materials: materials?.data
    })

    useEffect(() => {
        dispatch(calculateImpact(1))
    }, [overruleEndUseId, activeImpactFigureTypeId])

    if (!loading) {
        return (
            <div ref={testRef}>

                <PassportHeader passport={passport} composition={composition}/>

                <div className="c-app__content__container">
                    <div className="c-dashboard c-passport">
                        <div className="c-dashboard__row">
                            <div className="c-dashboard__group">

                                <PassportInfo
                                    changeEndUseId={(endUseId) => {
                                        dispatch(changeOverruleEndUse(endUseId))
                                    }}
                                    translations={translations} passport={passport} composition={composition}
                                    overruleEndUseId={overruleEndUseId}
                                    activeImpactFigureTypeId={activeImpactFigureTypeId}/>
                                {displayModuleByUserRole('passportEdit', userMe) &&
                                    <PassportProgress translations={translations} passport={passport}
                                                      composition={composition}/>
                                }

                            </div>
                            <div className="c-dashboard__group">

                                {passport.webUrl &&
                                    <PassportWebsite translations={translations} website={passport.webUrl}/>}

                                <div className="c-dashboard__card c-passport__cta">

                                    {displayModuleByUserRole('passportEdit', userMe) &&
                                        <Link to={RouteEnum.PASSPORT_EDIT + '/' + passport.uuid}
                                              className="c-passport__cta__single">
                                            <span>{translations?.edit} C_passport<sup>®</sup></span>
                                            <div className="btn btn--circle btn--red">
                                                <img src="/images/icons/edit-white.svg" alt=""/>
                                            </div>
                                        </Link>
                                    }

                                    <button
                                        disabled={!graphBlob}
                                        onClick={() => generatePdfDocument('Passport - ' + passport.name)}
                                        className="c-passport__cta__single"
                                        style={{border: 0}}
                                    >
                                        <span>{translations?.downloadPdf}</span>
                                        <div className="btn btn--circle btn--red">
                                            <img src="/images/icons/download-white.svg" alt=""/>
                                        </div>
                                    </button>
                                </div>

                                <PassportQrCode translations={translations} passport={passport}
                                                composition={composition}/>

                            </div>
                        </div>

                        <div className="c-dashboard__row">
                            <div className="c-dashboard__group">

                                <PassportSdgGoals translations={translations} passport={passport}
                                                  composition={composition}/>

                                <PassportLabels passport={passport} composition={composition}
                                                translations={translations}/>

                            </div>
                            <div className="c-dashboard__group">
                                <div className="c-dashboard__card c-passport__tabs">

                                    <PassportTabNav
                                        overruleEndUseId={overruleEndUseId}
                                        translations={translations}
                                        onChangeImpactMultiplier={(value: number) => dispatch(calculateImpact(value))}
                                        activeTab={activeTab}
                                        public={false}
                                        passport={passport}
                                        activeImpactFigureTypeId={activeImpactFigureTypeId}
                                        showMultiplier={activeTab === PassportTabEnums.IMPACT}/>

                                    <div className="c-passport__tabs__content">
                                        {activeTab === PassportTabEnums.MATERIALS &&
                                            <PassportMaterials
                                                translations={translations}
                                                graphData={graphData}
                                                passport={passport}
                                                composition={composition}/>
                                        }
                                        {activeTab === PassportTabEnums.IMPACT &&
                                            <PassportImpact
                                                overruleEndUseId={overruleEndUseId}
                                                translations={translations}
                                                loading={loadImpactCalculation}
                                                passport={passport}
                                                activeImpactFigureTypeId={activeImpactFigureTypeId}
                                                changeImpactFigureTypeId={(impactFigureTypeId: number) => {
                                                    dispatch(changeImpactFigureCalculationType(impactFigureTypeId))
                                                }}
                                                isPublic={false}
                                                composition={composition}/>}
                                        {activeTab === PassportTabEnums.ENCODINGS &&
                                            <PassportEncodings translations={translations} passport={passport}
                                                               composition={composition}/>}
                                        {activeTab === PassportTabEnums.EXTRA_INFORMATION &&
                                            <PassportExtraInformation translations={translations} passport={passport}
                                                                      composition={composition}/>}

                                    </div>

                                </div>

                            </div>

                        </div>

                        {(!!assemblies.length && passports?.data?.length) &&
                            <div className="c-dashboard__row">
                                <PassportConnectedPassports
                                    passport={passport}
                                    assemblies={assemblies}
                                    composition={composition}
                                    passports={passports}
                                    translations={translations}
                                />
                            </div>
                        }

                        {!!passportGroups && passportGroups.length > 0 &&
                            <div className="c-dashboard__row">
                                <div className="c-dashboard__passport-groups">
                                    <h2>This passport belongs to the following group(s)</h2>
                                    <div className="c-card">

                                        {passportGroups.map((group: PassportGroupInterface) => {

                                            return (
                                                <div className="c-card__single c-card__single--small"
                                                     key={'group:' + group.id} style={{padding: '1rem 2rem'}}>

                                                    <div className="c-card__single__info">
                                                        <div className="c-card__single__info__text">
                                                            <h3>{group.name}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="c-card__single__cta">
                                                        <div className="c-card__single__cta__urls">
                                                            <div>
                                                                <Link to={RouteEnum.PASSPORT_GROUP + '/' + group.id}
                                                                      className="btn btn--text"><span>View</span>
                                                                </Link>

                                                                {displayModuleByUserRole('passportEdit', userMe) &&
                                                                    <Link to={RouteEnum.PASSPORT_GROUP + '/' + group.id}
                                                                          className="btn btn--red btn--circle">
                                                                        <img src="/images/icons/edit-white.svg" alt=""/></Link>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else return (
        <div className={'c-loader'}>
            <GridLoader color={'#E14343'}/>
        </div>
    )

}

export default PassportDetailPageContainer
