import React from "react";

interface Props {
    children?: React.ReactNode;
};

export const ForgotPasswordResetWrapper = (props: Props) => {

    return (
        <section className="c-auth">

            <div className="c-auth__primary">

                {props.children}

            </div>

            <div className="c-auth__media u-bg-cover-center"
                 style={{backgroundImage: 'url("/images/auth-image.png")'}}
            />

        </section>
    )

}