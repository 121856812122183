import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import ReactPaginate from 'react-paginate';
import {setPage, setTabState} from "../PassportOverviewPageSlice";
import {PassportOverviewPassports} from "./PassportOverviewPassports";
import {PassportOverviewGroups} from "./PassportOverviewGroups";


export const PassportOverviewMany = () => {

    const {userMe} = useAppSelector(state => state.root);
    const {passports, groups, settings, tabState} = useAppSelector(state => state.passportOverview);
    const dispatch = useAppDispatch()

    function handlePageClick(data: any) {
        dispatch(setPage(data.selected + 1))
    }

    function tabClick(value: string) {
        dispatch(setTabState(value))
        dispatch(setPage(1))
    }

    if (passports?.data && userMe?.organisation) {


        let pages: any = 0
        let total: any = 0

        let current = settings.page * settings.limit

        let tabClassPassport = ''
        let tabClassGroup = ''

        if (tabState === 'passport') {
            pages = passports?.pages
            total = passports?.total
            tabClassPassport = 'isActive'
        }

        if (tabState === 'group') {
            pages = groups?.pages
            total = groups?.total
            tabClassGroup = 'isActive'
        }


        if (total && (current > total)) {
            current = total
        }


        return (
            <div className="c-app__content__container">


                <div className="c-app__view">

                    <div className="c-app__tab c-app__tab--passport-overview">
                        <div>
                            <button className={tabClassPassport} onClick={() => tabClick('passport')}>All passports
                            </button>
                            <button className={tabClassGroup} onClick={() => tabClick('group')}>Groups</button>
                        </div>
                    </div>

                    {tabState === 'passport' &&
                        <PassportOverviewPassports/>
                    }

                    {tabState === 'group' &&
                        <PassportOverviewGroups/>
                    }

                    {!!(pages && pages.total && pages.total > 1) &&
                        <div className="c-app__pagination">
                            <p>{current} - {total} total</p>
                            <nav className={'c-pagination'}>
                                <ReactPaginate
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={Number(pages.total)}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageClick}
                                    containerClassName={'pagination'}
                                    forcePage={Number(pages.current)}
                                    activeClassName={'isActive'}
                                />
                            </nav>
                        </div>
                    }
                </div>
            </div>
        )
    } else return null

}
