import {PassportOverviewWrapper} from "./components/PassportOverviewWrapper";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {PassportOverviewMany} from "./components/PassportOverviewMany";
import {useEffect, useRef, useState} from "react";
import {groupList, passportList} from "./PassportOverviewPageSlice";
import {GridLoader} from "react-spinners";


const PassportOverviewPageContainer = () => {
    const mountedRef = useRef() as { current: boolean }
    const dispatch = useAppDispatch()
    const {trashPassportModal, duplicatePassportModal, trashPassportGroupModal, duplicatePassportGroupModal} = useAppSelector(state => state);
    const {settings} = useAppSelector(state => state.passportOverview);
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
            if (mountedRef.current) {
                const timeout = setTimeout(() => {
                    dispatch(passportList())
                }, 600);
                return () => {
                    clearTimeout(timeout);
                }
            }
        }, [settings.filters.searchString, dispatch]
    );

    useEffect(() => {
            if (mountedRef.current) {
                const timeout = setTimeout(() => {
                    dispatch(groupList())
                }, 600);
                return () => {
                    clearTimeout(timeout);
                }
            }
        }, [settings.filters.groupName, dispatch]
    );

    useEffect(() => {
            if (mountedRef.current) {
                dispatch(passportList())
                dispatch(groupList())
            }
        }, [settings.filters.type, dispatch]
    );

    useEffect(() => {
            if (mountedRef.current) {
                dispatch(passportList())
                dispatch(groupList())
            }
        }, [settings.filters.weightType, dispatch]
    );

    useEffect(() => {
            if (!trashPassportModal.passport && !duplicatePassportModal.passport) {
                dispatch(passportList()).then(() => {
                    setInitialLoading(false)
                })
            }
        }, [trashPassportModal.passport, duplicatePassportModal.passport, dispatch]
    );

    useEffect(() => {
            if (!trashPassportGroupModal.group && !duplicatePassportGroupModal.group) {
                dispatch(groupList()).then(() => {
                    setInitialLoading(false)
                })
            }
        }, [trashPassportGroupModal.group, duplicatePassportGroupModal.group, dispatch]
    );

    useEffect(() => {
            dispatch(passportList())
            dispatch(groupList())
            mountedRef.current = true
        }, [settings.page, dispatch]
    );

    if (!initialLoading) {
        return (
            <PassportOverviewWrapper>
                <PassportOverviewMany/>
            </PassportOverviewWrapper>
        )
    } else return (
        <div className={'c-loader'}>
            <GridLoader color={'#E14343'}/>
        </div>
    )

}

export default PassportOverviewPageContainer
