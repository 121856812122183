import {useAppSelector} from "../../app/hooks";

export const FormatEndUseTreatmentsString = (endUseTreatmentId?: number) => {

    const {endUses} = useAppSelector(state => state.root.options);
    const endUse = endUses?.data?.find((endUseItem) => endUseTreatmentId === endUseItem.id)

    if (endUse) {
        return endUse.name
    } else return ''

}