import {useAppDispatch} from "../../../app/hooks";
import {changeTab} from "../../../pages/passportDetailPage/PassportDetailSlice";
import {changePublicTab} from "../../../pages/passportPublicPage/PublicPassportSlice";
import {PassportPreferenceInterface} from "../../interfaces/PassportPreferenceInterface";
import {PassportInterface} from "../../interfaces/PassportInterface";
import {formatDashboardTabButtons} from "../../utils/formatPassportTabButtons";
import {useEffect, useState} from "react";
import useDidMountEffect from "../../utils/useDidMountEffect";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";


interface Props {
    public: boolean,
    activeTab: number,
    activeImpactFigureTypeId?: number,
    showMultiplier: boolean,
    onChangeImpactMultiplier: (value: number) => any,
    passport: PassportInterface,
    passportPreference?: PassportPreferenceInterface,
    translations?: TranslationsInterface,
    overruleEndUseId?: number
}

export const PassportTabNav = (props: Props) => {

    const dispatch = useAppDispatch()
    const buttons = formatDashboardTabButtons(props.passport, props.passportPreference, props.translations)
    const [impactMultiplier, setImpactMultiplier] = useState(1)

    useEffect(() => {
        setImpactMultiplier(1)
    }, [props.activeImpactFigureTypeId, props.overruleEndUseId]);

    useDidMountEffect(() => {
        {
            const delayDebounceFn = setTimeout(() => {
                props.onChangeImpactMultiplier(impactMultiplier)
            }, 400)

            return () => clearTimeout(delayDebounceFn)

        }
    }, [impactMultiplier])

    if (buttons.length > 0) {
        return (
            <header className="c-passport__tabs__header"
                    style={{display: 'flex', justifyContent: 'space-between', height: '5rem', alignItems: 'center'}}>

                <div className={'buttons'} style={{position: 'relative', top: '0'}}>

                    {buttons.map((button) => {

                        let className = ''

                        if (props.activeTab === button.id) {
                            className += 'isActive'
                        }

                        return (
                            <button onClick={() => {
                                if (props.public) {
                                    dispatch(changePublicTab(button.id))
                                } else {
                                    dispatch(changeTab(button.id))
                                }
                            }} className={className}>{button.title}</button>
                        )
                    })}

                </div>

                {props.showMultiplier &&
                    <div className={'text'} style={{display: 'flex', alignItems: 'center'}}>
                        <p style={{
                            fontSize: '1.4rem',
                            color: '#0E1427',
                            padding: '0',
                            margin: 0
                        }}>{props.translations?.amount}:</p>
                        <input
                            type={'number'} style={{
                            background: '#eee',
                            border: 0,
                            width: '6rem',
                            padding: '.3rem 1rem',
                            marginLeft: '1rem'
                        }}
                            value={impactMultiplier}
                            onChange={(e) => setImpactMultiplier(parseInt(e.target.value))}
                        />
                    </div>
                }

            </header>
        )
    } else return null

}