import {useAppAxios} from "../../../../app/hooks";
import {ApiEnum} from "../../../enums/ApiEnum";


export const apiOrganisationInfoSubscriptions = async () => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/subscriptions', {}, {
        params: {
            limit: 9999
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}

