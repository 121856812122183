import {Link} from "react-router-dom";
import {RouteEnum} from "../../../common/enums/RouteEnum";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setUserData, submit} from "../loginSlice";
import {ClipLoader} from "react-spinners";

export const LoginForm = () => {

    const {user, submitting, submitStatus} = useAppSelector(state => state.login);
    const dispatch = useAppDispatch()


    return (

        <div className="c-auth__primary__container">

            <h1 className="c-auth__primary__title">Login</h1>

            <p>Enter credentials to gain access to your Cirmar application</p>

            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(submit())
                }}
                className="c-auth__form c-form c-form--black">
                <div className="c-form__row">

                    <div className="c-form__group">

                        <label htmlFor="">Email address</label>

                        <input
                            type={'email'}
                            value={user.email}
                            onChange={(e) => dispatch(setUserData({email: e.target.value}))}
                        />

                    </div>

                </div>

                <div className="c-form__row">
                    <div className="c-form__group">

                        <label htmlFor="">Password</label>

                        <input type="password"
                               value={user.password}
                               onChange={(e) => dispatch(setUserData({password: e.target.value}))}
                        />

                    </div>
                </div>

                {(submitStatus === 401 || submitStatus === 404) &&
                <div className={'c-form__row'}>
                    <div className={'c-notification c-notification--red'} style={{marginTop: '1rem'}}>
                        <p style={{textAlign: 'center'}}>The user or password is incorrect</p>
                    </div>
                </div>
                }

                <div className="c-form__row">
                    <div className="c-form__group">
                        <button disabled={submitting} style={{display: 'flex'}}
                                className="btn btn--rounded btn--large btn--red">
                            <span style={{margin: 0}}>Login</span> {submitting &&
                        <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}
                        </button>
                    </div>
                </div>

                <div className="c-form__row">
                    <div className="c-form__group">
                        <Link to={RouteEnum.RESET_PASSWORD} className="btn btn--text">
                            <span>I forgot my password</span>
                        </Link>
                    </div>
                </div>

            </form>
        </div>
    )
}