import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {DashboardInterface} from "../../../interfaces/DashboardInterface";
import {dashboardToFormData} from "../../../utils/dashboardToFormData";

export const apiOrganisationDashboardCreate = async (dashboard: DashboardInterface) => {

    const formData = dashboardToFormData(dashboard);

    return await useAppAxios.post(ApiEnum.ORGANISATION_DASHBOARD + '/create', formData, {
        headers: {'content-type': 'multipart/form-data'}
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
