import {CompositionInterface, PassportInterface} from "../interfaces/PassportInterface";
import {calculateStepPercentage} from "./calculateStepPercentage";
import {ProgressInterface} from "../interfaces/ProgressInterface";

export const getPassportProgressData = (passport: PassportInterface, composition: CompositionInterface[]) => {

    let totalPercentage = 0

    let lowest = {
        step: 0,
        text: 'none',
        percentage: 100
    }

    const percentages = [
        {step: 2, text: 'Basic information', percentage: calculateStepPercentage(2, passport, composition)},
        {step: 3, text: 'Composition', percentage: calculateStepPercentage(3, passport, composition)},
        {step: 4, text: 'Impact & SDG goals', percentage: calculateStepPercentage(4, passport, composition)},
        {step: 5, text: 'Extra information', percentage: calculateStepPercentage(5, passport, composition)}
    ]

    percentages.forEach((percentageItem) => {
        totalPercentage += Math.round(percentageItem.percentage / 4)

        if (lowest.percentage > percentageItem.percentage) {
            lowest = percentageItem
        }

    })

    return {
        totalPercentage: totalPercentage,
        lowest: lowest
    } as ProgressInterface

}