import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiSubscriptionPreview = async (type:string, amount:number) => {
    return await useAppAxios.post(ApiEnum.SUBSCRIPTION + '/preview', {}, {
        params: {
            type: type,
            amount: amount
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}

