import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeCreateStep, setCreatePassportData} from "../passportCreateSlice";
import {useEffect} from "react";
import {ImpactFigureCalculationTypeInterface} from "../../../common/interfaces/ImpactFigureCalculationTypeInterface";


export const PassportCreateTypeSelect = () => {

    const {passportTypes} = useAppSelector(state => state.root.options);
    const {userMe, options} = useAppSelector(state => state.root);

    const dispatch = useAppDispatch()

    const setType = (id: number) => {

        const lcaPlusCalculationType = options.impactFigureCalculationTypes?.data?.find((type: ImpactFigureCalculationTypeInterface) => {
            return type.code === 'lca_plus' && type.enabled
        })

        dispatch(setCreatePassportData({
            typeId: id,
            passportImpactFigureCalculationTypeId: lcaPlusCalculationType?.id
        }))

        dispatch(changeCreateStep(2))
    }

    useEffect(() => {
            window.scrollTo(0, 0)

        }, []
    );

    return (
        <div className="c-app__content__container">
            <div className="c-app__create c-app__create--start">
                <header className="c-app__create__header">
                    <small>Step 1/6</small>
                    <h1>Type of C_passport<sup>®</sup></h1>
                    <p>What type of passport would you like to create?</p>
                </header>
                {passportTypes?.data &&
                <div className="c-app__create__cards" style={{flexDirection: 'row-reverse'}}>
                    {passportTypes.data.map((passportType) => {

                        let className = 'c-app__create__cards__single'
                        let imagePath = ''
                        let text = ''

                        switch (passportType.id) {
                            case(1):
                                imagePath = '/images/image-component.svg'
                                text = 'A component is part of a product which in total makes an endproduct. A component is always part of a product. An example would be a table leg.'
                                break;
                            case(2):
                                imagePath = '/images/image-product.svg'
                                text = 'A product is part of an endproduct. There can be many products that make one end product. An example of a product would be the leg base structure of a dining table.'
                                break;

                            case(3):
                                imagePath = '/images/image-endproduct.svg'
                                text = 'An end product is a completely assembled or finished set of products, often ready for sale. An example of an end product would be a completely finished dining table.'

                                break;
                        }

                        return (
                            <button className={className} onClick={() => setType(passportType.id)}>

                                <img src={imagePath} alt=""/>

                                <h3>{passportType.name}</h3>

                                <p style={{minHeight: '9rem'}}>
                                    {text}
                                </p>

                                <div className="btn btn--rounded btn--blue"><span>Create passport</span></div>

                            </button>
                        )

                    })}
                </div>
                }

                {!!userMe?.organisation?.pricingVisible &&
                <p className={'c-dislaimer'}
                   style={{fontSize: '1.3rem', marginTop: '2rem', display: 'flex', alignItems: 'center'}}><img
                    alt={'disclaimer'} src={'/images/icons/info-red.svg'} style={{marginRight: '.5rem'}}/> Adding and/or
                    deleting of C_passports® will lead to changes in your monthly costs. You can find your monthly fee
                    in your C_account.</p>
                }
            </div>
        </div>
    )

}