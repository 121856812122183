import {useAppAxios} from "../../../../app/hooks";
import {ApiEnum} from "../../../enums/ApiEnum";


export const apiOrganisationUploadVisibleForPublic = async (uploadId: number, visible: boolean) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_UPLOAD + '/visible-for-public', {}, {
        params: {
            id: uploadId,
            visible: visible
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
