import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {PassportPreferenceInterface} from "../../interfaces/PassportPreferenceInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {formatImpactNumberDecimals} from "../../utils/formatImpactNumberDecimals";
import {useAppSelector} from "../../../app/hooks";
import {getCurrentImpactFigure, getCurrentImpactFigureType} from "../../utils/PassportImpactFigures";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    passportPreference?: PassportPreferenceInterface,
    loading: boolean,
    translations?: TranslationsInterface
    activeImpactFigureTypeId?: number
    changeImpactFigureTypeId?: (impactFigureTypeId: number) => void
    isPublic: boolean
    overruleEndUseId?: number
}

export const PassportImpact = (props: Props) => {
    const {passport, passportPreference} = props
    let className = 'c-passport__tabs__content__wrapper c-passport__tabs__content__wrapper--impact'
    let impactClassName = 'c-passport__tabs__content__single c-passport__tabs__content__single--impact'
    const {impactFigureCalculationTypes} = useAppSelector(state => state.root.options);

    const currentImpactFigure = getCurrentImpactFigure(
        {
            passport: passport,
            activeImpactFigureTypeId: props.activeImpactFigureTypeId,
            impactFigureCalculationTypes: impactFigureCalculationTypes?.data,
            overruleEndUseId: props.overruleEndUseId
        }
    )

    const currentImpactFigureType = getCurrentImpactFigureType(impactFigureCalculationTypes?.data, props.activeImpactFigureTypeId)

    if (passport.typeId === 3 && passport.sroiImpactObject && (!passportPreference || passportPreference.sroiConsumption)) {
        impactClassName += ' c-passport__tabs__content__single--end-product'
    }

    const sroiImpactObject = currentImpactFigure?.sroiImpactObject ?? passport.sroiImpactObject;

    return (
        <div className={className}
             style={{flexWrap: 'wrap'}}>

            {!props.isPublic && passport.passportImpactFigureCalculationTypeId !== impactFigureCalculationTypes?.data?.find((impactFigureCalculationType) => impactFigureCalculationType.code === 'manual')?.id &&
                <div className={'c-passport__tabs__scope-selector'}>
                    <p className={'c-passport__tabs__scope-selector__title'}>Impact scope selector</p>
                    <div className={'c-passport__tabs__scope-selector__options'}>
                        {impactFigureCalculationTypes?.data?.filter(impactFigureCalculationType => impactFigureCalculationType.enabled)
                            .map((impactFigureCalculationType) => {
                                return (
                                    <div className={'c-passport__tabs__scope-selector__radio'}>
                                        <input type="radio"
                                               name="impactFigureCalculationType"
                                               onChange={() => {
                                                   props.changeImpactFigureTypeId?.(impactFigureCalculationType.id);
                                               }}
                                               checked={props.activeImpactFigureTypeId == impactFigureCalculationType.id}
                                               value={impactFigureCalculationType.id}/>
                                        <label>{impactFigureCalculationType.name}</label>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            }

            {(!!currentImpactFigure?.co2ImpactConverted && (!passportPreference || passportPreference.emission)) &&
                <div className={impactClassName}>
                    <span className={'text-color-copy'}>{props.translations?.co2Impact}</span>
                    <img src={'/images/tree.png'} alt={''}/>
                    <span
                        className={'text-color-copy'}>{formatImpactNumberDecimals(currentImpactFigure.co2ImpactConverted?.toFixed(8) + '')} {currentImpactFigure.co2ImpactConvertedType}</span>
                </div>
            }

            {(!!currentImpactFigure?.energyImpact && (!passportPreference || passportPreference.energyConsumption)) &&
                <div className={impactClassName}>
                    <span className={'text-color-copy'}>{props.translations?.energyImpact}</span>
                    <img src={'/images/thunder.png'} alt={''}/>
                    <span
                        className={'text-color-copy'}>{formatImpactNumberDecimals(parseFloat(currentImpactFigure.energyImpactConverted + '')?.toFixed(8) + '')} kWh</span>
                </div>
            }

            {(!!currentImpactFigure?.h2oImpactConverted && (!passportPreference || passportPreference.waterConsumption)) &&
                <div className={impactClassName}>
                    <span className={'text-color-copy'}>{props.translations?.h2oImpact}</span>
                    <img src={'/images/raindrops.png'} alt={''}/>
                    <span
                        className={'text-color-copy'}>{formatImpactNumberDecimals(currentImpactFigure.h2oImpactConverted?.toFixed(8) + '')} {currentImpactFigure.h2oImpactConvertedType}</span>
                </div>
            }

            {(!!currentImpactFigure?.rawMaterialImpact && (!passportPreference || passportPreference.rawMaterialConsumption)) &&
                <div className={impactClassName}>
                    <span className={'text-color-copy'}>{props.translations?.rawMaterialsSaved}</span>
                    <img src={'/images/layer.png'} alt={''}/>
                    <span
                        className={'text-color-copy'}>{formatImpactNumberDecimals(currentImpactFigure.rawMaterialImpactConverted?.toFixed(8) + '')} {currentImpactFigure.rawMaterialImpactConvertedType}</span>
                </div>
            }

            {(!!currentImpactFigure?.co2PriceConverted && (!passportPreference || passportPreference.emissionPrice)) &&
                <div className={impactClassName}>
                    <span className={'text-color-copy'}>{props.translations?.co2Price}</span>
                    <img src={'/images/co2prijs.png'} alt={''}/>
                    <span
                        className={'text-color-copy'}>{formatImpactNumberDecimals(currentImpactFigure.co2PriceConverted?.toFixed(8) + '')} EUR</span>
                </div>
            }

            {(passport.typeId === 3 && sroiImpactObject && (!passportPreference || passportPreference.sroiConsumption)) &&
                <div className={impactClassName}>
                    <span className={'text-color-copy'}>{props.translations?.sroiImpact}</span>
                    <img src={'/images/people.png'} alt={''}/>
                    <span
                        className={'text-color-copy'}>{sroiImpactObject.hours}:{sroiImpactObject.minutes} h</span>
                </div>
            }

            {passport.impactFigureDetermination &&
                <p className={'c-disclaimer-line'} style={{
                    textAlign: 'center',
                    marginTop: '1.5rem',
                    fontSize: '1rem',
                    fontStyle: 'italic',
                    minWidth: '100%',
                    padding: '0 2rem 0',
                }}
                   dangerouslySetInnerHTML={{__html: (passport.impactFigureDetermination.code === 'calculated' ? currentImpactFigureType?.disclaimer : passport.impactFigureDetermination.disclaimer) + ''}}/>
            }
        </div>
    )
}
