import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {load, loadStyle, setPassportTab} from "./dashboardPublicSlice";
import {useParams} from "react-router-dom";
import {DashboardImpact} from "../../common/components/dashboard/DashboardImpact";
import {DashboardComposition} from "../../common/components/dashboard/DashboardComposition";
import {DashboardConnectedPassports} from "../../common/components/dashboard/DashboardConnectedPassports";
import {DashboardSdgGoals} from "../../common/components/dashboard/DashboardSdgGoals";
import {connect, getTranslationList} from "../../app/rootReducer";
import {DashboardQrCode} from "../../common/components/dashboard/DashboardQrCode";
import {PublicStyle} from "../../common/components/general/PublicStyle";
import {GridLoader} from "react-spinners";
import {setSimulatedPassportAmount} from "./dashboardPublicSlice";
import {loadBySimulation, setOverruleActiveTab} from "./dashboardPublicSlice";
import {DashboardExtraInformation} from "../../common/components/dashboard/DashboardExtraInformation";
import {LanguagePicker} from "../../common/components/general/LanguagePicker";
import {getUuid} from "../dashboardDetailPage/dashboardDetailPageSlice";

interface DashboardDetailParams {
    uuid: string;
}

export const DashboardPublicPageContainer = () => {

    const dispatch = useAppDispatch()
    const {uuid} = useParams<DashboardDetailParams>();
    const [loading, setLoading] = useState(true)
    const {activeLanguage, translations} = useAppSelector((state) => state.root)

    const {
        dashboardContainer,
        connectedPassports,
        style,
        simulatedPassports,
        dashboardPreference,
        overruleActiveTab
    } = useAppSelector((state) => state.dashboardPublic)

    useEffect(() => {

        if (!isNaN(Number(uuid))) {
            dispatch(connect()).then(() => {
                dispatch(getUuid(parseFloat(uuid))).then((response) => {
                    if (response.payload) {
                        window.location.href = '/public-dashboard/' + response.payload
                    }
                })
            })
        }

        setLoading(true)

        dispatch(connect()).then(() => {
            dispatch(load(uuid)).then(() => {
                dispatch(loadStyle()).then(() => {
                    setLoading(false)
                })
            })
        })

    }, [uuid, dispatch, activeLanguage])

    useEffect(() => {
        if (simulatedPassports.active) {
            const timeOutId = setTimeout(() => dispatch(loadBySimulation(uuid)), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [simulatedPassports, dispatch, uuid])

    useEffect(() => {
        dispatch(connect()).then(() => {
            dispatch(getTranslationList(activeLanguage))
        })
    }, [activeLanguage])

    if (!dashboardContainer?.dashboard.isPublic) {
        return null;
    }


    if (!loading && dashboardContainer) {
        const {dashboard} = dashboardContainer
        return (
            <main className="c-app__content background-color-passport">

                <LanguagePicker/>

                <PublicStyle style={style}/>

                <header className="c-app__content__header c-app__content__header--public"
                        style={{justifyContent: 'space-between'}}>

                    {dashboardPreference?.logoVisible &&
                        <div className={'c-cirmar__logo'}>
                            <img src="/images/CIRMAR-logo.svg" style={{height: '5rem'}} alt={''}/>
                        </div>
                    }

                    <div className="c-app__content__header__name">
                        {dashboard?.organisation?.logo && <img src={dashboard.organisation.logo} alt=""/>}
                        <figure/>
                        <h1 className="text-color-highlight">{dashboard?.organisation?.companyName}</h1></div>
                </header>


                <div className="c-app__content__container">
                    <div className="c-dashboard">

                        <div className="c-dashboard__row c-dashboard__primary">
                            <div className="c-dashboard__group c-dashboard__primary__info">
                                <div className="c-dashboard__card background-color-blocks c-passport__info">
                                    <div className="c-passport__info__intro">

                                        {dashboard.image &&
                                            <div className="c-passport__info__intro__image">
                                                <img src={dashboard.image} alt={''}/>
                                            </div>
                                        }

                                        <div className="c-passport__info__intro__text">
                                            <div>
                                                {dashboard.title &&
                                                    <h1 className="text-color-highlight">{dashboard.title}</h1>}
                                                <div className="c-tag c-tag--black">{translations?.collection}</div>
                                            </div>
                                            {dashboardPreference?.introductionVisible &&
                                                <p className="text-color-copy">
                                                    {dashboard.introduction}
                                                </p>
                                            }
                                        </div>

                                    </div>
                                </div>
                                <div className="c-dashboard__card background-color-blocks c-passport__progress">
                                    <div
                                        className="c-passport__progress__indication c-passport__progress__indication--public">
                                        <h4 className="text-color-highlight">{translations?.interestedInYourOwn} C_dashboard<sup>®</sup>?
                                        </h4>
                                        <p className="text-color-copy">{translations?.goTo} <a
                                            href="https://www.cirmar.com"
                                            className="text-color-highlight">cirmar.com</a> {translations?.andLearnAllAbout} dashboards.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {dashboardPreference?.qrVisible &&
                                <DashboardQrCode translations={translations} dashboard={dashboard}/>
                            }
                        </div>

                        {dashboardPreference?.impactVisible &&
                            <DashboardImpact

                                translations={translations}
                                dashboardContainer={dashboardContainer}
                                changeActiveTab={(activeTab) => {
                                    dispatch(setOverruleActiveTab(activeTab))
                                }}
                                activeTab={overruleActiveTab}
                            />
                        }

                        {!!dashboardContainer.dashboard.extraInformation &&
                            <DashboardExtraInformation translations={translations}
                                                       extraInformation={dashboardContainer.dashboard.extraInformation}/>
                        }

                        {dashboardPreference?.totalCompositionVisible &&
                            <DashboardComposition translations={translations} dashboardPreference={dashboardPreference}
                                                  dashboardContainer={dashboardContainer}/>
                        }

                        {dashboardPreference?.passportCompositionVisible &&
                            <DashboardConnectedPassports
                                translations={translations}
                                setSimulatedPassportAmount={setSimulatedPassportAmount}
                                simulatedPassportAmounts={simulatedPassports.amounts}
                                dashboardContainer={dashboardContainer}
                                connectedPassports={connectedPassports}
                                dashboardPreference={dashboardPreference}
                                setPassportTab={setPassportTab}
                            />
                        }

                        <DashboardSdgGoals translations={translations} sdgGoals={dashboardContainer.sdgGoals}/>


                    </div>
                </div>
            </main>
        )
    } else return (
        <div className={'c-loader'}>
            <GridLoader color={'#E14343'}/>
        </div>
    )

}
