import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationInfoUploadLogo = async (logo: File) => {
    const formData = new FormData();
    formData.append('logo', logo)

    return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/upload-logo', formData, {
        headers: {'content-type': 'multipart/form-data'}
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
