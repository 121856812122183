import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationAddressList = async () => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_ADDRESS + '/list', {}, {
        params: {
            limit: 9999
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
