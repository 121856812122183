import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    website: string,
    translations?: TranslationsInterface
}

export const PassportWebsite = (props: Props) => {

    const {translations} = props

    return (
        <div className="c-dashboard__card background-color-blocks c-passport__public"
             style={{marginBottom: '1rem', fontWeight: 100}}>
            <a href={props.website} target="_blank" rel="noreferrer"
               className="btn btn--small btn--rounded btn--red">
                <span>{translations?.visitProductWebsite}</span>
            </a>

            <a href={props.website} className="btn btn--text">
                <span style={{whiteSpace: 'pre-wrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '20rem'}}
                      className="text-color-copy">{props.website}
                </span>
            </a>
        </div>

    )

}