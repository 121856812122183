import {Link} from "react-router-dom";
import {ClipLoader} from "react-spinners";
import {useAppSelector} from "../../../app/hooks";
import {RouteEnum} from "../../../common/enums/RouteEnum";

export const PassportSettingsHeader = () => {

    const {publicPassport} = useAppSelector((state) => state.passportSettings)

    return (
        <header className="c-app__content__header">
            <nav className="c-breadcrumbs">
                <ul className="u-reset-ul">
                    <li>
                        <Link to={RouteEnum.PASSPORT_OVERVIEW}>C_passport<sup>®</sup></Link>
                        <img src="/images/icons/arrow-right-black.svg" alt=""/>
                    </li>
                    <li>
                        <Link to={RouteEnum.PASSPORT_SETTINGS}>Settings</Link>
                        <img src="/images/icons/arrow-right-black.svg" alt=""/>
                    </li>
                </ul>
            </nav>
            <div>
                <button disabled={publicPassport.submitting} type={'submit'} style={{marginLeft: '1rem'}}
                        className="btn btn--small btn--rounded btn--black">

                    {!publicPassport.submitted ?
                        <span style={{display: 'flex', alignItems: 'center'}}>Save {publicPassport.submitting &&
                        <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}</span>
                        :
                        <span style={{display: 'flex', alignItems: 'center'}}>Saved {publicPassport.submitting &&
                        <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}</span>
                    }
                </button>
            </div>
        </header>

    )

}