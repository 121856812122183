import {stringToColour} from "../../utils/StringToColor";
import {CompositionInterface, MaterialInterface} from "../../interfaces/PassportInterface";
import {MaterialGroupInterface} from "../../interfaces/MaterialGroupInterface";
import {formatCompositionPercentageDecimals} from "../../utils/formatCompositionPercentageDecimals";
import {sortCompositions} from "../../utils/sortComposition";

interface Props {
    composition: CompositionInterface[],
    materialGroup: MaterialGroupInterface[],
    materials: MaterialInterface[],
    hideMaterials?: boolean,
}

export const CompositionLegenda = (props: Props) => {

    if (props.composition && props.composition.length > 0) {


        // let compositions = props.composition
        let compositions = sortCompositions(props.composition)

        return (
            <ul className={'legenda'} style={{listStyleType: 'none'}}>

                {compositions.map((composition) => {

                    const materialGroupItem = props.materialGroup?.find((materialGroup) => materialGroup.id === composition.materialGroupId)

                    let materials = composition.materials

                    if (composition.materials && composition.materials.length > 1) {
                        materials = [...composition.materials]
                        materials.sort((a: { compositionPercentage: number; }, b: {
                            compositionPercentage: number;
                        }) => {
                            if (a.compositionPercentage > b.compositionPercentage) {
                                return -1;
                            }
                            if (a.compositionPercentage < b.compositionPercentage) {
                                return 1;
                            }
                            return 0;
                        });
                    }

                    if (materialGroupItem) {

                        const compositionColor = stringToColour(materialGroupItem.name)

                        return (<li style={{margin: '1rem', display: 'flex', flexWrap: 'wrap'}}>
                            <span className={'color-circle'} style={{
                                minWidth: '2rem',
                                background: compositionColor,
                                borderRadius: '50%',
                                width: '2rem',
                                height: '2rem',
                                display: 'inline-block',
                                position: 'relative',
                                top: '.3rem',
                                margin: '0 .5rem 0 0'
                            }}/>
                                <span style={{
                                    padding: 0,
                                    margin: 0
                                }}>{materialGroupItem.name} {formatCompositionPercentageDecimals(composition.compositionPercentage + '')}</span>

                                {!!materials?.length && !props.hideMaterials &&
                                    <ul style={{
                                        listStyleType: 'none',
                                        minWidth: '100%',
                                        margin: '.4rem 0 0 0',
                                        wordBreak: 'break-word'
                                    }}>
                                        {materials.map((material) => {
                                            const materialItem = props.materials?.find((materialItem) => materialItem.id === material.materialId)

                                            if (materialItem) {
                                                const materialColor = stringToColour(materialItem.name)

                                                return (
                                                    <li style={{display: 'flex', marginBottom: '.5rem'}}>
                                                <span className={'color-circle'} style={{
                                                    minWidth: '2rem',
                                                    background: materialColor,
                                                    borderRadius: '50%',
                                                    width: '2rem',
                                                    height: '2rem',
                                                    display: 'inline-block',
                                                    position: 'relative',
                                                    top: '.3rem',
                                                    margin: '0 .5rem 0 0'
                                                }}/>
                                                        <span style={{padding: 0}}>
                                                    {materialItem.name} {formatCompositionPercentageDecimals(material.compositionPercentage + '')}
                                                </span>
                                                    </li>
                                                )
                                            } else return null

                                        })}

                                    </ul>
                                }

                            </li>
                        )
                    } else return null
                })}

            </ul>
        )
    } else {
        return null
    }

}
