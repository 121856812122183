import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationGroupDetail = async (id:string) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_GROUP + '/detail', {}, {
        params: {
            id: id,
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
