interface InputProps {
    label?: string,
    name?: string,
    required?: boolean,
    onChange: (value: boolean) => void;
    placeholder?: string,
    value?: boolean,
    extraClass?: string,
    loading?: boolean,
}

const FormCheckbox = (props: InputProps) => {

    return (
        <div className={'c-form__group c-form__group--checkbox' + props.extraClass} style={{position: 'relative'}}>

            <label htmlFor="">

                <input
                    value={props.value + ''}
                    checked={props.value}
                    type={'checkbox'}
                    disabled={props.loading}
                    name={props.name}
                    required={props.required}
                    onChange={(e) => {
                        props.onChange(e.target.checked)
                    }}
                    placeholder={props.placeholder}
                />

                <span>{props.label} </span>

            </label>

        </div>

    )

}

export default FormCheckbox