import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiOrganisationInfoPassportPreference} from "../../common/api/organisation/info/apiOrganisationInfoPassportPreference";
import {PassportPreferenceInterface} from "../../common/interfaces/PassportPreferenceInterface";
import {apiOrganisationUpdatePassportPreference} from "../../common/api/organisation/info/apiOrganisationUpdatePassportPreference";

interface PassportSettingsStateInterface {
    loading: boolean,
    publicPassport: {
        submitting: boolean,
        submitted: boolean,
    }
    styling: {
        submitting: boolean,
        submitted: boolean,
    }
    passportPreference: PassportPreferenceInterface,
    activeView: 'public' | 'styling'
}

const initialState: PassportSettingsStateInterface = {
    publicPassport: {
        submitting: false,
        submitted: false,
    },
    styling: {
        submitting: false,
        submitted: false,
    },
    loading: false,
    passportPreference: {},
    activeView: 'public'
}


export const load = createAsyncThunk(
    'passportSettings/load',
    async () => {
        const response = await apiOrganisationInfoPassportPreference()
        return response.data.items.passportPreference
    }
)

export const submitPublic = createAsyncThunk(
    'passportSettings/submitPublic',
    async (_, {getState}) => {
        const {passportSettings} = getState() as { passportSettings: PassportSettingsStateInterface };
        await apiOrganisationUpdatePassportPreference(passportSettings.passportPreference)
    }
)


const passportSettings = createSlice({
    name: 'passportSettings',
    initialState,
    reducers: {
        setPassportSettingsPublicData: (state, action: PayloadAction<PassportPreferenceInterface>) => {
            state.publicPassport.submitted = false
            state.styling.submitted = false
            state.passportPreference = {
                ...state.passportPreference,
                ...action.payload
            }
        },
        setView: (state, action: PayloadAction<'public' | 'styling'>) => {
            state.activeView = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(load.pending, (state) => {
            state.loading = true
        })
        builder.addCase(load.fulfilled, (state, action: PayloadAction<PassportPreferenceInterface>) => {
            state.loading = false
            state.passportPreference = action.payload
        })
        builder.addCase(submitPublic.pending, (state) => {
            state.publicPassport.submitting = true
        })
        builder.addCase(submitPublic.fulfilled, (state) => {
            state.publicPassport.submitting = false
            state.publicPassport.submitted = true

        })

    }
})

export const {
    setView,
    setPassportSettingsPublicData,
} = passportSettings.actions
export const passportSettingsReducer = passportSettings.reducer