import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {ClipLoader} from "react-spinners";
import {submit} from "../upgradeAccountSlice";

export const UpgradeStep3 = () => {

  const {organisation, addresses, submitting, submitStatus} = useAppSelector(state => state.upgradeAccount);
  const {countries, addressTypes} = useAppSelector(state => state.root.options);
  let addressCount = 0;

  const dispatch = useAppDispatch()

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        dispatch(submit())
      }}
      className="c-form">
      <div className="c-signup__confirm" style={{width: '100%',  marginTop: 0}}>
        <div className="c-signup__details">
          <div className="c-signup__details__row" style={{marginBottom: 0}}>
            <div className="c-signup__details__single">
              <h2>Account details</h2>
              <span>{organisation.companyName}</span>
              <span>{organisation.cocNumber}</span>
              <span>{organisation.vatNumber}</span>
              <span>{organisation.invoiceEmail}</span>
              <span>{organisation.phoneNumber}</span>
            </div>

            {addresses.map((address) => {

              addressCount++
              const country = countries && countries.data?.find((country) => country.id === address.countryId);
              const addressType = addressTypes?.data?.find((addressType) => addressType.id === address.typeId);

              return (
                <div className="c-signup__details__single" key={'address: ' + address.id}>
                  <h2>Address {addressCount} - {addressType && <>{addressType.name}</>}</h2>
                  <span>{address.street} {address.number} {address.numberAddition}</span>
                  <span>{address.postalCode} {address.city}</span>
                  <span>{address.state} {country && <>{country.nameEng}</>}</span>
                </div>
              )
            })}

          </div>
          <div className="c-signup__details__row">
            <div className="c-signup__details__single">
              <h2>Contactperson</h2>
              <span>{organisation.contactPersonName}</span>
              <span>{organisation.contactPersonEmail}</span>
            </div>
          </div>
        </div>

        <div className="c-form">
          <div className="c-form__row c-form__row--radio">
            <div className="c-form__group">
              <label htmlFor="">
                <input type="checkbox" required/>
                <span>I agree to be billed monthly and I accept the <a
                  href={'https://www.cirmar.com/general-terms-privacy-statements/'}
                  style={{color: '#E14343'}} rel="noreferrer"
                  target={'_blank'}>General Terms</a> & <a
                  href={'https://www.cirmar.com/general-terms-privacy-statements/'}
                  style={{color: '#E14343'}} rel="noreferrer"
                  target={'_blank'}>Privacy Statement</a>.</span>
              </label>
            </div>
          </div>
        </div>

        <button disabled={submitting} className="btn btn--large btn--rounded btn--black">
          <span style={{margin: 0}}>Upgrade account</span>{submitting &&
        <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}
        </button>

        {(submitStatus === 401 || submitStatus === 404 || submitStatus === 400) &&
        <div className={'c-form__row'}>
            <div className={'c-notification c-notification--red'} style={{marginTop: '1rem'}}>
                <p style={{textAlign: 'center'}}>There has been een error. Please contact us at: </p>
            </div>
        </div>
        }

      </div>
    </form>
  )

}