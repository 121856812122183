import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationUserVerify = async (token: string, password: string) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_USER + '/verify', {
        password: password
    }, {
        params: {
            token: token
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
