import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props{
    translations?: TranslationsInterface
}

export const PassportPoweredBy = (props:Props) => {

    return (
        <footer className="c-dashboard__row c-passport__footer"><span>{props.translations?.poweredBy}</span>
            <a href="https://www.cirmar.com/" target={'_blank'} rel="noreferrer">
                <img src="/images/CIRMAR-logo.svg" alt=""/>
            </a>
        </footer>
    )

}