import {PassportCreateWrapper} from "./components/PassportCreateWrapper";
import {PassportCreateTypeSelect} from "./steps/PassportCreateTypeSelect";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {PassportCreateBasicInformationForm} from "./steps/PassportCreateBasicInformationForm";
import {PassportCreateMaterials} from "./steps/PassportCreateMaterials";
import {PassportCreateImpact} from "./steps/PassportCreateImpact";
import {PassportCreateExtraInformation} from "./steps/PassportCreateExtraInformation";
import {PassportCreateConnect} from "./steps/PassportCreateConnect";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {changeCreateStep, groupList, load, passportList, reset} from "./passportCreateSlice";
import {GridLoader} from "react-spinners";

interface PassportParams {
  uuid: string;
  step: string
}

const PassportCreatePageContainer = () => {

  const [loading, setLoading] = useState(true)
  const {activeStep} = useAppSelector(state => state.passportCreate);

  const dispatch = useAppDispatch()
  let {uuid, step} = useParams<PassportParams>();


  useEffect(() => {

      dispatch(reset())

      if (uuid) {
        dispatch(load(uuid)).then(() => {
          if (step) {
            dispatch(changeCreateStep(Number(step)))
          }
          setLoading(false)
        })
      } else {
        setLoading(false)
      }

    }, [uuid, dispatch, step]
  );

  useEffect(() => {
      dispatch(passportList())
      dispatch(groupList())

    }, [dispatch]
  );


  return (
    <>
      {!loading ?
        <PassportCreateWrapper>
          <>
            {activeStep === 1 && <PassportCreateTypeSelect/>}
            {activeStep === 2 && <PassportCreateBasicInformationForm/>}
            {activeStep === 3 && <PassportCreateMaterials/>}
            {activeStep === 4 && <PassportCreateImpact/>}
            {activeStep === 5 && <PassportCreateExtraInformation/>}
            {activeStep === 6 && <PassportCreateConnect/>}
          </>
        </PassportCreateWrapper>
        :
        <div className={'c-loader'}>
          <GridLoader color={'#E14343'}/>
        </div>

      }</>
  )

}

export default PassportCreatePageContainer
