interface Props {
    organisation: any,
}

export const UpgradeResult = (props: Props) => {

  const contactPersonEmail = props.organisation.contactPersonEmail

  return (
    <div className="c-app__info-card" style={{margin: 0}}>
      <div className="c-app__info-card__intro" style={{margin: 0}}>
        <h2>Upgrade completed!</h2>
        <p />
        <p>Thank you for upgrading! A confirmation has been sent to: {contactPersonEmail}</p>
      </div>
    </div>
  )

}

