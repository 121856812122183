import {useAppSelector} from "../../../app/hooks";
import {useState} from "react";

interface Props {
    code: string
}

export const HowToCloud = (props: Props) => {

    const [showModal, setShowModal] = useState(false);
    const {howTo} = useAppSelector((state) => state.root)
    const howToItem = howTo?.find((howToItem) => howToItem.codeName === props.code)

    if (howToItem) {
        return (
            <>

                <button type={'button'} onClick={() => setShowModal(true)} className="btn--info">
                    <img src="/images/icons/info-red.svg" alt=""/>
                </button>

                {showModal &&
                <div className="c-info-popup popupActive">
                    <div className="c-info-popup__bg"/>
                    <button className="c-info-popup__close-btn c-info-popup__close" type={'button'} onClick={() => {
                        setShowModal(false)
                    }}>
                        <img src="/images/icons/cross-black.svg" alt=""/>
                    </button>
                    <div className="c-info-popup__content">

                        <h1>{howToItem.title}</h1>

                        <div dangerouslySetInnerHTML={{__html: howToItem.content}}/>

                        <footer className="c-info-popup__content__footer">
                            <button type={'button'} className="btn btn--rounded btn--black c-info-popup__close"
                                    onClick={() => {
                                        setShowModal(false)
                                    }}>
                                <span>I understand</span>
                            </button>
                            <a href="https://www.cirmar.com/contact/" rel={'noreferrer'} target={'_blank'} className="btn btn--text"
                               style={{marginLeft: '1rem'}}>Help me out</a>
                        </footer>
                    </div>
                </div>
                }

            </>
        )
    } else return null

}