import {MaterialInterface} from "../../../common/interfaces/PassportInterface";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import FormInput from "../../../common/components/form/FormInput";
import {setCompositionMaterialData} from "../passportCreateSlice";
import CreatableSelect from "react-select/creatable";
import {MaterialSetInterface} from "../../../common/interfaces/MaterialSetInterface";
import {HowToCloud} from "../../../common/components/howTo/HowToCloud";

interface Props {
    materialGroupId: number,
    material: MaterialInterface
}

export const PassportCreateMaterialItem = (props: Props) => {

    const {materials} = useAppSelector(state => state.root.options);
    const activeMaterial = materials?.data?.find((material) => material.id === props.material.materialId)
    const dispatch = useAppDispatch()

    const {materialSets} = useAppSelector(state => state.root.options);

    const options = materialSets?.filter((materialSet) => (materialSet.casNumber && materialSet.materialId === props.material.materialId)).map((materialSet) => {
        return {
            value: materialSet.casNumber + '',
            label: materialSet.casNumber + ''
        }
    });


    if (activeMaterial) {

        return (
            <div className="c-tab__content__secondary">
                <header>
                    <h3>{activeMaterial.name}</h3>
                    <FormInput
                        howToCode={'pp-comp-percentage-of-total-composition'}
                        onChange={(value) => {
                            dispatch(setCompositionMaterialData({
                                materialGroupId: props.materialGroupId,
                                material: {
                                    materialId: props.material.materialId,
                                    compositionPercentage: value + ''
                                }
                            }))
                        }}
                        label={'Percentage of total composition'}
                        extraClass={'c-tab__percentage'}
                        type={'number'}
                        value={props.material.compositionPercentage}
                        smallHtml={'%'}
                    />
                </header>

                {props.material.percentageWarning &&
                <div className="c-notification c-notification--red" style={{margin: '2rem 0'}}>
                    <p>Total percentage cannot be higher than 100%</p>
                </div>
                }

                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">CAS number <HowToCloud code={'pp-comp-case'} /></label>
                        <CreatableSelect
                            isClearable
                            onKeyDown={(e) => {
                                if (e.nativeEvent.code.includes('Key')) {
                                    e.preventDefault()
                                }
                            }}
                            options={options}
                            value={{
                                label: props.material.organisationMaterials?.casNumber,
                                value: props.material.organisationMaterials?.casNumber
                            }}
                            onChange={(selected) => {

                                if (selected) {

                                    const materialSet = materialSets?.find((material: MaterialSetInterface) => material.casNumber === selected.value)

                                    const material: MaterialInterface = {
                                        materialId: props.material.materialId,
                                        organisationMaterials: {
                                            casNumber: selected.value + ''
                                        }

                                    }

                                    if (materialSet && material.organisationMaterials) {
                                        material.organisationMaterials.alternateDescription = materialSet.alternateDescription
                                        material.organisationMaterials.abbreviation = materialSet.abbreviation
                                    }

                                    dispatch(
                                        setCompositionMaterialData({
                                            materialGroupId: props.materialGroupId,
                                            material: material
                                        })
                                    )
                                }
                            }}
                        />
                    </div>
                </div>

                {props.material.organisationMaterials?.casNumber && <>

                    <div className="c-form__row">
                        <FormInput
                            howToCode={'pp-comp-abbreviation'}
                            onChange={(value) => {
                                dispatch(setCompositionMaterialData({
                                    materialGroupId: props.materialGroupId,
                                    material: {
                                        materialId: props.material.materialId,
                                        organisationMaterials: {
                                            ...props.material.organisationMaterials,
                                            abbreviation: value + ''
                                        }
                                    }
                                }))
                            }}
                            label={'Abbreviation'}
                            type={'text'}
                            value={props.material.organisationMaterials.abbreviation}
                        />
                    </div>
                    <div className="c-form__row">
                        <FormInput
                            howToCode={'pp-comp-alternate-description'}
                            onChange={(value) => {
                                dispatch(setCompositionMaterialData({
                                    materialGroupId: props.materialGroupId,
                                    material: {
                                        materialId: props.material.materialId,
                                        organisationMaterials: {
                                            ...props.material.organisationMaterials,
                                            alternateDescription: value + ''
                                        }
                                    }
                                }))
                            }}
                            label={'Alternate description or name'}
                            type={'text'}
                            value={props.material.organisationMaterials.alternateDescription}
                        />
                    </div>

                </>}

            </div>
        )
    } else return null

}