import {AccountWrapper} from "../../wrappers/AccountWrapper/AccountWrapper";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {load} from "./accountBillingSlice";
import {GridLoader} from "react-spinners";
import formatPrice from "../../common/utils/formatPrice";
import {FreeTrialBanner} from "../../common/components/general/FreeTrialBanner";
const AccountBillingPageContainer = () => {

    const dispatch = useAppDispatch()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
            setLoading(true)
            dispatch(load()).then(() => {
                setLoading(false)
            })
        }, [dispatch]
    )

    const {data, invoiceEmail} = useAppSelector(state => state.billing);

    return (


        <AccountWrapper>

            {!loading ? <>

                    <div className="c-app__info-card">
                        <div className="c-app__info-card__intro">
                            <h1>Plan & billing</h1>
                            <p>
                                Your monthly invoice is dependant on the amount of passports and extra users you create.
                            </p>
                        </div>
                        <div className="c-signup__info">

                            {(data.subscriptions && Object.values(data.subscriptions).map((subscription) => {
                                if (subscription && subscription.price) {
                                    return (
                                        <div key={'subscription:' + subscription.id} className="c-signup__info__single">
                                            <div>
                                                <h2>{subscription.name} ({subscription.total})</h2>
                                                <small>{formatPrice(subscription.priceSingle)} per {subscription.name}</small>
                                            </div>
                                            <span>{formatPrice(subscription.price)}</span>
                                        </div>
                                    )
                                } else return null

                            }))}

                            <div className="c-signup__info__single c-signup__info__single--line">
                                <div>
                                    <h2>Price per month</h2>
                                    <small>Price is dependant on amount of C_passports® and extra users</small>
                                </div>
                                <span>{formatPrice(data.totalPrice)}</span>
                            </div>

                        </div>
                    </div>
                    <div className="c-app__info-card">
                        <div className="c-app__info-card__intro" style={{margin: 0}}>
                            <h2>Invoices</h2>
                            <p style={{margin: 0}}>
                                Invoices will be sent monthly to:
                            </p>
                            <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>
                                <p style={{margin: '0 .5rem 0 0'}}>
                                    <strong>{invoiceEmail}</strong>
                                </p>
                            </div>
                        </div>
                    </div>

                </> :
                <div className={'c-loader'}>
                    <GridLoader color={'#E14343'}/>
                </div>
            }

          <FreeTrialBanner text={'Enjoying Cirmar and want to create more passports and dashboards? Complete your Cirmar account today!'} />

        </AccountWrapper>


    )

}

export default AccountBillingPageContainer