import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setOrganisationData, setUserData, submit} from "../signupSlice";
import {ClipLoader} from "react-spinners";
import ReCAPTCHA from "react-google-recaptcha";
import React, {useState} from "react";

const recaptchaRef = React.createRef() as any;

export const SignupStep = () => {

  const {user, organisation, submitting, submitStatus} = useAppSelector(state => state.signup);
  const dispatch = useAppDispatch()

  const [captchaError, setCaptchaError] = useState(false)

  const formatErrorCodeText = () => {

    switch (submitStatus) {
      case(400):
        return 'The email: ' + user.email + ' has already been taken';
      default:
        return 'There has been een error.'
    }

  }

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          const value = recaptchaRef.current.getValue()
          if (value) {
            setCaptchaError(false)
            dispatch(submit(value))
          } else {
            setCaptchaError(true)
          }
        }}
        className="c-form">
        <div className="c-form__row">
          <div className="c-form__group">
            <label htmlFor="">Email<span>*</span></label>
            <input required={true}
                   value={user.email}
                   onChange={(e) => dispatch(setUserData({email: e.target.value}))}
                   type="email"
            />
          </div>
        </div>
        <div className="c-form__row c-form__row--name">
          <div className="c-form__group">
            <label htmlFor="">First name<span>*</span></label>
            <input required={true}
                   value={user.firstName}
                   onChange={(e) => dispatch(setUserData({firstName: e.target.value}))}
                   type="text"
            />
          </div>
          <div className="c-form__group">
            <label htmlFor="">Insertion</label>
            <input
              value={user.insertion}
              onChange={(e) => dispatch(setUserData({insertion: e.target.value}))}
              type="text"
            />
          </div>
          <div className="c-form__group">
            <label htmlFor="">Last name<span>*</span></label>
            <input
              required={true}
              value={user.lastName}
              onChange={(e) => dispatch(setUserData({lastName: e.target.value}))}
              type="text"
            />
          </div>
        </div>
        <div className="c-form__row">
          <div className="c-form__group">
            <label htmlFor="">Company name<span>*</span></label>
            <input
              required={true}
              value={organisation.companyName}
              onChange={(e) => dispatch(setOrganisationData({companyName: e.target.value}))}
              type="text"
            />
          </div>
        </div>
        <div className="c-form__row">
          <div className="c-form__group">
            <label htmlFor="">Phone</label>
            <input
              value={organisation.phoneNumber}
              onChange={(e) => dispatch(setOrganisationData({phoneNumber: e.target.value}))}
              type="text"/>
          </div>
        </div>

        {(submitStatus === 401 || submitStatus === 404 || submitStatus === 400) &&
        <div className={'c-form__row'}>
            <div className={'c-notification c-notification--red'} style={{marginTop: '1rem'}}>
                <p style={{textAlign: 'center'}}>There has been een error. Please contact us at:
                    <a href={'mailto:info@cirmar.com'}> info@cirmar.com</a>
                </p>
            </div>
        </div>
        }


        <div className="c-signup__confirm" style={{width: '100%'}}>

          <div className="c-form">
            <div className="c-form__row c-form__row--radio">
              <div className="c-form__group">
                <label htmlFor="">
                  <input type="checkbox" required/>
                  <span>I accept the <a
                    href={'https://www.cirmar.com/general-terms-privacy-statements/'}
                    style={{color: '#E14343'}} rel="noreferrer"
                    target={'_blank'}>General Terms</a> & <a
                    href={'https://www.cirmar.com/general-terms-privacy-statements/'}
                    style={{color: '#E14343'}} rel="noreferrer"
                    target={'_blank'}>Privacy Statement</a>.</span>
                </label>
              </div>
            </div>
          </div>

          <div style={{margin: '1rem 0 0'}}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lf6NB0qAAAAAFkW54ID4WZ1m5jQZYKVwvq2H0AT"
            />
          </div>

          <button disabled={submitting} className="btn btn--large btn--rounded btn--black">
            <span style={{margin: 0}}>Create account</span>{submitting &&
          <div style={{marginLeft: '1rem'}}><ClipLoader size={12} color={'#ffffff'}/></div>}
          </button>

          {!!captchaError &&
          <div className={'c-form__row'}>
              <div className={'c-notification c-notification--red'} style={{marginTop: '1rem'}}>
                  <p style={{textAlign: 'center'}}>Please complete the captcha-test</p>
              </div>
          </div>
          }

          {(submitStatus === 401 || submitStatus === 404 || submitStatus === 400) &&
          <div className={'c-form__row'}>
              <div className={'c-notification c-notification--red'} style={{marginTop: '1rem'}}>
                  <p style={{textAlign: 'center'}}>{formatErrorCodeText()}</p>
              </div>
          </div>
          }

        </div>

      </form>


    </>
  )

}
