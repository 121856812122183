import {setParentAssemblyData} from "../passportCreateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

interface Props {
    typeId: number,
}

export const PassportParentAssemblyForm = (props: Props) => {

    const dispatch = useAppDispatch()
    const {parentAssemblies} = useAppSelector(state => state.passportCreate);
    const {options} = useAppSelector(state => state.root);
    const {passportTypes} = options;
    const passportType = passportTypes?.data?.find((passportType) => passportType.id === props.typeId)

    const {passports} = useAppSelector(state => state.passportCreate);
    const activePassports = passports?.data.filter((passport) => parentAssemblies?.find((parentAssembly) => parentAssembly.passportId === passport.id && passport.typeId === props.typeId))

    if (activePassports?.length) {
        return (
            <>

                <h3 style={{marginTop: '3rem'}}>{passportType?.name}</h3>

                <form action="" className="c-form c-form--black">
                    {activePassports?.map((passport) => {

                        const parentAssembly = parentAssemblies.find((parentAssembly) => parentAssembly.passportId === passport.id)
                        const passportType = passportTypes?.data?.find((passportType) => passportType.id === passport.typeId)


                        if (parentAssembly && passportType) {
                            return (
                                <>
                                    <div className="c-form__row c-form__row--block-small">
                                        <div className="c-form__group">
                                        </div>
                                        <div className="c-form__group c-form__group--horizontal"
                                             style={{justifyContent: 'space-between'}}>
                                            <div>
                                                <h5 style={{fontSize: '1.8rem'}}>{passport.name} ({passportType.name})</h5>
                                                <label htmlFor="">Number of this product used in {passport.name}</label>
                                            </div>
                                            <input
                                                onChange={(e) => {
                                                    let validate = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
                                                    if (validate) {
                                                        dispatch(setParentAssemblyData({
                                                            passportId: parentAssembly.passportId,
                                                            amount: Number(e.target.value)
                                                        }))
                                                    }
                                                }}
                                                type="number"
                                                step={'0.001'}
                                                value={parentAssembly.amount}
                                            />
                                        </div>
                                    </div>

                                </>
                            )
                        } else return null
                    })}

                </form>
            </>
        )
    } else return null

}