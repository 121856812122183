import {removeAddress, setAddressData} from "../upgradeAccountSlice";
import {AddressInterface} from "../../../common/interfaces/AddressInterface";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

export const UpgradeAddress = (address: AddressInterface) => {

    const dispatch = useAppDispatch()
    const {countries, addressTypes} = useAppSelector(state => state.root.options);

    return (
        <div className={'c-address'} key={'address-' + address.createId}>
            {addressTypes &&
            <div className="c-form__row c-form__row--radio">
                {addressTypes.data?.map((addressType) => {
                    return (
                        <div key={'address-type-' + addressType.id} className="c-form__group">
                            <label htmlFor="">
                                <input
                                    disabled={address.createId === 'default'}
                                    checked={address.typeId === addressType.id}
                                    value={addressType.id}
                                    type="radio"
                                    name={'address-type[' + address.createId + ']'}
                                    onChange={(e) => dispatch(setAddressData({
                                        ...address,
                                        typeId: parseInt(e.target.value)
                                    }))}
                                />
                                <span>{addressType.name}</span>
                            </label>
                        </div>
                    )
                })}
            </div>
            }
            <div className="c-form__row c-form__row--address">
                <div className="c-form__group">
                    <label htmlFor="">Street<span>*</span></label>
                    <input type="text"
                           required={true}
                           onChange={(e) => dispatch(setAddressData({
                               ...address,
                               street: e.target.value
                           }))}
                           value={address.street}
                    />
                </div>
                <div className="c-form__group">
                    <label htmlFor="">Number<span>*</span></label>
                    <input type="number"
                           required={true}
                           onChange={(e) => dispatch(setAddressData({
                               ...address,
                               number: parseInt(e.target.value)
                           }))}
                           value={address.number}
                    />
                </div>
                <div className="c-form__group">
                    <label htmlFor="">Addition</label>
                    <input type="text"
                           onChange={(e) => dispatch(setAddressData({
                               ...address,
                               numberAddition: e.target.value
                           }))}
                           value={address.numberAddition}
                    />
                </div>
            </div>
            <div className="c-form__row c-form__row--half">

                <div className="c-form__group">
                    <label htmlFor="">Postal code<span>*</span></label>
                    <input type="text"
                           required={true}
                           onChange={(e) => dispatch(setAddressData({
                               ...address,
                               postalCode: e.target.value
                           }))}
                           value={address.postalCode}
                    />
                </div>

                <div className="c-form__group">

                    <label htmlFor="">City<span>*</span></label>

                    <input type="text"
                           required={true}
                           onChange={(e) => dispatch(setAddressData({
                               ...address,
                               city: e.target.value
                           }))}
                           value={address.city}
                    />

                </div>
            </div>
            <div className="c-form__row c-form__row--half">
                <div className="c-form__group">
                    <label htmlFor="">State<span>*</span></label>
                    <input type="text"
                           required={true}
                           onChange={(e) => dispatch(setAddressData({
                               ...address,
                               state: e.target.value
                           }))}
                           value={address.state}
                    />
                </div>
                {countries &&
                <div className="c-form__group">
                    <label htmlFor="">Country<span>*</span></label>

                    <select
                        value={address.countryId}
                        onChange={(e) =>
                            dispatch(setAddressData({
                                ...address,
                                countryId: parseInt(e.target.value)
                            }))
                        }
                    >
                        {countries.data?.map((country) => {
                            return (<option key={'country-' + country.id} value={country.id}>{country.nameEng}</option>)
                        })}

                    </select>
                </div>
                }
            </div>

            {address.createId !== 'default' &&
            <div className="btn btn--text js-removeAddress" onClick={() => dispatch(removeAddress(address))}>- Remove
                address</div>}

            <hr/>

        </div>
    )
}