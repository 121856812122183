import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {OrganisationInfoInterface} from "../../common/interfaces/OrganisationInfoInterface";
import {AddressInterface} from "../../common/interfaces/AddressInterface";
import nextId from "react-id-generator";
import {apiOrganisationAddressList} from "../../common/api/organisation/address/apiOrganisationAddressList";
import {apiOrganisationInfoDetail} from "../../common/api/organisation/info/apiOrganisationInfoDetail";
import {apiOrganisationInfoUpdate} from "../../common/api/organisation/info/apiOrganisationInfoUpdate";
import {apiOrganisationAddressCreate} from "../../common/api/organisation/address/apiOrganisationAddressCreate";
import {apiOrganisationAddressUpdate} from "../../common/api/organisation/address/apiOrganisationAddressUpdate";
import {apiOrganisationAddressDelete} from "../../common/api/organisation/address/apiOrganisationAddressDelete";
import {apiOrganisationInfoUploadLogo} from "../../common/api/organisation/info/apiOrganisationInfoUploadLogo";

interface organisationEditStateInterface {
    organisation: OrganisationInfoInterface,
    addresses: AddressInterface[],
    deletedAddressIds: number[],
    submitting: boolean,
    submitted: boolean
}


export const load = createAsyncThunk(
    'organisationEdit/load',
    async () => {

        const organisationEditResponse = await apiOrganisationInfoDetail()
        const addressResponse = await apiOrganisationAddressList()

        return {
            organisation: organisationEditResponse.data.items.info.organisation,
            addresses: addressResponse.data.items.data
        }
    }
)

export const submit = createAsyncThunk(
    'organisationEdit/submit',
    async (_, {getState}) => {

        const {organisationEdit} = getState() as { organisationEdit: organisationEditStateInterface };
        const organisationUpdateResponse = await apiOrganisationInfoUpdate(organisationEdit.organisation)

        if(organisationEdit.organisation.logoUpload){
            await apiOrganisationInfoUploadLogo(organisationEdit.organisation.logoUpload)
        }

        organisationEdit.addresses.forEach((address) => {

            const removeKey = (key: string, {[key]: _, ...rest}) => rest;
            address = removeKey('createId', address)

            if (address.id) {
                apiOrganisationAddressUpdate(address)
            } else {
                apiOrganisationAddressCreate(address)
            }

        })

        // Delete removed addresses
        organisationEdit.deletedAddressIds.forEach((addressId) => {
            apiOrganisationAddressDelete(addressId)
        })

        return organisationUpdateResponse

    }
)


const addressState = {
    street: '',
    number: undefined,
    typeId: undefined,
    postalCode: '',
    city: '',
    state: '',
    countryId: 123
}

const initialState: organisationEditStateInterface = {
    organisation: {
        cocNumber: undefined,
        companyName: '',
        invoiceEmail: '',
        phoneNumber: '',
        vatNumber: '',
        website: '',
        contactPersonName: '',
        contactPersonEmail: ''
    },
    addresses: [],
    deletedAddressIds: [],
    submitting: false,
    submitted: false
}

const organisationEditSlice = createSlice({
    name: 'organisationEdit',
    initialState,
    reducers: {
        setOrganisationData: (state, action: PayloadAction<OrganisationInfoInterface>) => {
            state.organisation = {
                ...state.organisation,
                ...action.payload
            }
        },
        setAddressData: (state, action: PayloadAction<AddressInterface>) => {



            state.addresses = state.addresses.map((address) => {
                if (address.createId === action.payload.createId) {
                    return {
                        ...address,
                        ...action.payload
                    }
                } else return address
            })

        },
        addAddress: (state) => {
            state.addresses.push({
                ...addressState,
                createId: nextId()
            })
        },
        removeAddress: (state, action: PayloadAction<AddressInterface>) => {

            if (action.payload.id) {
                state.addresses = state.addresses.filter((address) => address.id !== action.payload.id)
            } else {
                state.addresses = state.addresses.filter((address) => address.createId !== action.payload.createId)
            }
            if (action.payload.id) {
                state.deletedAddressIds.push(action.payload.id)
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(load.fulfilled, (state, action: PayloadAction<{ organisation: OrganisationInfoInterface, addresses: AddressInterface[] }>) => {
            state.organisation = action.payload.organisation
            state.addresses = action.payload.addresses
        })
        builder.addCase(submit.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(submit.fulfilled, (state) => {
            state.submitting = false
            state.submitted = true
        })
    },
})

export const {
    setOrganisationData,
    setAddressData,
    addAddress,
    removeAddress
} = organisationEditSlice.actions
export const organisationEditReducer = organisationEditSlice.reducer