import {ApiEnum} from "../../../enums/ApiEnum";
import {AddressInterface} from "../../../interfaces/AddressInterface";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationAddressCreate = async (address: AddressInterface) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_ADDRESS + '/create', {
        address: address
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
