import {Link} from "react-router-dom";
import {useAppSelector} from "../../../app/hooks";
import {RouteEnum} from "../../enums/RouteEnum";

const FreeTrialMenu = () => {

  const {userMe} = useAppSelector((state) => state.root)

  if (!userMe?.organisation?.freeTrial) {
    return null
  }

  return (
    <section className={'c-free-trial-menu'}>

      <div className={'c-free-trial-menu__left'}>
        <ul>
          <li><img src={'/images/icons/info-red.svg'}/> {userMe?.activePassports}/2 Free passports active</li>
          <li><img
            src={'/images/icons/info-red.svg'}/> {userMe?.organisation?.passportsCreated ? userMe.organisation.passportsCreated : 0}/6
            Free passports
            created
          </li>
        </ul>
      </div>

      <div className={'c-free-trial-menu__right'}>
        <small>Do you want the full experience?</small>
        <Link to={RouteEnum.UPGRADE_ACCOUNT} className={'btn btn--small btn--red btn--rounded'}>
          <span>Complete account</span>
        </Link>
      </div>

    </section>
  )

}

export default FreeTrialMenu