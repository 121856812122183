import React from "react";
import {PassportCreateBreadcrumbs} from "./PassportCreateBreadcrumbs";
import {PassportSummary} from "./PassportSummary";
import {useAppSelector} from "../../../app/hooks";

interface Props {
    children?: React.ReactNode;
};

export const PassportCreateWrapper = (props: Props) => {

    const {activeStep} = useAppSelector(state => state.passportCreate);

    return (
        <>
            <PassportCreateBreadcrumbs/>
            <div className={'c-app__content__container'}>
                <div className={'c-app__create'}>
                    {props.children}
                    <PassportSummary
                        step={activeStep}
                    />
                </div>
            </div>
        </>
    )

}

