import {ApiEnum} from "../../../enums/ApiEnum";
import {UserInterface} from "../../../interfaces/UserInterface";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationUserSendResetMail = async (user: UserInterface) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_USER + '/send-reset-mail', {
        email: user.email
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
