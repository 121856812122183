import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
}

export const PassportHeader = (props:Props) => {


    return(
        <header className="c-app__content__header">
            <nav className="c-breadcrumbs">
                <ul className="u-reset-ul">
                    <li>
                        <button>C_passport<sup>®</sup></button>
                        <img src="/images/icons/arrow-right-black.svg" alt=""/>
                    </li>
                    <li>
                        <button>{props.passport.name}</button>
                        <img src="/images/icons/arrow-right-black.svg" alt=""/>
                    </li>
                </ul>
            </nav>
        </header>
    )

}