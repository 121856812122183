import {useAppAxios} from "../../../../app/hooks";
import {ApiEnum} from "../../../enums/ApiEnum";

export const apiOrganisationInfoStyle = async (id?: number) => {

    return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/style', {}, {
        params: {
            id: id
        }
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}