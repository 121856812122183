import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {
  PassportGroupCreateInterface,
  PassportInterface
} from "../../common/interfaces/PassportInterface";
import {apiOrganisationPassportDuplicate} from "../../common/api/organisation/passport/apiOrganisationPassportDuplicate";
//import {apiOrganisationGroupUpdate} from "../../common/api/organisation/group/apiOrganisationGroupUpdate";


interface DuplicatePassportInterface {
  passport?: PassportInterface,
  submitting: boolean,
  passportGroup?: PassportGroupCreateInterface,
  related: boolean,
  duplicated: boolean,
}


export const duplicatePassport = createAsyncThunk(
  'duplicatePassportModal/duplicatePassport',
  async (_, {getState}) => {

    const {duplicatePassportModal} = getState() as { duplicatePassportModal: DuplicatePassportInterface };

    if (duplicatePassportModal.passport?.uuid) {

      await apiOrganisationPassportDuplicate(duplicatePassportModal.passport.uuid, duplicatePassportModal.related)


      // I dont see how this code belongs here
      // if (response && response.data && response.data.items && response.data.items.passport) {
      //
      //   if (duplicatePassportModal.passportGroup) {
      //
      //     const passportGroup = {...duplicatePassportModal.passportGroup}
      //
      //     if (passportGroup.passports) {
      //
      //       if (duplicatePassportModal.passport.id) {
      //
      //         passportGroup.passports = [...passportGroup.passports, response.data.items.passport.id]
      //         await apiOrganisationGroupUpdate(passportGroup)
      //
      //
      //       }
      //     }
      //
      //   }
      //
      //   return response.data.items
      // }
    }
  }
)


const initialState: DuplicatePassportInterface = {
  submitting: false,
  duplicated: false,
  related: true
}

const duplicatePassportModal = createSlice({
  name: 'duplicatePassportModal',
  initialState,
  reducers: {
    setDuplicatePassport: (state, action: PayloadAction<{
      passport?: PassportInterface,
      showPopup: boolean
      passportGroup?: PassportGroupCreateInterface
    }>) => {
      if (action.payload.showPopup) {
        state.passport = action.payload.passport
        state.passportGroup = action.payload.passportGroup
        state.duplicated = false
      } else {
        delete state.passport
      }
    },
    setRelated: (state, action: PayloadAction<boolean>) => {
      state.related = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(duplicatePassport.pending, (state) => {
      state.submitting = true
    })
    builder.addCase(duplicatePassport.fulfilled, (state) => {
      delete state.passport
      delete state.passportGroup
      state.duplicated = true
      state.submitting = false
    })
  },
})

export const {setDuplicatePassport, setRelated} = duplicatePassportModal.actions

export const duplicatePassportModalReducer = duplicatePassportModal.reducer
