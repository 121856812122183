import {setAssemblyData} from "../passportCreateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";

interface Props {
    typeId: number,
}

export const PassportAssemblyForm = (props: Props) => {

    const dispatch = useAppDispatch()
    const {assemblies} = useAppSelector(state => state.passportCreate);
    const {options} = useAppSelector(state => state.root);
    const {passportTypes} = options;
    const passportType = passportTypes?.data?.find((passportType) => passportType.id === props.typeId)

    const {passports} = useAppSelector(state => state.passportCreate);

    const activePassports = passports?.data.filter((passport) => assemblies?.find((assembly) => assembly.assemblyPassportId === passport.id && passport.typeId === props.typeId))

    if (activePassports?.length) {
        return (
            <>

                <h3 style={{marginTop: '3rem'}}>{passportType?.name}</h3>

                <form action="" className="c-form c-form--black">
                    {activePassports?.map((passport) => {

                        const assembly = assemblies.find((assembly) => assembly.assemblyPassportId === passport.id)
                        const passportType = passportTypes?.data?.find((passportType) => passportType.id === passport.typeId)

                        if (assembly && passportType) {
                            return (
                                <div className="c-form__row c-form__row--block-small">
                                    <div className="c-form__group">
                                    </div>
                                    <div className="c-form__group c-form__group--horizontal"
                                         style={{justifyContent: 'space-between'}}>
                                        <div>
                                            <h5 style={{fontSize: '1.8rem'}}>{passport.name} ({passportType.name})</h5>
                                            <label htmlFor="">Number of {passportType.name} used in this product</label>
                                        </div>
                                        <input
                                            onChange={(e) => {
                                                let validate = e.target.value.match(/^(\d*\.{0,1}\d{0,3}$)/)
                                                if (validate) {
                                                    dispatch(setAssemblyData({
                                                        assemblyPassportId: assembly.assemblyPassportId,
                                                        amount: Number(e.target.value)
                                                    }))
                                                }
                                            }}
                                            type="number"
                                            value={assembly.amount}
                                        />
                                    </div>
                                </div>
                            )
                        }else return null
                    })}

                </form>
            </>
        )
    } else return null

}