import {Link} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";
import {PassportInterface} from "../../interfaces/PassportInterface";

interface Props {
    connectedPassport: PassportInterface
    assemblyAmount?: number
    isGrandChild?: boolean
}

export const PassportConnectedPassportLink = (props: Props) => {

    const className = props.isGrandChild
        ? 'c-passport__connected__single c-passport__connected__single--grandchild'
        : 'c-passport__connected__single c-passport__connected__single--child'
    return (
        <Link to={RouteEnum.PASSPORT_DETAIL + '/' + props.connectedPassport.uuid}
              className={className}>
            <img src="/images/icons/passport.svg" alt=""/>
            <span>{props.connectedPassport.name + ' (' + props.assemblyAmount + 'x)'}</span>
        </Link>
    )
}
