import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    translations?: TranslationsInterface
}


export const PassportLabels = (props: Props) => {
    if (props.passport.labels?.length) {
        return (
            <div className="c-dashboard__card background-color-blocks c-passport__labels">
                <h2 className={'text-color-highlight'}>{props.translations?.labelsAndCertificates}</h2>
                <div>
                    {props.passport?.labels?.map((label) => {
                        return (<img src={label.image} alt=''/>)

                    })}
                </div>
                <p>{props.translations?.labelsMoreInfo}</p>
            </div>
        )
    } else return null
}
