import {ApiEnum} from "../../../enums/ApiEnum";
import {PassportGroupCreateInterface} from "../../../interfaces/PassportInterface";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationGroupCreate = async (passportGroup: PassportGroupCreateInterface) => {

    return await useAppAxios.post(ApiEnum.ORGANISATION_GROUP + '/create', passportGroup)
        .then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
