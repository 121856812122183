import {AccountWrapper} from "../../wrappers/AccountWrapper/AccountWrapper";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {GridLoader} from "react-spinners";
import {deleteUser, load} from "./AccountUsersSlice";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {Link} from "react-router-dom";
import {FreeTrialBanner} from "../../common/components/general/FreeTrialBanner";

const AccountUsersPageContainer = () => {

  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
      setLoading(true)
      dispatch(load()).then(() => {
        setLoading(false)
      })
    }, [dispatch]
  )

  const {users} = useAppSelector(state => state.users);

  return (
    <AccountWrapper>

      {!loading ?
        <>

          <div className="c-app__info__users">
            {users?.map((user) => {
              return (
                <div className="c-app__info__users__single" key={'user:' + user.id}>

                  <div className="c-app__info__users__single__info">
                    <div>
                      {user.roleName && <div className="c-tag c-tag--black">{user.roleName}</div>}
                      {user.active && <div className="c-tag c-tag--outline">Active</div>}
                    </div>
                    <span>{user.firstName} {user.insertion} {user.lastName}</span>
                    <span>{user.email}</span>
                  </div>


                  <div className="c-app__info__users__single__cta">
                    <Link to={RouteEnum.ACCOUNT_USERS_EDIT + '/' + user.id}
                          className="btn btn--small btn--blue btn--rounded"><span>Edit</span></Link>

                    {!user.active && <button
                        style={{background: 'none', marginLeft: '1rem'}}
                        onClick={() => {
                          if (user.id) {
                            dispatch(deleteUser(user.id)).then(() => {
                              dispatch(load())
                            })
                          }
                        }} className="btn btn--small btn--text"><span>Delete account</span>
                    </button>}
                  </div>
                </div>
              )
            })}

          </div>

        </> :
        <div className={'c-loader'}>
          <GridLoader color={'#E14343'}/>
        </div>
      }

      <FreeTrialBanner text={'Would you like to create more users? Complete your Cirmar account today!'}/>

    </AccountWrapper>
  )

}

export default AccountUsersPageContainer