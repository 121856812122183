import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationUserDetail = async ( id: number) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_USER + '/detail',{}, {
        params: {
            id: id
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
