import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {apiOrganisationDashboardList} from "../../common/api/organisation/dashboard/apiOrganisationDashboardList";
import {DashboardContainerInterface} from "../../common/interfaces/DashboardContainerInterface";

interface dashboardOverviewInterface {
    settings: {
        page: number,
        limit: number,
        filters: {
            name?: string,
            type?: number
        }
    },
    dashboardContainers?: {
        data: DashboardContainerInterface[],
        pages?: {
            limit?: number,
            total?: number,
            current?: number
        }
        total?: number,
    },
    loading: boolean
}

const initialState: dashboardOverviewInterface = {
    settings: {
        page: 1,
        limit: 10,
        filters: {}
    },
    loading: true
}

export const dashboardList = createAsyncThunk(
    'dashboard/dashboardList',
    async (_, {getState}) => {

        const {dashboardOverview} = getState() as { dashboardOverview: dashboardOverviewInterface };

        const response = await apiOrganisationDashboardList(dashboardOverview.settings.limit, dashboardOverview.settings.page)
    

        if (response) {
            return response.data.items
        }
    }
)


const dashboardOverviewSlice = createSlice({
    name: 'dashboardOverview',
    initialState,
    reducers: {
        setPage: (state, action: PayloadAction<number>) => {
            state.settings.page = action.payload
        },
        setLimit: (state, action: PayloadAction<number>) => {
            state.settings.limit = action.payload
        },
        setFilterName: (state, action: PayloadAction<string>) => {
            state.settings.filters.name = action.payload
            state.settings.page = 1
        },
        setFilterType: (state, action: PayloadAction<number>) => {
            state.settings.filters.type = action.payload
            state.settings.page = 1
        }
    },
    extraReducers: (builder) => {
        builder.addCase(dashboardList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(dashboardList.fulfilled, (state, action: PayloadAction<dashboardOverviewInterface["dashboardContainers"]>) => {
            state.loading = false
            state.dashboardContainers = action.payload
        })
    }
})

export const {
    setFilterName, setFilterType, setLimit, setPage
} = dashboardOverviewSlice.actions

export const dashboardOverviewReducer = dashboardOverviewSlice.reducer
