import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";

interface Args {
    uuid: string,
}

export const apiOrganisationPassportDetail = async (args:Args) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_PASSPORT + '/detail', {}, {
        params: {
            uuid: args.uuid,
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
