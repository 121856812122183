import {Pie} from 'react-chartjs-2';
import {Chart as ChartJS, ArcElement, Tooltip} from 'chart.js';
import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {useRef, useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setGraphImage} from "../../../pages/passportDetailPage/PassportDetailSlice";
import {GraphDataInterface} from "../../interfaces/GraphDataInterface";
import {CompositionLegenda} from "../general/CompositionLegenda";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {PassportPreferenceInterface} from "../../interfaces/PassportPreferenceInterface";

ChartJS.register(ArcElement, Tooltip);


interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    graphData: GraphDataInterface,
    graphRef?: any,
    translations?: TranslationsInterface
    passportPreference?: PassportPreferenceInterface,
}


export const PassportMaterials = (props: Props) => {


    const dispatch = useAppDispatch()

    const ref = useRef<any>(null);
    const [animationCompleted, setAnimationCompleted] = useState(false);

    let hideMaterials = false
    if (props.passportPreference && !props.passportPreference.materialsVisible) {
        hideMaterials = true
    }

    const {materialGroup, materials} = useAppSelector(state => state.root.options);

    useEffect(() => {
        if (animationCompleted) {
            const base64Data = ref.current.toBase64Image()
            dispatch(setGraphImage(base64Data))
        }
    }, [ref, animationCompleted, dispatch]);




    return (
        <div className="c-passport__tabs__content__wrapper"
             style={{
                 padding: '5rem',
                 display: 'flex',
                 justifyContent: 'center',
                 flexDirection: 'inherit',
                 alignItems: 'center'
             }}>

            <div className={'c-pie-chart'} style={{width: '30rem'}}>
                <Pie ref={ref} data={props.graphData} options={{
                    animation: {
                        duration: 0,
                        onComplete: function () {
                            setAnimationCompleted(true)
                        }
                    }
                }}/>
            </div>

            {(materialGroup?.data && materials?.data) &&
                <CompositionLegenda composition={props.composition} materialGroup={materialGroup.data}
                                    materials={materials.data} hideMaterials={hideMaterials} />
            }

        </div>

    )

}