import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useEffect, useState} from "react";
import {
    changeOverruleEndUse,
    load,
    loadBySimulation,
    setOverruleActiveTab,
    setPassportTab
} from "./dashboardDetailPageSlice";
import {useHistory, useParams} from "react-router-dom";
import {Link} from 'react-router-dom'
import {RouteEnum} from "../../common/enums/RouteEnum";
import {DashboardImpact} from "../../common/components/dashboard/DashboardImpact";
import {DashboardIntroduction} from "../../common/components/dashboard/DashboardIntroduction";
import {DashboardComposition} from "../../common/components/dashboard/DashboardComposition";
import {DashboardConnectedPassports} from "../../common/components/dashboard/DashboardConnectedPassports";
import {DashboardSdgGoals} from "../../common/components/dashboard/DashboardSdgGoals";
import {GridLoader} from "react-spinners";
import {setSimulatedPassportAmount} from "./dashboardDetailPageSlice";
import {displayModuleByUserRole} from "../../common/utils/displayModuleByUserRole";
import {DashboardExtraInformation} from "../../common/components/dashboard/DashboardExtraInformation";
import {getTranslationList} from "../../app/rootReducer";
import {getUuid} from "./dashboardDetailPageSlice";

interface DashboardDetailParams {
    uuid: string;
}

export const DashboardDetailPageContainer = () => {

    const history = useHistory()

    const dispatch = useAppDispatch()
    const {uuid} = useParams<DashboardDetailParams>()
    const {userMe, translations} = useAppSelector((state) => state.root)
    const [loading, setLoading] = useState(true)

    const {
        overruleActiveTab,
        overruleEndUseId,
        dashboardContainer,
        connectedPassports,
        simulatedPassports,
        loadImpacts
    } = useAppSelector((state) => state.dashboardDetail)

    const publicLabel = !!dashboardContainer?.dashboard.isPublic ? 'Public' : 'Private'

    useEffect(() => {

        if (!isNaN(Number(uuid))) {
            dispatch(getUuid(parseFloat(uuid))).then((response) => {
                if (response.payload) {
                    history.push('/dashboard/detail/' + response.payload)
                }
            })
        }

        setLoading(true)
        dispatch(getTranslationList('EN'))
        dispatch(load(uuid)).then(() => {
            setLoading(false)
        })
    }, [uuid, dispatch])

    useEffect(() => {
        if (simulatedPassports.active) {
            const timeOutId = setTimeout(() => dispatch(loadBySimulation(uuid)), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [simulatedPassports, dispatch, uuid])

    if (dashboardContainer && !loading) {
        return (
            <>

                <header className="c-app__content__header">
                    <nav className="c-breadcrumbs">
                        <ul className="u-reset-ul">
                            <li>
                                <Link to={RouteEnum.DASHBOARD_OVERVIEW}>C_dashboard<sup>®</sup></Link>
                                <img src="/images/icons/arrow-right-black.svg" alt={'Arrow right black'}/>
                            </li>
                            <li>
                                <Link
                                    to={RouteEnum.DASHBOARD_DETAIL + '/' + uuid}>{dashboardContainer.dashboard.title}</Link>
                                <img src="/images/icons/arrow-right-black.svg" alt={'Arrow right black'}/>
                            </li>
                        </ul>
                    </nav>

                    {displayModuleByUserRole('dashboardEdit', userMe) &&
                        <div>
                            <Link to={RouteEnum.DASHBOARD_EDIT + '/' + uuid}
                                  className="btn btn--small btn--outline btn--rounded">
                                <span>Edit</span>
                            </Link>
                        </div>
                    }
                </header>
                <div className="c-app__content__container">
                    <div className="c-dashboard">

                        <div className="c-dashboard__row c-dashboard__type">
                            <div className="c-dashboard__group">
                                <small>{translations?.collection} dashboard · {publicLabel}</small>
                            </div>
                            {!!dashboardContainer.dashboard.isPublic &&
                                <div className="c-dashboard__group">
                                    <span>{window.location.origin}{RouteEnum.PUBLIC_DASHBOARD}/{uuid} </span>
                                    <span
                                        onClick={() => navigator.clipboard.writeText(window.location.origin + RouteEnum.PUBLIC_DASHBOARD + '/' + uuid)}>
                                    {translations?.copyPublic} URL
                                </span>
                                </div>
                            }
                        </div>

                        <DashboardIntroduction
                            translations={translations}
                            dashboardContainer={dashboardContainer}
                        />

                        <DashboardImpact
                            translations={translations}
                            dashboardContainer={dashboardContainer}
                            overruleEndUseId={overruleEndUseId}
                            changeEndUseId={(endUseId) => {
                                dispatch(changeOverruleEndUse(endUseId))
                            }}
                            loadImpacts={loadImpacts}
                            changeActiveTab={(activeTab) => {
                                dispatch(setOverruleActiveTab(activeTab))
                            }}
                            activeTab={overruleActiveTab}
                        />

                        <DashboardExtraInformation
                            translations={translations}
                            extraInformation={dashboardContainer.dashboard.extraInformation}
                        />

                        <DashboardComposition
                            translations={translations}
                            dashboardContainer={dashboardContainer}
                        />

                        <DashboardConnectedPassports
                            translations={translations}
                            simulatedPassportAmounts={simulatedPassports.amounts}
                            dashboardContainer={dashboardContainer}
                            setSimulatedPassportAmount={setSimulatedPassportAmount}
                            connectedPassports={connectedPassports}
                            loadImpacts={loadImpacts}
                            setPassportTab={setPassportTab}
                        />

                        <DashboardSdgGoals
                            translations={translations}
                            sdgGoals={dashboardContainer.sdgGoals}
                        />

                    </div>
                </div>
            </>
        )
    } else return (
        <div className={'c-loader'}>
            <GridLoader color={'#E14343'}/>
        </div>
    )

}
