import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import type {RootState, AppDispatch} from './store'
import {useLocation} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh';
import {camelizeKeys, decamelizeKeys} from "humps";
import {ApiEnum} from "../common/enums/ApiEnum";
import {authToggle} from "./rootReducer";
import {store} from "./store";
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppLocation = () => useLocation()


export const useAppAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "content-type": "application/json"
    },
    responseType: "json"
});

const refreshAuthLogic = (failedRequest: AxiosAuthRefreshRequestConfig) =>
    useAppAxios
        .post(ApiEnum.AUTH + '/refresh', {
            refresh_token: localStorage.getItem("refreshToken")
        })
        .then(tokenRefreshResponse => {
            localStorage.setItem("accessToken", tokenRefreshResponse.data.items.accessToken.accessToken);
            localStorage.setItem("refreshToken", tokenRefreshResponse.data.items.accessToken.refreshToken);
            failedRequest.data.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.items.accessToken.accessToken;
            return Promise.resolve();
        })
        .catch(error => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("user");
            //pushToLogin();

            store.dispatch(authToggle())
            console.log("refresh fail toggle");


            return Promise.reject(error);
        });

createAuthRefreshInterceptor(useAppAxios, refreshAuthLogic);

// Use interceptor to inject the token to requests
useAppAxios.interceptors.request.use(request => {

    request.headers['Authorization'] = `Bearer ${getAccessToken()}`;

    const user = JSON.parse(localStorage.getItem('user') + '');

    if (request.headers['content-type'] === 'multipart/form-data') {
        request.data.append('auth_token', user.authToken)
    } else {
        if (user && user.authToken) {
            request.data['auth_token'] = user.authToken
        }

        if (request.data) {
            request.data = decamelizeKeys(request.data);
        }
    }

    return request;
});

useAppAxios.interceptors.response.use((response: AxiosResponse) => {

    if (response.data.status.code === 401) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        store.dispatch(authToggle())

        return response
    }

    response.data = camelizeKeys(response.data);
    return response;


});

function getAccessToken() {
    return localStorage.getItem('accessToken');
}
