import {changeCreateStep, deleteUpload, setCreatePassportData, setEndUse} from "../passportCreateSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import FormInput from "../../../common/components/form/FormInput";
import FormSelect from "../../../common/components/form/FormSelect";
import FormFile from "../../../common/components/form/FormFile";
import Select from "react-select";
import {useEffect} from "react";
import {PassportTypeInterface} from "../../../common/interfaces/PassportTypeInterface";
import {HowToCloud} from "../../../common/components/howTo/HowToCloud";
import {
  IsFreeTrialOrganisation,
  SetTrialPassportName,
} from "../../../common/utils/freeTrialRestrictions";

export const PassportCreateBasicInformationForm = () => {

  const dispatch = useAppDispatch()
  const {weightTypes, amountTypes, passportTypes} = useAppSelector(state => state.root.options);
  const {passport} = useAppSelector(state => state.passportCreate);
  const {userMe} = useAppSelector(state => state.root);

  const {endUses} = useAppSelector(state => state.root.options);

  const options = endUses?.data?.map((endUseItem) => ({
    value: endUseItem.id,
    label: endUseItem.name
  }));

  const activeOption = options && options.find((option) => passport.endOfUseSolution && passport.endOfUseSolution.id === option.value)
  const passportType = passportTypes?.data?.find((passportType: PassportTypeInterface) => passportType.id === passport.typeId)

  useEffect(() => {
      window.scrollTo(0, 0)

      if (IsFreeTrialOrganisation(userMe)) {
        dispatch(setCreatePassportData({name: SetTrialPassportName(userMe) + ''}))
      }
    }, []
  );

  return (
    <div className="c-app__create__primary">
      <header className="c-app__create__header">
        <small>Step 2/6</small>
        <h1>Basic information</h1>
        <p>Tell us more about your product by filling in basic information.</p>
      </header>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          dispatch(changeCreateStep(3))
        }}
        className="c-form">

        <div className="c-form__row">
          <FormInput
            onChange={(value) => dispatch(setCreatePassportData({name: value + ''}))}
            type={'text'}
            label={'Name of ' + passportType?.name}
            howToCode={'pp-name'}
            value={passport.name}
            required={true}
            loading={IsFreeTrialOrganisation(userMe)}
          />
        </div>

        <div className="c-form__row">

          <div className="c-form__group">

            <label htmlFor="">Description <HowToCloud code={'pp-description'}/></label>

            <textarea
              onChange={(e) => {
                dispatch(setCreatePassportData({
                  description: e.target.value
                }))
              }}
              value={passport.description}
            />

          </div>

        </div>

        {passportType?.name !== 'Component' &&
        <div className="c-app__info-card">
            <div className="c-app__info-card__intro" style={{margin: 0}}>

                <h2>Select weight source</h2>

                <form action="" className="c-form c-form--black">

                    <div className="c-form__row c-form__row--radio c-form__row--half">

                        <div className="c-form__group">
                            <label>
                                <input
                                    onClick={() => dispatch(setCreatePassportData({
                                      useAssemblyWeight: 0
                                    }))}
                                    checked={!passport.useAssemblyWeight}
                                    name={'useAssemblyWeight'}
                                    type="radio"
                                />
                                <span>Enter weight here</span>
                            </label>
                        </div>

                        <div className="c-form__group">
                            <label>
                                <input
                                    onClick={() => dispatch(setCreatePassportData({
                                      useAssemblyWeight: 1
                                    }))}
                                    checked={!!passport.useAssemblyWeight}
                                    name={'useAssemblyWeight'}
                                    type="radio"
                                />
                                <span>Use the weight entered from a lower assembly level (products and/or components)</span>
                            </label>
                        </div>

                      {!!passport.useAssemblyMaterials &&
                      <p style={{marginTop: '1.5rem', fontSize: '1rem'}}>*A lower level
                          C_passport<sup>®</sup> needs to be selected in the assembly (step 6) to use
                          it's weight</p>
                      }

                    </div>
                </form>
            </div>

        </div>
        }

        {!passport.useAssemblyWeight &&

        <div className="c-form__row c-form__row--half">

            <FormInput
                howToCode={'pp-weight'}
                onChange={(value) => dispatch(setCreatePassportData({weight: value + ''}))}
                type={'number'}
                label={'Weight'}
                required={true}
                value={passport.weight?.replace(',', '.')}
            />

            <FormSelect
                howToCode={'pp-weight-unit'}
                options={weightTypes?.data?.map((weightType) => {
                  return {value: weightType.id, label: weightType.name}
                })}
                defaultValue={'Select unit'}
                label={'Weight unit'}
                required={true}
                value={passport.weightTypeId}
                onChange={(value) => dispatch(setCreatePassportData({weightTypeId: parseInt(value)}))}
            />

        </div>
        }

        <div className="c-form__row c-form__row--half">

          <FormInput
            howToCode={'pp-quantity'}
            onChange={(value) => dispatch(setCreatePassportData({amount: value + ''}))}
            type={'number'}
            label={'Quantity'}
            value={passport.amount?.replace(',', '.')}
          />

          <FormSelect
            howToCode={'pp-quantity-unit'}
            options={amountTypes?.data?.map((amountType) => {
              return {value: amountType.id, label: amountType.name}
            })}
            defaultValue={'Select unit'}
            label={'Quantity unit'}
            value={passport.amountTypeId}
            onChange={(value) => dispatch(setCreatePassportData({amountTypeId: parseInt(value)}))}
          />

        </div>


        <div className="c-form__row c-form__row--three">

          <FormInput
            howToCode={'pp-length'}
            onChange={(value) => dispatch(setCreatePassportData({length: value + ''}))}
            type={'number'}
            label={'Length (mm)'}
            value={passport.length}
          />

          <FormInput
            howToCode={'pp-width'}
            onChange={(value) => dispatch(setCreatePassportData({width: value + ''}))}
            type={'number'}
            label={'Width (mm)'}
            value={passport.width}
          />

          <FormInput
            howToCode={'pp-height'}
            onChange={(value) => dispatch(setCreatePassportData({height: value + ''}))}
            type={'number'}
            label={'Height (mm)'}
            value={passport.height}
          />
        </div>

        <div className="c-form__row c-form__row--half">

          <FormInput
            howToCode={'pp-defined-use-period'}
            onChange={(value) => dispatch(setCreatePassportData({definedUsePeriod: value + ''}))}
            type={'number'}
            label={'Defined use period (years)'}
            value={passport.definedUsePeriod}
          />

          <FormFile
            defaultLabel={true}
            howToCode={'msds'}
            deleteAction={() => dispatch(deleteUpload({name: 'msdsFile', id: passport.msdsFile?.id}))}
            accept={'.pdf'}
            onChange={(value) => dispatch(setCreatePassportData({msdsFileUpload: value}))}
            value={passport.msdsFileUpload}
            currentPath={passport.msdsFile?.uploadPath}
            label={'MSDS'}
          />

        </div>

        <div className={'c-form__row'}>
          <FormInput
            howToCode={'pp-next-use-application'}
            onChange={(value) => dispatch(setCreatePassportData({nextUseApplication: value + ''}))}
            type={'text'}
            label={'Next use application'}
            value={passport.nextUseApplication}
          />
        </div>

        {passportType?.name !== 'Component' &&
        <div className="c-app__info-card">

            <div className="c-app__info-card__intro" style={{margin: 0}}><h2>Select end of use treatment</h2>
                <form action="" className="c-form c-form--black">
                    <div className="c-form__row c-form__row--radio c-form__row--half">

                        <div className="c-form__group">
                            <label htmlFor="">
                                <input
                                    type="radio"
                                    onClick={() => dispatch(setCreatePassportData({
                                      calculateEndOfUse: 0
                                    }))}
                                    checked={!passport.calculateEndOfUse}
                                    name={'calculateEndOfUse'}
                                />
                                <span>Fill in your end of use treatment</span>
                            </label>
                        </div>

                        <div className="c-form__group">
                            <label htmlFor="">
                                <input
                                    type="radio"
                                    onClick={() => dispatch(setCreatePassportData({
                                      calculateEndOfUse: 1
                                    }))}
                                    checked={!!passport.calculateEndOfUse}
                                    name={'calculateEndOfUse'}
                                />
                                <span>Let the system calculate the end of use treatment based on lower assembly levels</span>
                            </label>
                        </div>

                    </div>
                </form>
            </div>
        </div>
        }

        {!!passport.calculateEndOfUse &&
        passportType?.name !== 'Component' &&
        <div className="c-form__group">
            <input
                type="text"
                placeholder={passport.endOfUseCalculatedList}
                disabled
            />
        </div>
        }

        {(!passport.calculateEndOfUse ||
          passportType?.name === 'Component') &&

        <div className="c-form__row">

            <div className="c-form__group">
                <label htmlFor="">End of use treatment <HowToCloud
                    code={'pp-end-of-use-treatment'}/></label>
                <Select
                    options={options}
                    value={activeOption}
                    onChange={(option) => {
                      dispatch(setEndUse(Number(option?.value)))
                    }}
                />
            </div>

        </div>
        }

        <div className="c-form__subject c-form__subject--border c-form__subject--small">
          <h2>Encodings</h2>
        </div>
        <div className="c-form__row c-form__row--half">

          <FormInput
            howToCode={'pp-code'}
            onChange={(value) => dispatch(setCreatePassportData({code: value + ''}))}
            type={'text'}
            label={'Code'}
            value={passport.code}
          />

          <FormInput
            howToCode={'pp-serial-number'}
            onChange={(value) => dispatch(setCreatePassportData({serialNumber: value + ''}))}
            type={'text'}
            label={'Serial number'}
            value={passport.serialNumber}
          />

        </div>
        <div className="c-form__row c-form__row--half">

          <FormInput
            howToCode={'pp-etim-code'}
            onChange={(value) => dispatch(setCreatePassportData({etimCode: value + ''}))}
            type={'text'}
            label={'ETIM code'}
            value={passport.etimCode}
          />

          <FormInput
            howToCode={'pp-gtin-number'}
            onChange={(value) => dispatch(setCreatePassportData({gtinNumber: value + ''}))}
            type={'text'}
            label={'GTIN number'}
            value={passport.gtinNumber}
          />

        </div>

        <button type={'submit'} className="btn btn--large btn--width btn--margin btn--rounded btn--red">
          <span>Step 3: materials</span>
        </button>

      </form>
    </div>
  )

}
