import {ApiEnum} from "../../enums/ApiEnum";
import axios from "axios";

export const apiCockpitInfoWebsite = async () => {
    return await axios.get(ApiEnum.WEBSITE_COCKPIT + '/cockpit', {}).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
