import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {ClipLoader} from "react-spinners";
import {setAssemblies, setGroups, setParentAssemblies, submit} from "../passportCreateSlice";
import {useEffect} from "react";
import {PassportAssemblyForm} from "../components/PassportAssemblyForm";
import Select from "react-select";
import {PassportParentAssemblyForm} from "../components/PassportParentAssemblyForm";
import {HowToCloud} from "../../../common/components/howTo/HowToCloud";
import {passportIsSaveAble} from "../../../common/utils/passportIsSaveAble";

export const PassportCreateConnect = () => {

  const {passports, passport, groups} = useAppSelector(state => state.passportCreate);
  const {id} = useAppSelector(state => state.passportCreate.passport);
  const {
    submitting,
    submitted,
    assemblies,
    parentAssemblies,
    passportGroups
  } = useAppSelector(state => state.passportCreate);

  const activeAssemblyPassports = passports?.data.filter((passport) => assemblies.find((assembly) => assembly.assemblyPassportId === passport.id))
  const activeParentAssemblyPassports = passports?.data.filter((passport) => parentAssemblies.find((assembly) => assembly.passportId === passport.id))
  const activePassports = activeParentAssemblyPassports ? activeAssemblyPassports?.concat(activeParentAssemblyPassports) : activeAssemblyPassports


  const activeGroups = groups?.data.filter((group) => passportGroups?.find((passportGroup) => passportGroup.id === group.id))

  const {options} = useAppSelector(state => state.root);
  const {passportTypes} = options;

  const dispatch = useAppDispatch();
  const title = id ? 'Update' : 'Save'

  useEffect(() => {
      window.scrollTo(0, 0)
    }, []
  );

  const passportOptions = passports?.data.filter((passportItem) => {
    return passport.typeId !== passportItem.typeId
  }).map((passport) => {
    const passportType = passportTypes?.data?.find((passportType) => passportType.id === passport.typeId)
    return {
      value: Number(passport.id),
      label: passport.name + ' (' + passportType?.name + ')'
    }
  });

  const activePassportOptions = activePassports?.map((passport) => {

    const passportType = passportTypes?.data?.find((passportType) => passportType.id === passport.typeId)

    return {
      value: Number(passport.id),
      label: passport.name + ' (' + passportType?.name + ')'
    }
  });

  const groupOptions = groups?.data.map((group) => {
    return {
      value: Number(group.id),
      label: group.name
    }
  });


  const activeGroupOptions = activeGroups?.map((group) => {

    return {
      value: Number(group.id),
      label: group.name
    }
  });


  const isSaveAble = passportIsSaveAble({
    passport: passport,
    activeAssemblyPassports: activeAssemblyPassports,
    activeParentAssemblyPassports: activeParentAssemblyPassports
  })

  return (
    <div className="c-app__create__primary">
      <header className="c-app__create__header">
        <small>Step 6/6</small>
        <h1>Assembly</h1>
        {passports?.data?.length ?
          <p>
            Please assemble your item, it's possible to connect multiple product or component passports to a
            end product passport.
          </p> :
          <p>There aren't any passports created yet to connect to</p>
        }
      </header>

      <form action="" className="c-form" style={{marginBottom: '2rem'}}>
        <div className="c-form__row">
          <div className="c-form__group">
            <label htmlFor="">Select (multiple) C_passport<sup>®</sup> <HowToCloud
              code={'pp-select-c-passport'}/></label>
            <Select
              value={activePassportOptions}
              isMulti={true}
              options={passportOptions}
              onChange={(passportOptions) => {

                const selectedAssemblyOptions = passportOptions.filter((option) => {
                  const passportOption = passports?.data.find((passport) => passport.id === option.value)
                  return Number(passport.typeId) > Number(passportOption?.typeId)
                })

                const selectedParentAssemblyOptions = passportOptions.filter((option) => {
                  const passportOption = passports?.data.find((passport) => passport.id === option.value)
                  return Number(passport.typeId) < Number(passportOption?.typeId)
                })

                dispatch(setAssemblies(selectedAssemblyOptions.map((option) => (option.value))))
                dispatch(setParentAssemblies(selectedParentAssemblyOptions.map((option) => (option.value))))

              }}
            />
          </div>
        </div>
      </form>

      {passport.typeId && passport.typeId === 3 &&
      <form action="" className="c-form" style={{marginBottom: '2rem'}}>
          <div className="c-form__row">
              <div className="c-form__group">
                  <label htmlFor="">Select (multiple) passport groups<sup>®</sup> <HowToCloud
                      code={'pp-select-c-passport-group'}/></label>
                  <Select
                      value={activeGroupOptions}
                      isMulti={true}
                      options={groupOptions}
                      onChange={(groupOptions) => {
                        dispatch(setGroups(groupOptions.map((option) => (option.value))))
                      }}
                  />
              </div>
          </div>
      </form>
      }

      {passport.typeId && passport.typeId === 1 &&
      <>
          <PassportParentAssemblyForm typeId={2}/>
          <PassportParentAssemblyForm typeId={3}/>
      </>
      }

      {passport.typeId && passport.typeId === 2 &&
      <>
          <PassportAssemblyForm typeId={1}/>
          <PassportParentAssemblyForm typeId={3}/>
      </>
      }

      {passport.typeId && passport.typeId === 3 &&
      <>
          <PassportAssemblyForm typeId={1}/>
          <PassportAssemblyForm typeId={2}/>
      </>
      }


      {(isSaveAble.saveAble) &&
      <button className="btn btn--large btn--margin btn--width btn--rounded btn--black" disabled={submitting}
              onClick={
                () => dispatch(submit())
              }>
                <span style={{display: 'flex', alignItems: 'center'}}>{title} C_passport<sup>®</sup>
                  {submitting && <div style={{marginLeft: '1rem'}}><ClipLoader size={18} color={'#ffffff'}/></div>}
                </span>
      </button>
      }

      {(!isSaveAble.saveAble && isSaveAble.message) &&
      <p className={'c-alert'} style={{left: 0, marginTop: '1rem'}}>
          <b>{isSaveAble.message}</b>
      </p>
      }

      {submitted &&
      <div className={'c-notification c-notification--green'} style={{marginTop: '1rem'}}>
          <p style={{textAlign: 'center'}}>Successfully saved</p>
      </div>
      }

    </div>
  )

}

