import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiCockpitInfo = async () => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_COCKPIT + '/info', {}).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
