import {DashboardContainerInterface} from "../../interfaces/DashboardContainerInterface";
import {useAppSelector} from "../../../app/hooks";
import {Pie} from "react-chartjs-2";
import {FormatCompositionToPieData} from "../../utils/FormatCompositionToPieData";
import {CompositionLegenda} from "../general/CompositionLegenda";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {DashboardPreferenceInterface} from "../../interfaces/DashboardPreferenceInterface";

interface Props {
    dashboardContainer?: DashboardContainerInterface,
    translations?: TranslationsInterface,
    dashboardPreference?: DashboardPreferenceInterface
}

export const DashboardComposition = (props: Props) => {

    const {dashboardContainer, translations, dashboardPreference} = props
    const {materialGroup, materials} = useAppSelector(state => state.root.options);


    let hideMaterials = false
    if (dashboardPreference && !dashboardPreference.materialsVisible) {
        hideMaterials = true
    }

    if (dashboardContainer && dashboardContainer.passports.length > 1 && dashboardContainer.composition && materialGroup && materials) {
        return (
            <div className="c-dashboard__row c-dashboard__passports-composition">
                <div className="c-dashboard__group c-dashboard__composition" style={{width: '100%'}}>
                    <div className="c-dashboard__card background-color-blocks">
                        <h4>{translations?.totalCompositionOfMaterials}</h4>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '3rem'
                        }}>

                            <div className={'c-pie-chart'} style={{width: '35rem'}}>
                                <Pie
                                    data={FormatCompositionToPieData({
                                        composition: dashboardContainer.composition,
                                        materialGroup: materialGroup.data,
                                        materials: materials.data,
                                        hideMaterials: hideMaterials
                                    })}
                                    options={{
                                        animation: {
                                            duration: 0
                                        }
                                    }}
                                />
                            </div>
                            {(materialGroup?.data && materials?.data) &&
                                <CompositionLegenda
                                    composition={dashboardContainer.composition}
                                    materialGroup={materialGroup.data}
                                    materials={materials.data}
                                    hideMaterials={hideMaterials}

                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    } else return null

}
