import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setActiveLanguage} from "../../../app/rootReducer";
import {Switch, Route} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";

export const LanguagePicker = () => {
  const dispatch = useAppDispatch()
  const [isOpen, toggleOpen] = useState(false)
  const {activeLanguage, options} = useAppSelector((state) => state.root)
  const {passport, updateAblePassport} = useAppSelector((state) => state.passportCreate)
  const {submitted} = useAppSelector((state) => state.dashboardCreate)

  const activeLanguageOption = options?.languages?.find((language) => language.code === activeLanguage)
  const inActiveLanguageOptions = options?.languages?.filter((language) => language.code !== activeLanguage)

  return (
    <div className={'c-language-picker-container'}>

      {(!!passport && updateAblePassport) &&
      <Switch>
          <Route path={RouteEnum.PASSPORT_CREATE} exact={true}>
              <div className={'c-language-picker__notice'}>
                  <p>Please create the passport before switching the language</p>
              </div>
          </Route>
          <Route path={RouteEnum.PASSPORT_EDIT + '/:id/:step?'} exact={true}>
              <div className={'c-language-picker__notice'}>
                  <p>Please update the passport before switching the language</p>
              </div>
          </Route>
      </Switch>
      }

      {(!submitted) &&
      <Switch>
          <Route path={RouteEnum.DASHBOARD_CREATE} exact={true}>
              <div className={'c-language-picker__notice'}>
                  <p>Please create the dashboard before switching the language</p>
              </div>
          </Route>
          <Route path={RouteEnum.DASHBOARD_EDIT + '/:id/:step?'} exact={true}>
              <div className={'c-language-picker__notice'}>
                  <p>Please update the dashboard before switching the language</p>
              </div>
          </Route>
      </Switch>
      }

      <div className={'c-language-picker'}>

        {activeLanguageOption &&
        <div className={'c-language-picker__language c-language-picker__language--selected'}
             onClick={() => toggleOpen(!isOpen)}>
            <span>{activeLanguageOption.name}</span>
        </div>
        }

        {isOpen && <div className={'c-language-picker__list'}>


          {inActiveLanguageOptions?.map((languageOption) => {

            return (


              <div className={'c-language-picker__language'} onClick={() => {
                dispatch(setActiveLanguage(languageOption.code))
                toggleOpen(false)
              }}>
                <span>{languageOption.name}</span>
              </div>

            )
          })}

        </div>}


      </div>

    </div>
  )

}