import {UpgradeWrapper} from "./components/UpgradeWrapper";
import {UpgradeStep1} from "./steps/UpgradeStep1";
import {UpgradeStep2} from "./steps/UpgradeStep2";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {UpgradeResult} from "./steps/UpgradeResult";
import {useEffect} from "react";
import {changeStep, load, loadExamplePrices} from "./upgradeAccountSlice";
import {UpgradeStep3} from "./steps/UpgradeStep3";

const UpgradePageContainer = () => {

  const {activeStep, completed} = useAppSelector(state => state.upgradeAccount);
  const dispatch = useAppDispatch()

  useEffect(() => {

      dispatch(load())
      dispatch(loadExamplePrices())

      if (completed || !userMe?.organisation?.freeTrial) {
        dispatch(changeStep(4))
      }

    }, [completed, dispatch]
  );

  const {userMe} = useAppSelector(state => state.root);

  return (
    <UpgradeWrapper>
      {activeStep === 1 && <UpgradeStep1/>}
      {activeStep === 2 && <UpgradeStep2/>}
      {activeStep === 3 && <UpgradeStep3/>}
      {activeStep === 4 && <UpgradeResult organisation={userMe?.organisation}/>}
    </UpgradeWrapper>
  )

}

export default UpgradePageContainer