import {useAppSelector} from "../../../app/hooks";

export const CockpitWelcome = () => {

    const {userMe} = useAppSelector((state) => state.root)

    return (
        <>
            <div className="c-app__view">
                <header className="c-app__view__header">
                    <h1 style={{color: '#fff', fontWeight: 'bold', fontSize: '2.5rem', fontStyle: 'italic'}}>{'Welcome to your Cirmar cockpit, ' + userMe?.firstName + '!'}</h1>
                </header>
            </div>
        </>
    )
}
