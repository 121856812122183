import {getPassportProgressData} from "../../utils/getPassportProgressData";
import {ProgressInterface} from "../../interfaces/ProgressInterface";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";
import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";


const getProgressText = (progress: ProgressInterface) => {

    if (progress.totalPercentage && progress.lowest) {

        if (progress.totalPercentage === 100) {
            return 'Well done! This passport seems to be completely filled'
        } else {
            if (progress.totalPercentage > 50) {
                return 'This C_passport<sup>®</sup> is on the right track. Add ' + progress.lowest.text + ' to increase your C_passport<sup>®</sup> score.'
            } else {
                return 'This C_passport<sup>®</sup> still needs some work. Add ' + progress.lowest.text + ' to increase your C_passport<sup>®</sup> score.'
            }
        }
    }

}

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    translations?: TranslationsInterface
}


export const PassportProgress = (props: Props) => {

    const progress = getPassportProgressData(props.passport, props.composition)
    const {translations} = props
    return (
        <div className="c-dashboard__card c-passport__progress">
            <div className="c-passport__progress__indication">
                <div className="c-card__single__score">
                    <span>{progress.totalPercentage}%</span>
                    <figure style={{width: progress.totalPercentage + '%'}}/>
                </div>
                <p dangerouslySetInnerHTML={{__html: getProgressText(progress) + ''}}/>

            </div>

            {(progress.totalPercentage !== 100) &&
            <div className="c-passport__progress__cta">
                <span>{progress.lowest?.text} ({progress.lowest?.step}/6)</span>
                <Link to={RouteEnum.PASSPORT_EDIT + '/' + props.passport.uuid + '/' + progress.lowest?.step}
                      className="btn btn--rounded btn--small btn--black">
                    <span>{translations?.completeStep}</span>
                </Link>
            </div>
            }
        </div>
    )

}
