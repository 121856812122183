import {ApiEnum} from "../../../enums/ApiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {OrganisationInfoInterface} from "../../../interfaces/OrganisationInfoInterface";
import {UserInterface} from "../../../interfaces/UserInterface";
import {AddressInterface} from "../../../interfaces/AddressInterface";

export const apiOrganisationInfoUpgradeAccount = async (organisation: OrganisationInfoInterface, user: UserInterface, addresses: AddressInterface[]) => {

  return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/upgrade-account', {
    organisation: organisation,
    user: user,
    addresses: addresses
  }).then(function (response) {
    return response
  }).catch(function (error) {
    return error
  })
}
