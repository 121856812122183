import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiCountryList = async () => {
    return await useAppAxios.post(ApiEnum.COUNTRY + '/list?limit=99999', {}).then(function (response) {
        return response
    }).catch(function (error) {
        //dispatch(setAuthApiError(error.response.status))
        return error
    })
}
