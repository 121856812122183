import {OnboardingWrapper} from "./components/OnboardingWrapper";
import {OnboardingContent} from "./components/OnboardingContent";

const OnboardingPageContainer = () => {

    return (
        <OnboardingWrapper>
            <OnboardingContent/>
        </OnboardingWrapper>
    )

}

export default OnboardingPageContainer