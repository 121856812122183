import {useAppSelector} from "../../../app/hooks";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../../common/enums/RouteEnum";

export const PassportCreateBreadcrumbs = () => {

    const {id} = useAppSelector(state => state.passportCreate.passport);

    let title = id ? 'Update' : 'Create'

    return (
        <nav className="c-breadcrumbs">
            <ul className="u-reset-ul">
                <li>
                    <Link to={RouteEnum.PASSPORT_OVERVIEW}>
                    <span>
                       C_passport<sup>®</sup>
                    </span>
                </Link>
                    <img src="/images/icons/arrow-right-black.svg" alt=""/>
                </li>
                <li><span>{title}</span> <img src="/images/icons/arrow-right-black.svg" alt=""/></li>
            </ul>
        </nav>
    )

}