import {CompositionInterface, PassportInterface} from "../../common/interfaces/PassportInterface";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {apiOrganisationDashboardDetail} from "../../common/api/organisation/dashboard/apiOrganisationDashboardDetail";
import {DashboardContainerInterface} from "../../common/interfaces/DashboardContainerInterface";
import {AssemblyInterface} from "../../common/interfaces/AssemblyInterface";
import {LoadStyleAction} from "../../common/actions/LoadStyleAction";
import {StyleInterface} from "../../common/interfaces/StyleInterface";
import {SimulatedPassportAmountInterface} from "../../common/interfaces/SimulatedPassportAmountInterface";
import {LoadDashboardAction} from "../../common/actions/LoadDashboardAction";
import {DashboardPreferenceInterface} from "../../common/interfaces/DashboardPreferenceInterface";
import {mapSimulatedPassports} from "../../common/utils/mapSimulatedPassports";

interface dashboardPublicInterface {
    dashboardContainer?: DashboardContainerInterface,
    id?: number,
    connectedPassports: {
        passport: PassportInterface,
        assembly: AssemblyInterface,
        composition: CompositionInterface[]
    }[],
    simulatedPassports: {
        active: boolean,
        amounts?: SimulatedPassportAmountInterface[]
    },
    dashboardPreference?: DashboardPreferenceInterface,
    style?: StyleInterface,
    overruleActiveTab?: string
}


const initialState: dashboardPublicInterface = {
    connectedPassports: [],
    simulatedPassports: {
        active: false,
        amounts: []
    },
    overruleActiveTab: 'materials',
}


export const load = createAsyncThunk(
    'dashboardPublic/load',
    LoadDashboardAction
)

export const loadBySimulation = createAsyncThunk(
    'dashboardPublic/loadBySimulation',
    async (uuid: string, {getState}) => {
        const {dashboardPublic} = getState() as { dashboardPublic: dashboardPublicInterface };

        const response = await apiOrganisationDashboardDetail({
            uuid: uuid,
            simulatedPassportAmounts: dashboardPublic.simulatedPassports.amounts
        })
        const connectedPassports = await Promise.all(dashboardPublic.connectedPassports?.map(async (connectedPassport) => await mapSimulatedPassports(dashboardPublic.simulatedPassports, connectedPassport)))

        return {
            dashboardContainer: response.data.items,
            connectedPassports: connectedPassports
        }
    }
)

export const loadStyle = createAsyncThunk(
    'dashboardPublic/loadStyle',
    async (_, {getState}) => {
        const {dashboardPublic} = getState() as { dashboardPublic: dashboardPublicInterface };

        return await LoadStyleAction(dashboardPublic.dashboardContainer?.dashboard.organisation?.id)
    }
)

const dashboardPublicSlice = createSlice({
    name: 'dashboardPublic',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState)
        },
        setSimulatedPassportAmount: (state, action: PayloadAction<{ uuid: string, amount: string }>) => {
            state.simulatedPassports.amounts = state.simulatedPassports.amounts?.map((simulatedPassportAmount) => {
                if (simulatedPassportAmount.uuid === action.payload.uuid) {
                    return {
                        ...simulatedPassportAmount,
                        amount: action.payload.amount
                    }
                } else return simulatedPassportAmount
            })
        },
        setPassportTab: (state, action: PayloadAction<{
            uuid: string,
            tab: string
        }>) => {

            let hasPassportsWithCustomActiveTab = false

            state.connectedPassports = state.connectedPassports.map((passport) => {
                if ((passport.passport.uuid === action.payload.uuid)) {

                    if (action.payload.tab !== state.overruleActiveTab) {
                        hasPassportsWithCustomActiveTab = true
                    }

                    return {
                        ...passport,
                        passport: {
                            ...passport.passport,
                            dashboardSettings: {
                                activeTab: action.payload.tab
                            }
                        }

                    }
                }
                return passport
            })


            if (hasPassportsWithCustomActiveTab) {
                state.overruleActiveTab = 'custom'
            }

        },
        setOverruleActiveTab: (state, action: PayloadAction<string>) => {
            state.overruleActiveTab = action.payload
            state.connectedPassports = state.connectedPassports.map((passport) => {
                return {
                    ...passport,
                    passport: {
                        ...passport.passport,
                        dashboardSettings: {
                            activeTab: action.payload
                        }
                    }
                }
            })
        }
    },
    extraReducers: (builder) => {

        builder.addCase(load.fulfilled, (state, action: PayloadAction<any>) => {
            state.dashboardContainer = action.payload.dashboardContainer
            state.connectedPassports = action.payload.connectedPassports
            state.dashboardPreference = action.payload.dashboardPreference
            state.simulatedPassports.active = true
            state.simulatedPassports.amounts = action.payload.defaultSimulatedPassportAmounts
        })
        builder.addCase(loadBySimulation.fulfilled, (state, action: PayloadAction<{
            dashboardContainer: dashboardPublicInterface['dashboardContainer'],
            connectedPassports: any
        }>) => {
            state.dashboardContainer = action.payload.dashboardContainer
            state.connectedPassports = action.payload.connectedPassports
        })
        builder.addCase(loadStyle.fulfilled, (state, action: PayloadAction<StyleInterface>) => {
            state.style = action.payload
        })
    },
})

export const {
    reset,
    setSimulatedPassportAmount,
    setPassportTab,
    setOverruleActiveTab
} = dashboardPublicSlice.actions

export const dashboardPublicReducer = dashboardPublicSlice.reducer
