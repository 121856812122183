import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {PassportGroupInterface} from "../../common/interfaces/PassportInterface";
import {apiOrganisationGroupDelete} from "../../common/api/organisation/group/apiOrganisationGroupDelete";

interface TrashPassportGroupModalInterface {
    group?: PassportGroupInterface,
    detailPage: boolean,
    deleted: boolean,
    submitting: boolean,
}

export const deletePassportGroup = createAsyncThunk(
    'trashPassportGroupModal/deletePassportGroup',
    async (_, {getState}) => {

        const {trashPassportGroupModal} = getState() as { trashPassportGroupModal: TrashPassportGroupModalInterface };

        if (trashPassportGroupModal.group?.id) {
            const response = await apiOrganisationGroupDelete(trashPassportGroupModal.group.id)

            if (response && response.data && response.data.items && response.data.items.data) {
                return response.data.items
            }
        }
    }
)

const initialState: TrashPassportGroupModalInterface = {
    submitting: false,
    detailPage: false,
    deleted: false
}

const trashPassportGroupModal = createSlice({
    name: 'trashPassportGroupModal',
    initialState,
    reducers: {
        setTrashPassportGroup: (state, action: PayloadAction<{
            group?: PassportGroupInterface,
            detailPage?: boolean
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup) {
                if (action.payload.detailPage) {
                    state.detailPage = true
                }
                state.group = action.payload.group
            } else {
                state.detailPage = false
                delete state.group
            }
        },
        resetDeleted: (state) => {
            state.deleted = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deletePassportGroup.pending, (state) => {
            state.deleted = false
            state.submitting = true
        })
        builder.addCase(deletePassportGroup.fulfilled, (state) => {
            if (state.detailPage){
                state.deleted = true
                state.detailPage = false
            }
            delete state.group
            state.submitting = false
        })
    },
})

export const {setTrashPassportGroup, resetDeleted} = trashPassportGroupModal.actions

export const trashPassportGroupModalReducer = trashPassportGroupModal.reducer
