import {useHistory} from "react-router-dom";
import {RouteEnum} from "../../../common/enums/RouteEnum";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {changeCreateStep, setCreatePassportData} from "../../passportCreatePage/passportCreateSlice";
import {setFilterType} from "../../passportOverviewPage/PassportOverviewPageSlice";

interface CockpitPassportItem {
  svg: string,
  count: number,
  title: string,
  buttonText: string,
  typeId: number
}

export const CreatePassportItems = () => {

  const {info} = useAppSelector((state) => state.cockpit)

  const data = [
    {
      svg: 'image-endproduct',
      count: info?.passportTotals?.endproduct,
      title: 'End product passports',
      typeId: 3,
      buttonText: 'Create end product passport'
    },
    {
      svg: 'image-product',
      count: info?.passportTotals?.product,
      title: 'Product passports',
      typeId: 2,
      buttonText: 'Create product passport'
    },
    {
      svg: 'image-component',
      count: info?.passportTotals?.component,
      title: 'Component passports',
      typeId: 1,
      buttonText: 'Create component passport'
    }
  ] as CockpitPassportItem[]

  return (
    <>
      {data.map((passportItem) => {
        return <CreatePassportItem passportItem={passportItem}/>
      })}
    </>
  )

}

interface Props {
  passportItem: CockpitPassportItem
}

const CreatePassportItem = (props: Props) => {

  const {passportItem} = props
  const dispatch = useAppDispatch()
  const history = useHistory()
  const onButtonClick = () => {
    history.push(RouteEnum.PASSPORT_CREATE)

    setTimeout(() => {

      dispatch(setCreatePassportData({
        typeId: passportItem.typeId
      }))
      dispatch(changeCreateStep(2))

    }, 1)
  }

  const passportOverviewWithFilter = () => {
    history.push(RouteEnum.PASSPORT_OVERVIEW)
    dispatch(setFilterType(Number(passportItem.typeId)))
  }

  return (
    <div className="c-dashboard__card-v2">
      <img src={'images/' + passportItem.svg + '.svg'}/>
      <div className={'c-dashboard__card-v2__count'}>
        {props.passportItem.count}
      </div>
      <h3>{props.passportItem.title}</h3>
      <p onClick={() => passportOverviewWithFilter()}>Show passports of this type</p>

      <span className={'c-dashboard__card-v2__add'} onClick={() => onButtonClick()}>
        +
      </span>

    </div>
  )
}