import {removeFromPassportGroup, setRemoveFromPassportGroup} from "./removeFromPassportGroupModalSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";


export const RemoveFromPassportGroupModalContainer = () => {

    const dispatch = useAppDispatch()
    const {group, submitting} = useAppSelector(state => state.removeFromPassportGroupModal)

    return (
        <div className="c-modal" style={{display: 'flex', opacity: 1, pointerEvents: 'all'}}>
            <div className="c-modal__background"/>
            <div className="c-modal__content">
                <button className="c-modal__close" onClick={() => {
                    dispatch(setRemoveFromPassportGroup({
                        showPopup: false
                    }))
                }}>
                    <img src="/images/icons/cross-black.svg" alt=""/>
                </button>
                <header className="c-modal__header">
                    <h1>Remove passport from - {group?.name}</h1>
                </header>
                <div className="c-modal__body">
                    <p>
                        Are you sure you would like to remove this passport from this group? This action can't be undone.
                    </p>
                    <button className="btn btn--rounded btn--red" disabled={submitting}
                            onClick={() => dispatch(removeFromPassportGroup())}>
                        <span>Confirm deletion</span>
                    </button>
                </div>
            </div>
        </div>
    )

}
