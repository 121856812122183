import {UserInterface} from "../interfaces/UserInterface";

export const displayModuleByUserRole = (module: 'dashboardView' | 'dashboardEdit' | 'passportView' | 'passportEdit' | 'settings' | 'settingsBilling', user?: UserInterface) => {

    switch (module) {

        case('dashboardView'):
            return true
        case('dashboardEdit'):
            return true

        case('passportView'):
            return true

        case('passportEdit'):
            return user?.roleName === 'admin' || user?.roleName === 'super_admin'

        case('settings'):
            return user?.roleName === 'admin' || user?.roleName === 'super_admin'

        case('settingsBilling'):
            return user?.organisation?.pricingVisible && (user?.roleName === 'admin' || user?.roleName === 'super_admin')

    }

}