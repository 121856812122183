import {HowToCloud} from "../howTo/HowToCloud";

interface InputProps {
    howToCode?: string,
    label?: string,
    extraLabel?: string
    name?: string,
    required?: boolean,
    onChange: (value: File) => void,
    placeholder?: string,
    value?: File,
    currentPath?: string,
    modal?: string,
    loading?: boolean,
    defaultLabel?: boolean,
    accept: string,
    deleteAction?: any,
    visibleForPublic?: boolean,
    visibleAction?: any
}

const FormFile = (props: InputProps) => {


    return (
        <div className="c-form__group" style={{position: 'relative'}}>

            {(props.defaultLabel || props.howToCode) && <label style={{
                margin: '0 0 -2rem 0'
            }}>{props.label} {props.howToCode && <HowToCloud code={props.howToCode}/>}</label>}

            {!!props.extraLabel && !props.defaultLabel && <label style={{
                margin: '0 0 -2rem 0'
            }}>{props.extraLabel}</label>}

            {(props.visibleAction && props.currentPath) &&
                <button type={'button'} className={'c-visible-input'} onClick={() => props.visibleAction()}>
                    {!props.visibleForPublic &&
                        <svg fill="#000000" width="800px" height="800px" viewBox="0 0 1024 1024"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M844.877 118.781L567.185 396.47c-16.708-7.997-35.426-12.474-55.185-12.474-70.694 0-128 57.309-128 128 0 19.763 4.478 38.477 12.474 55.185l-270.69 270.69 60.34 60.339 158.277-158.276C395.54 757.159 452.19 767.996 512 767.996c115.823 0 219.797-40.64 294.839-89.6 37.559-24.499 69.001-51.81 91.575-78.532 20.809-24.627 40.252-55.979 40.252-87.868 0-31.885-19.443-63.241-40.252-87.868-22.575-26.722-54.016-54.031-91.575-78.533a547.946 547.946 0 00-42.859-25.24l141.235-141.233-60.339-60.34zM700.322 384.012c21.666 9.997 41.749 21.215 59.895 33.052 31.932 20.832 56.725 42.857 73.015 62.134 8.145 9.643 13.589 17.92 16.819 24.371 2.483 4.958 3.089 7.684 3.238 8.427-.149.742-.755 3.469-3.238 8.427-3.23 6.451-8.674 14.729-16.819 24.371-16.29 19.277-41.084 41.301-73.015 62.135-63.936 41.711-151.966 75.733-248.218 75.733-34.155 0-67.277-4.284-98.651-11.678l43.466-43.465c16.708 8 35.426 12.476 55.185 12.476 70.694 0 128-57.306 128-128 0-19.759-4.48-38.477-12.476-55.185l72.798-72.799zM263.783 606.929c1.53.998 3.074 1.993 4.631 2.978l-61.579 61.581c-33.009-22.669-60.776-47.386-81.251-71.625-20.809-24.627-40.251-55.979-40.251-87.868 0-31.885 19.443-63.241 40.251-87.868 22.576-26.722 54.016-54.031 91.574-78.533 75.044-48.957 179.017-89.598 294.841-89.598 34.641 0 68.22 3.635 100.284 10.041l-76.006 76.009A413.57 413.57 0 00512 341.33c-96.253 0-184.28 34.025-248.217 75.735-31.932 20.832-56.727 42.857-73.015 62.134-8.148 9.643-13.589 17.92-16.821 24.371-2.484 4.958-3.091 7.684-3.236 8.427.145.742.752 3.469 3.236 8.427 3.232 6.451 8.674 14.729 16.821 24.371 16.287 19.277 41.083 41.301 73.015 62.135z"/>
                        </svg>
                    }
                    {!!props.visibleForPublic &&
                        <svg fill="#000000" width="800px" height="800px" viewBox="0 0 1024 1024"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M170.711 512c.145.742.752 3.469 3.236 8.427 3.232 6.451 8.674 14.729 16.821 24.371 16.287 19.277 41.083 41.301 73.015 62.135C327.719 648.64 415.746 682.666 512 682.666c96.252 0 184.282-34.027 248.218-75.733 31.932-20.834 56.725-42.859 73.015-62.135 8.145-9.643 13.589-17.92 16.819-24.371 2.483-4.958 3.089-7.684 3.238-8.427-.149-.742-.755-3.469-3.238-8.427-3.23-6.451-8.674-14.729-16.819-24.371-16.29-19.277-41.084-41.301-73.015-62.134-63.936-41.71-151.966-75.735-248.218-75.735-96.253 0-184.28 34.025-248.217 75.735-31.932 20.832-56.727 42.857-73.015 62.134-8.148 9.643-13.589 17.92-16.821 24.371-2.484 4.958-3.091 7.684-3.236 8.427zm46.448-166.402C292.203 296.641 396.176 256 512 256s219.797 40.642 294.839 89.598c37.559 24.502 69.001 51.811 91.575 78.533 20.809 24.628 40.252 55.984 40.252 87.869s-19.443 63.241-40.252 87.868c-22.575 26.722-54.016 54.033-91.575 78.532C731.797 727.36 627.822 768 512 768s-219.797-40.64-294.841-89.6c-37.559-24.499-68.998-51.81-91.574-78.532-20.809-24.627-40.251-55.983-40.251-87.868s19.443-63.241 40.251-87.869c22.576-26.722 54.016-54.031 91.574-78.533z"/>
                            <path
                                d="M640 512c0 70.692-57.308 128-128 128s-128-57.308-128-128c0-70.692 57.308-128 128-128s128 57.308 128 128z"/>
                        </svg>
                    }
                </button>
            }

            {(props.deleteAction && props.currentPath) &&
                <button type={'button'} className={'c-delete-input'} onClick={() => props.deleteAction()}>x</button>
            }

            <label className={'inputfile'}>

                <div className={'content'}>

                    <input
                        accept={props.accept}
                        disabled={props.loading}
                        name={props.name}
                        required={props.required}
                        type={'file'}
                        onChange={(e) => {
                            if (e.target.files) {
                                props.onChange(e.target.files[0])
                            }
                        }}
                        placeholder={props.placeholder}
                    />

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                        <path
                            d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                    </svg>

                    <span>{props.label}</span>

                </div>

                {props.value ?
                    <small>{props.value.name}</small>
                    :
                    <>{props.currentPath &&
                        <small style={{fontSize: '1rem'}}><a
                            href={props.currentPath}>{props.currentPath}</a></small>}</>
                }

            </label>


        </div>

    )

}

export default FormFile
