import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setOrganisationData, addAddress, changeStep} from "../upgradeAccountSlice";
import {UpgradeAddress} from "../components/UpgradeAddress";

export const UpgradeStep1 = () => {

    const {organisation, addresses} = useAppSelector(state => state.upgradeAccount);
    const dispatch = useAppDispatch()

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    dispatch(changeStep(2))
                }}
                className="c-form">
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Company name<span>*</span></label>
                        <input
                            required={true}
                            value={organisation.companyName}
                            onChange={(e) => dispatch(setOrganisationData({companyName: e.target.value}))}
                            type="text"
                        />
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Chamber of commerce number<span>*</span></label>
                        <input
                            required={true}
                            value={organisation.cocNumber}
                            onChange={(e) => dispatch(setOrganisationData({cocNumber: parseInt(e.target.value)}))}
                            type="number"/>
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">VAT number<span>*</span></label>
                        <input
                            required={true}
                            value={organisation.vatNumber}
                            onChange={(e) => dispatch(setOrganisationData({vatNumber: e.target.value}))}
                            type="number"/>
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Website</label>
                        <input
                            value={organisation.website}
                            onChange={(e) => dispatch(setOrganisationData({website: e.target.value}))}
                            type="url"/>
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Invoice email<span>*</span></label>
                        <input
                            required={true}
                            value={organisation.invoiceEmail}
                            onChange={(e) => dispatch(setOrganisationData({invoiceEmail: e.target.value}))}
                            type="email"/>
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Phone<span>*</span></label>
                        <input
                            required={true}
                            value={organisation.phoneNumber}
                            onChange={(e) => dispatch(setOrganisationData({phoneNumber: e.target.value}))}
                            type="text"/>
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Purchase order nr. <span>(optional)</span></label>
                        <input
                            value={organisation.purchaseOrderNr}
                            onChange={(e) => dispatch(setOrganisationData({purchaseOrderNr: e.target.value}))}
                            type="text"/>
                    </div>
                </div>
                <div className="c-form__subject">
                    <h2>Contact person</h2>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Name<span>*</span></label>
                        <input required={true}
                               value={organisation.contactPersonName}
                               onChange={(e) => dispatch(setOrganisationData({contactPersonName: e.target.value}))}
                               type="text"/>
                    </div>
                </div>
                <div className="c-form__row">
                    <div className="c-form__group">
                        <label htmlFor="">Email<span>*</span></label>
                        <input required={true}
                               value={organisation.contactPersonEmail}
                               onChange={(e) => dispatch(setOrganisationData({contactPersonEmail: e.target.value}))}
                               type="email"/>
                    </div>
                </div>
                <div className="c-form__subject">
                    <h2>Address</h2>
                    <small>Atleast one billing address is required</small>
                </div>

                {addresses && addresses.map((address) => {
                    return <UpgradeAddress key={'address-' + address.createId} {...address} />
                })}

                <div
                    className="btn btn--text js-newAddress"
                    onClick={() => dispatch(addAddress())}
                >+ Add new address
                </div>
                <button className="btn btn--large btn--rounded btn--red">
                    <span>Step 2: C_passport<sup>®</sup> information</span>
                </button>

            </form>
        </>
    )

}