import {ApiEnum} from "../../../enums/ApiEnum";
import {OrganisationInfoInterface} from "../../../interfaces/OrganisationInfoInterface";
import {useAppAxios} from "../../../../app/hooks";

export const apiOrganisationInfoUpdate = async (organisation: OrganisationInfoInterface) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_INFO + '/update', {
        organisation: organisation
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
