import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useEffect} from "react";
import {load, setDashboardSettingsPublicData} from "../DashboardSettingsSlice";
import FormCheckbox from "../../../common/components/form/FormCheckbox";

export const DashboardSettingsPublicView = () => {

    const dispatch = useAppDispatch()
    const {dashboardPreference} = useAppSelector(state => state.dashboardSettings);

    useEffect(() => {
        dispatch(load())
    }, [dispatch])

    return (
        <div className="c-app__info-card">
            <div className="c-app__info-card__intro">
                <h2>C_dashboard<sup>®</sup> settings</h2>
                <p>Elements to display on a public dashboard</p>
            </div>
            <div className="c-form c-form--black">
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Impact'}
                        value={dashboardPreference.impactVisible}
                        onChange={(value) => {
                            dispatch(setDashboardSettingsPublicData({
                                impactVisible: value
                            }))
                        }}
                    />
                </div>

                <div className="c-form__row">
                    <FormCheckbox
                        label={'Passport composition'}
                        value={dashboardPreference.passportCompositionVisible}
                        onChange={(value) => {
                            dispatch(setDashboardSettingsPublicData({
                                passportCompositionVisible: value
                            }))
                        }}
                    />
                </div>

                <div className="c-form__row">
                    <FormCheckbox
                        label={'Logo'}
                        value={dashboardPreference.logoVisible}
                        onChange={(value) => {
                            dispatch(setDashboardSettingsPublicData({
                                logoVisible: value
                            }))
                        }}
                    />
                </div>

                <div className="c-form__row">
                    <FormCheckbox
                        label={'Materials visible'}
                        value={dashboardPreference.materialsVisible}
                        onChange={(value) => {
                            dispatch(setDashboardSettingsPublicData({
                                materialsVisible: value
                            }))
                        }}
                    />
                </div>

                <div className="c-form__row">
                    <FormCheckbox
                        label={'QR code'}
                        value={dashboardPreference.qrVisible}
                        onChange={(value) => {
                            dispatch(setDashboardSettingsPublicData({
                                qrVisible: value
                            }))
                        }}
                    />
                </div>

                <div className="c-form__row">
                    <FormCheckbox
                        label={'Total composition'}
                        value={dashboardPreference.totalCompositionVisible}
                        onChange={(value) => {
                            dispatch(setDashboardSettingsPublicData({
                                totalCompositionVisible: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Introduction'}
                        value={dashboardPreference.introductionVisible}
                        onChange={(value) => {
                            dispatch(setDashboardSettingsPublicData({
                                introductionVisible: value
                            }))
                        }}
                    />
                </div>

            </div>
        </div>
    )
}

