import {useAppAxios} from "../../../../app/hooks";
import {ApiEnum} from "../../../enums/ApiEnum";
import {UserInterface} from "../../../interfaces/UserInterface";

export const apiOrganisationUserCreate = async (user: UserInterface) => {
    return await useAppAxios.post(ApiEnum.ORGANISATION_USER + '/create', {user: user}).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}