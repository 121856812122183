import FormSelect from "../form/FormSelect";
import {useAppSelector} from "../../../app/hooks";
import {PassportInterface} from "../../interfaces/PassportInterface";
import {passportHasImpactFigureWithImpactFigureType} from "../../utils/passportHasImpactFigureWithImpactFigureType";
import {passportHasManualImpact} from "../../utils/passportHasManualImpact";

interface Props {
    passport?: PassportInterface,
    overruleEndUseId?: number,
    changeEndUseId?: (endUseId: number) => void,
    activeImpactFigureTypeId?: number | null
    extraOptions?: {
        value: number,
        label: string
    }[]
}

export const EndOfUseSelector = (props: Props) => {

    const {impactFigureCalculationTypes, endUses} = useAppSelector(state => state.root.options);

    const hasImpactFigureWithImpactFigureType = passportHasImpactFigureWithImpactFigureType(props.passport)
    const passportHasManualImpactFigure = passportHasManualImpact(props.activeImpactFigureTypeId, impactFigureCalculationTypes?.data)

    if (props.passport && (passportHasManualImpactFigure || !hasImpactFigureWithImpactFigureType)) {
        return null
    }

    const endUseOptions = endUses?.data?.map((endUseItem) => ({
        value: endUseItem.id,
        label: '100% ' + endUseItem.name
    }));

    if (props.extraOptions) {
        endUseOptions?.push(...props.extraOptions)
    }

    const activeOption = endUseOptions && endUseOptions.find((option) => props.overruleEndUseId && props.overruleEndUseId === option.value)

    return (
        <div className="c-form__select c-form__select--smaller" style={{minWidth: '18rem'}}>
            <FormSelect
                options={endUseOptions}
                value={activeOption ? activeOption.value : undefined}
                defaultValue={typeof activeOption !== 'undefined' ? 'Original end of use' : 'Overrule end of use'}
                onChange={(option) => {
                    props.changeEndUseId?.(Number(option));
                }}
            />
        </div>
    )
}