import {apiOrganisationDashboardDetail} from "../api/organisation/dashboard/apiOrganisationDashboardDetail";
import {DashboardPassportInterface} from "../interfaces/DashboardInterface";
import {PassportInterface} from "../interfaces/PassportInterface";
import {apiOrganisationPassportDetail} from "../api/organisation/passport/apiOrganisationPassportDetail";
import {apiOrganisationInfoDashboardPreference} from "../api/organisation/info/apiOrganisationInfoDashboardPreference";

export const LoadDashboardAction = async (uuid: string) => {

    const response = await apiOrganisationDashboardDetail({
        uuid: uuid
    })

    const connectedPassports = []
    response.data.items.dashboard.passports = [] as DashboardPassportInterface[]

    // Getting default passport info
    response.data.items?.passports.forEach((passport: PassportInterface) => {
        response.data.items.dashboard.passports.push({
            uuid: passport.uuid,
            amount: passport.amount + '',
            passportImpactFigureCalculationTypeId: passport.passportImpactFigureCalculationTypeId
        })
    })

    // Setting default simulated passports
    const defaultSimulatedPassportAmounts = response.data.items?.passports.map((passport: PassportInterface) => {
        return {
            uuid: passport.uuid,
            amount: Number(passport.amount),
            passportImpactFigureCalculationTypeId: passport.passportImpactFigureCalculationTypeId
        }
    })

    // Getting connected passport data
    for (const passport of response.data.items.dashboard.passports) {

        const passportData = await apiOrganisationPassportDetail({
            uuid: passport.uuid
        })

        if (passportData.data.items.data?.passport?.endOfUseSolution) {
            passportData.data.items.data.passport.endUseSolution = passportData.data.items.data.passport.endOfUseSolution.id
        }

        passportData.data.items.data.dashboardSettings = {
            activeTab: 'materials'
        }

        if (passportData && passportData.data.items) {
            connectedPassports.push(passportData.data.items.data)
        }
    }

    const preferenceResponse = await apiOrganisationInfoDashboardPreference(response?.data?.items?.dashboard?.organisationId)

    return {
        dashboardContainer: response.data.items,
        connectedPassports: connectedPassports,
        defaultSimulatedPassportAmounts: defaultSimulatedPassportAmounts,
        dashboardPreference: preferenceResponse.data.items.dashboardPreference
    }
}
