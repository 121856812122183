import {PassportInterface} from "../interfaces/PassportInterface";

interface Args {
    passport: PassportInterface,
    activeAssemblyPassports?: PassportInterface[],
    activeParentAssemblyPassports?: PassportInterface[]
}

export const passportIsSaveAble = (args: Args) => {


    if (!args.passport.name) {
        return {
            saveAble: false,
            message: 'First, enter a name in "Basic information (Step 2/6)" to save the passport'
        }
    }

    if(!args.passport.useAssemblyWeight && !args.passport.weight){
        return {
            saveAble: false,
            message: 'Enter weight in "Basic information (Step 2/6)" to save the passport'
        }
    }

    if(!args.passport.useAssemblyWeight && !args.passport.weightTypeId){
        return {
            saveAble: false,
            message: 'Enter weight unit in "Basic information (Step 2/6)" to save the passport'
        }
    }

    if ((args.passport.typeId === 1 || args.passport.typeId === 2) && args.activeParentAssemblyPassports) { // If component [1] or product [2]

        for (const parentAssemblyPassport of args.activeParentAssemblyPassports) {
            // check if parent assembly passport uses it's own materials

            if (!parentAssemblyPassport.useAssemblyMaterials) {

                return {
                    saveAble: false,
                    message: 'Passport "' + parentAssemblyPassport.name + '" uses its own material composition. Please remove that passport from the assembly before saving.'
                }
            }
        }
    }

    if (args.passport.typeId === 2 || args.passport.typeId === 3) {
        // If product [2] or end product [3]

        if (!args.passport.useAssemblyMaterials && args.activeAssemblyPassports?.length) {
            return {
                saveAble: false,
                message: 'The materials (and thus impact data) from the connected passports cannot be used. To change this please go back to step 3/6 and select the option ‘Use the materials selected from a lower assembly level (products and/or components)’.'
            }
        }
    }

    return {
        saveAble: true
    }
}