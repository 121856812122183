import {CompositionInterface, PassportInterface} from "../../interfaces/PassportInterface";
import {RouteEnum} from "../../enums/RouteEnum";
import {useQRCode} from "next-qrcode";
import {useEffect} from "react";
import {setQrBLob} from "../../../pages/passportDetailPage/PassportDetailSlice";
import {useAppDispatch} from "../../../app/hooks";
import {TranslationsInterface} from "../../interfaces/TranslationsInterface";
import {downloadQR} from "../../utils/downloadQR";

interface Props {
    passport: PassportInterface,
    composition: CompositionInterface[],
    translations?: TranslationsInterface
}

export const PublicPassportQrCode = (props: Props) => {

    const dispatch = useAppDispatch()

    const {inputRef} = useQRCode<HTMLCanvasElement>({
        text: window.location.origin + RouteEnum.PUBLIC_PASSPORT + '/' + props.passport.uuid,
        options: {
            level: 'M',
            margin: 0,
            scale: 1,
            width: 100,
            color: {
                dark: '#000',
            },
        },
    });

    useEffect(() => {
        if (inputRef.current) {
            const base64Data = inputRef.current.toDataURL()
            dispatch(setQrBLob(base64Data))
        }
    }, [inputRef, dispatch]);

    return (
        <div className="c-dashboard__card background-color-blocks c-passport__public">
            <canvas id={'qr-code'} ref={inputRef}/>
            <button onClick={() => downloadQR(props.passport.name + '')}
                    className="btn btn--small btn--rounded btn--red" style={{marginTop: '2rem'}}>
                <span>{props.translations?.downloadQrCode}</span>
            </button>
            <div
                onClick={() => navigator.clipboard.writeText(window.location.origin + RouteEnum.PUBLIC_PASSPORT + '/' + props.passport.uuid)}
                className="btn btn--text"><span
                className={'text-color-copy'}>{props.translations?.copyPublic} C_passport<sup>®</sup> {props.translations?.link}</span>
            </div>
        </div>
    )

}
