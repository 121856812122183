import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DashboardInterface} from "../../common/interfaces/DashboardInterface";
import {apiOrganisationDashboardDelete} from "../../common/api/organisation/dashboard/apiOrganisationDashboardDelete";


interface TrashDashboardModalInterface {
    dashboard?: DashboardInterface,
    submitting: boolean,
}


export const deleteDashboard = createAsyncThunk(
    'trashDashboardModal/deleteDashboard',
    async (_, {getState}) => {

        const {trashDashboardModal} = getState() as { trashDashboardModal: TrashDashboardModalInterface };

        if (trashDashboardModal.dashboard?.id) {
            const response = await apiOrganisationDashboardDelete(trashDashboardModal.dashboard.uuid + '')

            if (response && response.data && response.data.items && response.data.items.data) {
                return response.data.items
            }
        }
    }
)


const initialState: TrashDashboardModalInterface = {
    submitting: false
}

const trashDashboardModal = createSlice({
    name: 'trashDashboardModal',
    initialState,
    reducers: {
        setTrashDashboard: (state, action: PayloadAction<{
            dashboard?: DashboardInterface,
            showPopup: boolean
        }>) => {
            if (action.payload.showPopup) {
                state.dashboard = action.payload.dashboard
            } else {
                delete state.dashboard
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(deleteDashboard.pending, (state) => {
            state.submitting = true
        })
        builder.addCase(deleteDashboard.fulfilled, (state) => {
            delete state.dashboard
            state.submitting = false
        })
    },
})

export const {setTrashDashboard} = trashDashboardModal.actions

export const trashDashboardModalReducer = trashDashboardModal.reducer
