import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useEffect} from "react";
import {load, setPassportSettingsPublicData} from "../PassportSettingsSlice";
import FormCheckbox from "../../../common/components/form/FormCheckbox";

export const PassportSettingsPublicView = () => {

    const dispatch = useAppDispatch()
    const {passportPreference} = useAppSelector(state => state.passportSettings);

    useEffect(() => {
        dispatch(load())
    }, [dispatch])

    return (
        <div className="c-app__info-card">
            <div className="c-app__info-card__intro">
                <h2>C_passport<sup>®</sup> settings</h2>
                <p>Elements to display on a public passport</p>
            </div>
            <div className="c-form c-form--black">
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Water consumption'}
                        value={passportPreference.waterConsumption}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                waterConsumption: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Emission'}
                        value={passportPreference.emission}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                emission: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Emission price'}
                        value={passportPreference.emissionPrice}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                emissionPrice: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Energy consumption'}
                        value={passportPreference.energyConsumption}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                energyConsumption: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Raw material consumption'}
                        value={passportPreference.rawMaterialConsumption}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                rawMaterialConsumption: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'SROI consumption'}
                        value={passportPreference.sroiConsumption}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                sroiConsumption: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Sustainable development goals'}
                        value={passportPreference.sustainableDevelopmentGoals}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                sustainableDevelopmentGoals: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Logo'}
                        value={passportPreference.logo}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                logo: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Title & description'}
                        value={passportPreference.titleAndDescription}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                titleAndDescription: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Image'}
                        value={passportPreference.image}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                image: value
                            }))
                        }}
                    />
                </div>


                <div className="c-form__row">
                    <FormCheckbox
                        label={'Dominant material group'}
                        value={passportPreference.dominantMaterialGroups}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                dominantMaterialGroups: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'End of use treatment'}
                        value={passportPreference.endOfUseTreatment}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                endOfUseTreatment: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Quantity'}
                        value={passportPreference.quantity}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                quantity: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'MSDS'}
                        value={passportPreference.msds}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                msds: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Next use application'}
                        value={passportPreference.nextUseApplication}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                nextUseApplication: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Labels and certificates'}
                        value={passportPreference.labelsAndCertificates}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                labelsAndCertificates: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Encodings'}
                        value={passportPreference.encodings}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                encodings: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Material share diagram'}
                        value={passportPreference.materialShareDiagram}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                materialShareDiagram: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Materials visible'}
                        value={passportPreference.materialsVisible}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                materialsVisible: value
                            }))
                        }}
                    />
                </div>
                <div className="c-form__row">
                    <FormCheckbox
                        label={'Extra information'}
                        value={passportPreference.extraInformation}
                        onChange={(value) => {
                            dispatch(setPassportSettingsPublicData({
                                extraInformation: value
                            }))
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

