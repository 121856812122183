import {Link, useParams} from "react-router-dom";
import {RouteEnum} from "../../common/enums/RouteEnum";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import FormInput from "../../common/components/form/FormInput";
import {load, reset, setUserData, submit} from "./accountUsersCreateSlice";
import {ClipLoader} from "react-spinners";
import {useEffect} from "react";
import {UserRoles} from "../../common/enums/UserRoleEnum";

interface UserCreateParams {
    id: string;
}


export const AccountUsersCreatePageContainer = () => {

    const {user, submitting, submitStatus} = useAppSelector(state => state.userCreate);
    const dispatch = useAppDispatch()

    let {id} = useParams<UserCreateParams>();

    useEffect(() => {
            dispatch(reset())

            if (id) {
                dispatch(load(Number(id)))
            }

        }, [dispatch, id]
    );

    let saveText: string = id ? 'Update user' : 'Create user'


    return (
        <main className="c-app__content">

            {!submitStatus &&
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                    dispatch(submit())
                }}
            >
                <header className="c-app__content__header">
                    <nav className="c-breadcrumbs">
                        <ul className="u-reset-ul">
                            <li>
                                <Link to={RouteEnum.ACCOUNT_ORGANISATION}>Account</Link>
                                <img src="/images/icons/arrow-right-black.svg" alt=""/>
                            </li>
                            <li>
                                <Link to={RouteEnum.ACCOUNT_USERS}>Users</Link>
                                <img src="/images/icons/arrow-right-black.svg" alt=""/>
                            </li>
                            <li>
                                <Link to={RouteEnum.ACCOUNT_USERS_CREATE}>Create</Link>
                                <img src="/images/icons/arrow-right-black.svg" alt=""/>
                            </li>
                        </ul>
                    </nav>

                    <div>
                        <Link to={RouteEnum.ACCOUNT_USERS}
                              className="btn btn--small btn--text"><span>Cancel</span></Link>
                        <button
                            disabled={submitting} type={'submit'} style={{marginLeft: '1rem'}}
                            className="btn btn--small btn--rounded btn--black">

                            <span style={{display: 'flex', alignItems: 'center'}}>{saveText}
                                {submitting &&
                                <div style={{marginLeft: '1rem'}}>
                                    <ClipLoader size={12} color={'#ffffff'}/>
                                </div>
                                }
                            </span>


                        </button>
                    </div>
                </header>
                <div className="c-app__content__container">
                    <div className="c-app__view">
                        <div className="c-app__info-card">
                            <div className="c-app__info-card__intro">
                                <h2>Create new user</h2>
                                <p>
                                    Please fill in the required fields
                                </p>
                            </div>
                            <div className="c-form c-form--black">
                                <div className="c-form__row c-form__row--name">
                                    <FormInput
                                        required={true}
                                        value={user.firstName}
                                        onChange={(value) => dispatch(setUserData({firstName: value + ''}))}
                                        type={'text'}
                                        label={'First name'}
                                    />
                                    <FormInput
                                        value={user.insertion}
                                        onChange={(value) => dispatch(setUserData({insertion: value + ''}))}
                                        type={'text'}
                                        label={'Insertion'}

                                    />
                                    <FormInput
                                        required={true}
                                        value={user.lastName}
                                        onChange={(value) => dispatch(setUserData({lastName: value + ''}))}
                                        type={'text'}
                                        label={'Last name'}
                                    />
                                </div>
                                <div className="c-form__row">
                                    <FormInput
                                        required={true}
                                        value={user.email}
                                        onChange={(value) => dispatch(setUserData({email: value + ''}))}
                                        type={'email'}
                                        label={'Email address'}
                                    />
                                </div>

                                <div className={'c-form__row'}>

                                    <div className="c-form__group c-form__select">
                                        <label htmlFor="">Role</label>
                                        <select value={user.roleName}
                                                required={true}
                                                onChange={(e) => dispatch(setUserData({roleName: e.target.value}))}>

                                            <option value="" selected disabled>Select role</option>

                                            {Object.values(UserRoles).map((userRole) => {
                                                return (
                                                    <option key={'user-role-option:' + userRole.value} value={userRole.value}>{userRole.label}</option>
                                                )
                                            })}

                                        </select>
                                        <img src="images/icons/arrow-down-black.svg" alt=""/>
                                    </div>

                                </div>

                            </div>

                            {!id &&
                            <div className="c-message" style={{marginTop: '1rem'}}>
                                <header className="c-message__header">
                                    <img src="/images/icons/info-red.svg" alt="" />
                                    <h4>Information regarding billing</h4>
                                </header>
                                <p>Your monthly bill will be increased by €25,00 for creating a new user.</p>
                            </div>
                            }

                        </div>
                    </div>
                </div>
            </form>

            }

            {submitStatus === 400 &&
            <div className="c-app__content__container">
                <div className="c-app__view">
                    <div className="c-app__info-card">
                        <div className="c-app__info-card__intro" style={{marginBottom: 0}}>
                            <h2>This email already exists!</h2>
                            <p>
                                This email address already has an active user
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            }

            {submitStatus === 200 &&

            <main className="c-app__content">
                <header className="c-app__content__header">
                    <nav className="c-breadcrumbs">
                        <ul className="u-reset-ul">
                            <li>
                                <Link to={RouteEnum.ACCOUNT_ORGANISATION}>Account</Link>
                                <img src="/images/icons/arrow-right-black.svg" alt=""/>
                            </li>
                            <li>
                                <Link to={RouteEnum.ACCOUNT_USERS}>Users</Link>
                                <img src="/images/icons/arrow-right-black.svg" alt=""/>
                            </li>
                            <li>
                                <span>Create new user</span>
                                <img src="/images/icons/arrow-right-black.svg" alt=""/>
                            </li>
                        </ul>
                    </nav>
                    <div>
                        <Link to={RouteEnum.ACCOUNT_USERS}
                              className="btn btn--small btn--text"><span>Back to overview</span></Link>
                    </div>
                </header>
                <div className="c-app__content__container">
                    <div className="c-app__view">


                        {id ?
                            <div className="c-app__info-card">
                                <div className="c-app__info-card__intro" style={{marginBottom: 0}}>
                                    <h2>User updated!</h2>
                                    <p>
                                        The data of the user has been updated.
                                    </p>
                                </div>
                            </div>
                            :
                            <div className="c-app__info-card">
                                <div className="c-app__info-card__intro" style={{marginBottom: 0}}>
                                    <h2>New user created!</h2>
                                    <p>
                                        A new user was created for {user.email}
                                    </p>
                                    <p>
                                        Newly created users will receive an email to activate their account and setup
                                        their
                                        password.
                                    </p>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </main>

            }

        </main>
    )
}

