import {ApiEnum} from "../../enums/ApiEnum";
import {useAppAxios} from "../../../app/hooks";

export const apiImpactCalculationTypeList = async () => {
    return await useAppAxios.post(ApiEnum.PASSPORT_IMPACT_FIGURE_CALCULATION_TYPES + '/list', {})
        .then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}

